import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import DTable from "../../../components/DTable";
import filterIcon from "../../../assets/images/sliders.png";
import userImg from "../../../assets/images/figure.png";
import AddIcon from "@mui/icons-material/Add";
import { Image, TextInput } from "react-native-web";
import AddAdminForm from "./New";
import Profile from "./Profile";
import moment from "moment";

// Import tools
import Search from "../Tools/Search";
import { HeaderTab, TabItems, TabItem, Filter } from "../Tools/Tab";
import Title from "../Tools/Title";
import UserFigure from "../Tools/UserFigure";
import InfoBull from "../Tools/InfoBull";
import adminsService from "../../../store/apis/adminsApis";
import Delete from "./Delete";
import Congrats from "../congrats/Congrats";
import RemoveCongrat from "../congrats/RemoveCongrats";
import { useSelector } from "react-redux";
import { limitPagination } from "../../../config/keys";

const HEAD = [
  {
    label: "Profils",
    width: 18,
  },
  {
    label: "Type de Profil  ",
    width: 24,
  },
  /* {
    label: "Dernière activité",
    width: 20,
  }, */
  {
    label: "Email",
    width: 23,
  },
  {
    label: "Tel",
    width: 18,
  },
  {
    label: false,
    width: 15,
  },
  {
    label: false,
    width: 2,
  },
];

export default function Admins() {
  const { user } = useSelector((state) => state.userAuth);
  const [activePage, setActivePage] = useState("list");
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const [error, setError] = useState([]);
  const [currentProfile, setCurrentProfile] = useState(false);
  const [admns, setAdmns] = useState(false);
  const [load, setLoad] = useState(true);
  const [add, setAdd] = useState(false);
  const [remove, setRemove] = useState(false);
  const [edit, setEdit] = useState(false);
  const [congrat, setCongrat] = useState(false);
  const [removeCongrat, setRemoveCongrat] = useState(false);
  const [id, setId] = useState(null);

  const [page, setPage] = useState(1);
  const [showPagination, setShowPagination] = useState(false);

  const handleShowProfile = (id) => {
    setCurrentProfile(id);
    setActivePage("detail");
  };

  useEffect(() => {
    loadAdmin(page);
  }, [page]);

  const loadAdmin = (page) => {
    setShowPagination(false);
    setLoad(true);
    let Admns = {
      head: HEAD,
      body: [],
    };
    adminsService
      .listAdmins(page)
      .then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            const El = res[i];
            if (user?.data?.role === "ROLE_SUPER_ADMIN" || user?.user?.data?.role === "ROLE_SUPER_ADMIN") {
              var row = [
                {
                  type: false,
                  text: (
                    <UserFigure
                      name={El.prenom + " " + El.nom}
                      Address={El.adresse}
                      image={""}
                    />
                  ),
                  link: "",
                },
                /* {
                type: "text",
                text: (
                  <InfoBull
                    datetime={
                      moment.parseZone(El.date_inscription).format("DD/MM/YYYY") +
                      " " +
                      moment.parseZone(El.date_inscription).format("HH:mm")
                    }
                    linktext="Voir tous les activités"
                    infobulltext="Ajout client"
                  />
                ),
              }, */
                {
                  type: "text",
                  text: El.roles[0],
                },
                {
                  type: "text",
                  text: El.email,
                },
                {
                  type: "text",
                  text: El.telephone,
                },
                {
                  type: "more",
                  text: "Voir profil",
                  onClick: () => handleShowProfile(El.id),
                },
                {
                  type: "menu",
                  text: "",
                  items: [
                    {
                      label: "Modifier",
                      callback: () => {
                        setId(El.id);
                        setEdit(true);
                      },
                    },
                    {
                      label: "Supprimer",
                      callback: () => {
                        setId(El.id);
                        setRemove(true);
                      },
                    },
                  ],
                },
              ];
            } else {
              var row = [
                {
                  type: false,
                  text: (
                    <UserFigure
                      name={El.prenom + " " + El.nom}
                      Address={El.adresse}
                      image={""}
                    />
                  ),
                  link: "",
                },
                /* {
                  type: "text",
                  text: (
                    <InfoBull
                      datetime={
                        moment.parseZone(El.date_inscription).format("DD/MM/YYYY") +
                        " " +
                        moment.parseZone(El.date_inscription).format("HH:mm")
                      }
                      linktext="Voir tous les activités"
                      infobulltext="Ajout client"
                    />
                  ),
                }, */
                {
                  type: "text",
                  text: El.roles[0],
                },
                {
                  type: "text",
                  text: El.email,
                },
                {
                  type: "text",
                  text: El.telephone,
                },
                {
                  type: "more",
                  text: "Voir profil",
                  onClick: () => handleShowProfile(El.id),
                },
                {
                  type: "menu",
                  text: "",
                  items: [
                    {
                      label: "Modifier",
                      callback: () => {
                        setId(El.id);
                        setEdit(true);
                      },
                    },
                  ],
                },
              ];
            }

            page > 1
              ? admns.body.push({ icon: "", columns: row })
              : Admns.body.push({ icon: "", columns: row });
          }
          page > 1 ? setAdmns(admns) : setAdmns(Admns);

          setTimeout(() => {
            setLoad(false);
            res.length === limitPagination
              ? setShowPagination(true)
              : setShowPagination(false);
          }, 500);
        }
      })
      .catch((e) => {
        setLoad(false);
        console.log(error);
      });
  };

  return (
    <Box>
      {(() => {
        switch (activePage) {
          case "detail":
            return (
              <Profile
                goBack={() => setActivePage("list")}
                setEdit={setEdit}
                setRemove={setRemove}
                id={currentProfile}
              />
            );

          case "list":
            return (
              <>
                <Box>
                  {/* {load ? (
                  <Box
                    height={"30vh"}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <> */}
                  <HeaderTab className="header-tab">
                    <Title onClick={() => setAdd(true)}>
                      <AddIcon sx={{ fontSize: 34, verticalAlign: "bottom" }} />{" "}
                      Ajouter un admin
                    </Title>
                    {/* <Filter>
                      <Box flex="1"></Box>
                      <Search className={"searchinput"}>
                        <TextInput type="search" placeholder="Recherche" />
                      </Search>
                      <Box className="filterIcon">
                        <Image
                          source={filterIcon}
                          style={{ width: 30, height: 30 }}
                        />
                      </Box>
                    </Filter> */}
                  </HeaderTab>

                  <DTable
                    load={load}
                    showPagination={showPagination}
                    setPage={setPage}
                    page={page}
                    keyprop="admins"
                    key="admins"
                    data={admns}
                  />

                  {/*   </>
                )} */}
                </Box>
              </>
            );

          case "Restore_Password":
            return <RestorePassword handleDialog={handleDialog} />;
        }
      })()}
      {add && (
        <AddAdminForm
          dialog={add}
          onClose={() => {
            setAdd(false);
            loadAdmin();
          }}
          setCongrat={setCongrat}
        />
      )}
      {remove && (
        <Delete
          dialog={remove}
          id={currentProfile || id}
          onClose={() => setRemove(false)}
          setRemoveCongart={setRemoveCongrat}
          goBack={() => setActivePage("list")}
        />
      )}
      {congrat && (
        <Congrats
          congrat={congrat}
          onClose={() => setCongrat(false)}
          type={"administrateur"}
        />
      )}
      {removeCongrat && (
        <RemoveCongrat
          removeCongrat={removeCongrat}
          onClose={() => {
            setRemoveCongrat(false);
            loadAdmin();
          }}
        />
      )}
    </Box>
  );
}
