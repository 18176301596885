import { styled } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, FormControl } from "@mui/material";
import { useRoute } from "@react-navigation/native";
import theme from "../../assets/theme";

const Input = styled("input", {
  shouldForwardProp: (prop) => prop !== "route",
})(({ theme, route }) => ({
  width: "100%",
  textAlign: "center",
  outline: "none",
  fontSize: theme.spacing(1.8),
  height: theme.spacing(6),
  padding: theme.spacing(1, 2),
  border: "2px solid transparent",
  backgroundColor:
    route !== "home" ? "rgba(62, 195, 236, 0.02)" : "transparent",

  backgroundImage:
    route !== "home"
      ? "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)"
      : "none",
  borderImageSlice: 1,
  borderImageSource:
    "linear-gradient(360deg, #3EC3EC -36%, rgba(62, 195, 236, 0) 76%);",

  "&:focus, &:hover": {
    backgroundColor: "rgba(62, 195, 236, 0.08)",
    border: `2px dashed ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: theme.spacing(2.2),
  },
}));

export default function PasswordInput({
  visible,
  handleVisible,
  placeholder,
  register,
}) {
  const route = useRoute();

  return (
    <FormControl sx={{
      position: "relative",
      width: "100%",
      "&:focus, &:hover": {
        "& div": {
          display: "flex"
        }
      }
    }}>
      <Input
        route={route.name}
        type={visible ? "text" : "password"}
        placeholder={placeholder ? placeholder.toUpperCase() : "PASSWORD*"}
        {...register}
      />
      <Box
        component={"span"}
        position={"absolute"}
        top={"11%"}
        right={"2%"}
        onClick={handleVisible}
      >
        {visible ? <Visibility /> : <VisibilityOff />}
      </Box>
      <Box sx={{
        display: 'none',
        width: 15,
        height: 15,
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        position: 'absolute',
        top: 0,
        left: 0,
      }}/>
      <Box sx={{
        display: 'none',
        width: 15,
        height: 15,
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderRight: `4px solid ${theme.palette.primary.main}`,
        position: 'absolute',
        top: 0,
        right: 0,
      }}/>
      <Box sx={{
        display: 'none',
        width: 15,
        height: 15,
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        position: 'absolute',
        bottom: 0,
        left: 0,
      }}/>
      <Box sx={{
        display: 'none',
        width: 15,
        height: 15,
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        borderRight: `4px solid ${theme.palette.primary.main}`,
        position: 'absolute',
        bottom: 0,
        right: 0,
      }}/>
    </FormControl>
  );
}
