import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { STRIPE_KEY, STRIPE_URL } from "../../../../config/keys";
import { Box, CircularProgress } from "@mui/material";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
// Stripe publish key
const stripePromise = loadStripe(STRIPE_KEY);

export default function StripeApp({
  userId,
  offreId,
  offrePrice,
  onSuccess,
  onClose,
}) {
  console.log(offrePrice);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Créer PaymentIntent dès que la page se charge
    fetch(`${STRIPE_URL}` + "create_payment_intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_offre: offreId,
        id_client: userId,
        prix: offrePrice,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (clientSecret === "") setClientSecret(data.clientSecret);
        if (paymentIntentId === "") setPaymentIntentId(data.id);
      });
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && paymentIntentId ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            paymentIntentId={paymentIntentId}
            userId={userId}
            offrePrice={offrePrice}
            offreId={offreId}
            onSuccess={onSuccess}
            onClose={onClose}
          />
        </Elements>
      ) : (
        <Box textAlign={"center"} marginTop={"30px"}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
