import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import DTable from "../../../components/DTable";
import searchIcon from "../../../assets/images/search.png";
import filterIcon from "../../../assets/images/sliders.png";
import userImg from "../../../assets/images/figure.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import { fontSize, styled } from "@mui/system";
import { Image, TextInput } from "react-native-web";
import classNames from "classnames";
import inpBorder from "../../../assets/images/searchbar.png";

// Import tools
import Search from "../Tools/Search";
import { HeaderTab, TabItems, TabItem, Filter } from "../Tools/Tab";
import Title from "../Tools/Title";
import UserFigure from "../Tools/UserFigure";
import InfoBull from "../Tools/InfoBull";
import { Add } from "@mui/icons-material";
import partnersService from "../../../store/apis/partnersApis";
import Congrats from "../congrats/Congrats";
import RemoveCongrat from "../congrats/RemoveCongrats";
import EditCongrat from "../congrats/EditCongrat";
import ServicesService from "../../../store/apis/ServicesApi";
import EditServiceForm from "../Quotes/EditService";

const HEAD = [
  {
    hasDropDown: true,
    label: "Service",
    width: 30,
  },
  {
    label: "Description",
    width: 30,
  },
  {
    label: "Prix",
    width: 20,
  },
  {
    label: false,
    width: 15,
  },
];

export default function Params() {
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const [dialog, setDialog] = useState(false);
  const [activePage, setActivePage] = useState("list");
  const [currentService, setCurrentService] = useState(false);
  const [allServices, setAllServices] = useState();
  const [addedRecently, setAddedRecently] = useState(false);
  const [suspended, setSuspended] = useState(false);
  const [page, setPage] = useState(1);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editedProfil, setEditedProfil] = useState({});
  const [remove, setRemove] = useState(false);
  const [congrat, setCongrat] = useState(false);
  const [removeCongrat, setRemoveCongrat] = useState(false);
  const [editCongrat, setEditCongrat] = useState(false);
  const [load, setLoad] = useState(false);
  const [id, setId] = useState(null);

  const handleShowService = (service) => {
    setCurrentService(service);
    setEdit(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoad(true);
    let AllServices = {
      head: HEAD,
      body: [],
    };
    ServicesService
      .listServices(page)
      .then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            const El = res[i];
            var row = [
              {
                type: "text",
                text: El.libelle,
                link: "",
              },
              {
                type: "text",
                text: El.description,
                link: "",
              },
              {
                type: "text",
                text: El.price,
              },
              {
                type: "more",
                text: "Modifier",
                onClick: () => handleShowService(El),
              }
            ];

            AllServices.body.push({ columns: row });
          }

          setAllServices(AllServices);
          setTimeout(() => {
            setLoad(false);
          }, 500);
        }
      })
      .catch((e) => {
        setLoad(false);
        console.log(error);
      });
  };

  return (
    <Box>
      {(() => {
        switch (activePage) {
          case "list":
            return (
              <>
                {load ? (
                  <Box
                    height={"30vh"}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <HeaderTab className="header-tab">
                      <Filter>
                        <TabItems>
                          <TabItem
                            className={classNames({ hovered: currentTab == 1 })}
                            onClick={() => setCurrentTab(1)}
                          >
                            Liste des services
                          </TabItem>
                          {/* <TabItem
                            className={classNames({ hovered: currentTab == 2 })}
                            onClick={() => setCurrentTab(2)}
                          >
                            Ajouts récents
                          </TabItem>
                          <TabItem
                            className={classNames({ hovered: currentTab == 3 })}
                            onClick={() => setCurrentTab(3)}
                          >
                            Partenaires suspendus
                          </TabItem> */}
                        </TabItems>
                        {/* <Search className={"searchinput"}>
                          <TextInput type="search" placeholder="Recherche" />
                        </Search>
                        <Box className="filterIcon">
                          <Image
                            source={filterIcon}
                            style={{ width: 30, height: 30 }}
                          />
                        </Box> */}
                      </Filter>
                    </HeaderTab>
                    <Title
                      onClick={() => setAdd(true)}
                      style={{ marginBottom: 20, marginTop: 20 }}
                    >
                      <Add sx={{ fontSize: 34, verticalAlign: "bottom" }} />{" "}
                      ajouter un service
                    </Title>
                    {allServices && <DTable data={allServices} keyprop="allServices" />}
                  </>
                )}
              </>
            );
        }
      })()}


      {edit && (
        <EditServiceForm
          dialog={edit}
          onClose={() => { setEdit(false); fetchData(); }}
          subServices={currentService}
          setSubServices={null}
        />
      )}


    </Box>
  );
}
