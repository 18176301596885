import { forwardRef, useState } from "react";
import {
  Box,
  Container,
  Dialog,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import signinBg from "../../../../assets/images/signin_bg.png";
import { styled } from "@mui/system";
import { useNavigation } from "@react-navigation/native";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useTheme } from "@emotion/react";
import { RegisterHeading } from "../../../../components/headings";
import { EmailInput, PasswordInput } from "../../../../components/inputs";

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
  height: "100%",
  backgroundColor: "#fff",
  backgroundImage: `url(${signinBg})`,
  backgroundSize: "100%",
  backgroundPosition: "bottom center",
  padding: theme.spacing(4, 1),
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("md")]: {
    backgroundImage: "none",
    padding: theme.spacing(2, 0),
  },
}));

export default function ForgotPassword({ dialog, handleDialog }) {
  const navigation = useNavigation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const handleVisible1 = () => {
    setVisible1(!visible1);
  };
  const handleVisible2 = () => {
    setVisible2(!visible2);
  };
  return (
    <>
      <BoxContainer>
        <Container maxWidth={isMd ? "sm" : "xs"}>
          <Box display={"flex"} flexDirection={{ xs: "column" }}>
            <Box component={"span"} alignSelf={{ xs: "center", md: "end" }}>
              <IconButton
                aria-label="close"
                size="large"
                onClick={handleDialog}
              >
                <CloseRounded fontSize="large" />
              </IconButton>
            </Box>
            <Box mb={2} mt={{ md: -5 }}>
              <RegisterHeading>
                Réinitialisation de mot de passe
              </RegisterHeading>
            </Box>
          </Box>
          <Box paddingLeft={{ md: 5 }} paddingRight={{ md: 5 }}>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <PasswordInput
                placeholder={"nouveau mot de passe*"}
                visible={visible1}
                handleVisible={handleVisible1}
              />
              <PasswordInput
                placeholder={"confirmer le nouveau mot de passe*"}
                visible={visible2}
                handleVisible={handleVisible2}
              />
            </Box>

            <Box
              display={"flex"}
              mb={4}
              mt={6}
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
            >
              <CustomButton type={"red"}>annuler</CustomButton>
              <CustomButton>Se connecter</CustomButton>
            </Box>
          </Box>
        </Container>
      </BoxContainer>
    </>
  );
}
