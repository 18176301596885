import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Button = styled(Box, {
  shouldForwardProp: (prop) => prop !== "type",
  name: "Button",
})(({ theme, type }) => ({
  position: 'relative',
  border: "none",
  padding: theme.spacing(1.8, 0),
  width: theme.spacing(20.6),
  cursor: "pointer",
  backgroundColor:
    type === "yellow" ? "rgba(236, 221, 59, 0.71)" : "transparent",
  backgroundImage:
    type === "yellow"
      ? "none"
      : type === "red"
      ? "linear-gradient(176.86deg, rgba(214, 22, 68, 0) -1.22%, rgba(214, 22, 68, 0.8) 73.33%)"
      : "linear-gradient(0deg, rgba(3, 235, 255, 0.9) -28%, rgba(3, 235, 255, 0.0935268) 114%)",
  clipPath:
    "polygon(15px 0, calc(100% - 15px) 0, 100% 15px, 100% calc(100% - 15px),calc(100% - 15px) 100%, 15px 100%, 0 calc(100% - 15px), 0 15px)",

  [theme.breakpoints.up("md")]: {
    width: theme.spacing(28.3),
    padding: theme.spacing(2.5, 0),
  },
}));

export default function CustomButton({
  children,
  type,
  handleClick,
  textColor,
}) {
  return (
    <Button type={type} onClick={handleClick} component={"button"}>
      <Typography
        fontSize={{ xs: 14, md: 18 }}
        fontWeight={{ xs: 700 }}
        textTransform={"uppercase"}
        textAlign={"center"}
        color={textColor ? textColor : "default"}
      >
        {children}
      </Typography>
    </Button>
  );
}
