import { Box, styled } from "@mui/material";
import { WhyCard, SectionsHeading } from "../../../../components";
import clock from "../../../../assets/images/icons/clock.png";
import gem from "../../../../assets/images/icons/gem.png";
import team from "../../../../assets/images/icons/team.png";
import piggy from "../../../../assets/images/icons/piggy.png";
import reviews from "../../../../assets/images/icons/reviews.png";
import shakingHands from "../../../../assets/images/icons/shaking_hands.png";
import miniClock from "../../../../assets/images/icons/mini_clock.png";
import miniGem from "../../../../assets/images/icons/mini_gem.png";
import miniTeam from "../../../../assets/images/icons/mini_team.png";
import miniPiggy from "../../../../assets/images/icons/mini_piggy.png";
import miniReviews from "../../../../assets/images/icons/mini_reviews.png";
import miniShakingHands from "../../../../assets/images/icons/mini_shaking_hands.png";

import verticalLineLg from "../../../../assets/images/lines/vertical_line_lg.png";
import horizontalLine from "../../../../assets/images/lines/horizontal_line.png";
import { Container } from "@mui/material";
import theme from "../../../../assets/theme";

const arr = [
  /* {
    icon: clock,
    miniIcon: miniClock,
    heading: "gain de temps",
    description: "Choisissez la date et l’heure de passage du technicien"
  }, */
  {
    icon: shakingHands,
    miniIcon: miniShakingHands,
    heading: "Accompagnement sur mesure",
    description:
      "Un chargé d’affaire est dédié à chaque client. Un diagnostic précis de votre problème et de sa solution est expliqué par un de nos opérateurs. Un contrôle qualité est réalisé après chaque intervention.",
  },
  {
    icon: piggy,
    miniIcon: miniPiggy,
    heading: "Une tarification claire",
    description:
      "Tous nos prix sont clairement annoncés et toutes nos interventions sont réalisées avec l’acceptation préalable d’un devis.",
  },
  /* {
    icon: gem,
    miniIcon: miniGem,
    heading: "Qualité prix",
    description: "Nos techniciens sont des professionnels expérimentés et qualifiés"
  }, */
  {
    icon: team,
    miniIcon: miniTeam,
    heading: "équipe à l’écoute",
    description:
      "Nous desservons plusieurs régions et sommes disponibles pour répondre à vos besoins urgents 24/7.",
  },
  {
    icon: reviews,
    miniIcon: miniReviews,
    heading: "Nos garanties",
    description:
      "Toutes nos interventions sont couvertes par nos assurances responsabilités civile et décennale. Notre services après-vente est disponible à tout moment par mail et/ou par téléphone (09h00 - 17h00).",
  },
];

const FlexContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
  flexWrap: "wrap",
  backgroundImage: `url(${verticalLineLg}), url(${horizontalLine})`,
  backgroundPosition: "center center, 50% center",
  backgroundSize: " auto, auto 100%, auto 100%",
  backgroundRepeat: " no-repeat",
  [theme.breakpoints.down("md")]: {
    backgroundImage: "none",
    justifyContent: "center",
  },
}));

export default function WhySection() {
  return (
    <Box
      component={"div"}
      id="sec3"
      sx={{
        scrollMarginTop: 150,
        backgroundColor: theme.palette.background.main,
      }}
    >
      <Container maxWidth="lg">
        <SectionsHeading>pourquoi fibme ?</SectionsHeading>
        <FlexContainer>
          {arr.map((x) => (
            <WhyCard
              key={x.heading}
              img={x.icon}
              miniImg={x.miniIcon}
              heading={x.heading}
              description={x.description}
            />
          ))}
        </FlexContainer>
      </Container>
    </Box>
  );
}
