import axios from "axios";
import authHeader from "./authHeader";
import { base_url_api } from "../../config/keys";

const getCalendar = async (date) => {
  try {
    const response = await axios.get(
      `${base_url_api}/calendar/occuped/${date}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const addCalendar = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/calendars`, data, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCalendarList = async (type) => {
  try {
    const response = await axios.get(`${base_url_api}/calendars?type=${type}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

const getCalendarListByMounth = async (date) => {
  try {
    const response = await axios.get(
      `${base_url_api}/calendars?dateDebut=${date}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const getCalendarListPartner = async (date, partnerId) => {
  try {
    const response = await axios.get(
      `${base_url_api}/calendars?dateDebut=${date}&type=rdv_prestation&user.demandesClient.prestataire=${partnerId}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const editCalendar = async (id, data) => {
  try {
    const response = await axios.put(
      `${base_url_api}/calendars/${id}`,
      {
        ...data,
      },
      { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const CalendarService = {
  getCalendar,
  addCalendar,
  getCalendarList,
  editCalendar,
  getCalendarListByMounth,
  getCalendarListPartner,
};

export default CalendarService;
