import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DetailPresta = ({ dialog, onClose, demande }) => {
  console.log(demande?.detail);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 600, maxWidth: { md: 600, lg: 600 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Détails
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItem={"center"}
          justifyContent={"space-between"}
          rowGap={2}
          flexWrap={"wrap"}
        >
          <Box flexBasis={{ xs: "100%", md: "100%" }}>
            <Box mt={0.75} mb={3} sx={{ float: "left" }}>
              <Typography
                component={"p"}
                variant={"body2"}
                fontSize={{ lg: 18 }}
              >
                {demande?.detail}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default DetailPresta;
