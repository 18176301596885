import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import DTable from "../../../components/DTable";
import searchIcon from "../../../assets/images/search.png";
import filterIcon from "../../../assets/images/sliders.png";
import userImg from "../../../assets/images/figure.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import { fontSize, styled } from "@mui/system";
import { Image, TextInput } from "react-native-web";
import classNames from "classnames";
import inpBorder from "../../../assets/images/searchbar.png";
import AddClient from "./New";
import EditClient from "./Edit";
import Profile from "./Profile";
import moment from "moment";

// Import tools
import Search from "../Tools/Search";
import { HeaderTab, TabItems, TabItem, Filter } from "../Tools/Tab";
import Title from "../Tools/Title";
import UserFigure from "../Tools/UserFigure";
import InfoBull from "../Tools/InfoBull";
import { Add } from "@mui/icons-material";
import { FBMHttp } from "../../../config/Services";
import clientsService from "../../../store/apis/clientsApis";
import Congrats from "../congrats/Congrats";
import RemoveClient from "./Remove";
import RemoveCongrat from "../congrats/RemoveCongrats";
import EditCongrat from "../congrats/EditCongrat";
import { useSelector } from "react-redux";
import { limitPagination } from "../../../config/keys";

const HEAD = [
  {
    label: "Client",
    width: 42,
  },
  /*   {
    label: "Phase du processus",
    width: 20,
  }, */
  {
    label: "Type de client  ",
    width: 23,
  },
  {
    label: "Technicien",
    width: 18,
  },
  {
    label: false,
    width: 12,
  },
  {
    label: false,
    width: 3,
  },
];

export default function Customer() {
  const { user } = useSelector((state) => state.userAuth);
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const [dialog, setDialog] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(false);
  const [allCustomers, setAllCustomers] = useState(false);
  const [notPaid, setNotPaid] = useState(false);
  const [urgent, setUrgent] = useState(false);
  const [error, setError] = useState(null);
  const [congrat, setCongrat] = useState(false);
  const [edit, setEdit] = useState(false);
  const [remove, setRemove] = useState(false);
  const [editCongrat, setEditCongrat] = useState(false);
  const [removeCongrat, setRemoveCongrat] = useState(false);
  const [id, setId] = useState(null);
  const [load, setLoad] = useState(true);

  const [page, setPage] = useState(1);
  const [showPagination, setShowPagination] = useState(false);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    setShowPagination(false);
    setLoad(true);
    let Allcustomers = {
      head: HEAD,
      body: [],
    };
    let Urgent = {
      head: HEAD,
      body: [],
    };

    clientsService
      .listClients(page)
      .then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            const El = res[i];

            if (user?.data?.role === "ROLE_SUPER_ADMIN" || user?.user?.data?.role === "ROLE_SUPER_ADMIN") {
              var row = [
                {
                  type: false,
                  text: (
                    <UserFigure
                      name={El.prenom + " " + El.nom}
                      Address={El.adresse}
                      /* image={userImg} */
                    />
                  ),
                  link: "",
                },
                /* {
                  type: "text",
                  text: (
                    <>
                      Étape {El.demandes_client[0]?.etape}{" "}
                      {/* <Box>
                        {moment.parseZone(El.calendar[0]?.date_debut).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        à {moment.parseZone(El.calendar[0]?.date_debut).format("HH:mm")}
                      </Box> 
                    </>
                  ),
                }, */
                {
                  type: "text",
                  text: El.type_client,
                },
                {
                  type: "text",
                  text: El.niveau_experience,
                },
                {
                  type: "more",
                  text: "Voir profil",
                  onClick: () => setCurrentProfile(El.id),
                },
                {
                  type: "menu",
                  text: "",
                  items: [
                    {
                      label: El.telephone,
                      callback: false,
                    },
                    {
                      label: El.email,
                      callback: false,
                    },
                    {
                      label: "Modifier",
                      callback: () => {
                        setId(El.id);
                        setEdit(true);
                      },
                    },
                    {
                      label: "Supprimer",
                      callback: () => {
                        setId(El.id);
                        setRemove(true);
                      },
                    },
                  ],
                },
              ];
            } else {
              var row = [
                {
                  type: false,
                  text: (
                    <UserFigure
                      name={El.prenom + " " + El.nom}
                      Address={El.adresse}
                      /* image={userImg} */
                    />
                  ),
                  link: "",
                },
                /* {
                  type: "text",
                  text: (
                    <>
                      Étape {El.demandes_client[0]?.etape}{" "}
                      <Box>
                        {moment.parseZone(El.calendar[0]?.date_debut).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        à {moment.parseZone(El.calendar[0]?.date_debut).format("HH:mm")}
                      </Box>
                    </>
                  ),
                }, */
                {
                  type: "text",
                  text: El.type_client,
                },
                {
                  type: "text",
                  text: El.niveau_experience,
                },
                {
                  type: "more",
                  text: "Voir profil",
                  onClick: () => setCurrentProfile(El.id),
                },
                {
                  type: "menu",
                  text: "",
                  items: [
                    {
                      label: El.telephone,
                      callback: false,
                    },
                    {
                      label: El.email,
                      callback: false,
                    },
                    {
                      label: "Modifier",
                      callback: () => {
                        setId(El.id);
                        setEdit(true);
                      },
                    },
                  ],
                },
              ];
            }

            if (El.urgent) {
              page > 1
                ? urgent.body.push({ icon: "danger", columns: row })
                : Urgent.body.push({ icon: "danger", columns: row });
            } else {
              page > 1
                ? allCustomers.body.push({ icon: "", columns: row })
                : Allcustomers.body.push({ icon: "", columns: row });
            }
          }

          //setAllCustomers(Allcustomers);
          //setUrgent(Urgent);

          page > 1
            ? setAllCustomers(allCustomers)
            : setAllCustomers(Allcustomers);

          setTimeout(() => {
            setLoad(false);
            res.length === limitPagination
              ? setShowPagination(true)
              : setShowPagination(false);
          }, 500);
        }
      })
      .catch((e) => {
        setLoad(false);
        setError(e);
      });
  };

  const FormatDate = (dte) => {
    dte = new Date(dte);

    return (
      dte.getDay() +
      " / " +
      dte.getMonth() +
      " / " +
      dte.getFullYear() +
      " " +
      dte.getHours() +
      "h" +
      dte.getMinutes()
    );
  };
  return (
    <Box>
      {currentProfile && (
        <Profile
          goBack={() => setCurrentProfile(false)}
          id={currentProfile}
          onEditClose={() => setEdit(false)}
          edit={edit}
          setEditCongrat={setEditCongrat}
          setEdit={setEdit}
          setRemove={setRemove}
          remove={remove}
          setRemoveCongrat={setRemoveCongrat}
        />
      )}

      {!currentProfile && (
        <>
          {/* {load ? (
            <Box
              height={"30vh"}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <> */}{" "}
          <HeaderTab className="header-tab">
            <Filter>
              <TabItems>
                <TabItem
                  className={classNames({ hovered: currentTab == 1 })}
                  onClick={() => setCurrentTab(1)}
                >
                  Tous les clients
                </TabItem>
                <TabItem
                  className={classNames({ hovered: currentTab == 2 })}
                  onClick={() => setCurrentTab(2)}
                >
                  En attente
                </TabItem>
                <TabItem
                  className={classNames({ hovered: currentTab == 3 })}
                  onClick={() => setCurrentTab(3)}
                >
                  Urgent
                </TabItem>
              </TabItems>
              {/* <Search className={"searchinput"}>
                <TextInput type="search" placeholder="Recherche" />
              </Search>
              <Box className="filterIcon">
                <Image source={filterIcon} style={{ width: 30, height: 30 }} />
              </Box> */}
            </Filter>
          </HeaderTab>
          <Title onClick={() => setDialog(true)} style={{ marginBottom: 20, marginTop: 20 }}>
            <Add sx={{ fontSize: 34, verticalAlign: "bottom" }} /> ajouter un
            client
          </Title>
          {currentTab == 1 && (
            <DTable
              keyprop="allCustomers"
              data={allCustomers}
              error={error}
              load={load}
              showPagination={showPagination}
              setPage={setPage}
              page={page}
            />
          )}
          {currentTab == 2 && (
            <DTable
              keyprop="notPaid"
              data={notPaid}
              load={load}
              showPagination={showPagination}
              setPage={setPage}
              page={page}
            />
          )}
          {currentTab == 3 && (
            <DTable
              keyprop="urgent"
              data={urgent}
              load={load}
              showPagination={showPagination}
              setPage={setPage}
              page={page}
            />
          )}
          {/*  </>
          )} */}
        </>
      )}
      {dialog && (
        <AddClient
          dialog={dialog}
          onClose={() => setDialog(false)}
          setCongrat={setCongrat}
        />
      )}
      {edit && (
        <EditClient
          edit={edit}
          onClose={() => setEdit(false)}
          //currentProfile={currentProfile}
          setEditCongrat={setEditCongrat}
          id={currentProfile || id}
        />
      )}
      {remove && (
        <RemoveClient
          remove={remove}
          onClose={() => setRemove(false)}
          setRemoveCongrat={setRemoveCongrat}
          goBack={() => setCurrentProfile(false)}
          id={currentProfile || id}
        />
      )}
      {congrat && (
        <Congrats
          congrat={congrat}
          onClose={() => setCongrat(false)}
          type={"client"}
        />
      )}
      {removeCongrat && (
        <RemoveCongrat
          removeCongrat={removeCongrat}
          onClose={() => {
            setRemoveCongrat(false);
            fetchData();
          }}
        />
      )}
      {editCongrat && (
        <EditCongrat
          editCongrat={editCongrat}
          onClose={() => setEditCongrat(false)}
        />
      )}
    </Box>
  );
}
