import { Box, Container, Dialog, Typography } from "@mui/material";
import React, { useState } from "react";
import { ScrollView, StyleSheet } from "react-native-web";
import styles from "../../assets/css/style";
import { Footer, SuperAdminNavBar } from "../../components";
import TabPanel from "../../components/TabPanel";
import Admins from "./Admins";
import Benefits from "./Benefits";
import Customer from "./Customers";
import Documents from "./Documents";
import Partners from "./Partners";
import Quotes from "./Quotes";
import bgimg from "../../assets/images/black-bg-dots.png";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import CallsCalendar from "./CallsCalendar/CallsCalendar";
import Params from "./Params";

export default function SuperAdminLayout() {
  const [activePage, setActivePage] = useState('dashboard');
  const items = [
    {
      label: "Prestations",
      content: <Benefits />,
    },
    {
      label: "Partenaires",
      content: <Partners />,
    },
    {
      label: "clients",
      content: <Customer />,
    },
    {
      label: "Admins",
      content: <Admins />,
    },
    /* {
      label: "créer un devis",
      content: <Quotes />,
    }, */
    {
      label: "documents",
      content: <Documents />,
    },
    {
      label: "Paramètres",
      content: <Params />,
    },
  ];

  return (
    <ScrollView
      style={{
        backgroundImage: "url(" + bgimg + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <SuperAdminNavBar callendarClick={() => setActivePage('calls')} />

      <Box style={StyleSheet.flatten(styles.bgWrap)}>
        <Typography
          color={"#fff"}
          style={StyleSheet.flatten(styles.dottedLine)}
          align="center"
          variant="h3"
        >
          {(() => {
            switch (activePage) {
              case "dashboard":
                return "Dashboard"

              case "calls":
                return "Calendrier"
            }
          })()}
          
        </Typography>
        <Container maxWidth="lg">
          {(() => {
            switch (activePage) {
              case "dashboard":
                return <TabPanel type="style2" items={items} />

              case "calls":
                return <CallsCalendar setActivePage={setActivePage} />
            }
          })()}
          
        </Container>
      </Box>
    </ScrollView>
  );
}
