// import { Box, Container } from "@mui/material";
// import { styled } from "@mui/system";
// import { SectionsHeading } from "../../../../components";
// import BackgroundImg from "../../../../assets/images/bgs/bg2.png";

// const StyledBox = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(4, 0),
//   backgroundImage: `url(${BackgroundImg})`,
//   backgroundSize: "100%",
//   backgroundPosition: "top center",
//   backgroundRepeat: "no-repeat",
// }));

// export default function AboutSection() {
//   return (
//     <StyledBox component={"div"}>
//       <Box component={"div"} width={"100%"} height={"100%"}>
//         <Container maxWidth="lg">
//           <SectionsHeading>comment ça marche ?</SectionsHeading>
//         </Container>
//       </Box>
//     </StyledBox>
//   );
// }

import { Box, Typography, Link } from "@mui/material";
import { styled } from "@mui/system";
import backgroundImage from "../../../../assets/images/bgs/bg2.png";
import clockCircle from "../../../../assets/images/clock-circle.png";
import circle from "../../../../assets/images/circle.png";
import circleArrow from "../../../../assets/images/circle.png";
import SlickSlider from "./components/SlickSlider";
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classnames from "classnames";
import Container from "../../../../components/layout/Container";
import verticalLine from "../../../../assets/images/lines/vertical_line.png";
import { BlackButton } from "../../../../components/buttons";
import cstyled from "styled-components";

const Root = styled(Box)(({ theme }) => ({
  paddingBottom: 50,
  //paddingTop: 50,
  backgroundColor: theme.palette.background.main,
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "100%",
  backgroundPosition: "top center",
  backgroundRepeat: "no-repeat",
  scrollMarginTop: 100,
}));

const H1 = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  textAlign: "center",
  letterSpacing: 0.05,
  textTransform: "uppercase",
  paddingBottom: 16,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
  paddingTop: 50,
  marginBottom: 80,
  backgroundImage: `url(${verticalLine})`,
}));

const AdvSlider = styled(Box)(
  ({ theme }) => `
        display: flex;
        justify-content: space-between;
        align-items: center;
        .circle{
          display: none;
          @media screen and (min-width: 900px) {
            display: block;
            width: 100%;
            max-width: 324px;
            margin-right: 40px;
            position: relative;
          }
          @media screen and (min-width: 1200px) {
            width: 100%;
            max-width: 490px;
            position: relative;
          }
           
            .slds {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 60px;
                height: 60px;
                transform: translate(-50%, -50%);
                z-index: 100;
                .sld-item {
                    width: 60px;
                    height: 60px;
                    background-color: #1E6278;
                    border-radius: 50%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    text-align: center;
                    line-height: 52px;
                    border: 4px solid  ${theme.palette.primary.main};
                    font-family: ${theme.typography.fontFamily};
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    /* line-height: 69px; */
                    text-transform: uppercase;
                    color: #000000;
                    font-size: 0;
                    transition: all .3s ease-in-out;
                    cursor: pointer;
                    &.active{
                        font-size: 38px;
                        background-color: ${theme.palette.primary.main};
                        border-color: #1E6278;
                    }
                    &:nth-of-type(1){

                      @media screen and (min-width: 900px) {
                        transform: rotate(-45deg) translate(180px, -5px) rotate(45deg) scale(.3);
                        &.active{
                          transform: rotate(-45deg) translate(200px, -5px) rotate(45deg);
                        }
                      }

                      @media screen and (min-width: 1200px) {
                        transform: rotate(-45deg) translate(260px, -5px) rotate(45deg) scale(.3);
                        &.active{
                          transform: rotate(-45deg) translate(280px, -5px) rotate(45deg);
                        }
                      }
                    }
                    &:nth-of-type(2){

                        @media screen and (min-width: 900px) {
                          transform: rotate(0deg) translate(180px, -5px) rotate(-0deg) scale(.3);
                          &.active{
                            transform: rotate(0deg) translate(200px, -5px) rotate(-0deg);
                          }
                        }
  
                        @media screen and (min-width: 1200px) {
                          transform: rotate(0deg) translate(260px, -5px) rotate(-0deg) scale(.3);
                        
                          &.active{
                              transform: rotate(0deg) translate(280px, -5px) rotate(-0deg);
                          }
                        }
                    }
                    &:nth-of-type(3){
                      @media screen and (min-width: 900px) {
                        transform: rotate(45deg) translate(180px, -5px) rotate(-45deg) scale(.3);
                        &.active{
                          transform: rotate(45deg) translate(200px, -5px) rotate(-45deg);
                        }
                      }

                      @media screen and (min-width: 1200px) {
                        transform: rotate(45deg) translate(260px, -5px) rotate(-45deg) scale(.3);
                      
                        &.active{
                            transform: rotate(45deg) translate(280px, -5px) rotate(-45deg);
                        }
                      }

                    }
                    &:nth-of-type(4){
                        transform: rotate(90deg) translate(260px, -5px) rotate(-90deg) scale(.3);
                        
                        &.active{
                            transform: rotate(90deg) translate(280px, -5px) rotate(-90deg);
                        }
                    }
                    &:nth-of-type(5){
                        transform: rotate(135deg) translate(260px, -5px) rotate(-135deg) scale(.3);
                        
                        &.active{
                            transform: rotate(135deg) translate(280px, -5px) rotate(-135deg);
                        }
                    }
                    &:nth-of-type(6){
                        transform: rotate(225deg) translate(260px, -5px) rotate(-225deg) scale(.3);
                        
                        &.active{
                            transform: rotate(225deg) translate(280px, -5px) rotate(-225deg);
                        }
                    }
                    &:nth-of-type(7){
                        transform: rotate(270deg) translate(260px, -5px) rotate(-270deg) scale(.3);
                        
                        &.active{
                            transform: rotate(270deg) translate(280px, -5px) rotate(-270deg);
                        }
                    }
                    &:nth-of-type(8){
                        transform: rotate(315deg) translate(260px, -5px) rotate(-315deg) scale(.3);
                        
                        &.active{
                            transform: rotate(315deg) translate(280px, -5px) rotate(-315deg);
                        }
                    }
                }
            }
            .clock-animation{
                width: 100%;
                padding-top: 100%;
                position: relative;
                &[data-active="1"]{
                    .clock{
                        transform: rotate(0deg);
                    }
                    .ylw-circle{
                        transform: translate(-50%, -50%) rotate(85deg);
                    }
                    .crl-arrow{
                        transform: translate(-50%, -50%) rotate(-90deg);
                    }
                }
                &[data-active="2"]{
                    .clock{
                        transform: rotate(-45deg);
                    }
                    .ylw-circle{
                        transform: translate(-50%, -50%) rotate(86deg);
                    }
                    .crl-arrow{
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
                &[data-active="3"]{
                    .clock{
                        transform: rotate(45deg);
                    }
                    .ylw-circle{
                        transform: translate(-50%, -50%) rotate(190deg);
                    }
                    .crl-arrow{
                        transform: translate(-50%, -50%) rotate(0deg);
                    }
                }
                &[data-active="4"]{
                    .clock{
                        transform: rotate(90deg);
                    }
                    .ylw-circle{
                        transform: translate(-50%, -50%) rotate(190deg);
                    }
                    .crl-arrow{
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                }
                &[data-active="5"]{
                    .clock{
                        transform: rotate(135deg);
                    }
                    .ylw-circle{
                        transform: translate(-50%, -50%) rotate(190deg);
                    }
                    .crl-arrow{
                        transform: translate(-50%, -50%) rotate(90deg);
                    }
                }
                .clock{   
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url(${clockCircle});
                    background-size: contain;
                    background-position: center;
                    transition: all .5s ease-in-out;
                    
                }
                .ylw-circle{
                    background-image: url(${circle});
                    background-size: contain;
                    background-position: center;
                    width: 60%;
                    height: 60%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: all .5s ease-in-out;
                }
                .crl-arrow{
                    background-image: url(${circleArrow});
                    background-size: contain;
                    background-position: center;
                    width: 60%;
                    height: 60%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: all .5s ease-in-out;
                }
                .circle-sldr {
                    width: 260px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                }
            }
        }
        ._cntsld{
            padding-left: 0px;  
            padding-right: 0px;
            flex: 1;
            width: calc(100% - 490px);
            @media screen and (min-width: 900px) {
              padding-left: 36px;
            }
            @media screen and (min-width: 1200px) {
              padding-left: 60px;
              padding-right: 100px
            }
            .items{
                .txt{
                    text-align: left;
                }
                .slick-dots{
                    bottom: -135px;
                    display: flex!important;
                    position: relative;
                    background-color: rgba(65, 200, 241, 0.2);
                    li{
                        flex: 1;
                        width: auto;
                        height: auto;
                        position: relative;transition: all .3s ease-in-out;
                        margin: 0;
                        
                        &:hover{
                            background-color: rgba(0, 0, 0, 0.1);
                        }
                        &.slick-active{
                            button{
                                background-color: ${theme.palette.textColor};
                                width: 100%;
                            }
                        }
                        button{
                            height: 4px;
                            width: 0%;
                            transition: all .3s ease-in-out;;    
                            padding: 0;  
                            
                            &:after, &:before{
                                display: none;
                            }
                        }
                    }
                
                }
            }
            .button{
                margin: 30px 0 0 auto;
            }
        }
        `
);

const circleSliderItems = [
  "Diagnostique téléphonique",
  "Passage du technicien",
  "Second passage",
];

const slickSliderItems = [
  {
    title: "Diagnostique téléphonique",
    description:
      "En prenant rendez-vous avec FIBME, un de nos conseillers vous appellera sur un créneau horaire que vous aurez préalablement choisi. Notre conseiller sera votre interlocuteur privilégié pour expliquer votre souci de raccordement. Il sera en mesure d’identifier le problème, vous apporter une solution et vous proposer un devis pour la réalisation du raccordement.",
  },
  {
    title: "Passage du technicien",
    description:
      "Une fois le problème de raccordement identifié et le devis pour l’intervention signé, vous aurez la possibilité de choisir une date et un créneau horaire pour le passage de notre technicien. Il se présentera pour réaliser la prestation et il vous fera constater les modifications effectuées et la réussite du raccordement.",
  },
  {
    title: "Second passage",
    description:
      "Dans de rares cas, il est possible que le problème de raccordement nécessite un second passage. Dans cette éventualité, notre technicien vous sollicitera pour choisir un second créneau de passage. Lors de ce second passage, notre technicien se présentera avec les outils ou personnes adéquats pour réaliser le raccordement à la fibre.",
  },
];

const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  dots: false,
  arrows: false,
};

const Span = cstyled.span`
  font-family: "Brandon Grotesque";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  /* rb */

  background: linear-gradient(101.22deg, #3ec3ec 6.23%, #ecdd3b 90.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export default function HowItWork() {
  const [sliderCircule, setSliderCircule] = useState(null);
  const [activeSlide, setActiveSlide] = useState(1);
  return (
    <Root className="hdw-bloc" id={"sec2"}>
      <Box sx={{ backdropFilter: "blur(8px)" }}>
        <Container maxWidth={false} className="container">
          <Box className="wrap">
            <H1 variant="h1" component="h1">
              comment ça marche ?
            </H1>
            <AdvSlider className="adv-slider">
              <Box className="circle">
                {/** Circle **/}
                <Box className="slds">
                  <Box
                    onClick={() => {
                      setActiveSlide(3);
                      sliderCircule.slickGoTo(activeSlide);
                    }}
                    className={classnames({
                      "sld-item": true,
                      active: activeSlide == 1,
                    })}
                    data="1"
                  >
                    1
                  </Box>
                  <Box
                    onClick={() => {
                      setActiveSlide(1);
                      sliderCircule.slickGoTo(activeSlide);
                    }}
                    className={classnames({
                      "sld-item": true,
                      active: activeSlide == 2,
                    })}
                    data="2"
                  >
                    2
                  </Box>
                  <Box
                    onClick={() => {
                      setActiveSlide(2);
                      sliderCircule.slickGoTo(activeSlide);
                    }}
                    className={classnames({
                      "sld-item": true,
                      active: activeSlide == 3,
                    })}
                    data="3"
                  >
                    3
                  </Box>
                </Box>
                <Box className="clock-animation">
                  <Box className="clock"></Box>
                  <Box className="ylw-circle"></Box>
                  <Box className="crl-arrow"></Box>
                  {/** Circle Slider **/}
                  <Slider
                    beforeChange={(oldIndex, newIndex) =>
                      setActiveSlide(newIndex + 1)
                    }
                    ref={(slider) => setSliderCircule(slider)}
                    {...settings}
                    className="circle-sldr"
                  >
                    {circleSliderItems.map((item, index) => (
                      <Box key={index} className="itm">
                        <Typography
                          fontSize={{ md: 18, lg: 24 }}
                          fontWeight={700}
                          textTransform={"uppercase"}
                        >
                          {item}
                        </Typography>
                      </Box>
                    ))}
                  </Slider>
                </Box>
                {/** End Circle **/}
              </Box>

              <Box className="_cntsld">
                <SlickSlider
                  items={slickSliderItems}
                  sliderCircule={sliderCircule}
                />

                <Box mt={4} display={"flex"} justifyContent={"flex-end"}>
                  <BlackButton>
                    <Span>prendre un rendez-vous</Span>
                  </BlackButton>
                </Box>
              </Box>
            </AdvSlider>
          </Box>
        </Container>
      </Box>
    </Root>
  );
}
