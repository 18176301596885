import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import theme from "../../assets/theme/index";
// const theme = useTheme();

const WrapCircle = styled.div`
  position: relative;
`;
const Span = styled.span`
  display: flex;
`;
const A = styled.a`
  position: relative;
  display: block;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 1px solid
    ${(props) =>
      props.admin
        ? theme.palette.primary.main
        : theme.palette.primary.contrastText};
  padding: 7px;
  transition: all 1.5s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    border-width: 1px;
    border-color: ${theme.palette.primary.main};
    span {
      border-color: ${theme.palette.primary.main};
      border-width: 1px;

      &:after {
        transform: rotate(320deg) translate(22px, 10px);
      }
      &:before {
        transform: rotate(-80deg) translate(30px, 10px);
      }
      img {
        transform: scale(0.9);
      }
    }
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid
      ${(props) =>
        props.admin
          ? theme.palette.primary.main
          : theme.palette.primary.contrastText};
    border-radius: 50%;
    position: relative;
    transition: all 1.5s ease-in-out;
    img {
      height: 22px;
      transition: all 0.25s ease-in-out;
    }
    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: ${theme.palette.primary.main};
      left: 50%;
      top: 50%;
      transform: rotate(180deg) translate(19px, 10px);
      margin: -4px 0 0 -4px;
      border-radius: 50%;
      transition: all 1.5s ease-in-out;
    }
    &::before {
      width: 12px;
      height: 12px;
      margin: -6px 0 0 -6px;
      transform: rotate(90deg) translate(29px, 10px);
    }
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    width: 50px;
    height: 50px;
    padding: 5px;
    &:hover {
      span {
        &:after {
          transform: rotate(320deg) translate(13px, 10px);
        }
        &:before {
          transform: rotate(-80deg) translate(22px, 10px);
        }
      }
    }
    span {
      img {
        height: 18px;
      }
      &:after {
        transform: rotate(180deg) translate(13px, 10px);
      }
      &:before {
        transform: rotate(90deg) translate(22px, 10px);
        width: 10px;
        height: 10px;
      }
    }
  }
`;
const CircleButton = ({ onClick, children, admin }) => (
  <WrapCircle onClick={onClick} type="default" cornerColor="red">
    <A admin={admin}>
      <Span>{children}</Span>
    </A>
  </WrapCircle>
);

// CircleButton.propTypes = {
//   children: PropTypes.node.isRequired,
//   onClick: PropTypes.func.isRequired,
// };

export default CircleButton;
