import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Text } from "react-native";
import { styled, ThemeProvider } from "@mui/system";
import envelope from "../../../../assets/images/envelope.png";
import wp from "../../../../assets/images/wp.png";
import mic from "../../../../assets/images/mic.png";
import warning from "../../../../assets/images/warning.png";
import theme from "../../../../assets/theme";
import { Window, WinItem } from "../../../../components/Window";
import MyAssistanceForm from "./MyAssistanceForm";

const Img = styled("img")(({ theme }) => ({}));
const Icon = styled("div")(({ theme }) => ({
  minHeight: 60,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 20,
}));

const Items = [
  {
    icon: <Img src={envelope} />,
    text: "Envoyez-nous un mail",
    sujet: "Envoyez-nous un mail",
  },
  {
    icon: <Img src={wp} />,
    text: "Chatter avec votre assistance sur Whatsapp",
    sujet: "",
  },
  {
    icon: <Img src={mic} />,
    text: "Demande à être contacté ",
    sujet: "Demande à être contacté",
  },
  {
    icon: <Img src={warning} />,
    text: "Signaler un problème",
    sujet: "Signaler un problème",
  },
];

export default function MyAssistance() {
  const [showForm, setShowForm] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Box className="my-assistance">
        <Typography variant="h2" style={{ marginBottom: 30 }}>
          Vous avez besoin d’aide ?{" "}
        </Typography>

        {!showForm ? (
          <Window>
            {Items.map((item, ind) => {
              return (
                <WinItem key={"ctsy-" + ind} onClick={() => (item.sujet !== "") ? setShowForm(item.sujet) : window.location.href = "https://wa.me/0033164475249"}>
                  <Icon>{item.icon}</Icon>
                  <Text className="label">{item.text}</Text>
                </WinItem>
              );
            })}
          </Window>
        ) : (
          <MyAssistanceForm setShowForm={setShowForm} sujet={showForm} />
        )}
      </Box>
    </ThemeProvider>
  );
}
