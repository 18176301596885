import { Box, useMediaQuery, styled, Slider } from "@mui/material";
import { SectionsHeading } from "../../../../components";
import { TestimonialCard } from "../../../../components/cards";
import { useTheme } from "@mui/material/styles";
import quoteLg from "../../../../assets/images/icons/quoteLg.png";
import quoteSm from "../../../../assets/images/icons/quoteSm.png";
import bg3 from "../../../../assets/images/bgs/bg3.png";
import { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

const CustomContainer = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${bg3})`,
  backgroundSize: "100%",
  backgroundPosition: "bottom center",
  backgroundRepeat: "no-repeat",
  scrollMarginTop: 150,
  userSelect: "none",
  //cursor: "pointer",
}));

const CustomSlider = styled(Box)(({ theme }) => ({
  display: "flex",
  minWidth: "min-content",
  margin: theme.spacing(0, 7),
}));

const FlexContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  flex: 1,
  overflow: "auto",
  scrollbarWidth: "none", //for mz browser
  "&::-webkit-scrollbar": {
    // this
    display: "none", // for chrome
  }, // browser
}));

const Track = styled(Box, {
  shouldForwardProp: (prop) => prop !== "activeThumb" && prop !== "thumbs",
  name: "ListContainer",
})(({ theme, activeThumb, thumbs }) => ({
  display: "inline-flex",
  height: 6,
  position: "relative",
  backgroundColor: "rgba(66, 199, 241, 0.2)",
  borderRaduis: "50%",
  flexBasis: "90%",
  [theme.breakpoints.up("md")]: {
    flexBasis: "75%",
  },
  [theme.breakpoints.up("md")]: {
    flexBasis: "60%",
  },
  cursor: "pointer",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: activeThumb ? `calc((100% / ${thumbs}) * ${activeThumb})` : 0,
    width: `calc(100% / ${thumbs})`,
    height: "100%",
    backgroundColor: "#000",
    transition: "left .25s linear",
    zIndex: 99,
  },
}));
export default function TestimonialsSection() {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const [thumbs, setThumbs] = useState(8);
  const [activeThumb, setActiveThumb] = useState(0);

  const handleClick = (i) => {
    const anchor = document.querySelector(`#item-${i}`);

    setActiveThumb(i);
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  return (
    <CustomContainer component={"div"} id="sec4">
      <SectionsHeading>Témoignages</SectionsHeading>
      <Box position={"relative"} pt={3} pb={4} scroll>
        <Box position={"absolute"} left={{ xs: 42 }} top={{ lg: -24, xs: 2 }}>
          <img src={lg ? quoteLg : quoteSm} alt={"quote icon"} />
        </Box>
        <FlexContainer component={"div"} id={"scroll"}>
          <ScrollContainer className="scroll-container">
            <CustomSlider component={"div"}>
              {Array.from({ length: 8 }, (_, i) => i).map((i) => (
                <TestimonialCard key={i} id={`item-${i}`} />
              ))}
            </CustomSlider>
          </ScrollContainer>
        </FlexContainer>

        <Box
          mb={{ xs: 3, lg: 12 }}
          mt={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Track component={"div"} activeThumb={activeThumb} thumbs={thumbs}>
            {Array.from({ length: thumbs }, (_, i) => i).map((item) => (
              <Box
                key={item}
                flex={1}
                backgroundColor={"transparent"}
                component={"div"}
                sx={{ cursor: "pointer" }}
                onClick={() => handleClick(item)}
              />
            ))}
          </Track>
        </Box>
      </Box>
    </CustomContainer>
  );
}
