import { useTheme } from "@emotion/react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  FormControlLabel,
  FormGroup,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";

import Select from "react-select";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PrestationService from "../../../store/apis/PrestationApi";
import {
  list_phase_demande,
  phase_demande,
} from "../../../config/keys";
import { RadioButtonUnchecked, TaskAlt } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object({});

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const EditStatusForm = ({ dialog, onClose, demande, setLoadPresta }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    data.etape = selectedStatus.value;
    setLoading(true);
    PrestationService.editPrestation(demande.id, data)
      .then((res) => {
        setLoading(false);
        setLoadPresta();
        onClose();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {}, []);

  const handleChangeStatus = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 600, maxWidth: { md: 600, lg: 600 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Phase de la prestation
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItem={"center"}
            justifyContent={"space-between"}
            rowGap={2}
            flexWrap={"wrap"}
          >
            <Box flexBasis={{ xs: "100%", md: "100%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16, md: 24 }}
                >
                  Phase du process
                </Typography>
                <Box>
                  {/* <Stepper
                    sx={{ fontSize: "14px" }}
                    activeStep={demande.etape}
                    orientation="vertical"
                  >
                    {list_phase_demande.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel sx={{ fontSize: "18px !important" }}>
                          {step.label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper> */}

                  <FormGroup aria-label="position">
                    {list_phase_demande.map((phase) => {
                      return (
                        <>
                          <FormControlLabel
                            labelPlacement="end"
                            control={
                              <Checkbox
                                size="small"
                                checked={demande.etape >= phase.id}
                                icon={<RadioButtonUnchecked />}
                                checkedIcon={<TaskAlt />}
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {phase.label}
                              </Typography>
                            }
                          />
                        </>
                      );
                    })}
                  </FormGroup>
                </Box>
              </Box>
            </Box>
            <Box flexBasis={{ xs: "100%", md: "100%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Changer la phase du process
                </Typography>
                <Box>
                  <Controller
                    name={"etape"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={selectedStatus}
                        styles={customStyles}
                        placeholder={"Selectionner la phase"}
                        options={phase_demande}
                        onChange={handleChangeStatus}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </form>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton type={"red"} handleClick={onClose}>
                Annuler
              </CustomButton>

              <CustomButton
                type={"yellow"}
                textColor={"white"}
                handleClick={handleSubmit(onSubmit)}
              >
                Modifier
              </CustomButton>
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default EditStatusForm;
