import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useSelector } from "react-redux";
import { Home, Parameters, Dashboard } from "../pages/customer";
import Register from "../pages/register";
import { ActivityDashboard, FacturationDashboard, SuperAdminDashboard } from "../pages/superadmin";
import SuperAdminLayout from "../pages/superadmin/SuperAdminLayout";
import { Cgu, Mentions, Politiques } from "../pages/legalFiles";
import RegisterPartner from "../pages/registerPartner";
import { PartnerDashboard } from "../pages/partner";
import Profile from "../pages/partner/Profile/Profile";

const Stack = createNativeStackNavigator();

function AppNavigation() {
  const { userToken } = useSelector((state) => state.auth);
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{ headerShown: false }}
        // initialRouteName={ hboard"}
        // initialRouteName={"Home"}
        initialRouteName={"Home"}
        // initialRouteName={"Login"}
        // initialRouteName={"Home"}
      >
        {!userToken ? (
          <>
            <Stack.Screen
              name="AdminDashboard"
              component={SuperAdminDashboard}
            />
            <Stack.Screen
              name="ActivityDashboard"
              component={ActivityDashboard}
            />
            <Stack.Screen
              name="FacturationDashboard"
              component={FacturationDashboard}
            />
            <Stack.Screen
              name="PartnerDashboard"
              component={() => PartnerDashboard("dashboard")}
            />
            <Stack.Screen name="Dashboard" component={Dashboard} />
            <Stack.Screen name="Home" component={Home} />
            <Stack.Screen name="Parameters" component={Parameters} />
            <Stack.Screen name="Register" component={Register} />
            <Stack.Screen name="RegisterPartner" component={RegisterPartner} />
            <Stack.Screen name="PartnerProfile" component={() => PartnerDashboard("profile")} />
            <Stack.Screen
              name="SuperAdminLayout"
              component={SuperAdminLayout}
            />
            <Stack.Screen name="CGU" component={Cgu} />
            <Stack.Screen name="Mentions légales" component={Mentions} />
            <Stack.Screen
              name="Politiques de confidentialités"
              component={Politiques}
            />
          </>
        ) : (
          <>
            <Stack.Screen name="Signup" component={Register} />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default AppNavigation;
