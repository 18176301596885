import { useTheme } from "@emotion/react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { BlackButton } from "../buttons";
import logo from "../../assets/images/logos/logo.png";
import logoMobile from "../../assets/images/logos/logo_mobile.png";
import logoMd from "../../assets/images/logos/logo_md.png";
import { DragHandleRounded } from "@mui/icons-material";
import account from "../../assets/images/icons/account.png";
import { useNavigation } from "@react-navigation/native";

export default function SignUpAppBar() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const navigation = useNavigation();
  return (
    <AppBar
      position="sticky"
      color="transparent"
      elevation={0}
      sx={{
        height: 100,
        /*backdropFilter: "blur(5px)",*/
        backgroundColor: theme.palette.background.main,
        boxShadow: theme.palette.shadow.header,
        padding: "8px 0",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              mr: 2,
              flexGrow: { xs: 1, lg: 0 },
              display: "flex",
              cursor: "pointer",
            }}
          >
            <img
              src={isLg ? logo : isMd ? logoMd : logoMobile}
              alt="logo"
              onClick={() => navigation.navigate("Home")}
            />
          </Box>
          {/*<Box flexGrow={1} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <BlackButton shadow textRainbow>
              prendre un rendez-vous
            </BlackButton>
          </Box>*/}

          {/*<Box
            sx={{
              flexGrow: 0,
              display: { xs: "flex", md: "none" },
              alignItems: "center",
            }}
          >
            <Box>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <DragHandleRounded fontSize="large" />
              </IconButton>
            </Box>

            <Box>
              <Tooltip title="Open settings">
                <IconButton color="inherit">
                  <img src={account} alt="account icon" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>*/}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
