import { styled } from "@mui/system";
import { useRoute } from "@react-navigation/native";

const Input = styled("button", {
  shouldForwardProp: (prop) => prop !== "height",
})(({ theme, route, height }) => ({
  textAlign: "center",
  outline: "none",
  fontSize: theme.spacing(1.8),
  /*height: height ? height : theme.spacing(6),*/
  height: 150,
  width: "100%",
  padding: theme.spacing(1, 2),
  border: "2px solid transparent",
  backgroundColor:
    route !== "home" ? "rgba(62, 195, 236, 0.02)" : "transparent",
  transition: "all .25s",
  backgroundImage:
    route !== "home"
      ? "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)"
      : "none",
  borderImageSlice: 1,
  borderImageSource:
    "linear-gradient(360deg, #3EC3EC -36%, rgba(62, 195, 236, 0) 76%);",

  "&:active": {
    backgroundColor: "rgba(62, 195, 236, 0.08)",
    border: `2px dashed ${theme.palette.primary.main}`,
  },
  "&:hover": {
    borderImageSource:
      "linear-gradient(360deg, #078eb8 -36%, rgb(3 20 26 / 0%) 76%)",
    cursor: "pointer",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: theme.spacing(2.2),
    height: 220
  },
}));

export default function ButtonInput({ /*height,*/ children, onClick }) {
  const route = useRoute();
  return (
    <Input type={"button"} /*height={height}*/ onClick={onClick}>
      {children}
    </Input>
  );
}
