import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userToken: null,
  isLoading: true,
  isSignout: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restore: (state, action) => {
      state.isSignout = false;
      state.isLoading = false;
      state.userToken = action.payload;
    },

    signIn: (state, action) => {
      state.isLoading = false;
      state.isSignout = false;
      state.userToken = action.payload;
    },
    signOut: (state) => {
      state.isLoading = false;
      state.isSignout = true;
      state.userToken = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { restore, signIn, signOut } = authSlice.actions;

export default authSlice.reducer;
