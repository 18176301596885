import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useState } from "react";
import dots from "../../../../assets/images/dots.png";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MessageCongrats = ({ congrat, setCongrat }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      open={congrat}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          maxWidth: { sm: 650 },
          width: { md: 650 },
          height: { xs: 232 },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      onClose={() => setCongrat(false)}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: theme.palette.background.backdropModal,
        },
      }}
    >
      <Container maxWidth={"md"}>
        {/* <Box mt={3} mb={3}></Box> */}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          columnGap={4}
        >
          <Box alignSelf={"flex-end"}>
            <img src={dots} />
          </Box>
          <Box>
            <Typography variant="h1" textAlign={"center"} mb={2}>
              Félicitations !
            </Typography>
            <Typography
              fontFamily={"Bauhaus"}
              textAlign={"center"}
              fontSize={{ xs: 16 }}
              fontWeight={700}
            >
              Le message a été envoyé.
            </Typography>
          </Box>
          <Box alignSelf={"flex-end"}>
            <img src={dots} />
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default MessageCongrats;
