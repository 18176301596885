import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import moment from "moment";
import Select from "react-select";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import partnersPartner from "../../../store/apis/partnersApis";
import PrestationService from "../../../store/apis/PrestationApi";
import {
  hoursList,
  rdv_type_prestation,
  status_demande,
} from "../../../config/keys";
import CalendarService from "../../../store/apis/CalendarApi";
import dateFormat from "dateformat";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object({});

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const PlanificationForm = ({
  dialog,
  onClose,
  demande,
  partner,
  setPartner,
  datePlanification,
  setDatePlanification,
  setLoadPresta,
}) => {
  const [selected, setSelected] = useState(
    partner
      ? {
          value: partner.id,
          label: partner.nom + " " + partner.prenom,
          id: partner.id,
          libelle: partner.nom + " " + partner.prenom,
          description:
            "Email : " +
            partner.email +
            "<br /> Telephone : " +
            partner.telephone,
        }
      : null
  );
  const [selectedStatus, setSelectedStatus] = useState(
    demande.type
      ? {
          value: demande.type,
          label: demande.type,
        }
      : null
  );
  const [selectedDate, setSelectedDate] = useState(
    datePlanification ? datePlanification.date_debut.split("T")[0] : null
  );
  const [selectedCreneau, setSelectedCreneau] = useState(
    datePlanification
      ? {
          value: datePlanification.date_debut
            .split("T")[1]
            .split("+")[0]
            .substring(0, 5),
          label: datePlanification.date_debut
            .split("T")[1]
            .split("+")[0]
            .substring(0, 5),
        }
      : null
  );

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState({});
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    setLoading(true);
    let newData = {
      prestataire: `/api/users/${selected.id}`,
      type: selectedStatus.value,
    };
    /* console.log(newData, demande, calendar);
    return; */

    PrestationService.editPrestation(demande.id, newData)
      .then((res) => {
        setPartner(res.prestataire);
        if (selectedStatus.value === "Déplanifier") {
          let newDate = {
            date_debut: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
            date_fin: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
          };
          if (datePlanification) {
            CalendarService.editCalendar(datePlanification.id, newDate)
              .then((res2) => {
                setDatePlanification(res2);
                setLoading(false);
                setLoadPresta();
                onClose();
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
          } else {
            const obj = {
              user: `/api/users/${res.client.id}`,
              dateDebut:
                selectedDate + "T" + selectedCreneau.value + ":00.120Z",
              dateFin: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
              titre: "Rdv",
              type: rdv_type_prestation,
              status: true,
            };
            CalendarService.addCalendar(obj)
              .then((res2) => {
                setDatePlanification(res2);
                setLoading(false);
                setLoadPresta();
                onClose();
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
          }
        } else if (demande.type_prestation === "devis_ignorer") {
          const obj = {
            user: `/api/users/${res.client.id}`,
            dateDebut: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
            dateFin: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
            titre: "Rdv",
            type: rdv_type_prestation,
            status: true,
          };
          CalendarService.addCalendar(obj)
            .then((res2) => {
              setDatePlanification(res2);
              setLoading(false);
              setLoadPresta();
              onClose();
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        } else {
          setLoading(false);
          setLoadPresta();
          onClose();
        }
      })
      .catch((error) => console.log(error));
    setSelected();
  };

  useEffect(() => {
    const listPartners = [];
    partnersPartner
      .listPartners(1)
      .then((res) => {
        for (let i = 0; i < res.length; i++) {
          const El = res[i];
          listPartners.push({
            value: El.id,
            label: El.nom + " " + El.prenom,
            id: El.id,
            libelle: El.nom + " " + El.prenom,
            description:
              "Email : " + El.email + "<br /> Telephone : " + El.telephone,
          });
        }
        setPartners(listPartners);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChangePartner = (newPartner) => {
    setSelected(newPartner);
  };
  const handleChangeStatus = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  const handleChangeDate = (event) => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
  };

  const handleChangeCreneau = (creneau) => {
    setSelectedCreneau(creneau);
  };
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 600, maxWidth: { md: 600, lg: 600 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Planification
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItem={"center"}
            justifyContent={"space-between"}
            rowGap={2}
            flexWrap={"wrap"}
          >
            <Box flexBasis={{ xs: "100%", md: "100%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Statut de la prestation
                </Typography>
                <Box>
                  <Controller
                    name={"status"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={selectedStatus}
                        styles={customStyles}
                        placeholder={"Selectionner le type de planification"}
                        options={status_demande}
                        onChange={handleChangeStatus}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            {(selectedStatus?.value === "Préplanifier" ||
              selectedStatus?.value === "Planifier") && (
              <Box flexBasis={{ xs: "100%", md: "100%" }}>
                <Box mt={0.75}>
                  <Typography
                    variant="caption"
                    component={"label"}
                    fontSize={{ xs: 16 }}
                  >
                    Selectionner le partenaire
                  </Typography>
                  <Box>
                    <Controller
                      name={"service"}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={selected}
                          styles={customStyles}
                          placeholder={"Selectionner le partenaire"}
                          options={partners}
                          onChange={handleChangePartner}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {(demande.type_prestation === "devis_ignorer" ||
              selectedStatus?.value === "Préplanifier" ||
              selectedStatus?.value === "Planifier") && (
              <>
                <Box flex={{ xs: "0 1 100%", md: "0 1 50%" }}>
                  <Box mt={0.75}>
                    <Typography
                      variant="caption"
                      component={"label"}
                      fontSize={{ xs: 16 }}
                    >
                      Selectionner la date
                    </Typography>
                    <Box>
                      <input
                        type="date"
                        name="date-planification"
                        id="date-planification"
                        lang="fr_FR"
                        locale="fr_FR"
                        min={dateFormat(tomorrow, "yyyy-mm-dd")}
                        style={{
                          color: "#ddd",
                          fontSize: "24px",
                          border: "1px solid #3EC3EC",
                          borderRadius: "3px",
                          padding: "10px",
                        }}
                        value={selectedDate}
                        onChange={handleChangeDate}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box flex={{ xs: "0 1 100%", md: "0 1 50%" }}>
                  <Box mt={0.75}>
                    <Typography
                      variant="caption"
                      component={"label"}
                      fontSize={{ xs: 16 }}
                    >
                      Selectionner le créneau
                    </Typography>
                    <Box>
                      <Controller
                        name={"service"}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            value={selectedCreneau}
                            styles={customStyles}
                            placeholder={"Créneau"}
                            options={hoursList}
                            onChange={handleChangeCreneau}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {selected && (
              <Box>
                <Box>
                  <Typography variant="caption" component={"label"}>
                    Contact du partenaire
                  </Typography>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                    fontSize={18}
                    lineHeight={"26px"}
                  >
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: selected.description }}
                    ></div>
                  </Box>
                </Box>
                {datePlanification && (
                  <Box>
                    <Typography variant="caption" component={"label"}>
                      Intervention prévu
                    </Typography>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      flexWrap={"wrap"}
                      fontSize={18}
                      lineHeight={"26px"}
                    >
                      {moment
                        .parseZone(datePlanification.date_debut)
                        .format("DD/MM/YYYY")}{" "}
                      {moment
                        .parseZone(datePlanification.date_debut)
                        .format("HH:mm")}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </form>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton type={"red"} handleClick={onClose}>
                Annuler
              </CustomButton>
              {((demande.type_prestation !== "devis_ignorer" &&
                selectedStatus &&
                selectedStatus?.value !== "Déplanifier") ||
                (demande.type_prestation === "devis_ignorer" &&
                  selectedDate &&
                  selectedCreneau) ||
                (selectedStatus?.value === "Déplanifier" &&
                  selected &&
                  selectedDate &&
                  selectedCreneau)) && (
                <CustomButton
                  type={"yellow"}
                  textColor={"white"}
                  handleClick={handleSubmit(onSubmit)}
                >
                  Modifier
                </CustomButton>
              )}
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default PlanificationForm;
