

export function unserialize(serializedString) {
  if (serializedString === 'a:0:{}' || serializedString === '') return ''
  // Extract text between double quotes using regular expression
  const extractedValues = serializedString
    .match(/"(.*?)"/g)
    .map((value) => value.replace(/"/g, ''))

  return extractedValues
}

export function truncate(str) {
  if(str) {
    return str.length > 150 ? str.substring(0, 150) + '...' : str
  }
  return ''
}

export function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(this, args), delay)
  }
}

export function fileSize(size) {
  let convertSize = size / 1024 ** 2
  return convertSize.toFixed(3)
}

export function formatNumberWithLeadingZeros(number) {
  // Convertir le nombre en chaîne de caractères
  let numberStr = number.toString();

  // Ajouter des zéros à gauche jusqu'à ce que la longueur soit de 5 chiffres
  while (numberStr.length < 5) {
    numberStr = "0" + numberStr;
  }

  return numberStr;
}

export function changeDateFormat(inputDate) {
  const [year, month, day] = inputDate.split('-');
  return `${day}/${month}/${year}`;
}


export const generateEmail = (username) => {
  const domain = 'fibme.fr';
  return `client-${username}@${domain}`;
};

// Optional: Function to generate a random username
export const generateRandomUsername = () => {
  const characters = '0123456789';
  let username = '';
  const length = 8; // Length of the random username
  for (let i = 0; i < length; i++) {
    username += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return username;
};