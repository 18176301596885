import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import userAuthReducer from "./slices/userAuthReducer";
const store = configureStore({
  reducer: {
    auth: authReducer,
    userAuth: userAuthReducer,
  },
  devTools: true,
});

export default store;
