import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../../components/buttons/CustomButton";
import moment from "moment";
import Select from "react-select";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import partnersPartner from "../../../../store/apis/partnersApis";
import PrestationService from "../../../../store/apis/PrestationApi";
import {
  hoursList,
  rdv_type_call,
  rdv_type_prestation,
  status_demande,
} from "../../../../config/keys";
import CalendarService from "../../../../store/apis/CalendarApi";
import { TextAreaInput } from "../../../../components/inputs";
import { TextInput } from "react-native";
import { changeDateFormat } from "../../../../config/utils";
import StripeApp from "../Stripe/StripeApp";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentForm = ({ dialog, onClose, demande, onSuccess }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 700, maxWidth: { md: 700, lg: 700 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Payement
          </Typography>
        </Box>
        <StripeApp
          userId={demande?.client?.id}
          offreId={demande?.id}
          offrePrice={demande?.total_ttc}
          onSuccess={onSuccess}
          onClose={onClose}
        />
      </Container>
    </Dialog>
  );
};

export default PaymentForm;
