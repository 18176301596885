import { useTheme } from "@emotion/react";
import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Text, View } from "react-native-web";
import { TextAreaInput } from "../../../../components/inputs";
import CalendarService from "../../../../store/apis/CalendarApi";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PermDeviceInformation,
  SystemSecurityUpdateGood,
} from "@mui/icons-material";
import { rdv_type_call, rdv_type_prestation } from "../../../../config/keys";
import PrestationService from "../../../../store/apis/PrestationApi";

const schema = yup.object({});

export default function DetailCallClient({
  dialog,
  onClose,
  detailItem,
  setEditRdv,
  refresh,
}) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  setValue("libelle", detailItem?.detail?.commentaire);

  const onSubmit = (data) => {
    setLoading(true);

    /* console.log(newData, demande, calendar);
    return; */
    let newDate = {
      commentaire: data.commentaire,
      description: "Appel réussi",
    };

    let calnedarId = detailItem?.detail?.id;

    CalendarService.editCalendar(calnedarId, newDate)
      .then((res) => {
        setLoading(false);
        refresh(true);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const onSubmit2 = (data) => {
    /* setEditRdv(true);
    onClose();
    return; */
    setLoading(true);

    /* console.log(newData, demande, calendar);
    return; */
    let newDate = {
      commentaire: data.commentaire,
      description: "Appel non réussi",
      status: false,
    };

    let calnedarId = detailItem?.detail?.id;

    CalendarService.editCalendar(calnedarId, newDate)
      .then((res) => {
        setLoading(false);
        setEditRdv(true);
        refresh(false);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [selectedStatus, setSelectedStatus] = useState(
    detailItem.detail.user.demandes_client[0]?.status
  );
  const handleChangeStatus = (newStatus) => {
    setSelectedStatus(newStatus.target.value);
  };
  const onSubmit3 = (data) => {
    setLoading(true);

    let demandeId = detailItem?.detail?.user?.demandes_client[0]?.id;
    PrestationService.editPrestation(demandeId, { status: selectedStatus })
      .then((res) => {
        setLoading(false);
        refresh(true);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={dialog}
      /* TransitionComponent={Transition} */
      animationType="fade"
      fullScreen={!isMd}
      PaperProps={{ sx: { maxWidth: { md: 760, lg: 865 } } }}
      onClose={onClose}
    >
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text style={{ padding: 20 }}>
          <b>Détail rendez-vous</b>
        </Text>
        {detailItem.detail.type === "rdv_call" ? (
          <View
            style={{ padding: 20, backgroundColor: "white", borderRadius: 10 }}
          >
            <View style={{ marginBottom: 10 }}>
              <Text>
                <b>Client :</b> {detailItem?.name}
              </Text>
              <Text>
                <b>Téléphone :</b> {detailItem?.telephone}
              </Text>
              <Text>
                <b>Heure d'appel :</b> {detailItem?.heure}
              </Text>
              {detailItem?.detail?.description && (
                <Text>
                  <b>Etat de l'appel :</b> {detailItem?.detail?.description}
                </Text>
              )}
              <Text style={{ marginBottom: "5px" }}>
                <b>Commentaire :</b>
              </Text>
              {detailItem?.detail?.commentaire ||
              detailItem?.detail?.description ? (
                <Text style={{ marginBottom: "5px" }}>
                  {detailItem?.detail?.commentaire}
                </Text>
              ) : (
                <TextAreaInput register={{ ...register("commentaire") }} />
              )}
            </View>
          </View>
        ) : (
          <View
            style={{ padding: 20, backgroundColor: "white", borderRadius: 10 }}
          >
            <View style={{ marginBottom: 10 }}>
              <Text>
                <b>Client :</b> {detailItem?.name}
              </Text>
              <Text>
                <b>Téléphone :</b> {detailItem?.telephone}
              </Text>
              <Text>
                <b>Heure de l'intervention :</b> {detailItem?.heure}
              </Text>
              {detailItem.detail.user.demandes_client[0]?.status && (
                <Text>
                  <b>État de la prestation :</b>{" "}
                  {detailItem.detail.user.demandes_client[0]?.status}
                </Text>
              )}
              <Text style={{ marginBottom: "5px" }}>
                <b>Changer l'état :</b>
              </Text>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={selectedStatus}
                  onChange={handleChangeStatus}
                >
                  <FormControlLabel
                    value="Clos"
                    control={<Radio size="small" />}
                    label="Clos"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        lineHeight: "30px",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="Sans suite"
                    control={<Radio size="small" />}
                    label="Sans suite"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        lineHeight: "30px",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="DIAG"
                    control={<Radio size="small" />}
                    label="DIAG"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        lineHeight: "30px",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="Stand BY"
                    control={<Radio size="small" />}
                    label="Stand BY"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        lineHeight: "30px",
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
              {/* <Controller
                name={"status"}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={selectedStatus}
                    styles={customStyles}
                    placeholder={"Selectionner l'état"}
                    options={etat_demande}
                    onChange={handleChangeStatus}
                  />
                )}
              /> */}
            </View>
          </View>
        )}

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {detailItem.detail.type === rdv_type_call &&
              !detailItem?.detail?.description && (
                <>
                  <Button
                    variant={"text"}
                    color={"primary"}
                    sx={{ textTransform: "capitalize", color: "#ecdd3b" }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Appel réussi <SystemSecurityUpdateGood />
                  </Button>
                  <Button
                    variant={"text"}
                    color={"primary"}
                    sx={{ textTransform: "capitalize", color: "#d61644" }}
                    onClick={handleSubmit(onSubmit2)}
                  >
                    Appel non réussi <PermDeviceInformation />
                  </Button>
                </>
              )}
            {detailItem.detail.type === rdv_type_prestation && (
              <Button
                variant={"text"}
                color={"primary"}
                sx={{ textTransform: "capitalize", color: "#ecdd3b" }}
                onClick={handleSubmit(onSubmit3)}
              >
                Modifier
              </Button>
            )}
            <Button
              variant={"text"}
              color={"primary"}
              sx={{ fontWeight: 700, textTransform: "capitalize" }}
              onClick={onClose}
            >
              Retour
            </Button>
          </>
        )}
      </View>
    </Dialog>
  );
}
