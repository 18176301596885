import React from 'react'
import { Box, Typography } from "@mui/material";
import logo from '../../../assets/images/logos/light_logo_lg.png';
import { styled, ThemeProvider } from '@mui/system';
import theme from '../../../assets/theme';
import styles from '../../../assets/css/style.js';
import { StyleSheet } from 'react-native';
import signature from '../../../assets/images/signature.png';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PrintIcon from '@mui/icons-material/Print';

const PdfWrap = styled('div')(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 390,
    fontSize: 14,
    lineHeight: '20px',
    color: '#fff',
    maxWidth: 725, 
    border: '1px solid #03EBFF',
    backgroundColor: 'rgba(0, 0, 0, .69)',
    padding: '20px 30px',

    '.paddingRight40' : {
        paddingRight: 40,
    },      
    '.infos': {
        maxWidth: 'max-content',
        margin: '0 0 0 auto',
    },
    '.fz18' : {
        fontSize: 18,
    },
    '.details': {
        margin: '30px 0 20px',

        '.dt1': {
            width: '56%',
        },
        '.dt2': {
            width: '44%',
        },
    },
    '.h2': {
        marginBottom: 10,
    },
    '.prodlist': {
        borderSpacing: 0,

        'tr': {
            'td': {
                padding: 6,

                '&:not(:nth-of-type(2))': {
                    textAlign: 'center',
                }
            },
            '&:first-of-type': {
                fontSize: 16,
                lineHeight: '23px',
            },
            '&:not(:first-of-type)': {
               border: '1px solid #03EBFF',
               'td': {
                   border: '1px solid #03EBFF',
                   verticalAlign: 'top',
               }
           } 
        }
    },
    '.paiment': {
        margin: '0 -30px',
        padding: '10px 30px 30px',
        borderBottom: '1px solid #03EBFF',
    },
    '.signature': {
        padding: '10px 0',
        'img': {
            display: 'block',
            margin: 'auto',
        }
    },
    '.textBfrBtns': {
        display: 'flex',
        marginBottom: 20,
        '> div:first-of-type': {
            flex: 1,
        },
        '> div:last-child': {
            width: 250,
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: '0 -20px',
            width: 'auto',

            '> div:last-child': {
                display: 'none',
            },
        }
    },
    '._btssp': {
        display: 'flex',

        '> div:first-of-type': {
            flex: 1,
        },
        '> div:last-child': {
            width: 250,
        },
        '._blcsntr': {
            height: '100%',
            display: 'flex',
            border: '2px dashed #04ebff',
            marginLeft: 10,
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 18,
            lineHeight: '22px',
            fontFamily: theme.typography.fontFamilyBauhaus,
            color: '#04ebff',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: '10px -20px 0',
            width: 'auto',

            '>div:last-child': {
                width: '100%',
            },
            '._blcsntr': {
                minHeight: 100,
                margin: '13px 0 0 0',
            },
        },
    },
    '.btn': {
        height: 44,
        fontSize: 18,
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #03EBFF',

        '&.style1': {
            backgroundImage: 'linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)',
            textTransform: 'uppercase',
            borderWidth: 2,
            marginBottom: 10,
        }
    },
    '.txt':{
        fontSize: 12,
        lineHeight: '17px',
        margin: '20px 0',
    } ,
    '.ftr': {
        margin: 'auto',
        maxWidth: 270,
        fontSize: 9,
        lineHeight: '13px',
        textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
        '.fz18': {
            fontSize: 14,
        },
        '.head': {
            '> tbody > tr':{
                display: 'flex',
                flexDirection: 'column',

                'img': {
                    width: 105,
                }
            }
        },
        '.infos': {
            margin: 0,
            marginTop: '15px',

            'td': {
                textAlign: 'left',

                '&:last-child': {
                    textAlign: 'center',
                }
            }
        },
        '.paiment': {
            padding: '10px 10px 10px',

            '> table > tbody > tr':{
                display: 'flex',
                flexDirection: 'column-reverse',

                '> td': {
                    marginBottom: 15,
                    
                    '&:last-child': {
                        maxWidth: 170,
                        width: '100%',
                        margin: '0 0 0 auto',
                    
                    },
                    'table td:first-of-type': {
                        whiteSpace: 'nowrap',
                        verticalAlign: 'text-bottom',
                    },
                    'table td': {
                        paddingTop: 4,
                    }
                }
            },
        },
        '.details': {
            marginBottom: 5,

            '> tbody > tr':{
                display: 'flex',
                flexDirection: 'column',

                '> td': {
                    marginBottom: 15,
                }
            },
            '.dt1, .dt2': {
                width: '100%',

                'td': {
                    width: '50%',
                }
            }
        },
        '.prodlist ': {
            margin: '15px -31px 0',
            width: 'calc(100% + 62px)',

            'tr': {
                '&:first-of-type': {
                    fontSize: 12,
                }
            },
            '.tgl': {
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',

                '&:before': {
                    content: 'attr(data-label-sm)',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontFamily: theme.typography.fontFamilyBauhaus,
                    color: '#000',
                },
                '&:after': {
                    content: '""',
                    border: '6px solid transparent',
                    borderTopColor: theme.palette.primary.main,
                    borderBottom: 'none',
                    marginLeft: 6,
                },
                '> div': {
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    width: 200,
                    backgroundColor: '#fff',
                    padding: 4,
                    zIndex: 100,
                    border: '1px solid #ccc',
                    borderRadius: 4,
                    opacity: 0,
                    visibility: 'hidden',
                },
                '&:hover': {
                    '> div': {
                        opacity: 1,
                        visibility: 'visible',
                    }
                }
            }
        },
        '.signature': {
            margin: '0 -20px',
            width: 'auto',

            '> tbody > tr':{
                display: 'flex',
                flexWrap: 'wrap',

                'td': {
                    width: '50%',
                    textAlign: 'center',

                    '&:first-of-type': {
                        width: '100%',
                        marginBottom: 15,
                        textAlign: 'left',
                    }
                }
            }
        },
        '.txt': {
            margin: '20px -20px',
        }
    }
}));
const Table = styled('table')(({ theme }) => ({
    width: '100%',
    '.b' : {
        fontWeight: 'bold',
    },
    '.ffbauhaus' : {
        fontFamily: theme.typography.fontFamilyBauhaus,
    },
}));
const Tbody = styled('tbody')(({ theme }) => ({}));
const Tr = styled('tr')(({ theme, color }) => ({color: color ? color : 'inherit',}));
const Td = styled('td')(({ theme }) => ({}));
const Img = styled('img')(({ theme }) => ({}));
const Link = styled('a')(({ theme }) => ({}));
const Strong = styled('strong')(({ theme }) => ({}));
const Card = styled('div')(({ theme }) => ({
    '&.card': {
        border: '1px solid rgba(62, 195, 236, 0.6)',
        padding: '10px 25px',
        minHeight: 126,
        
    },
    [theme.breakpoints.down('sm')]: {
        '&.card': {
            padding: '10px 16px',
        }
    }
}));
const CRTBtn = styled('div')(({ theme }) => ({
    height: 60,
    position: 'relative',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamilyBauhaus,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '18px',
    textAlign: 'center',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    background: 'radial-gradient(circle at center, rgba(3, 235, 255, .31) 0%, transparent 88%, transparent 0%)',

    '.before': {
        width: 15,
        height: 1,
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        bottom: 0,
        right: 0,
        transformOrigin: 'center',
        transform: 'translate(2px, -5px) rotate(-45deg)',
    },
    '.after': {
        width: 15,
        height: 1,
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        left: 0,
        top: 0,
        transformOrigin: 'center',
        transform: 'translate(-2px, 5px) rotate(-45deg)',
    },
    '&:after': {
        content: "''",
        position: 'absolute',
        right: 0,
        top: 0,
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        borderTop: '1px solid '+theme.palette.primary.main,
        borderRight: '1px solid '+theme.palette.primary.main,
    },
    '&:before': {
        content: "''",
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        borderBottom: '1px solid '+theme.palette.primary.main,
        borderLeft: '1px solid '+theme.palette.primary.main,
    },
}));

export default function PDFDevis() {
  return (
    <ThemeProvider theme={theme}>
        <Box display={'flex'} alignItems="flex-start">
            <Box>
                <PdfWrap className="pdf" style={StyleSheet.flatten(styles.pdf)}>
                    <Table className="head">
                        <Tbody>
                            <Tr>
                                <Td>
                                    <Img src={logo} />
                                </Td>
                                <Td>
                                    <Table className="infos">
                                        <Tbody>
                                            <Tr className="b fz18">
                                                <Td align="right" className="paddingRight40">Devis N°</Td>
                                                <Td>D878889781</Td>
                                            </Tr>
                                            <Tr className="b fz18">
                                                <Td align="right" className="paddingRight40">Date° :</Td>
                                                <Td>18/02/2012</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>

                    <Table className="details">
                        <Tbody>
                            <Tr>
                                <Td className="dt1">
                                    <Table>
                                        <Tbody>
                                            <Tr>
                                                <Td>Société</Td>
                                                <Td><Strong>Fibme</Strong></Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Siège social</Td>
                                                <Td>18 Place Marcel Rebuffat</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Code Postal</Td>
                                                <Td>91140 </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Ville</Td>
                                                <Td>VILLEJUST </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Téléphone</Td>
                                                <Td>01 64 47 52 49</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>E-mail</Td>
                                                <Td>contact@fibme.com</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </Td>
                                <Td className="dt2">
                                    <Table>
                                        <Tbody>
                                            <Tr>
                                                <Td width="50%">Client</Td>
                                                <Td><Strong>Jean Baptiste</Strong></Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Adresse</Td>
                                                <Td>37 rue de Londres</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Code Postal</Td>
                                                <Td>91140 </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Ville</Td>
                                                <Td>VILLEJUST </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Téléphone</Td>
                                                <Td>01 64 47 52 49</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>E-mail</Td>
                                                <Td>contact@fibme.com</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>

                    <Typography variant="h2" className="h2">Nature du problème</Typography>
                    <Card className="card" >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit ut sed urna, elementum mi vulputate quam felis. Magna diam vehicula nulla risus. 
                    </Card>

                    <Table className="prodlist">
                        <Tbody>
                            <Tr>
                                <Td width="90">Réf</Td>
                                <Td>Déscriptions</Td>
                                <Td width="70">Qté</Td>
                                <Td width="90">PU HT</Td>
                                <Td width="120">Montant HT</Td>
                            </Tr>
                            <Tr>
                                <Td className="td b fz18 ffbauhaus">P0001</Td>
                                <Td>
                                    <Box className="tgl" data-label-sm="DIAGNOSTIC">
                                        <Box className="">Déplacement pour diagnostic sur site pour étude raccordement à la fibre optique suite à l’échec rencontré lors du raccordement opérateur sur partie privative du logement de client. </Box>
                                    </Box>
                                </Td>
                                <Td><Strong>2</Strong></Td>
                                <Td><Strong>38</Strong></Td>
                                <Td><Strong>38</Strong></Td>
                            </Tr>
                            <Tr>
                                <Td className="td b fz18">P0001</Td>
                                <Td>
                                    <Box className="tgl" data-label-sm="TRAVAUX">
                                        <Box>Déplacement pour diagnostic sur site pour étude raccordement à la fibre optique suite à l’échec rencontré lors du raccordement opérateur sur partie privative du logement de client. </Box>
                                    </Box>
                                </Td>
                                <Td><Strong>2</Strong></Td>
                                <Td><Strong>38</Strong></Td>
                                <Td><Strong>38</Strong></Td>
                            </Tr>
                        </Tbody>
                    </Table>
                    <Box className="paiment">
                        <Table>
                            <Tbody>
                                <Tr>
                                    <Td>
                                        <Table>
                                            <Tbody>
                                                <Tr>
                                                    <Td>Validité devis:</Td>
                                                    <Td>Notre devis est valable 3 mois</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Paiement :</Td>
                                                    <Td>A date du diagnostic sur site sur présentation de la facture</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Règlement : </Td>
                                                    <Td>Par carte bancaire</Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </Td>
                                    <Td>
                                        <Table>
                                            <Tbody>
                                                <Tr>
                                                    <Td className="b fz18 ffbauhaus">Total HT </Td>
                                                    <Td className="fz18">38 €</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="b fz18 ffbauhaus">TVA ( 20 % )  </Td>
                                                    <Td className="fz18">7,6 €</Td>
                                                </Tr>
                                                <Tr color={theme.palette.secondary.main}>
                                                    <Td className="b fz18 ffbauhaus">Total TTC </Td>
                                                    <Td className="fz18 b">45,60 €</Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>

                    <Table className="signature">
                        <Tbody>
                            <Tr>
                                <Td className="fz18" width="33.33%">
                                    Signé le 10 novembre 2021
                                </Td>
                                <Td width="33.33%">
                                    <Img src={signature} />
                                </Td>
                                <Td width="33.33%" >
                                    <Strong className="fz18">Craig Korsgaard</Strong>
                                    <Box>Chef d’affaire</Box>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>

                    <Box className="textBfrBtns">
                        <Box className="fz18">
                            Devis gratuit à nos retourner par mail daté et signé par le “ Bon pour Accord”  pour commande
                        </Box>
                        <Box className="fz18">
                            avec mention manuscrite
                        </Box>
                    </Box>
                    <Box className="_btssp">
                        <Box>
                            <Link className="btn style1">BON POUR ACCORD</Link>
                            <Link className="btn">11 novembre 2021</Link>
                        </Box>
                        <Box>
                            <Box className="_blcsntr">
                                Cliquer ici pour signer
                            </Box>
                        </Box>
                    </Box>
                    <Box className="txt">
                        Règlement comptant sans escompte. Le non respect du délai de paiement expose le client, après mise en demeure, à des pénalité de retard ainsi qu’à une indemnité forfaitaire pour frais de recouvrement de 40,00€
                    </Box>
                    <Box className="ftr">
                        SASU au capital de 30 000,00 € - RCS EVRY 901 827 931 - TVA intracommunautaire FR 16804898500 - APE 6190Z
                    </Box>
                </PdfWrap>
                <Box fontSize={18} border={"1px solid " + theme.palette.secondary.main} margin="10px 0 20px 0" maxWidth="725px" padding="8px 15px">
                    <Table>
                        <Tbody>
                            <Tr color={theme.palette.secondary.main}>
                                <Td>
                                    Créneau choisi pour l’intervention
                                </Td>
                                <Td width='110px' align='center'>
                                    25/11/2021
                                </Td>
                                <Td width='80px' align='center'>
                                    15h30
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Box>
            </Box>
            <Box flex={1}>
                <CRTBtn>
                    <Box component={'span'} className="before"></Box>
                    Créer la fiche d’intervention
                    <Box component={'span'} className="after"></Box>
                </CRTBtn>
                <Box marginLeft={'5px'} fontFamily={theme.typography.fontFamilyBauhaus} fontSize="14px" marginTop="10px" color={'#fff'} >
                    <BorderColorIcon fontSize='17px' /> Modifier
                </Box>
                <Box marginLeft={'5px'} fontFamily={theme.typography.fontFamilyBauhaus} fontSize="14px" marginTop="10px" color={'#fff'} >
                    <PrintIcon fontSize='20px' /> Télécharger
                </Box>
            </Box>
        </Box>
    </ThemeProvider>
  )
}
