import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userAuthService from "../apis/userAuthApi";

const user = JSON.parse(localStorage.getItem("user"));

export const userLogin = createAsyncThunk(
  "userAuth/userLogin",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await userAuthService.login(email, password);
      return { user: data };
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue((err?.response?.data?.message) ? err?.response?.data?.message : err?.response?.data);
    }
  }
);

export const userLogout = createAsyncThunk("userAuth/userLogout", async () => {
  await userAuthService.logout();
});

const initialState = user
  ? { isSignedIn: true, isFetching: false, user, error: null }
  : { isSignedIn: false, isFetching: false, user: null, error: null };

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    reset: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isSignedIn = true;
      state.isFetching = false;
      state.error = null;
      state.user = action.payload;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isSignedIn = false;
      state.isFetching = false;
      state.error = action.payload;
    });
    builder.addCase(userLogin.pending, (state, _) => {
      state.isFetching = true;
      state.error = null;
      state.user = null;
    });
    builder.addCase(userLogout.fulfilled, (state, _) => {
      state.isSignedIn = false;
      state.user = null;
    });
  },
  // extraReducers: {
  //   [userLogin.fulfilled]: (state, action) => {
  //     state.isLoggedIn = true;
  //     state.user = action.payload.user;
  //   },
  //   [userLogin.rejected]: (state, action) => {
  //     state.isLoggedIn = false;
  //     state.user = null;
  //   },
  // },
});
const { actions } = userAuthSlice;
export const { reset } = actions;
export default userAuthSlice.reducer;
