import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import { styled } from "@mui/system";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const H4 = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  color: theme.palette.textColor,
  marginBottom: 15,
  fontFamily: theme.typography.fontFamily,
  textTransform: "inherit",
  textAlign: "Left",
  fontWeight: "bold",
}));

const Text = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontStyle: "normal",
  fontWeight: 330,
  fontSize: 24,
  lineHeight: "34px",
  color: theme.palette.textColor,
}));

const SlickSlider = ({ items, sliderCircule }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    asNavFor: sliderCircule,
  };

  return (
    <Slider {...settings} className="items">
      {items.map((item, index) => (
        <Box className="item" key={index}>
          <H4 variant="h1" component="h3">
            {item.title}
          </H4>
          <Text className="txt">{item.description}</Text>
        </Box>
      ))}
    </Slider>
  );
};

export default SlickSlider;
