import { styled } from "@mui/system";
import inpBorder from '../../../assets/images/searchbar.png'
import searchIcon from '../../../assets/images/search.png'

const Search = styled('div')(({theme}) => ({
    display: 'block',

    'input': {
        height: 35,
        backgroundImage: 'url('+inpBorder+'), url('+searchIcon+')',
        backgroundSize: '100%, 25px',
        backgroundPosition: 'bottom center, center right 12px',
        backgroundRepeat: 'no-repeat',
        fontFamily: theme.palette.fontFamily,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '17px',
        color: '#FFFFFF',
        padding: '0 25px',
        outline: 0,
        marginBottom: 15,

        '&::placeholder': {
            color: '#fff',
        }
    },

    [theme.breakpoints.down('sm')]: {
        order: 1,
        margin: '0 0 0 auto',
    }
}));

export default Search;