import { createTheme } from "@mui/material/styles";
import BrandonGrotesqueBlack from "../fonts/BrandonGrotesque-Black.woff2";
import BrandonGrotesqueLight from "../fonts/BrandonGrotesque-Light.woff2";
import BrandonGrotesqueBold from "../fonts/BrandonGrotesqueBold.woff2";
import BrandonGrotesque from "../fonts/BrandonGrotesqueRegular.woff2";
import Bauhaus from "../fonts/BauhausITCbyBT-Bold.woff2";

let theme = createTheme({
  //TODO: Customize the theme here
  palette: {
    mode: "light",
    primary: {
      main: "#03EBFF",
      contrastText: "#000",
    },
    secondary: {
      main: "#F2E208",
      contrastText: "#000",
      closeButton: "rgba(124, 245, 255, .2)",
      socialIcon: "#42C7F1"
    },
    warning: {
      main: "rgba(236, 221, 59, 1)",
    },
    text: {
      primary: "rgba(0, 44, 58, 1)",
      secondary: "rgba(14, 69, 74, 1)",
      gradientText: "linear-gradient(101.22deg, #3ec3ec 6.23%, #ecdd3b 90.48%)",
      navlistHeader: "#1E6278",
    },
    background : {
      main: "#F4F2F6",
      backdropModal: "rgba(0, 88, 115, .5)",
      backgroundMainOpacity: "rgba(244, 242, 246, 0.5)"
    },
    gradient : {
      navlistHeader : "linear-gradient( 0deg,rgba(3,235,255,0.9) -38%,rgba(3,235,255,0.0935268) 85%)",

    },
    shadow: {
      header : "0px 4px 20px rgba(0, 0, 0, 0.05)",
    }

  },
  components: {
    MuiCssBaseline: {
      styleOverrides: [
        `
      @font-face {
        font-family: 'Bauhaus';
        font-style: normal;
        font-display: swap;
        font-weight: 700;
        src: url(${Bauhaus}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
    `,
        `
      @font-face {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-display: swap;
      font-weight: 900;
      src: url(${BrandonGrotesqueBlack}) format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }`,
        `
      @font-face {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-display: swap;
      font-weight: 300;
      src: url(${BrandonGrotesqueLight}) format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }`,
        `
      @font-face {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-display: swap;
      font-weight: bold;
      src: url(${BrandonGrotesqueBold}) format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }`,
        `
      @font-face {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-display: swap;
      font-weight: 500;
      src: url(${BrandonGrotesque}) format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }`,
      ],
    },
  },
  typography: {
    fontFamily: "Brandon Grotesque",
    fontFamilyBauhaus: "Bauhaus",
    h1: {
      fontSize: 24,
      fontWeight: 500,
      fontFamily: "Bauhaus",
    },
    h2: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: "Bauhaus",
      textTransform: "uppercase",
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      fontFamily: "Brandon Grotesque",
      textTransform: "uppercase",
    },
    h4: {
      fontSize: 48,
      fontWeight: 500,
    },
    h5: {
      fontSize: 36,
      fontWeight: 500,
      fontFamily: "Bauhaus",
      textTransform: "uppercase",
    },
    body1: {
      fontSize: 24,
      lineHeight: "34px",
      fontWeight: 300,
      fontFamily: "Brandon Grotesque",
    },
    body2: {
      fontSize: 18,
      fontWeight: 400,
      fontFamily: "Brandon Grotesque",
    },
    caption: {
      fontSize: 18,
      fontWeight: 400,
      fontStyle: "italic",
      fontFamily: "Brandon Grotesque",
    },
  },
});

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.up("lg")]: {
    fontSize: 36,
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.up("sm")]: {
    fontSize: 20,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 22,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 24,
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.up("md")]: {
    fontSize: 36,
  },
};

theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.up("sm")]: {
    fontSize: 26,
    lineHeight: "37px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 36,
    lineHeight: "51px"
  },
};

theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.up("lg")]: {
    fontSize: 24,
  },
};

export default theme;
