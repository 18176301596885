import { Box } from '@mui/material'
import React, { useState } from 'react'
import DTable from '../../../components/DTable'
import filterIcon from '../../../assets/images/sliders.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';
import { Image, TextInput } from 'react-native-web';
import classNames from 'classnames';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Import tools 
import Search from '../Tools/Search'
import {HeaderTab, TabItems, TabItem, Filter} from '../Tools/Tab'
import Title from '../Tools/Title'
import GoBack from '../Tools/BoBack';


const pendding = {
    head : [
        {
            label: 'N°',
            width: 10.66,
        },
        {
            label: 'Date',
            width: 13.66,
        },
        {
            label: 'Client',
            width: 16.66,
        },
        {
            label: 'Adresse',
            width: 19.66,
        },
        {
            label: false,
            width: 26.66,
        },
        {
            label: false,
            width: 12.66,
        },
    ],
    body : [
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: 'En attente de signature client',
            },
            {
              type: 'text',
              text: <AccessTimeIcon />,
              colorIcon: '#F4EB7C',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: 'En attente de signature client',
            },
            {
              type: 'text',
              text: <AccessTimeIcon />,
              colorIcon: '#F4EB7C',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: 'En attente de signature client',
            },
            {
              type: 'text',
              text: <AccessTimeIcon />,
              colorIcon: '#F4EB7C',
            },
          ]
        },
    ]
}
const Signed = {
    head : [
      {
        label: 'N°',
        width: 10.66,
      },
      {
          label: 'Date',
          width: 15.66,
      },
      {
          label: 'Client',
          width: 16.66,
      },
      {
          label: 'Adresse',
          width: 19.66,
      },
      {
          label: false,
          width: 28.66,
      },
      {
          label: false,
          width: 8.66,
      },
    ],
    body : [
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'more',
              text: 'Créer la fiche d’intervention',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <CheckCircleIcon />
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'more',
              text: 'Créer la fiche d’intervention',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <CheckCircleIcon />
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'more',
              text: 'Créer la fiche d’intervention',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <CheckCircleIcon />
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'more',
              text: 'Créer la fiche d’intervention',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <CheckCircleIcon />
            },
          ]
        },
    ]
}
const refused = {
    head : [
      {
        label: 'N°',
        width: 10.66,
      },
      {
          label: 'Date',
          width: 13.66,
      },
      {
          label: 'Client',
          width: 16.66,
      },
      {
          label: 'Adresse',
          width: 19.66,
      },
      {
          label: false,
          width: 33,
      },
      {
          label: false,
          width: 6.22,
      },
    ],
    body : [
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
    ]
}
const Draft = {
    head : [
      {
        label: 'N°',
        width: 12.66,
      },
      {
          label: 'Date',
          width: 12.66,
      },
      {
          label: 'Client',
          width: 17.66,
      },
      {
          label: 'Adresse',
          width: 20.66,
      },
      {
          label: false,
          width: 35,
      },
    ],
    body : [
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'updelete',
              updateText: 'Modifier',
              deleteText: 'Supprimer',
            },
          ]
        },
    ]
}



export default function Quotes({goBack}) {
    const [search, setSearch] = useState('');
    const [currentTab, setCurrentTab] = useState(1)

  return (
    <Box>
      <GoBack onClick={goBack}><ArrowBackIcon /> Retour</GoBack>
      <HeaderTab className='header-tab'>
          <Title>Devis</Title>
          <Filter>
              <TabItems>
                  <TabItem className={classNames({hovered: currentTab == 1})} onClick={() => setCurrentTab(1)}>En attente</TabItem>
                  <TabItem className={classNames({hovered: currentTab == 2})} onClick={() => setCurrentTab(2)}>Signés</TabItem>
                  <TabItem className={classNames({hovered: currentTab == 3})} onClick={() => setCurrentTab(3)}>Refusés</TabItem>
                  <TabItem className={classNames({hovered: currentTab == 4})} onClick={() => setCurrentTab(4)}>Brouillons</TabItem>
              </TabItems>
              <Search className={'searchinput'}>
                  <TextInput type="search" placeholder="Recherche" />
              </Search>
              <Box className='filterIcon'><Image source={filterIcon} style={{width: 30, height: 30}} /></Box>
          </Filter>
      </HeaderTab>
        

      {currentTab == 1 &&
          <DTable data={pendding} keyprop="pendding" />
      }
      {currentTab == 2 &&
          <DTable data={Signed} keyprop="Signed" />
      }
      {currentTab == 3 &&
          <DTable data={refused} keyprop="refused" />
      }
      {currentTab == 4 &&
          <DTable data={Draft} keyprop="Draft" />
      }
    </Box>
  )
}
