import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Span = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "textRainbow",
  name: "Span",
})(({ theme, textRainbow }) => ({
  fontFamily: "Brandon Grotesque",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "26px",
  /* identical to box height */
  textAlign: "center",
  textTransform: "uppercase",

  /* rb */

  background: textRainbow
    ? "linear-gradient(101.22deg, #3ec3ec 6.23%, #ecdd3b 90.48%)"
    : "#ddd",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

const Button = styled(Box, {
  shouldForwardProp: (prop) => prop !== "type" && prop !== "fullwidth",
  name: "Button",
})(({ theme, fullwidth }) => ({
  border: "none",
  padding: theme.spacing(2.5, 0),
  width: fullwidth ? 336 : 242,
  cursor: "pointer",
  backgroundColor: "black",
  clipPath:
    "polygon(10% 0, 80% 0%, 100% 0, 100% 70%, 90% 100%, 20% 100%, 0 100%, 0 30%)",

  [theme.breakpoints.up("lg")]: {
    width: 293,
  },
}));

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "shadow",
  name: "Container",
})(({ theme, shadow }) => ({
  // boxShadow:
  //   "-3px -3px 48px rgba(49, 239, 255, 0.4), 3px 3px 26px rgba(49, 239, 255, 0.4)",
  // backgroundColor: "transparent",
  filter: shadow ? "drop-shadow(0px 0px 20px rgba(49, 239, 255, .6))" : "none",
}));

export default function CustomButton({
  children,
  shadow,
  handleClick,
  textRainbow,
  fullwidth,
}) {
  return (
    <Container shadow={shadow}>
      <Button onClick={handleClick} component={"button"} fullwidth={fullwidth}>
        <Span
          fontSize={{ xs: fullwidth ? 18 : 14, lg: 18 }}
          textRainbow={textRainbow}
        >
          {children}
        </Span>
      </Button>
    </Container>
  );
}
