import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import {keyframes} from "@emotion/react";

const rainbowEffect = keyframes`
    0% { background-position: left }
    50% { background-position: right }
    100% { background-position: left }
`

const Span = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "textRainbow",
    name: "Span",
})(({ theme, textRainbow }) => ({
    fontFamily: "Brandon Grotesque",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    /* identical to box height */
    textAlign: "center",
    textTransform: "uppercase",

    /* rb */

    background: textRainbow
        ? theme.palette.text.gradientText
        : "#ddd",
    backgroundClip: "text",
    textFillColor: "transparent",
    backgroundSize: "150% 150%",
}));

const Button = styled(Box, {
    shouldForwardProp: (prop) => prop !== "type" && prop !== "fullwidth",
    name: "Button",
})(({ theme, fullwidth }) => ({
    border: "none",
    padding: theme.spacing(2.5, 0),
    width: fullwidth ? 336 : 242,
    cursor: "pointer",
    backgroundColor: "black",
    clipPath:
        "polygon(10% 0, 80% 0%, 100% 0, 100% 70%, 90% 100%, 20% 100%, 0 100%, 0 30%)",

    /**/
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transitionProperty: "all",
    transitionDuration: "0.2s",
    transitionTimingFunction: "linear",
    transitionDelay: "0s",
    position: "relative",
    transform: "translateZ(0)",
    overflow: "hidden",
    "&:before": {
        position: "absolute",
        bottom: 0,
        left: 0,
        transitionProperty: "transform",
        transitionDuration: "0.5s",
        transitionTimingFunction: "linear",
        transitionDelay: "0s",
        display: "block",
        width: "310%",
        height: "310%",
        content: '""',
        transform: "scale(0.5)",
        transformOrigin: "center center",
        background: theme.palette.secondary.main,
        borderRadius: "50%",
    },
    "&:after": {
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        content: '"CONTACT"',
        color: "transparent",
        fontFamily: "Brandon Grotesque",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
        fontSize: 18,
    },
    /**/


    "&:active": {
        transform: "scale(0.9)",
        transition: "all 0.3s",
        outline: "none"
    },

    "&:hover": {
        "p": {
            animation: `${rainbowEffect} 2s ease-in-out infinite`
        },
        "&:before": {
            transform: "scale(2)",
            transformOrigin: "center center",
        },
        "&:after": {
            transitionDelay: "0.2s",
            transitionProperty: "all",
            transitionDuration: "0.2s",
            transitionTimingFunction: "linear",
            color: "black",
            transform: "scale(1)"
        }
    },

    [theme.breakpoints.up("lg")]: {
        width: 290,
    },
}));

const Container = styled(Box, {
    shouldForwardProp: (prop) => prop !== "shadow",
    name: "Container",
})(({ theme, shadow }) => ({
    // boxShadow:
    //   "-3px -3px 48px rgba(49, 239, 255, 0.4), 3px 3px 26px rgba(49, 239, 255, 0.4)",
    // backgroundColor: "transparent",
    filter: shadow ? "drop-shadow(0px 0px 20px rgba(49, 239, 255, .6))" : "none",
}));



export default function CustomButton({
                                         children,
                                         shadow,
                                         handleClick,
                                         textRainbow,
                                         fullwidth,
                                     }) {
    return (
        <Container shadow={shadow}>
            <Button onClick={handleClick} component={"button"} fullwidth={fullwidth}>
                <Span
                    fontSize={{ xs: fullwidth ? 18 : 14, lg: 18 }}
                    lineHeight={{xs: "26px"}}
                    textRainbow={textRainbow}
                >
                    {children}
                </Span>
            </Button>
        </Container>
    );
}
