import React, { useState } from "react";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { CheckCircle, DownloadForOffline } from "@mui/icons-material";
import { CustomButton } from "../../../components/buttons";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import partnersService from "../../../store/apis/partnersApis";
import { useSelector } from "react-redux";
import Congrats from "../congrats/Congrats";
import { fileSize } from "../../../config/utils";

const Question = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyBauhaus,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "30px",
  color: "#002C3A",
  textAlign: "center",
  marginBottom: "45px",
}));

const Row = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  maxWidth: 1010,
  margin: "auto",
  flexWrap: "wrap",
};

const Input = styled("input")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  outline: "none",
  fontSize: theme.spacing(1.8),
  height: 45,
  width: "100%",
  padding: theme.spacing(1, 2),
  border: `1px solid rgba(62, 195, 236, 1)`,
  background: "transparent",
  "&:focus": {
    backgroundColor: "rgba(62, 195, 236, 0.08)",
    border: `1px dashed ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: theme.spacing(2.2),
  },
}));

const schema = yup.object({}).required();

export default function DocumentsNeed({ goBack }) {
  const { user } = useSelector((state) => state.userAuth);
  const [selectedFileKbis, setSelectedFileKbis] = useState();
  const [selectedFileCni, setSelectedFileCni] = useState();
  const [selectedFileAtAs, setSelectedFileAtAs] = useState();
  const [selectedFileIban, setSelectedFileIban] = useState();
  const [selectedFileSelfi, setSelectedFileSelfi] = useState();
  const [selectedFileCv, setSelectedFileCv] = useState();
  const [errorFiles, setErrorFiles] = useState(false);
  const [errorFileSize, setErrorFileSize] = useState(false);
  const [errorTypeFileSize, setErrorTypeFileSize] = useState();
  const [loading, setLoading] = useState(false);
  const [partenaire, setPartenaire] = useState();
  const [error, setError] = useState(false);
  const [congrat, setCongrat] = useState(false);
  let userData = user.user?.data ? user.user?.data : user.data;

  const changeHandler = (event, type) => {
    if (fileSize(event.target.files[0].size) > 2) {
      setErrorFileSize(true);
      setErrorTypeFileSize(type);
      return;
    }
    setErrorFileSize(false);
    setErrorTypeFileSize();
    switch (type) {
      case "kbis":
        setSelectedFileKbis(event.target.files[0]);
        break;
      case "cni":
        setSelectedFileCni(event.target.files[0]);
        break;
      case "AtAs":
        setSelectedFileAtAs(event.target.files[0]);
        break;
      case "iban":
        setSelectedFileIban(event.target.files[0]);
        break;
      case "selfi":
        setSelectedFileSelfi(event.target.files[0]);
        break;
      case "cv":
        setSelectedFileCv(event.target.files[0]);
        break;
    }
    /* setSelectedFile(event.target.files, ...selectedFile]);
        setValue("files", [event.target.files, ...selectedFile]) */
    // setIsSelected(true);
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const HandleRegister = (data) => {
    const isValid =
      selectedFileKbis &&
      selectedFileAtAs &&
      selectedFileCni &&
      selectedFileIban &&
      selectedFileSelfi;

    if (!isValid) {
      setErrorFiles(true);
      retun;
    }

    setError(false);
    setLoading(true);
    const { link } = data;

    const obj = {
      web_site: link,
    };
    /* console.log(
      obj,
      selectedFileKbis,
      selectedFileIban,
      selectedFileCni,
      selectedFileAtAs,
      selectedFileSelfi,
      selectedFileCv
    ); */
    //return;
    partnersService
      .editPartner(user.data?.id, obj)
      .then((res) => {
        /* const { id } = res; */
        const formData = new FormData();
        if (selectedFileCv) formData.append("cv", selectedFileCv);
        formData.append("iban", selectedFileIban);
        formData.append("kbis", selectedFileKbis);
        formData.append("cni", selectedFileCni);
        formData.append("selfi", selectedFileSelfi);
        formData.append("attestation", selectedFileAtAs);

        if (
          selectedFileIban &&
          selectedFileKbis &&
          selectedFileCni &&
          selectedFileSelfi &&
          selectedFileAtAs
        ) {
          partnersService.addFilesPartner(userData.id, formData).then((res) => {
            if (res?.response?.status === 500) {
              setLoading(false);
              setError(true);
            } else {
              setLoading(false);
              setPartenaire(res);
              setCongrat(res);
            }
          });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <Box>
      <Question sx={{ color: "#fff" }}>Documents officiels</Question>
      <Box sx={Row} justifyContent={"center"} color={"#fff"}>
        {error && (
          <Box>
            <Typography fontSize={{ xs: 16 }} color="red">
              Une erreur est survenue, merci de réessayer plus tard
            </Typography>
          </Box>
        )}

        <Box flexBasis={{ xs: "100%", md: "100%" }}>
          <Box sx={Row}>
            <Box sx={{ padding: 1 }} flexBasis={{ xs: "100%", md: "50%" }}>
              <Typography variant="caption">Kbis de moins de 3 mois</Typography>

              <input
                id={"upload-kbis"}
                {...register("kbis")}
                type={"file"}
                onChange={(event) => changeHandler(event, "kbis")}
                hidden
                accept={
                  "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
              />

              <Box component={"label"} htmlFor="upload-kbis">
                <Box
                  sx={{
                    border: "1px solid rgba(62, 195, 236, 1)",
                    height: 45,
                    padding: 1,
                    marginBottom: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textTransform: "uppercase",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                >
                  {!selectedFileKbis ? (
                    <>
                      <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                      cliquez ici pour télécharger
                    </>
                  ) : (
                    <>
                      <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                      {selectedFileKbis?.name}
                    </>
                  )}
                </Box>
              </Box>

              {errorFileSize && errorTypeFileSize === "kbis" && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Le fichier dépassent la limite autorisée (2MB). Veuillez
                  sélectionner un fichier plus petit.
                </Typography>
              )}
              {errorFiles && !selectedFileKbis && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Ce document est obligatoire
                </Typography>
              )}
            </Box>
            <Box sx={{ padding: 1 }} flexBasis={{ xs: "100%", md: "50%" }}>
              <Typography variant="caption">CNI recto / verso</Typography>
              <input
                id={"upload-cni"}
                {...register("cni")}
                type={"file"}
                onChange={(event) => changeHandler(event, "cni")}
                hidden
                accept={
                  "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
              />

              <Box component={"label"} htmlFor="upload-cni">
                <Box
                  sx={{
                    border: "1px solid rgba(62, 195, 236, 1)",
                    height: 45,
                    padding: 1,
                    marginBottom: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textTransform: "uppercase",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                >
                  {!selectedFileCni ? (
                    <>
                      <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                      cliquez ici pour télécharger
                    </>
                  ) : (
                    <>
                      <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                      {selectedFileCni?.name}
                    </>
                  )}
                </Box>
              </Box>
              {errorFileSize && errorTypeFileSize === "cni" && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Le fichier dépassent la limite autorisée (2MB). Veuillez
                  sélectionner un fichier plus petit.
                </Typography>
              )}
              {errorFiles && !selectedFileCni && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Ce document est obligatoire
                </Typography>
              )}
            </Box>
            <Box sx={{ padding: 1 }} flexBasis={{ xs: "100%", md: "50%" }}>
              <Typography variant="caption">Attestation d'assurance</Typography>
              <input
                id={"upload-attestationAssurance"}
                {...register("attestationAssurance")}
                type={"file"}
                onChange={(event) => changeHandler(event, "AtAs")}
                hidden
                accept={
                  "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
              />

              <Box component={"label"} htmlFor="upload-attestationAssurance">
                <Box
                  sx={{
                    border: "1px solid rgba(62, 195, 236, 1)",
                    height: 45,
                    padding: 1,
                    marginBottom: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textTransform: "uppercase",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                >
                  {!selectedFileAtAs ? (
                    <>
                      <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                      cliquez ici pour télécharger
                    </>
                  ) : (
                    <>
                      <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                      {selectedFileAtAs?.name}
                    </>
                  )}
                </Box>
              </Box>
              {errorFileSize && errorTypeFileSize === "AtAs" && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Le fichier dépassent la limite autorisée (2MB). Veuillez
                  sélectionner un fichier plus petit.
                </Typography>
              )}
              {errorFiles && !selectedFileAtAs && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Ce document est obligatoire
                </Typography>
              )}
            </Box>
            <Box sx={{ padding: 1 }} flexBasis={{ xs: "100%", md: "50%" }}>
              <Typography variant="caption">IBAN</Typography>
              <input
                id={"upload-iban"}
                {...register("iban")}
                type={"file"}
                onChange={(event) => changeHandler(event, "iban")}
                hidden
                accept={
                  "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
              />

              <Box component={"label"} htmlFor="upload-iban">
                <Box
                  sx={{
                    border: "1px solid rgba(62, 195, 236, 1)",
                    height: 45,
                    padding: 1,
                    marginBottom: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textTransform: "uppercase",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                >
                  {!selectedFileIban ? (
                    <>
                      <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                      cliquez ici pour télécharger
                    </>
                  ) : (
                    <>
                      <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                      {selectedFileIban?.name}
                    </>
                  )}
                </Box>
              </Box>
              {errorFileSize && errorTypeFileSize === "iban" && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Le fichier dépassent la limite autorisée (2MB). Veuillez
                  sélectionner un fichier plus petit.
                </Typography>
              )}
              {errorFiles && !selectedFileIban && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Ce document est obligatoire
                </Typography>
              )}
            </Box>
            <Box sx={{ padding: 1 }} flexBasis={{ xs: "100%", md: "50%" }}>
              <Typography variant="caption">Selfi</Typography>
              <input
                id={"upload-selfi"}
                {...register("selfi")}
                type={"file"}
                onChange={(event) => changeHandler(event, "selfi")}
                hidden
                accept={"image/png, image/jpeg"}
              />

              <Box component={"label"} htmlFor="upload-selfi">
                <Box
                  sx={{
                    border: "1px solid rgba(62, 195, 236, 1)",
                    height: 45,
                    padding: 1,
                    marginBottom: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textTransform: "uppercase",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                >
                  {!selectedFileSelfi ? (
                    <>
                      <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                      cliquez ici pour télécharger
                    </>
                  ) : (
                    <>
                      <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                      {selectedFileSelfi?.name}
                    </>
                  )}
                </Box>
              </Box>
              {errorFileSize && errorTypeFileSize === "selfi" && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Le fichier dépassent la limite autorisée (2MB). Veuillez
                  sélectionner un fichier plus petit.
                </Typography>
              )}
              {errorFiles && !selectedFileSelfi && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Ce document est obligatoire
                </Typography>
              )}
            </Box>
            <Box sx={{ padding: 1 }} flexBasis={{ xs: "100%", md: "50%" }}>
              <Typography variant="caption">
                Ajouter un CV (facultatif)
              </Typography>
              <input
                id={"upload-cv"}
                {...register("cv")}
                type={"file"}
                onChange={(event) => changeHandler(event, "cv")}
                hidden
                accept={
                  "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
              />

              <Box component={"label"} htmlFor="upload-cv">
                <Box
                  sx={{
                    border: "1px solid rgba(62, 195, 236, 1)",
                    height: 45,
                    padding: 1,
                    marginBottom: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textTransform: "uppercase",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                >
                  {!selectedFileCv ? (
                    <>
                      <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                      cliquez ici pour télécharger
                    </>
                  ) : (
                    <>
                      <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                      {selectedFileCv?.name}
                    </>
                  )}
                </Box>
              </Box>

              {errorFileSize && errorTypeFileSize === "cv" && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  Le fichier dépassent la limite autorisée (2MB). Veuillez
                  sélectionner un fichier plus petit.
                </Typography>
              )}
              {/* {errors.cv && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.cv.message}
                </Typography>
              )} */}
            </Box>
            <Box sx={{ padding: 1 }} flexBasis={{ xs: "100%", md: "50%" }}>
              <Typography variant={"caption"} component={"label"}>
                Ajouter un lien vers votre site (facultatif)
              </Typography>

              <Input
                type={"text"}
                placeholder={"Saisissez le lien ici"}
                {...register("link")}
              />
              {/*  {errors.link && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.link.message}
                </Typography>
              )} */}
            </Box>
            <Box
              display={"flex"}
              mb={4}
              mt={4}
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
              flexBasis={{ xs: "100%", md: "100%" }}
            >
              {" "}
              {loading ? (
                <CircularProgress />
              ) : (
                <CustomButton
                  type="blue"
                  handleClick={handleSubmit(HandleRegister)}
                >
                  envoyer
                </CustomButton>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Congrats congrat={congrat} onClose={() => setCongrat(false)} />
    </Box>
  );
}
