import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  Dialog,
  FormControlLabel,
  Slide,
  Switch,
  Typography,
  useMediaQuery,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import { EmailInput, TextInput } from "../../../components/inputs";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";

import clientsService from "../../../store/apis/clientsApis";

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const schema = yup
  .object({
    email: yup
      .string()
      .email("entrer un email valide")
      .required("email est requis"),
    //password: yup.string().required(),
    prenom: yup.string().required("prenom est requis"),
    nom: yup.string().required("nom est requis"),
    telephone: yup
      .string()
      .required("telephone est requis")
      .matches(phoneRegExp, "entrez un numéro de téléphone valide"),
    adresse: yup.string().required("adresse est requis"),
    codePostal: yup
      .string()
      .required("code postal est requis")
      .matches(/\d{2}[ ]?\d{3}/, "entrer un code postal valide"),
    ville: yup.string().required("ville est requis"),
    //message: yup.string().required(),
    // nom_societe: yup.string().required(),
    //function_client: yup.string().required(),
    type_batiment: yup.string().required("type de batiment est requis"),
    type_client: yup.string().required("type de client est requis"),
    operateur: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .nullable(),
    problemes: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .nullable(),
    urgent: yup.string().required(),
  })
  .required();

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} unmountOnExit />;
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 46,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "transparent",
    border: "2px solid rgba(62, 195, 236, 1)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    //margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 16,
    },
    "&:first-of-type": {
      borderRadius: 16,
    },
  },
}));

const obj = [
  { type: "Immeuble", key: "Immeuble" },
  { type: "Maison", key: "Maison" },
  { type: "Local commercial", key: "Local commercial" },
];

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const operatorOptions = [{ value: "ORANGE", label: "ORANGE" }];
const problemsOptions = [
  {
    value: "Câblage réseau et déploiement Wifi",
    label: "Câblage réseau et déploiement Wifi",
  },
  { value: "Antenne TV", label: "Antenne TV" },
  { value: "Interphonie", label: "Interphonie" },
  { value: "Électricité courant faible", label: "Électricité courant faible" },
  { value: "Autres", label: "Autres" },
];

export default function EditCustomerForm({
  edit,
  onClose,
  setEditCongrat,
  id,
}) {
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dataErr, setDataErr] = useState(null);
  const [dataLoad, setDataLoad] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [alignment, setAlignment] = useState(null);
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    setValue("type_client", alignment, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [alignment, setValue]);

  useEffect(() => {
    setDataLoad(true);
    clientsService
      .detailClient(id)
      .then((res) => {
        setDataLoad(false);
        const data = res;
        const {
          email,
          prenom,
          nom,
          code_postal,
          telephone,
          adresse,
          ville,
          type_batiment,
          type_client,
          operateur,
          problemes,
          urgent,
        } = res;

        setValue("prenom", prenom, { shouldValidate: true, shouldDirty: true });
        setValue("nom", nom, { shouldValidate: true, shouldDirty: true });
        setValue("email", email, { shouldValidate: true, shouldDirty: true });
        setValue("codePostal", code_postal, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue("telephone", telephone, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue("adresse", adresse, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue("ville", ville);
        setValue("type_batiment", data.demandes_client[0].type_batiment, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setSelected(data.demandes_client[0].type_batiment);
        setAlignment(type_client);
        // setValue(
        //   "operateur",
        //   operateur.length > 0 ? { label: null, value: null } : null,
        //   {
        //     shouldValidate: true,
        //     shouldDirty: true,
        //   }
        // );
        setValue(
          "problemes",
          problemes.length > 0
            ? { label: problemes[0], value: problemes[0] }
            : null,
          {
            shouldValidate: true,
            shouldDirty: true,
          }
        );
        setValue("urgent", !!urgent, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setData(res);
      })
      .catch((err) => {
        setDataLoad(false);
        setDataErr(err);
      });
  }, [id, setValue]);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const {
      email,
      prenom,
      nom,
      codePostal,
      telephone,
      adresse,
      ville,
      type_batiment,
      type_client,
      operateur,
      problemes,
      urgent,
    } = data;

    const obj = {
      email,
      prenom,
      nom,
      telephone,
      adresse,
      code_postal: codePostal.toString(),
      ville,
      nom_societe: "test",
      function_client: "Ingenieur",
      type_batiment,
      type_client: type_client.toString(),
      operateur: [`${operateur.value}`],
      problemes: [`${problemes.value}`],
      urgent: urgent ? 1 : 0,
    };

    clientsService
      .editClient(id, obj)
      .then((res) => {
        setLoading(false);
        onClose();
        setEditCongrat(true);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={edit}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{
        sx: {
          maxWidth: { md: 760, lg: 865 },
          width: { md: 760, lg: 865 },
          minHeight: 200,
        },
      }}
      onClose={onClose}
    >
      {dataLoad && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={200}
        >
          <CircularProgress />
        </Box>
      )}
      {data && (
        <Container maxWidth={"md"}>
          <Box mt={3} mb={3}>
            <Typography
              variant={"h2"}
              fontFamily={"Brandon Grotesque"}
              textAlign={"center"}
            >
              Modifier un client
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              variant="caption"
              component={"label"}
              fontSize={{ xs: 16, md: 24 }}
            >
              Problème rencontré par le client :
            </Typography>
            <Box
              display={"flex"}
              alignItems={{ md: "start", lg: "center" }}
              justifyConten={"center"}
              flexWrap={"wrap"}
              //gap={2}
            >
              <Box flexBasis={{ xs: "100%", md: "70%" }} mb={1}>
                <Box>
                  <Controller
                    name={"problemes"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        styles={customStyles}
                        placeholder={"---"}
                        options={problemsOptions}
                      />
                    )}
                  />
                </Box>
                {errors.problemes && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.problemes.value.message}
                  </Typography>
                )}
              </Box>
              <Box
                flexBasis={{ xs: "100%", md: "30%" }}
                display={"flex"}
                justifyContent={{ xs: "end", md: "center" }}
                alignItems={"center"}
              >
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16, md: 24 }}
                  mr={2}
                >
                  Urgent
                </Typography>
                <Controller
                  name="urgent"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      sx={{ mr: 0 }}
                      control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box mb={2}>
              <Typography
                variant="caption"
                component={"label"}
                fontSize={{ xs: 16, md: 24 }}
              >
                Type du client :
              </Typography>
              <Box>
                <StyledToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  sx={{
                    borderRadius: "12px",
                    border: "1px solid rgba(62, 195, 236, 1)",
                  }}
                >
                  <ToggleButton
                    value="Particulier"
                    sx={{
                      fontFamily: "Bauhaus",
                      fontWeight: "700",
                      fontSize: 18,
                      padding: "10px 32px",
                      borderRadius: 4,
                    }}
                  >
                    Particulier
                  </ToggleButton>
                  <ToggleButton
                    value="Hôtel & résidence"
                    sx={{
                      fontFamily: "Bauhaus",
                      fontWeight: "700",
                      fontSize: 18,
                      padding: "10px 32px",
                      borderRadius: 4,
                    }}
                  >
                    Hôtel & résidence
                  </ToggleButton>
                  <ToggleButton
                    value="Professionnel"
                    sx={{
                      fontFamily: "Bauhaus",
                      fontWeight: "700",
                      fontSize: 18,
                      padding: "10px 30px",
                      borderRadius: 4,
                    }}
                  >
                    Professionnel
                  </ToggleButton>
                </StyledToggleButtonGroup>
                {errors.type_client && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.type_client.message}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography
                variant="caption"
                component={"label"}
                fontSize={{ xs: 16, md: 24 }}
              >
                Type du bâtiment :
              </Typography>
              <Box
                display={"flex"}
                alignItems={"center"}
                //justifyContent={{ xs: "space-between", lg: "left" }}
                columnGap={"1%"}
                flexWrap={"wrap"}
                mb={4}
              >
                {obj.map((item, i) => {
                  if (alignment === "Particulier" && i === 2) return null;
                  return (
                    <Box
                      key={item.type}
                      onClick={() => {
                        setSelected(item.type);
                        setValue("type_batiment", item.type, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      sx={{
                        border: `1px solid`,
                        borderColor:
                          selected === item.type
                            ? "rgba(62, 195, 236, .2)"
                            : "rgba(62, 195, 236, 1)",
                        height: { xs: 76, md: 99 },
                        marginBottom: 0.5,
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontSize: 14,
                        flexBasis: { xs: "48%", md: "32%", lg: "26%" },
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography fontSize={{ xs: 18 }}>{item.type}</Typography>
                    </Box>
                  );
                })}
              </Box>
              {errors.type_batiment && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.type_batiment.message}
                </Typography>
              )}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              rowGap={2}
              flexWrap={"wrap"}
            >
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"Prénom*"}
                  register={{ ...register("prenom") }}
                />
                {errors.prenom && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.prenom.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"Nom**"}
                  register={{ ...register("nom") }}
                />
                {errors.nom && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.nom.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%" }}>
                <TextInput
                  placeholder={"adresse*"}
                  register={{ ...register("adresse") }}
                />
                {errors.adresse && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.adresse.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"code postal*"}
                  register={{ ...register("codePostal") }}
                />
                {errors.codePostal && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.codePostal.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"ville*"}
                  register={{ ...register("ville") }}
                />
                {errors.ville && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.ville.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <EmailInput register={{ ...register("email") }} />
                {errors.email && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.email.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"numéro de téléphone**"}
                  type={"tel"}
                  register={{ ...register("telephone") }}
                />
                {errors.telephone && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.telephone.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </form>

          <Box
            display={"flex"}
            mb={4}
            mt={4}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <CustomButton type={"red"} handleClick={onClose}>
                  retour
                </CustomButton>
                <CustomButton
                  type={"yellow"}
                  textColor={"white"}
                  handleClick={handleSubmit(onSubmit)}
                >
                  envoyer
                </CustomButton>
              </>
            )}
          </Box>
        </Container>
      )}
    </Dialog>
  );
}
