import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import star from "../../assets/images/Star-2.png";
import { styled } from "@mui/system";

const ProgressStyle = {
  background:
    "linear-gradient(360deg, rgba(62, 195, 236, 0.312) 0%, rgba(62, 195, 236, 0) 48.44%, rgba(62, 195, 236, 0.312) 100%)",
  border: "0.5px solid rgba(150, 247, 255, 0.5)",
  borderRadius: "2px",
  height: 21,
  mt: "20px",
  position: "relative",
};
const Star = styled("img")(({ theme }) => ({
  width: 40,
  height: 40,
  position: "absolute",
  right: 0,
  top: "50%",
  transform: "translate(50%, -50%)",
}));
const Progress = styled("div")(({ theme }) => ({
  width: 0,
  height: "100%",
  position: "relative",
  backgroundColor: theme.palette.secondary.main,
  transition: "all .25s linear",
}));
export default function ProgressBar({ percent }) {
  const [prc, setPrc] = useState(percent);
  useEffect(() => {
    if (percent > 100) {
      setPrc(100);
    } else if (percent < 0) {
      setPrc(0);
    } else {
      setPrc(percent);
    }
  }, [percent]);

  return (
    <Box className="ProgressBar">
      <Box sx={{ ml: "auto", mr: "auto", width: "244px" }}>
        <Box sx={ProgressStyle}>
          <Progress style={{ width: prc + "%" }}>
            <Star src={star} />
          </Progress>
        </Box>
      </Box>
    </Box>
  );
}
