import {ScrollView} from "react-native-web";
import {Box, Container, Typography} from "@mui/material";
import {HeadingLegals, SubHeadingLegals} from "../../components/headings";
import bgimg from "../../assets/images/bgs/bg1.png";
import {styled} from "@mui/system";
import theme from "../../assets/theme";
import ParagraphLegals from "../../components/HtmlTags/ParagraphLegals";
import LinkTag from "../../components/HtmlTags/LinkTag";
import Navbar from "../../components/navbar";
import {Footer} from "../../components";

const CustomContainer = styled(Box)({
    backgroundImage: 'url('+bgimg+')',
    backgroundSize: "100%",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
})

export default function Mentions() {
    return (
        <ScrollView style={{backgroundColor: theme.palette.background.main}}>
            <Navbar />
            <CustomContainer >
                <Box sx={{backgroundColor: theme.palette.background.backgroundMainOpacity}}>
                    <Container maxwidth="lg">
                        <HeadingLegals>
                            Mentions légales
                        </HeadingLegals>
                        <Box>
                            <SubHeadingLegals>Éditeur du site</SubHeadingLegals>
                            <ParagraphLegals>
                                Les présentes conditions d’utilisation régissent l’utilisation du site <LinkTag underline={"hover"}>https://www.fibme.fr/</LinkTag>
                            </ParagraphLegals>
                            <ParagraphLegals>
                                Fibme
                                <br/>
                                Siret : 901 827 931 00014
                            </ParagraphLegals>
                            <ParagraphLegals>
                                Adresse : PL Marcel Rebuffat, 91140, Villejust
                                <br/>
                                Tél : 06 31 30 01 22
                            </ParagraphLegals>
                        </Box>
                        <Box>
                            <SubHeadingLegals>Hébergeur</SubHeadingLegals>
                            <ParagraphLegals>
                                AWS EMEA
                                <br/>
                                SA au capital de 25 000 €
                                <br/>
                                RCS Nanterre 831 001 334 00018
                                <br/>
                                Siège social : 38 Avenue John F.Kennedy - L 1855 - 99137 LUXEMBOURG
                            </ParagraphLegals>
                        </Box>
                        <Box>
                            <SubHeadingLegals>Cookies</SubHeadingLegals>
                            <ParagraphLegals>Les cookies, qui sont l’ensemble des informations contenues dans un fichier et permettant de mémoriser et analyser les données de navigation, sont déposés lors de la navigation sur un site internet sur le disque dur de l’internaute par le biais du serveur du site internet visité, puis réutilisés pour les connexions suivantes. Les cookies permettent ensuite au site internet d’analyser les habitudes de navigation de l’internaute afin d’améliorer celle-ci.</ParagraphLegals>
                            <ParagraphLegals>
                                Les cookies utilisés sur le site de Fibme sont :
                                <br/>
                                - Les cookies strictement nécessaires
                                <br/>
                                - Les cookies d’analyses et de personnalisation
                                <br/>
                                - Les cookies de statistiques
                            </ParagraphLegals>
                            <ParagraphLegals>Des cookies sont utilisés sur notre site internet <LinkTag underline={"hover"}>https://www.fibme.fr/</LinkTag> et peuvent donc être installés sur votre terminal de connexion. Ces derniers ont une durée de vie maximale de 13 mois après l’écoulement desquels ils sont supprimés automatiquement de votre terminal.</ParagraphLegals>
                            <ParagraphLegals>Vous pouvez choisir d’accepter ou de refuser les cookies lors de votre navigation sur notre site internet.</ParagraphLegals>
                            <ParagraphLegals>Afin de refuser leur utilisation, vous pouvez vous référer aux procédures décrites ci-dessous concernant les principaux navigateurs internet et systèmes d’exploitation informatiques. Si vous choisissez de les accepter au moment de votre navigation, vous pouvez également procéder à leur suppression a posteriori.</ParagraphLegals>
                        </Box>
                        <Box>
                            <SubHeadingLegals>Liens hypertextes et cookies</SubHeadingLegals>
                            <ParagraphLegals>Le site <LinkTag underline={"hover"}>https://www.fibme.fr/</LinkTag> contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de Fibme. Cependant, Fibme n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</ParagraphLegals>
                            <ParagraphLegals>La navigation sur le site <LinkTag underline={"hover"}>https://www.fibme.fr/</LinkTag> est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation</ParagraphLegals>
                            <ParagraphLegals>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies :</ParagraphLegals>
                            <ParagraphLegals><strong>Sous Internet Explorer :</strong> onglet outil (pictogramme en forme de rouage en haut à droite) / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.</ParagraphLegals>
                            <ParagraphLegals><strong>Sous Firefox :</strong> en haut de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis aller dans l’onglet Options. Cliquer sur l’onglet Vie privée. Paramétrez les Règles de conservation sur : utiliser les paramètres personnalisés pour l’historique. Enfin décochez-la pour désactiver les cookies.</ParagraphLegals>
                            <ParagraphLegals><strong>Sous Safari :</strong> Cliquez-en haut à droite du navigateur sur le pictogramme de menu (symbolisé par un rouage). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section « Confidentialité », cliquez sur Paramètres de contenu. Dans la section « Cookies », vous pouvez bloquer les cookies.</ParagraphLegals>
                            <ParagraphLegals><strong>Sous Chrome :</strong> Cliquez-en haut à droite du navigateur sur le pictogramme de menu (symbolisé par trois lignes horizontales). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section « Confidentialité », cliquez sur préférences. Dans l’onglet « Confidentialité », vous pouvez bloquer les cookies.</ParagraphLegals>
                        </Box>
                    </Container>
                </Box>
            </CustomContainer>
            <Footer />
        </ScrollView>
    );
}