import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import headingBg from "../../assets/images/lines/vertical_line.png";

const Heading = styled(Box)(({ theme }) => ({
  // borderStyle: "dotted",
  // borderImage:
  //   "linear-gradient(90deg, rgba(3,235,255,0) 0%, rgba(3,235,255,1) 50%, rgba(3,235,255,0.0032387955182072714) 100%)",
  // borderImageWidth: 2,

  backgroundImage: `url(${headingBg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
  margin: theme.spacing(4, 0),
}));

function SectionsHeading({ children }) {
  return (
    <Heading component={"div"}>
      <Typography
        variant="h1"
        component="h2"
        padding={"24px 0"}
        textAlign={"center"}
        textTransform={"uppercase"}
      >
        {children}
      </Typography>
    </Heading>
  );
}

export default SectionsHeading;
