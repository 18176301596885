import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "./fr.json";
import en from "./en.json";

i18n.use(initReactI18next).init({
  fallbackLng: "fr",
  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },

  resources: {
    en,
    fr,
  },

  keySeparator: false,
  compatibilityJSON: "v3",
});

export default i18n;
