import { Typography } from "@mui/material";
import {styled} from "@mui/system";

const CustomParaph = styled(Typography)(({theme, color, fontStyle}) => ({
    fontFamily: theme.typography.fontFamily,
    lineHeight: {xs: "24px", md: "29px"},
    color: color ? color : "#000000",
    paddingBottom: "25px",
    fontStyle: fontStyle ? fontStyle : "initial"
}))

function ParagraphLegals({children, color, fontStyle}) {
    return (
        <CustomParaph variant={"body2"} color={color} fontStyle={fontStyle}>
            {children}
        </CustomParaph>
    )
}

export default ParagraphLegals;
