import { CloseOutlined, DoneOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

const CustomButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isDisabled",
  name: "CustomButton",
})(({ theme, isDisabled }) => ({
  border: "none",
  padding: theme.spacing(2, 0),
  width: 143,
  cursor: "pointer",
  backgroundImage: isDisabled
    ? "linear-gradient(180deg, rgba(0, 44, 58, 0) 0%, rgba(0, 44, 58, .1) 67.71%)"
    : "linear-gradient(180deg, rgba(62, 195, 236, 0) 0%, rgba(62, 195, 236, 0.5) 67.71%)",
  clipPath:
    "polygon(10% 0, 80% 0%, 100% 0, 100% 70%, 90% 100%, 20% 100%, 0 100%, 0 30%)",
  '+ button': {
    marginLeft: 15,
  }
}));

export default function ValidationButton({
  children,
  handleClick,
  disabled,
  icon,
}) {
  return (
    <CustomButton
      disabled={disabled}
      isDisabled={disabled}
      onClick={handleClick}
      color={"inherit"}
      startIcon={
        icon ? (
          <CloseOutlined fontSize={"large"} />
        ) : (
          <DoneOutlined fontSize={"large"} />
        )
      }
    >
      <Typography fontSize={{ xs: 16 }} fontFamily={"Bauhaus"} fontWeight={700}>
        {children}
      </Typography>
    </CustomButton>
  );
}
