import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Text } from "react-native-web";
import { Window, WinItem, Icon, Img } from "../../../components/Window";
import InterventionSheets from "./InterventionSheets";
import InterventionVouchers from "./InterventionVouchers";
import PDFBon from "./PDFBon";
import PDFDevis from "./PDFDevis";
import PDFFile from "./PDFFile";
import Quotes from "./Quotes";
import PrestationService from "../../../store/apis/PrestationApi";
import { limitPagination, STATUS_PENDING } from "../../../config/keys";

export default function Documents() {
  const [activePage, setActivePage] = useState("Window");

  /*   const [items, setItems] = useState([
    {
      text: "Devis",
      count: devisCount,
      page: "Quotes",
    },
    {
      text: "Fiches d’intervention",
      count: ficheInterventionCount,
      page: "InterventionSheets",
    },
    {
      text: "Bons d’intervention ",
      count: false,
      page: "InterventionVouchers",
    },
    {
      text: "Factures",
      count: false,
      page: "PDFFile",
    },
  ]); */

  const [loadingDevis, setLoadingDevis] = useState(true);
  const [loadingFicheIntervention, setLoadingFicheIntervention] =
    useState(true);
  const [devis, setDevis] = useState();
  const [ficheIntervention, setFicheIntervention] = useState();
  const [devisCount, setDevisCount] = useState(0);
  const [ficheInterventionCount, setFicheInterventionCount] = useState(0);
  const [page, setPage] = useState(1);
  const [showPaginationDevis, setShowPaginationDevis] = useState(false);
  const [showPaginationFI, setShowPaginationFI] = useState(false);
  const [status, setStatus] = useState(STATUS_PENDING);
  const [currentTabDevis, setCurrentTabDevis] = useState(1);
  const [currentTabFI, setCurrentTabFI] = useState(1);

  useEffect(() => {
    if (activePage === "Quotes" || activePage === "Window") {
      setDevis([]);
      fetchDevis();
    }
    if (activePage === "InterventionSheets" || activePage === "Window") {
      setFicheIntervention([]);
      fetchFicheIntervention();
    }
  }, [page, status]);

  const fetchDevis = () => {
    setShowPaginationDevis(false);
    setLoadingDevis(true);

    PrestationService.getListDocuments(page, "devis", status)
      .then((response) => {
        if (status === STATUS_PENDING)
          setDevisCount(response["hydra:totalItems"]);
        setDevis(response["hydra:member"]);
        setTimeout(() => {
          setLoadingDevis(false);
          response["hydra:member"].length === limitPagination
            ? setShowPaginationDevis(true)
            : setShowPaginationDevis(false);
        }, 500);
      })
      .catch((err) => console.log(err));
  };

  const fetchFicheIntervention = () => {
    setShowPaginationFI(false);
    setLoadingFicheIntervention(true);

    PrestationService.getListDocuments(page, "fiche_intervention", status)
      .then((response) => {
        if (status === STATUS_PENDING)
          setFicheInterventionCount(response["hydra:totalItems"]);
        setFicheIntervention(response["hydra:member"]);
        setTimeout(() => {
          setLoadingFicheIntervention(false);
          response["hydra:member"].length === limitPagination
            ? setShowPaginationFI(true)
            : setShowPaginationFI(false);
        }, 500);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {(() => {
        switch (activePage) {
          case "Window":
            return (
              <Window>
                <WinItem key={"cts-1"} color="#fff" count={devisCount}>
                  <Box
                    minHeight="150px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() =>
                      !loadingDevis ? setActivePage("Quotes") : console.log()
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <Text className="label">
                      Devis {loadingDevis && <CircularProgress size="15px" />}
                    </Text>
                  </Box>
                </WinItem>
                <WinItem
                  key={"cts-2"}
                  color="#fff"
                  count={ficheInterventionCount}
                >
                  <Box
                    minHeight="150px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() =>
                      !loadingFicheIntervention
                        ? setActivePage("InterventionSheets")
                        : console.log()
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <Text className="label">
                      Fiches d’intervention{" "}
                      {loadingFicheIntervention && (
                        <CircularProgress size="15px" />
                      )}
                    </Text>
                  </Box>
                </WinItem>
                {/* <WinItem key={"cts-3"} color="#fff" count={""}>
                  <Box
                    minHeight="150px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => setActivePage("InterventionVouchers")}
                  >
                    <Text className="label">Bons d’intervention</Text>
                  </Box>
                </WinItem> */}
                {/* <WinItem key={"cts-4"} color="#fff" count={devisCount}>
                  <Box
                    minHeight="150px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => setActivePage("Quotes")}
                  >
                    <Text className="label">Devis</Text>
                  </Box>
                </WinItem> */}
                {/* {items.map((item, ind) => {
                  return (
                    <WinItem
                      key={"cts-" + ind}
                      color="#fff"
                      count={item.count ? item.count : ""}
                    >
                      <Box
                        minHeight="150px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => setActivePage(item.page)}
                      >
                        <Text className="label">{item.text}</Text>
                      </Box>
                    </WinItem>
                  );
                })} */}
              </Window>
            );

          case "Quotes":
            return (
              <Quotes
                goBack={() => setActivePage("Window")}
                data={devis}
                page={page}
                setPage={setPage}
                status={status}
                setStatus={setStatus}
                loadingDevis={loadingDevis}
                showPagination={showPaginationDevis}
                currentTab ={currentTabDevis}
                setCurrentTab={setCurrentTabDevis}
              />
            );

          case "InterventionSheets":
            return (
              <InterventionSheets
                goBack={() => setActivePage("Window")}
                data={ficheIntervention}
                page={page}
                setPage={setPage}
                status={status}
                setStatus={setStatus}
                loadingDevis={loadingFicheIntervention}
                showPagination={showPaginationFI}
                currentTab ={currentTabFI}
                setCurrentTab={setCurrentTabFI}
              />
            );

          case "PDFFile":
            return <PDFFile goBack={() => setActivePage("Window")} />;

          case "PDFDevis":
            return <PDFDevis goBack={() => setActivePage("Window")} />;

          case "InterventionVouchers":
            return (
              <InterventionVouchers goBack={() => setActivePage("Window")} />
            );

          case "PDFBon":
            return <PDFBon goBack={() => setActivePage("Window")} />;
        }
      })()}
    </>
  );
}
