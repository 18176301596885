import { Box } from '@mui/material'
import React, { useState } from 'react'
import DTable from '../../../components/DTable'
import filterIcon from '../../../assets/images/sliders.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fontSize, styled } from '@mui/system';
import { Image, TextInput } from 'react-native-web';
import classNames from 'classnames';


// Import tools 
import Search from '../Tools/Search'
import {HeaderTab, TabItems, TabItem, Filter} from '../Tools/Tab'
import Title from '../Tools/Title'
import GoBack from '../Tools/BoBack';

const Recent = {
    head : [
        {
            label: 'N°',
            width: 14.66,
        },
        {
            label: 'Date',
            width: 15.66,
        },
        {
            label: 'Client',
            width: 16.66,
        },
        {
            label: 'Adresse',
            width: 19.66,
        },
        {
            label: 'Intervenant',
            width: 16.66,
        },
        {
            label: false,
            width: 16.66,
        },
    ],
    body : [
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: <HelpIcon />,
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: <HelpIcon />,
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: <HelpIcon />,
            },
          ]
        },
    ]
}
const Signed = {
    head : [
      {
        label: 'N°',
        width: 14.66,
      },
      {
          label: 'Date',
          width: 15.66,
      },
      {
          label: 'Client',
          width: 16.66,
      },
      {
          label: 'Adresse',
          width: 19.66,
      },
      {
          label: 'Intervenant',
          width: 16.66,
      },
      {
          label: false,
          width: 16.66,
      },
    ],
    body : [
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <CheckCircleIcon />
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <CheckCircleIcon />
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <CheckCircleIcon />
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <CheckCircleIcon />
            },
          ]
        },
    ]
}
const refused = {
    head : [
      {
        label: 'N°',
        width: 14.66,
      },
      {
          label: 'Date',
          width: 15.66,
      },
      {
          label: 'Client',
          width: 16.66,
      },
      {
          label: 'Adresse',
          width: 19.66,
      },
      {
          label: 'Intervenant',
          width: 16.66,
      },
      {
          label: false,
          width: 16.66,
      },
    ],
    body : [
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
        {
          columns: [
            {
              type: 'text',
              text: '123',
            },
            {
              type: 'text',
              text: '15/07/2021',
            },
            {
              type: 'text',
              text: 'Terry Stanton',
            },
            {
              type: 'text',
              text: '35 rue du Saint Lazar, Asnières sur Seine, 94100',
            },
            {
              type: 'text',
              text: 'Alexandre Quentin',
            },
            {
              type: 'status',
              color: '#F4EB7C',
              text: '',
              icon: <HighlightOffIcon />,
              colorIcon: '#FF5252',
            },
          ]
        },
    ]
}


export default function InterventionVouchers({goBack}) {
    const [search, setSearch] = useState('');
    const [currentTab, setCurrentTab] = useState(1)

  return (
    <Box>
      <GoBack onClick={goBack}><ArrowBackIcon /> Retour</GoBack>
        <HeaderTab className='header-tab'>
            <Title>Fiches d’intervention</Title>
            <Filter>
                <TabItems>
                    <TabItem className={classNames({hovered: currentTab == 1})} onClick={() => setCurrentTab(1)}>Récentes</TabItem>
                    <TabItem className={classNames({hovered: currentTab == 2})} onClick={() => setCurrentTab(2)}>Signés</TabItem>
                    <TabItem className={classNames({hovered: currentTab == 3})} onClick={() => setCurrentTab(3)}>Refusés</TabItem>
                </TabItems>
                <Search className={'searchinput'}>
                    <TextInput type="search" placeholder="Recherche" />
                </Search>
                <Box className='filterIcon'><Image source={filterIcon} style={{width: 30, height: 30}} /></Box>
            </Filter>
        </HeaderTab>
        

        {currentTab == 1 &&
            <DTable data={Recent} keyprop={'intvoucherRecent'} />
        }
        {currentTab == 2 &&
            <DTable data={Signed} keyprop={'intvoucherSigned'} />
        }
        {currentTab == 3 &&
            <DTable data={refused} keyprop={'intvoucherrefused'} />
        }
    </Box>
  )
}
