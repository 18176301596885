import axios from "axios";
import { base_url_api } from "../../config/keys";
import authHeader from "./authHeader";

const userAssistance = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/assistances`, data, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const assistanceService = { userAssistance };

export default assistanceService;
