import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import GoBack from "../Tools/BoBack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallClient from "./Popups/CallClient";
import {
  Modal,
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
} from "react-native-web";
import { Calendar } from "react-native-calendars";
import moment from "moment";
import CalendarService from "../../../store/apis/CalendarApi";
import { rdv_type_call } from "../../../config/keys";
import styles from "../../../assets/css/style";
import { styled } from "@mui/system";

const CalendarWrap = styled("div")(({ theme }) => ({
  borderRadius: 20,
  margin: "30px 0",

  ".css-text-901oao, .css-901oao, .p": {
    marginTop: 20,
    fontFamily: theme.typography.fontFamilyBauhaus,
    fontSize: 18,
    lineHeight: "21px",
    display: "block",
  },
  ".callayout": {
    display: "flex",
  },
  ".calbox": {
    flex: 1,
    marginRight: 25,
    backgroundImage:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(66, 199, 241, 0.122) 100%)",
    border: "1px solid rgba(66, 199, 241, 0.35)",
    padding: "0 30px 50px",

    ".r-backgroundColor-14lw9ot, .r-14lw9ot": {
      backgroundColor: "transparent",
    },
    ".r-width-1yvhtrz, .r-1yvhtrz": {
      width: 40,
    },
    '.r-height-mabqd8[role="button"], .r-mabqd8[role="button"]': {
      height: 36,
      background: "#f2F2F2",
      border: "1px solid #3dc3ec",
    },
    ".r-borderRadius-1867qdf, .r-1867qdf": {
      borderRadius: "0%",
    },
  },
  ".cre-list": {
    backgroundImage:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(66, 199, 241, 0.122) 100%)",
    border: "1px solid rgba(66, 199, 241, 0.35)",
    padding: 20,
    width: 190,

    span: {
      fontFamily: theme.typography.fontFamilyBauhaus,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "19px",
      textAlign: "center",
      color: "#002C3A",
      display: "block",
    },
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    border: "none",
    padding: 0,

    ".callayout": {
      flexDirection: "column",
      ".calbox": {
        marginRight: 0,
        marginBottom: 15,
      },
      ".cre-list": {
        width: "100%",
      },
    },
  },
}));
const CalendarLayout = styled("div")(({ theme }) => ({
  marginTop: 10,

  ".css-text-901oao, .css-901oao, .p": {
    fontSize: 15,
    margin: 0,
    lineHeight: "32px",
  },
  ".r-flex-13awgt0, .r-13awgt0": {
    backgroundColor: "rgba(14, 69, 74, 0.04)",
    margin: 2,
  },
  ".r-marginVertical-sfmlkt, .r-sfmlkt": {
    margin: 0,
  },
  ".r-paddingRight-1qfoi16, .r-1qfoi16": {
    justifyContent: "center",
    ".r-flexDirection-18u37iz, .r-18u37iz": {
      margin: "0 15px",
      minWidth: 116,
      justifyContent: "center",
    },
    ".r-padding-1sp51qo, .r-1sp51qo": {
      width: 35,
      height: 35,
      borderRadius: 35,
      backgroundColor: theme.palette.text.primary,
      color: "#fff",
      position: "relative",

      ".left, .right": {
        content: '""',
        position: "absolute",
        left: "50%",
        top: "50%",
        width: 11,
        height: 11,
        borderBottom: "2px solid #fff",
        borderRight: "2px solid #fff",
        transform: "translate(calc(-50% - 2px), -50%) rotate(-45deg)",
      },
      ".left": {
        transform: "translate(calc(-50% + 2px), -50%) rotate(135deg)",
      },
    },
  },
  ".dates-list": {
    listStyleType: "none",
    padding: "0",
    margin: "15px 0",
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: theme.typography.fontFamilyBauhaus,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    li: {
      padding: "2px 0",
      textAlign: "left",
      width: "calc(50% - 5px)",
      border: "1px solid #444",
      textAlign: "center",
      marginBottom: 10,
      boxShadow: "2px 2px 0 #bbb",
      trsansition: "all .4s ease-in-out",

      "&.active": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  ".calbox ": {
    paddingTop: 15,
    position: "relative",

    ".r-color-jwli3a, .r-jwli3a": {
      width: "100%",
      borderRadius: 0,
      border: "1px solid #3EC3EC",
      textAlign: "center",
      color: "#fff",
    },
    ".r-width-1yvhtrz, .r-1yvhtrz": {
      width: "100%",
      borderRadius: 0,
      /* background: "transparent!important",
      border: "none!important", */
      /* color: "#fff", */

      '&[aria-disabled="true"]': {
        /* background: "transparent!important",
        border: "none!important", */
        /* color: "#fff", */
        border: "none",
      },
    },
  },
  ".r-justifyContent-a2tzq0, .r-a2tzq0": {
    ".r-color-zd8e7p": {
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
  ".r-marginTop-1cvj4g8, .r-1cvj4g8": {
    top: "-8px",
  },
}));

export default function CallsCalendar({ setActivePage }) {
  const [load, setLoad] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [markedDates, setMarkedDates] = useState({});
  const [items, setItems] = useState({});
  const markedDateslist = {};
  const itemsList = {};
  useEffect(() => {
    setLoad(true);
    CalendarService.getCalendarList(rdv_type_call)
      .then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            const El = res[i];

            //const [markedDates, setMarkedDates] = useState({});
            if (
              Array.isArray(
                itemsList[moment.parseZone(El.date_debut).format("YYYY-MM-DD")]
              )
            ) {
              itemsList[moment.parseZone(El.date_debut).format("YYYY-MM-DD")].push({
                name: El.user.nom + " " + El.user.prenom,
                telephone: El.user.telephone,
                email: El.user.email,
                heure: moment.parseZone(El.date_debut).format("HH:mm"),
              });
            } else {
              itemsList[moment.parseZone(El.date_debut).format("YYYY-MM-DD")] = [
                {
                  name: El.user.nom + " " + El.user.prenom,
                  telephone: El.user.telephone,
                  email: El.user.email,
                  heure: moment.parseZone(El.date_debut).format("HH:mm"),
                },
              ];
            }

            markedDateslist[moment.parseZone(El.date_debut).format("YYYY-MM-DD")] = {
              selected: true,
              marked: true,
              selectedColor: "#03ebff70",
            };
            if (i + 1 === res.length) {
              console.log(itemsList);
              setMarkedDates(markedDateslist);
              setItems(itemsList);
              setLoad(false);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  }, []);

  //console.log(markedDates);
  //setMarkedDates(markedDatesList);

  const onDayPress = (day) => {
    const selectedDateStr = day.dateString;
    const selectedItemsForDate = items[selectedDateStr] || [];

    setSelectedDate(selectedDateStr);
    setSelectedItems(selectedItemsForDate);
  };

  const closeModal = () => {
    setSelectedDate(null);
    setSelectedItems([]);
  };

  if (load)
    return (
      <Box
        height={"30vh"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <GoBack
        sx={{ cursor: "pointer" }}
        onClick={() => setActivePage("dashboard")}
      >
        <ArrowBackIcon /> Retour au tableau de bord
      </GoBack>


      <CalendarWrap>
        <CalendarLayout className="callayout">
          <Box className="calbox">
            <Calendar
              // Handler which gets executed on day press. Default = undefined
              onDayPress={onDayPress}
              monthFormat={"MMMM, yyyy"}
              // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday
              firstDay={1}
              // Enable the option to swipe between months. Default = false
              enableSwipeMonths={true}
              //minDate={dateFormat(new Date(), "yyyy-mm-dd")}
              //markedDates={{ ...dates, ...selectedDay }}
              /* selected={currentDate}
            renderArrow={(direction) => <Arrow dir={direction} />} */
              onMonthChange={(month) => {
                //setCurrentMonth(month.dateString);
              }}
              current={moment().format("YYYY-MM-DD")}
              renderArrow={(direction) => <Arrow dir={direction} />}
              markedDates={markedDates}
              style={
                {
                  /* borderRadius: 5,
                margin: 12,
                elevation: 5,
                borderWidth: 1,
                borderColor: "rgba(100, 100, 100, 0.2)", */
                }
              }
              theme={
                {
                  /* backgroundColor: "rgba(100, 100, 100, 0.5)",
                calendarBackground: "rgba(100, 100, 100, 0.2)",
                dayTextColor: "#03EBFF",
                textDisabledColor: "#03EBFF",
                monthTextColor: "#03EBFF",
                selectedDayBackgroundColor: "#03EBFF",
                selectedDayTextColor: "#ffffff",
                dotColor: "#00adf5",
                selectedDotColor: "#ffffff",
                todayBackgroundColor: "#03EBFF",
                todayTextColor: "#00adf5", */
                  /* backgroundColor: "#ffffff",
          calendarBackground: "#ffffff",
          textSectionTitleColor: "#b6c1cd",
          selectedDayBackgroundColor: "#00adf5",
          selectedDayTextColor: "#ffffff",
          todayTextColor: "#00adf5",
          dayTextColor: "#2d4150",
          textDisabledColor: "#d9e1e8",
          dotColor: "#00adf5",
          selectedDotColor: "#ffffff",
          arrowColor: "orange",
          monthTextColor: "blue", */
                }
              }
            />
          </Box>
        </CalendarLayout>
      </CalendarWrap>
      <CallClient
        dialog={selectedDate !== null}
        onClose={closeModal}
        selectedItems={selectedItems}
        selectedDate={selectedDate}
      />
      {/* <Modal
        visible={selectedDate !== null}
        animationType="fade"
        transparent={true}
      >
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <View
            style={{ padding: 20, backgroundColor: "white", borderRadius: 10 }}
          >
            <Text>Date: {selectedDate}</Text>
            <Text>--------------------</Text>
            {selectedItems.map((item, index) => (
              <View key={index}>
                <Text>Client: {item.name}</Text>
                <Text>Numero: {item.telephone}</Text>
                <Text>Heure d'appel: {item.heure}</Text>
                <Text>--------------------</Text>
              </View>
            ))}
            <TouchableOpacity onPress={closeModal}>
              <Text>Fermer</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal> */}
    </Box>
  );
}

const Arrow = (props) => {
  return <Box className={props.dir}></Box>;
};
