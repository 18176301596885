import {Link} from "@mui/material";
import {styled} from "@mui/system";

const CustomLink = styled(Link)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.socialIcon,
    cursor: "pointer",
}))
function LinkTag({children, handleClick, underline}) {
    return (
        <CustomLink variant={"body2"} underline={underline} onClick={handleClick}>
            {children}
        </CustomLink>
    )
}

export default LinkTag;