import {
  Box,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
} from "@mui/material";
import {
  KeyboardBackspaceOutlined,
  EditOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import admin from "../../../assets/images/admin.png";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import ring from "../../../assets/images/ring.png";
import moment from "moment";
import {
  document_type_bon_intervention,
  document_type_devis,
  document_type_facture,
  document_type_fiche_intervention,
  rdv_type_prestation,
} from "../../../config/keys";
import PrestationService from "../../../store/apis/PrestationApi";
import ImagesList from "./Images";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    //margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 16,
    },
    "&:first-of-type": {
      borderRadius: 16,
    },
  },
}));

export default function DetailsDemande(props) {
  const [loading, setLoading] = useState(true);
  const [alignment, setAlignment] = useState();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);
  const [presta, setPresta] = useState();
  const [datePlanification, setDatePlanification] = useState();
  const [showImages, setShowImages] = useState(false);

  useEffect(() => {
    if (Object.keys(props.detailPresta).length > 0) {
      setPresta(props.detailPresta);
      setAlignment(props.detailPresta.client?.type_client);
      setDatePlanification(
        props.detailPresta.client?.calendar?.find(
          (rdv) => rdv.type === rdv_type_prestation
        )
      );
      setLoading(false);
    } else {
      fetchData(props.presta);
    }
  }, [props.presta]);

  const fetchData = (data) => {
    setLoading(true);

    PrestationService.getPrestation(data.id)
      .then((response) => {
        props.setDetailPresta(response);
        setPresta(response);
        setAlignment(response.client?.type_client);
        setDatePlanification(
          response.client?.calendar?.find(
            (rdv) => rdv.type === rdv_type_prestation
          )
        );
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  if (loading)
    return (
      <Box
        height={"30vh"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box>
        <Typography fontSize={{ xs: 16 }} color="red">
          Something Went Wrong
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ color: "#fff" }}>
      <Button
        startIcon={<KeyboardBackspaceOutlined />}
        variant={"text"}
        color={"primary"}
        sx={{ fontWeight: 700, textTransform: "capitalize" }}
        onClick={props.goBack}
      >
        Retour
      </Button>
      {load && (
        <Box
          height={"100%"}
          width={"100%"}
          display={"flex"}
          alignitems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Box>
      )}
      {presta && (
        <>
          <Typography
            variant={"body2"}
            fontWeight={"700"}
            textTransform={"uppercase"}
            fontSize={{ md: 24 }}
            mt={2}
            mb={2}
          >
            Detail client
          </Typography>
          <Box
            display={"flex"}
            alignItems={{ xs: "center", lg: "start" }}
            justifyContent={{ xs: "center", lg: "space-between" }}
            flexDirection={{ xs: "column", lg: "row" }}
            gap={{ xs: 3 }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: 3, md: 6 }}
            >
              <Box
                display={"flex"}
                alignItems={{ xs: "center", md: "start" }}
                justifyContent={{ xs: "center", md: "start" }}
                gap={{ xs: 3, md: 6, lg: 1 }}
                flexWrap={"wrap"}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Box>
                  <Typography
                    variant={"h1"}
                    fontSize={{ md: 36 }}
                    fontWeight={700}
                    mb={2}
                  >
                    {`${presta.client?.prenom} ${presta.client?.nom}`}
                  </Typography>
                </Box>
              </Box>
              <Box width={{ xs: 390, md: 604 }}>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Type client :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.type_client}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Problème :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.problemes.map((x) => `${x}`)}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Type de bâtiment :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.type_batiment}
                  </Typography>
                </Box>
                {presta.client?.function_client && (
                  <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                    <Typography
                      variant="h2"
                      textTransform={"capitalize"}
                      fontFamily={"Brandon Grotesque"}
                      font-weight={"500"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={160}
                      textAlign="right"
                      paddingRight={0.6}
                    >
                      Fonction :
                    </Typography>

                    <Typography
                      variant="h2"
                      textTransform={"capitalize"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={{ xs: 230, md: 444 }}
                      color={"primary"}
                    >
                      {presta.client?.function_client}
                    </Typography>
                  </Box>
                )}
                {presta.client?.nom_societe && (
                  <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                    <Typography
                      variant="h2"
                      textTransform={"capitalize"}
                      fontFamily={"Brandon Grotesque"}
                      font-weight={"500"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={160}
                      textAlign="right"
                      paddingRight={0.6}
                    >
                      Société :
                    </Typography>

                    <Typography
                      variant="h2"
                      textTransform={"capitalize"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={{ xs: 230, md: 444 }}
                      color={"primary"}
                    >
                      {presta.client?.nom_societe}
                    </Typography>
                  </Box>
                )}
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Adresse :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.adresse}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Code postal :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.code_postal}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Ville :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.ville}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    tél :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.telephone}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Email :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.email}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Message :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta?.client?.message}
                  </Typography>
                </Box>

                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Détail :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta?.detail}
                  </Typography>
                </Box>
                {/* <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Client depuis :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {moment.parseZone(presta.client?.date_inscription).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                </Box> */}
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={3}
            >
              <Box width={{ xs: 303, md: 604, lg: 303 }}>
                <Typography
                  fontWeight={{ xs: 700 }}
                  fontSize={{ xs: 18 }}
                  textAlign={{ xs: "center", md: "left" }}
                  textTransform={"uppercase"}
                  color={"rgba(255, 255, 255, 0.5)"}
                  mb={2}
                >
                  Phase du processus
                </Typography>
                <Box
                  border={"1px solid rgba(3, 235, 255, 1)"}
                  padding={"24px 24px 12px"}
                >
                  <Box>
                    {parseInt(presta.etape) < 5 && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            1
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Attribuer et en attente de planification
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(presta.etape) === 5 ||
                      parseInt(presta.etape) > 5) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            1
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Intervention prévu le{" "}
                              <Typography
                                variant={"h2"}
                                fontSize={{ xs: 16 }}
                                color={"warning.main"}
                                component={"span"}
                              >
                                {moment
                                  .parseZone(datePlanification.date_debut)
                                  .format("DD/MM/YYYY")}{" "}
                                á{" "}
                                {moment
                                  .parseZone(datePlanification.date_debut)
                                  .format("HH:mm")}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {(parseInt(presta.etape) === 7 ||
                      parseInt(presta.etape) > 7) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            2
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Intervention en cours
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {(parseInt(presta.etape) === 8 ||
                      parseInt(presta.etape) > 8) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            3
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Intervention terminer
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {/* <Box mt={1.6}>
                    <Typography
                      fontSize={{ xs: 16 }}
                      fontStyle={{ xs: "italic" }}
                      mb={1}
                    >
                      Intervenant :{" "}
                      <Typography
                        variant={"h2"}
                        fontSize={{ xs: 16 }}
                        component={"span"}
                        fontStyle={"normal"}
                      >
                        Corey Septimus
                      </Typography>
                    </Typography>
                  </Box> */}
                </Box>
              </Box>

              <Box width={{ xs: 303, md: 604, lg: 303 }}>
                <Typography
                  fontWeight={{ xs: 700 }}
                  fontSize={{ xs: 18 }}
                  textAlign={{ xs: "center", md: "left" }}
                  textTransform={"uppercase"}
                  color={"rgba(255, 255, 255, 0.5)"}
                  mb={2}
                >
                  Documents
                </Typography>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={{ xs: "column", md: "row", lg: "column" }}
                  flexWrap={"wrap"}
                  gap={1}
                  width={"100%"}
                >
                  {presta.images.length > 0 && (
                    <Box
                      width={{ xs: 303, md: 293 }}
                      height={70}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      border={"1px solid rgba(3, 235, 255, 1)"}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setShowImages(true)}
                    >
                      <Typography
                        fontWeight={{ xs: 300 }}
                        fontSize={{ xs: 24 }}
                      >
                        Photos client
                      </Typography>
                    </Box>
                  )}
                  {/* {parseInt(presta.etape) === 1 ? (
                    <Box
                      width={{ xs: 303, md: 293 }}
                      height={70}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      border={"1px solid rgba(3, 235, 255, 1)"}
                      sx={{ cursor: "pointer" }}
                      onClick={props.createDevis}
                    >
                      <Typography
                        fontWeight={{ xs: 300 }}
                        fontSize={{ xs: 24 }}
                      >
                        Créer un bon
                      </Typography>
                    </Box>
                  ) : (
                    <> */}
                  {[
                    {
                      id: document_type_fiche_intervention,
                      titre: "Fiche d’intervention",
                    },
                    {
                      id: document_type_bon_intervention,
                      titre: "Bon d’intervention",
                    },
                  ].map((x, i) => {
                    if (
                      presta.documents.find(
                        (obj) =>
                          obj.type.toLowerCase() === x.id.toLowerCase() &&
                          obj.signature_prestataire !== "signed"
                      )
                    ) {
                      return (
                        <Box
                          key={i}
                          width={{ xs: 303, md: 293 }}
                          height={70}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          border={"1px solid rgba(3, 235, 255, 1)"}
                          sx={{ cursor: "pointer" }}
                          onClick={() => props.showDocument(x.id)}
                        >
                          <Typography
                            fontWeight={{ xs: 300 }}
                            fontSize={{ xs: 24 }}
                          >
                            {x.titre}
                          </Typography>
                        </Box>
                      );
                    }
                  })}
                </Box>
              </Box>
            </Box>
          </Box>

          {showImages && (
            <ImagesList
              dialog={showImages}
              onClose={() => setShowImages(false)}
              demande={presta}
            />
          )}
        </>
      )}
    </Box>
  );
}
