import {ScrollView} from "react-native-web";
import {Box, Container, Typography} from "@mui/material";
import {HeadingLegals, SubHeadingLegals} from "../../components/headings";
import bgimg from "../../assets/images/bgs/bg1.png";
import {styled} from "@mui/system";
import theme from "../../assets/theme";
import ParagraphLegals from "../../components/HtmlTags/ParagraphLegals";
import LinkTag from "../../components/HtmlTags/LinkTag";
import Navbar from "../../components/navbar";
import {Footer} from "../../components";
const CustomContainer = styled(Box)({
    backgroundImage: 'url('+bgimg+')',
    backgroundSize: "100%",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
})

export default function Politiques() {
    return (
        <ScrollView style={{backgroundColor: theme.palette.background.main}}>
            <Navbar />
            <CustomContainer >
                <Box sx={{backgroundColor: theme.palette.background.backgroundMainOpacity}}>
                    <Container maxwidth="xl">
                        <HeadingLegals>
                            Politiques de confidentialités
                        </HeadingLegals>
                        <Box>
                            <SubHeadingLegals>Qui sommes-nous ?</SubHeadingLegals>
                            <ParagraphLegals>
                                L’adresse de notre site Web est : <LinkTag underline={"hover"}>https://www.fibme.fr/</LinkTag>
                            </ParagraphLegals>
                        </Box>
                        <Box>
                            <SubHeadingLegals>Utilisation des données personnelles collectées</SubHeadingLegals>
                            <ParagraphLegals color={theme.palette.primary.main} fontStyle={"italic"}>Commentaires</ParagraphLegals>
                            <ParagraphLegals>Quand vous laissez un commentaire sur notre site web, les données inscrites dans le formulaire de commentaire, mais aussi votre adresse IP et l’agent utilisateur de votre navigateur sont collectés pour nous aider à la détection des commentaires indésirables.</ParagraphLegals>
                            <ParagraphLegals color={theme.palette.primary.main} fontStyle={"italic"}>Médias</ParagraphLegals>
                            <ParagraphLegals>Si vous êtes un utilisateur enregistré et que vous téléversez des images sur le site web, nous vous conseillons d’éviter de téléverser des images contenant des données EXIF de coordonnées GPS.</ParagraphLegals>
                            <ParagraphLegals color={theme.palette.primary.main} fontStyle={"italic"}>Formulaires de contact</ParagraphLegals>
                            <ParagraphLegals>
                                Les données collectées par Fibme lors de ces formulaires de contact (mail, prénom, nom) sont utilisées afin de vous recontacter.
                                <br/>
                                Elles ne sont ni vendues, ni transmises à d’autres acteurs. Les données sont sauvegardées pendant une durée d’un an, puis supprimées.
                                <br/>
                                Vous pouvez demander leur modification ou suppression en envoyant un mail à <LinkTag underline={"hover"}>contact@fibme.fr</LinkTag>.</ParagraphLegals>
                            <ParagraphLegals color={theme.palette.primary.main} fontStyle={"italic"}>Cookies</ParagraphLegals>
                            <ParagraphLegals>
                                Les cookies, qui sont l’ensemble des informations contenues dans un fichier et permettant de mémoriser et analyser les données de navigation, sont déposés lors de la navigation sur un site internet sur le disque dur de l’internaute par le biais du serveur du site internet visité, puis réutilisés pour les connexions suivantes. Les cookies permettent ensuite au site internet d’analyser les habitudes de navigation de l’internaute afin d’améliorer celle-ci.
                            </ParagraphLegals>
                            <ParagraphLegals>
                                Les cookies utilisés sur le site de Fibme sont :
                                <br/>
                                - Les cookies strictement nécessaires
                                <br/>
                                - Les cookies d’analyses et de personnalisation
                                <br/>
                                - Les cookies de statistiques
                            </ParagraphLegals>
                            <ParagraphLegals>
                                Des cookies sont utilisés sur notre site internet <LinkTag underline={"hover"}>https://www.fibme.fr/</LinkTag> et peuvent donc être installés sur votre terminal de connexion. Ces derniers ont une durée de vie maximale de 13 mois après l’écoulement desquels ils sont supprimés automatiquement de votre terminal.
                            </ParagraphLegals>
                            <ParagraphLegals>
                                Vous pouvez choisir d’accepter ou de refuser les cookies lors de votre navigation sur notre site internet.
                            </ParagraphLegals>
                            <ParagraphLegals>
                                Afin de refuser leur utilisation, vous pouvez vous référer aux procédures décrites dans les mentions légales, concernant les principaux navigateurs internet et systèmes d’exploitation informatiques. Si vous choisissez de les accepter au moment de votre navigation, vous pouvez également procéder à leur suppression a posteriori.
                            </ParagraphLegals>
                            <ParagraphLegals color={theme.palette.primary.main} fontStyle={"italic"}>Contenu embarqué depuis d’autres sites</ParagraphLegals>
                            <ParagraphLegals>
                                Les articles de ce site peuvent inclure des contenus intégrés (par exemple des vidéos, images, articles…). Le contenu intégré depuis d’autres sites se comporte de la même manière que si le visiteur se rendait sur cet autre site.
                            </ParagraphLegals>
                            <ParagraphLegals>
                                Ces sites web pourraient collecter des données sur vous, utiliser des cookies, embarquer des outils de suivis tiers, suivre vos interactions avec ces contenus embarqués si vous disposez d’un compte connecté sur leur site web.
                            </ParagraphLegals>
                        </Box>
                        <Box>
                            <SubHeadingLegals>Utilisations et transmission de vos données personnelles</SubHeadingLegals>
                            <ParagraphLegals color={theme.palette.primary.main} fontStyle={"italic"}>Durées de stockage de vos données</ParagraphLegals>
                            <ParagraphLegals>
                                Si vous laissez un commentaire, le commentaire et ses métadonnées sont conservés indéfiniment. Cela permet de reconnaître et approuver automatiquement les commentaires suivants au lieu de les laisser dans la file de modération.
                            </ParagraphLegals>
                            <ParagraphLegals>
                                Pour les utilisateurs qui s’enregistrent sur notre site (si cela est possible), nous stockons également les données personnelles indiquées dans leur profil. Tous les utilisateurs peuvent voir, modifier ou supprimer leurs informations personnelles à tout moment (à l’exception de leur nom d’utilisateur). Les gestionnaires du site peuvent aussi voir et modifier ces informations.
                            </ParagraphLegals>
                            <ParagraphLegals color={theme.palette.primary.main} fontStyle={"italic"}>Les droits que vous avez sur vos données</ParagraphLegals>
                            <ParagraphLegals>
                                Si vous avez un compte ou si vous avez laissé des commentaires sur le site, vous pouvez demander à recevoir un fichier contenant toutes les données personnelles que nous possédons à votre sujet, incluant celles que vous nous avez fournies. Vous pouvez également demander la suppression des données personnelles vous concernant. Cela ne prend pas en compte les données stockées à des fins administratives, légales ou pour des raisons de sécurité.
                            </ParagraphLegals>
                            <ParagraphLegals color={theme.palette.primary.main} fontStyle={"italic"}>Transmission de vos données personnelles</ParagraphLegals>
                            <ParagraphLegals>
                                Les commentaires des visiteurs peuvent être vérifiés à l’aide d’un service automatisé de détection des commentaires indésirables.
                            </ParagraphLegals>
                            <ParagraphLegals color={theme.palette.primary.main} fontStyle={"italic"}>Informations de contact</ParagraphLegals>
                            <ParagraphLegals>
                                <LinkTag underline={"hover"}>contact@fibme.fr</LinkTag>, ou à travers les formulaires présents sur le site web Fibme.
                            </ParagraphLegals>
                        </Box>
                    </Container>
                </Box>
            </CustomContainer>
            <Footer />
        </ScrollView>
    );
}