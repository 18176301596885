import axios from "axios";
import { base_url_api } from "../../config/keys";

// const register = (username, email, password) => {
//   return axios.post(API_URL + "signup", {
//     username,
//     email,
//     password,
//   });
// };

const login = async (email, password) => {
  const response = await axios.post(`${base_url_api}/login`, {
    email,
    password,
  });
  localStorage.setItem("user", JSON.stringify(response.data));
  return response.data;
};

const logout = () => {
  localStorage.removeItem("user");
};
const userAuthService = {
  // register,
  login,
  logout,
};
export default userAuthService;
