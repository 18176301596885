import { Box, Container, Typography, Link } from "@mui/material";
import { SectionsHeading } from "../../../../components";
import BackgroundImg from "../../../../assets/images/bgs/bg1.png";
import { styled } from "@mui/system";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useNavigation } from "@react-navigation/native";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  scrollMarginTop: 200,
  backgroundColor: theme.palette.background.main,
  backgroundImage: `url(${BackgroundImg})`,
  backgroundSize: "100%",
  backgroundPosition: "bottom center",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.down("sm")]: {
    paddingTop: 0,
  },
}));

const Goto = styled(Link)(
  ({ theme }) => `
    border: 1px solid ${theme.palette.primary.main};
    margin-top: 30px;
    display: none;
    width: 100px;
    height: 100px;
    margin: auto;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    &:after, &:before, span{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 10px;
        height: 10px;
        transform: translate(-50%, -50%) rotate(45deg);
        border-bottom: 2px solid ${theme.palette.text.primary};
        border-right: 2px solid ${theme.palette.text.primary};
        animation: gotoAnime 1.5s infinite linear;
    }
    &:before{
        animation-delay: .75s;
    }
    span{
        animation-delay: .75s;
    }
    @keyframes gotoAnime {
        0%{
            transform: translate(-50%, calc(-50% - 15px)) rotate(45deg);
            opacity: 0;
        }
        25%{
            opacity: 1;
        }
        75%{
            transform: translate(-50%, calc(-50% + 0px)) rotate(45deg);
            border-color: ${theme.palette.primary.main};
            opacity: 1;
        }
        100%{
            transform: translate(-50%, calc(-50% + 15px)) rotate(45deg);
            opacity: 0;
            border-color: ${theme.palette.primary.main};
        }
    }
    `
);

export default function AboutSection() {
  const navigation = useNavigation();
  const handleClick = () => {
    const anchor = document.querySelector(`#sec2`);

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <StyledBox components="section" id={"sec1"}>
      <Container maxWidth="lg">
        <SectionsHeading>qui sommes-nous ?</SectionsHeading>
        <Box>
          <Typography
            variant="body1"
            component={"p"}
            textAlign={{ md: "center" }}
          >
            FIBME offre des services d’installation, maintenance et dépannage
            pour <b>particuliers, hôtel & résidence et professionnels</b>
            <br></br>
            Nous sommes la solution à vos besoins technologiques :<br></br>
            <b>Câblage réseau et déploiement Wifi, Antenne TV, Interphonie.</b>
            <br></br>
            Spécialisée dans <b>l'électricité courant faible</b> dans le{" "}
            <b>logement neuf individuel et collectif</b>. <br></br>Notre équipe
            s’engage au résultat pour faire de votre demande une réussite et
            garantir votre satisfaction. <br></br>FIBME vous accompagne tout au long de
            votre commande, du premier contact par téléphone ou via son site
            internet, à la réception des travaux.
          </Typography>
          {/*<Typography
            variant="body1"
            component={"p"}
            textAlign={{ md: "center" }}
            gutterBottom
          >
            Pour les particuliers comme pour les professionnels et les
            collectivités, une solution d’expertise et de conseil vous sera
            proposée.
          </Typography>*/}
        </Box>

        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={{ xs: "center", md: "flex-end", lg: "center" }}
          mb={{ xs: 2, md: 12, lg: 4 }}
          mt={{ xs: 2, md: 3, lg: 4 }}
        >
          <CustomButton
            type="blue"
            handleClick={() => navigation.navigate("Register")}
          >
            S’inscrire
          </CustomButton>
        </Box>

        <Goto
          href="#hdw"
          className="goto"
          display={{ lg: "block" }}
          onClick={handleClick}
        >
          <Typography component="span" />
        </Goto>
      </Container>
    </StyledBox>
  );
}
