import { View } from "react-native-web";
import { Footer, NavBar } from "../../../components";

export default function Parametrs() {
  return (
    <View>
      <NavBar />
      <Footer />
    </View>
  );
}
