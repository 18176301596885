import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { forwardRef, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import adminsService from "../../../store/apis/adminsApis";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteAdminPopup = ({
  dialog,
  onClose,
  id,
  setRemoveCongart,
  goBack,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [load, setLoad] = useState(false);

  const handleDelete = () => {
    setLoad(true);
    adminsService
      .removeAdmin(id)
      .then((res) => {
        if (res?.response?.status) {
          setError("Utilisateur impossible à supprimer!");
        } else {
          onClose();
          setRemoveCongart(true);
          goBack();
        }
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        setError(err);
      });
  };

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{
        sx: {
          maxWidth: { sm: 650 },
          height: { xs: 232, md: 385 },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      onClose={onClose}
    >
      <Container maxWidth={isMd ? "sm" : "xs"}>
        <Box paddingLeft={{ md: 5 }} paddingRight={{ md: 5 }}>
          <Box mb={4}>
            <Typography
              fontFamily={"Bauhaus"}
              textAlign={"center"}
              fontSize={{ xs: 16 }}
              fontWeight={700}
            >
              Êtes-vous sûr de vouloir supprimer ce profile ?
            </Typography>
            {error && (
              <Typography
                fontSize={{
                  xs: 14,
                  margin: "-15px 0 15px",
                  textAlign: "center",
                }}
                color="red"
              >
                {error}
              </Typography>
            )}
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Box
              display={"flex"}
              mb={4}
              mt={6}
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
            >
              {load ? (
                <CircularProgress />
              ) : (
                <>
                  <CustomButton type={"red"} handleClick={onClose}>
                    annuler
                  </CustomButton>
                  <CustomButton type="yellow" handleClick={handleDelete}>
                    Supprime
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default DeleteAdminPopup;
