import {Box, Typography} from "@mui/material";
import {styled} from "@mui/system";
import theme from "../../assets/theme";

const TitleLegal = styled(Typography)(({theme, color}) => ({
    fontFamily: theme.typography.fontFamilyBauhausBold,
    fontSize: {xs: 24, md: 36},
    lineHeight: {xs: "34px", md: "51px"},
    color: color ? color : theme.palette.secondary.main,
    textTransform: "uppercase",
    textAlign: "center",
    margin: "50px 0"
}))


function HeadingLegals({children, color}) {
    return (
        <Box>
            <TitleLegal variant={"h1"} component={"h1"} color={color}>
                {children}
            </TitleLegal>
        </Box>
    )
}

export default HeadingLegals;