import { styled } from "@mui/system";

const Input = styled("input")(({ theme }) => ({
  /* color: "white", */
  textAlign: "center",
  outline: "none",
  fontSize: theme.spacing(1.8),
  height: 45,
  width: "100%",
  padding: theme.spacing(1, 2),
  border: `1px solid rgba(62, 195, 236, 1)`,
  background: "transparent",
  "&:focus": {
    backgroundColor: "rgba(62, 195, 236, 0.08)",
    border: `1px dashed ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: theme.spacing(2.2),
  },
}));

export default function LinkInput({ placeholder, type, register }) {
  return (
    <Input
      type={type ? type : "text"}
      placeholder={placeholder?.toUpperCase()}
      {...register}
    />
  );
}
