import { Box, Container, Dialog, Typography } from "@mui/material";
import React, { useState } from "react";
import { ScrollView, StyleSheet } from "react-native-web";
import styles from "../../assets/css/style";
import { Footer, PartnerNavBar, SuperAdminNavBar } from "../../components";
import TabPanel from "../../components/TabPanel";
import Benefits from "./Benefits";
import Documents from "./Documents";
import bgimg from "../../assets/images/black-bg-dots.png";
import CallsCalendar from "./CallsCalendar/CallsCalendar";
import { useSelector } from "react-redux";
import DocumentsNeed from "./Documents/DocumentsNeed";
import Profile from "./Profile/Profile";
import RdvsCalendar from "./CallsCalendar/RdvsCalendar";

export default function PartnerLayout(page = "dashboard") {
  const { user } = useSelector((state) => state.userAuth);
  const [needDocument, setNeedDocument] = useState(
    user?.data?.status === 2 || user?.user?.data?.status === 2 ? true : false
  );
  const [activePage, setActivePage] = useState(page);
  const handleDialog = () => {};
  const items = [
    {
      label: "Prestations",
      content: <Benefits />,
    },
    /* {
      label: "Partenaires",
      content: <Partners />,
    },
    {
      label: "clients",
      content: <Customer />,
    },
    {
      label: "Admins",
      content: <Admins />,
    },
    {
      label: "créer un devis",
      content: <Quotes />,
    }, */
    /* {
      label: "documents",
      content: <Documents />,
    }, */
  ];

  return (
    <ScrollView
      style={{
        backgroundImage: "url(" + bgimg + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <PartnerNavBar callendarClick={() => setActivePage("calls")} />

      <Box style={StyleSheet.flatten(styles.bgWrap)}>
        <Typography
          color={"#fff"}
          style={StyleSheet.flatten(styles.dottedLine)}
          align="center"
          variant="h3"
        >
          {needDocument ? (
            <>Documents obligatoires</>
          ) : (
            (() => {
              switch (activePage) {
                case "dashboard":
                  return "Dashboard";

                case "profile":
                  return "Mon profile";

                case "calls":
                  return "Calendrier des rendez-vous";
              }
            })()
          )}
        </Typography>
        <Container maxWidth="lg">
          {needDocument ? (
            <DocumentsNeed />
          ) : (
            (() => {
              switch (activePage) {
                case "dashboard":
                  return <TabPanel type="style2" items={items} />;

                case "calls":
                  return <RdvsCalendar setActivePage={setActivePage} />;

               /*  case "documents":
                  return <TabPanel type="style2" items={items} />; */

                case "profile":
                  return <Profile type="style2" items={items} />;
              }
            })()
          )}
        </Container>
      </Box>
    </ScrollView>
  );
}
