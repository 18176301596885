import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { Footer, NavBar, ProgressBar } from "../../components/index.js";
import BG from "../../assets/images/bgs/bg1.png";
import theme from "../../assets/theme/index.js";
import Steps from "./Steps.js/index.js";
import Navbar from "../../components/navbar/index.js";
import SignUpAppBar from "../../components/appbars/index.js";

const wrapperStyle = {
  backgroundSize: "100%",
  backgroundPosition: "bottom",
  backgroundImage: "url(" + BG + ")",
  backgroundRepeat: "no-repeat",
  minHeight: "100vh",
  overflow: "auto",
  paddingBottom: 4,
  backgroundColor: theme.palette.background.main,
};
const TitleStyle = {
  textTransform: "uppercase",
  color: theme.palette.text.secondary,
  textAlign: "center",
  fontWeight: 700,
  marginTop: "75px",
};
export default function RegisterPartner() {
  const [progress, setProgress] = useState(0);

  return (
    <Box className="css-register-platform" sx={wrapperStyle}>
      {/*<Navbar />*/}
      <SignUpAppBar />

      <Typography component={"h2"} className="h2" sx={TitleStyle}>
        Devenir partenaire
      </Typography>

      <ProgressBar percent={progress} />

      <Steps
        step={0}
        onChange={(progress) => {
          console.log(progress);
          setProgress(progress);
        }}
      />
    </Box>
  );
}
