import { Box, Button, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DTable from "../../../components/DTable";
import filterIcon from "../../../assets/images/sliders.png";
import moment from "moment";
import { Image, TextInput } from "react-native-web";
import classNames from "classnames";
import { HeaderTab, TabItems, TabItem, Filter } from "../Tools/Tab";
import Search from "../Tools/Search";
import PrestationService from "../../../store/apis/PrestationApi";
import DetailsDemande from "./DetailsDemande";
import PDF from "../Quotes/PDF";
import {
  document_type_devis,
  document_type_fiche_intervention,
  document_type_order_travail,
  limitPagination,
  list_phase_demande,
  rdv_type_call,
  rdv_type_prestation,
  status_prestation_en_cours,
  status_prestation_terminer,
  status_prestation_urgent,
} from "../../../config/keys";
import PDFFile from "../Documents/PDFFile";
import OT from "../Quotes/OT";
import { DownloadOutlined } from "@mui/icons-material";
import DetailPresta from "./Detail";
import FilterBenefits from "./FilterBenefits";

const HEAD = [
  {
    hasDropDown: true,
    label: "référence",
    width: 10,
  },
  {
    label: "Date de création",
    width: 10,
  },
  {
    hasDropDown: true,
    label: "Nature des travaux",
    /* items: ["échec raccordement", "Modif. d’installation"], */
    width: 20,
  },
  {
    label: "Client",
    width: 10,
  },
  {
    label: "Adresse",
    width: 10,
  },
  {
    label: "Étape",
    width: 10,
  },
  {
    label: "Date du RDV",
    width: 10,
  },
  {
    label: "Partenaire",
    width: 10,
  },
  {
    label: false,
    width: 8,
  },
  {
    label: false,
    width: 2,
  },
];

export default function Benefits() {
  const [load, setLoad] = useState(true);
  const [loadExport, setLoadExport] = useState(false);
  const [search, setSearch] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [showCreateDevis, setShowCreateDevis] = useState(false);
  const [showEditPartenaire, setShowEditPartenaire] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [typeDocument, setTypeDocument] = useState(document_type_devis);
  const [detail, setDetail] = useState({});
  const [detailPresta, setDetailPresta] = useState({});
  const [filter, setFilter] = useState(status_prestation_en_cours);
  const [searchValue, setSearchValue] = useState("");
  const [prevFilter, setPrevFilter] = useState(status_prestation_en_cours);
  const [prestations, setPrestations] = useState(false);

  const [page, setPage] = useState(1);
  const [showPagination, setShowPagination] = useState(false);
  const [showDetailPresta, setShowDetailPresta] = useState(false);
  const [filterDetail, setFilterDetail] = useState("");

  const [open, setOpen] = useState(false);

  const onClickDetail = (demande) => {
    setDetail(demande);
    setShowDetail(true);
  };

  const onClickShowDetail = (demande) => {
    setDetail(demande);
    setShowDetailPresta(true);
  };

  const goBack = () => {
    setShowCreateDevis(false);
    setShowEditPartenaire(false);
    setShowDetail(false);
    setDetail({});
    setDetailPresta({});
  };

  const goDetail = () => {
    setShowCreateDevis(false);
    setShowDetail(true);
    setShowDocument(false);
    setShowEditPartenaire(false);
  };

  const createDevis = () => {
    setShowDetail(false);
    setShowCreateDevis(true);
    setShowEditPartenaire(false);
    //setDetail({});
  };

  const editPartenaire = () => {
    setShowDetail(false);
    setShowCreateDevis(false);
    setShowEditPartenaire(true);
    //setDetail({});
  };

  const handleShowDocument = (type) => {
    setTypeDocument(type);
    setShowDetail(false);
    setShowDocument(true);
    /*  if (type === document_type_devis) {
      setShowDetail(false);
      setShowDocuemnt(true);
    }
    if (type === document_type_fiche_intervention) {
      setShowDetail(false);
      setShowDocuemnt(true);
    } */
  };

  const handleChangeStatus = async (demande, status) => {
    setPrestations(false);
    setPage(1);
    setShowPagination(false);
    setLoad(true);
    PrestationService.editPrestation(demande.id, { status: status })
      .then((res) => {
        fetchData();
      })
      .catch((error) => console.log(error));
  };

  const handleDownload = async (active) => {
    setLoadExport(true);
    PrestationService.exportPrestations(active)
      .then((res) => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        // Create a link element
        const link = document.createElement("a");

        // Set the link's href to the Blob URL
        link.href = blobUrl;

        // Set the download attribute with the desired file name
        link.download = "prestations_" + active + "_export.xlsx";

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
        setLoadExport(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (prevFilter !== filter) {
      setFilterDetail("");
      setPrestations(false);
      setPrevFilter(filter);
      if (page !== 1) {
        setPage(1);
      } else {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [page, filter]);

  useEffect(() => {
    setFilterDetail("");
    setPrestations(false);
    setPrevFilter(filter);
    setPage(1);
    fetchData();
  }, [searchValue]);

  useEffect(() => {
    setSearchValue("");
    setPrestations(false);
    setPage(1);
    fetchData();
  }, [filterDetail]);

  const fetchData = () => {
    let Prestations = {
      head: HEAD,
      body: [],
    };
    setShowPagination(false);
    setLoad(true);
    PrestationService.listPrestation(page, filter, searchValue, filterDetail)
      .then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            const El = res[i];
            var row = [
              {
                type: "text",
                text: El.reference ? El.reference : El.id,
                link: "",
              },
              {
                type: "text",
                text: (
                  <Box component={"strong"}>
                    {moment
                      .parseZone(El.client?.date_inscription)
                      .format("DD/MM/YYYY")}{" "}
                    à{" "}
                    {moment
                      .parseZone(El.client?.date_inscription)
                      .format("HH:mm")}
                  </Box>
                ),
              },
              {
                type: El.detail && El.detail !== "" ? "link" : "text",
                text: El.nature_probleme,
                onClick: () => onClickShowDetail(El),
              },
              {
                type: "text",
                text: El.client?.nom + " " + El.client?.prenom,
              },
              {
                type: "text",
                text: El.client?.adresse ? El.client?.adresse : "",
              },
              {
                type: "text",
                text: (
                  <Box component={"strong"}>
                    ({El.etape}){" "}
                    {
                      list_phase_demande.find(
                        (obj) => obj.id === parseInt(El.etape)
                      ).label
                    }
                    {filter == status_prestation_terminer && El.status && (
                      <>
                        <br />({El.status})
                      </>
                    )}
                  </Box>
                ),
              },
              {
                type: "text",
                text: (
                  <Box>
                    {El.client?.calendar.length > 0 ? (
                      <Box component={"strong"}>
                        {El.client?.calendar?.filter(
                          (obj) =>
                            obj.type.toLowerCase() === rdv_type_prestation
                        ).length === 0 ? (
                          <Box>
                            Appel{" "}
                            {moment
                              .parseZone(
                                El.client?.calendar
                                  ?.filter(
                                    (obj) =>
                                      obj.type.toLowerCase() === rdv_type_call
                                  )
                                  ?.pop()?.date_debut
                              )
                              .format("DD/MM/YYYY")}{" "}
                            à{" "}
                            {moment
                              .parseZone(
                                El.client?.calendar
                                  ?.filter(
                                    (obj) =>
                                      obj.type.toLowerCase() === rdv_type_call
                                  )
                                  ?.pop()?.date_debut
                              )
                              .format("HH:mm")}
                          </Box>
                        ) : (
                          <Box>
                            Intervention{" "}
                            {moment
                              .parseZone(
                                El.client?.calendar
                                  ?.filter(
                                    (obj) =>
                                      obj.type.toLowerCase() ===
                                      rdv_type_prestation
                                  )
                                  ?.pop()?.date_debut
                              )
                              .format("DD/MM/YYYY")}{" "}
                            à{" "}
                            {moment
                              .parseZone(
                                El.client?.calendar
                                  ?.filter(
                                    (obj) =>
                                      obj.type.toLowerCase() ===
                                      rdv_type_prestation
                                  )
                                  ?.pop()?.date_debut
                              )
                              .format("HH:mm")}
                          </Box>
                        )}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                ),
              },
              {
                type: "text",
                text: El.prestataire
                  ? El.prestataire?.nom + " " + El.prestataire?.prenom
                  : "",
              },
              {
                type: "more",
                text: "Voir info",
                onClick: () => onClickDetail(El),
              },
              {
                type: filter == status_prestation_terminer ? "menu" : "",
                text: "",
                items: [
                  {
                    label: "Clos",
                    callback: () => {
                      handleChangeStatus(El, "Clos");
                    },
                  },
                  {
                    label: "Sans suite",
                    callback: () => {
                      handleChangeStatus(El, "Sans suite");
                    },
                  },
                  {
                    label: "DIAG",
                    callback: () => {
                      handleChangeStatus(El, "DIAG");
                    },
                  },
                  {
                    label: "Stand BY",
                    callback: () => {
                      handleChangeStatus(El, "Stand BY");
                    },
                  },
                ],
              },
            ];

            if (El.urgent) {
              page > 1
                ? prestations.body.push({ icon: "danger", columns: row })
                : Prestations.body.push({ icon: "danger", columns: row });
            } else {
              page > 1
                ? prestations.body.push({ icon: "", columns: row })
                : Prestations.body.push({ icon: "", columns: row });
            }
          }

          page > 1 ? setPrestations(prestations) : setPrestations(Prestations);
          //setUrgentPrestations(UrgentPrestations);
          setTimeout(() => {
            setLoad(false);
            res.length === limitPagination
              ? setShowPagination(true)
              : setShowPagination(false);
          }, 500);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleDebouncedChange = useCallback(
    _.debounce((newValue) => {
      //console.log("User finished typing:", newValue);
      setSearchValue(newValue);
      // Handle the finish typing event here
    }, 500),
    [] // Empty array ensures the debounce function is only created once
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    handleDebouncedChange(newValue);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  if (showDetail) {
    return (
      <DetailsDemande
        presta={detail}
        goBack={goBack}
        createDevis={createDevis}
        editPartenaire={editPartenaire}
        showDocument={handleShowDocument}
        setDetailPresta={setDetailPresta}
        detailPresta={detailPresta}
      />
    );
  } else if (showCreateDevis) {
    return (
      <PDF
        presta={detailPresta}
        goBack={goDetail}
        type={"newDevis"}
        setDetailPresta={setDetailPresta}
      />
    );
  } else if (showEditPartenaire) {
    return (
      <PDF
        presta={detailPresta}
        goBack={goDetail}
        type={"newDevis"}
        setDetailPresta={setDetailPresta}
      />
    );
  } else if (showDocument) {
    if (typeDocument === document_type_devis)
      return (
        <PDF
          presta={detailPresta}
          goBack={goDetail}
          type={typeDocument}
          setDetailPresta={setDetailPresta}
        />
      );
    if (typeDocument === document_type_fiche_intervention)
      return (
        <PDFFile
          presta={detailPresta}
          goBack={goDetail}
          type={typeDocument}
          setDetailPresta={setDetailPresta}
        />
      );
    if (typeDocument === document_type_order_travail)
      return <OT presta={detailPresta} goBack={goDetail} type={typeDocument} />;
  } else {
    return (
      <>
        {/*  {load ? (
          <Box
            height={"30vh"}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <> */}
        <Box>
          <HeaderTab className="header-tab">
            {/* <Title>bons d’intervention</Title> */}
            <Filter>
              <TabItems>
                <TabItem
                  sx={{ cursor: "pointer" }}
                  className={classNames({
                    hovered: filter == status_prestation_en_cours,
                  })}
                  onClick={() => setFilter(status_prestation_en_cours)}
                >
                  En cours
                </TabItem>
                <TabItem
                  sx={{ cursor: "pointer" }}
                  className={classNames({
                    hovered: filter == status_prestation_terminer,
                  })}
                  onClick={() => setFilter(status_prestation_terminer)}
                >
                  Réalisées
                </TabItem>
                <TabItem
                  sx={{ cursor: "pointer" }}
                  className={classNames({
                    hovered: filter == status_prestation_urgent,
                  })}
                  onClick={() => setFilter(status_prestation_urgent)}
                >
                  Urgentes
                </TabItem>
              </TabItems>
              <Button
                startIcon={<DownloadOutlined />}
                variant={"text"}
                color={"primary"}
                sx={{ fontWeight: 700, textTransform: "capitalize" }}
                onClick={() => handleDownload(filter)}
              >
                {loadExport ? <CircularProgress size="1rem" /> : "Exporter"}
              </Button>
              <Search className={"searchinput"}>
                <TextInput
                  type="search"
                  placeholder="Recherche"
                  onChange={handleChange}
                />
              </Search>
              <Box
                sx={{ cursor: "pointer" }}
                className="filterIcon"
                onClick={toggleDrawer(true)}
              >
                <Image source={filterIcon} style={{ width: 30, height: 30 }} />
              </Box>
            </Filter>
          </HeaderTab>
          <DTable
            load={load}
            showPagination={showPagination}
            setPage={setPage}
            page={page}
            data={prestations}
            keyprop={filter}
          />
        </Box>
        {showDetailPresta && (
          <DetailPresta
            dialog={showDetailPresta}
            onClose={() => setShowDetailPresta(false)}
            demande={detail}
          />
        )}
        <FilterBenefits
          setOpen={setOpen}
          open={open}
          toggleDrawer={toggleDrawer}
          setFilterDetail={setFilterDetail}
          filterDetail={filterDetail}
        />
      </>
    );
  }
}
