import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Link,
  ThemeProvider,
  Typography,
  styled,
} from "@mui/material";
import { CheckCircle, DownloadForOffline } from "@mui/icons-material";
import { CustomButton } from "../../../components/buttons";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import partnersService from "../../../store/apis/partnersApis";
import { useSelector } from "react-redux";
import Congrats from "../congrats/Congrats";
import { fileSize } from "../../../config/utils";
import theme from "../../../assets/theme";
import Select from "react-select";
import { EmailInput, LinkInput, TextInput } from "../../../components/inputs";
import { list_dispos, list_metiers } from "../../../config/keys";

const Question = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyBauhaus,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "30px",
  color: "#002C3A",
  textAlign: "center",
  marginBottom: "45px",
}));

const Row = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  maxWidth: 1010,
  margin: "auto",
  flexWrap: "wrap",
};

const Input = styled("input")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  outline: "none",
  fontSize: theme.spacing(1.8),
  height: 45,
  width: "100%",
  padding: theme.spacing(1, 2),
  border: `1px solid rgba(62, 195, 236, 1)`,
  background: "transparent",
  "&:focus": {
    backgroundColor: "rgba(62, 195, 236, 0.08)",
    border: `1px dashed ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: theme.spacing(2.2),
  },
}));

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const schema = yup
  .object({
    email: yup
      .string()
      .email("entrer un email valide")
      .required("email est requis"),
    //password: yup.string().required(),
    prenom: yup.string().required("prenom est requis"),
    nom: yup.string().required("nom est requis"),
    telephone: yup
      .string()
      .required("telephone est requis")
      .matches(phoneRegExp, "entrez un numéro de téléphone valide"),
    adresse: yup.string().required("adresse est requis"),
    code_postal: yup
      .string()
      .required("code postal est requis")
      .matches(/\d{2}[ ]?\d{3}/, "entrer un code postal valide"),
    ville: yup.string().required("ville est requis"),
    /*  niveau_experience: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter a problem"),
    startMon: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter your experience start month"),
    startYear: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter your experience start end"),
    endMon: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter your experience end month"),
    endYear: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter your experience start month"),

    cv: yup.mixed().required("Cv est requis"),
    link: yup.string().required("lien est requis"),
    type_client: yup.string().required("function est requis"), */
    //urgent: yup.string().required(),
  })
  .required();
const Profile = (props) => {
  const { user } = useSelector((state) => state.userAuth);
  const [selectedFileKbis, setSelectedFileKbis] = useState();
  const [selectedFileCni, setSelectedFileCni] = useState();
  const [selectedFileAtAs, setSelectedFileAtAs] = useState();
  const [selectedFileIban, setSelectedFileIban] = useState();
  const [selectedFileSelfi, setSelectedFileSelfi] = useState();
  const [selectedFileCv, setSelectedFileCv] = useState();
  const [errorFiles, setErrorFiles] = useState(false);
  const [errorFileSize, setErrorFileSize] = useState(false);
  const [errorTypeFileSize, setErrorTypeFileSize] = useState();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [partenaire, setPartenaire] = useState();
  const [error, setError] = useState(false);
  const [congrat, setCongrat] = useState(false);
  let userData = user.user?.data ? user.user?.data : user.data;

  useEffect(() => {
    fetchData();
  }, [userData]);

  const fetchData = () => {
    setLoad(true);
    partnersService
      .detailPartner(userData.id)
      .then((res) => {
        setValue("nom", res.nom);
        setValue("prenom", res.prenom);
        setValue("email", res.email);
        setValue("adresse", res.adresse);
        setValue("code_postal", res.code_postal);
        setValue("ville", res.ville);
        setValue("telephone", res.telephone);
        setCheckedDispo(res.disponibilites);
        setCheckedMetiers(res.metiers);
        setLoad(false);
        setPartenaire(res);
      })
      .catch((e) => {
        setLoad(false);
        console.log(error);
      });
  };

  const changeHandler = (event, type) => {
    if (fileSize(event.target.files[0].size) > 2) {
      setErrorFileSize(true);
      setErrorTypeFileSize(type);
      return;
    }
    setErrorFileSize(false);
    setErrorTypeFileSize();
    switch (type) {
      case "kbis":
        setSelectedFileKbis(event.target.files[0]);
        break;
      case "cni":
        setSelectedFileCni(event.target.files[0]);
        break;
      case "AtAs":
        setSelectedFileAtAs(event.target.files[0]);
        break;
      case "iban":
        setSelectedFileIban(event.target.files[0]);
        break;
      case "selfi":
        setSelectedFileSelfi(event.target.files[0]);
        break;
      case "cv":
        setSelectedFileCv(event.target.files[0]);
        break;
    }
    /* setSelectedFile(event.target.files, ...selectedFile]);
        setValue("files", [event.target.files, ...selectedFile]) */
    // setIsSelected(true);
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const HandleRegister = (data) => {
    data.disponibilites = checkedDispo;
    data.metiers = checkedMetiers;
    /* const isValid =
      selectedFileKbis &&
      selectedFileAtAs &&
      selectedFileCni &&
      selectedFileIban &&
      selectedFileSelfi;

    if (!isValid) {
      setErrorFiles(true);
      retun;
    } */

    //return;

    setError(false);
    setLoading(true);
    /* const { iban, kbis, selfi, cv, cni, attestationAssurance } = data;
    console.log(data);
    return; */
    /* const obj = {
      web_site: link,
    }; */
    /* console.log(
      obj,
      selectedFileKbis,
      selectedFileIban,
      selectedFileCni,
      selectedFileAtAs,
      selectedFileSelfi,
      selectedFileCv
    ); */
    //return;
    partnersService
      .editPartner(user.data?.id, data)
      .then((res) => {
        /* const { id } = res; */
        const formData = new FormData();
        if (selectedFileCv) formData.append("cv", selectedFileCv);
        if (selectedFileIban) formData.append("iban", selectedFileIban);
        if (selectedFileKbis) formData.append("kbis", selectedFileKbis);
        if (selectedFileCni) formData.append("cni", selectedFileCni);
        if (selectedFileSelfi) formData.append("selfi", selectedFileSelfi);
        if (selectedFileAtAs) formData.append("attestation", selectedFileAtAs);

        if (
          selectedFileIban ||
          selectedFileKbis ||
          selectedFileCni ||
          selectedFileSelfi ||
          selectedFileAtAs ||
          selectedFileCv
        ) {
          partnersService.addFilesPartner(userData.id, formData).then((res) => {
            if (res?.response?.status === 500) {
              setLoading(false);
              setError(true);
            } else {
              setLoading(false);
              setPartenaire(res);
              setCongrat(res);
            }
          });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  const [errorMetiers, setErrorMetiers] = useState(false);
  const [errorDispo, setErrorDispo] = useState(false);

  const [checkedMetiers, setCheckedMetiers] = useState([]);

  const handleChange = (event, metier) => {
    if (event.target.checked) {
      setCheckedMetiers([...checkedMetiers, metier]);
    } else {
      const newMetiers = checkedMetiers.filter((item) => item !== metier);
      setCheckedMetiers(newMetiers);
    }
  };

  const [checkedDispo, setCheckedDispo] = useState([]);

  const handleChangeDispo = (event, dispo) => {
    if (event.target.checked) {
      setCheckedDispo([...checkedDispo, dispo]);
    } else {
      const newMetiers = checkedDispo.filter((item) => item !== dispo);
      setCheckedDispo(newMetiers);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box sx={Row} justifyContent={"center"} color={"#fff"}>
          {load ? (
            <CircularProgress />
          ) : (
            <form /* onSubmit={handleSubmit(onSubmit)} */>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                rowGap={2}
                flexWrap={"wrap"}
              >
                <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                  <TextInput
                    color={"#03EBFF!important"}
                    placeholder={"Prénom*"}
                    register={{ ...register("prenom") }}
                  />
                  {errors.prenom && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.prenom.message}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                  <TextInput
                    color={"#03EBFF!important"}
                    placeholder={"Nom*"}
                    register={{ ...register("nom") }}
                  />
                  {errors.nom && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.nom.message}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%" }}>
                  <TextInput
                    color={"#03EBFF!important"}
                    placeholder={"adresse*"}
                    register={{ ...register("adresse") }}
                  />
                  {errors.adresse && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.adresse.message}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                  <TextInput
                    color={"#03EBFF!important"}
                    placeholder={"code postal*"}
                    register={{ ...register("code_postal") }}
                  />
                  {errors.code_postal && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.code_postal.message}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                  <TextInput
                    color={"#03EBFF!important"}
                    placeholder={"ville*"}
                    register={{ ...register("ville") }}
                  />
                  {errors.ville && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.ville.message}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                  <EmailInput
                    color={"#03EBFF!important"}
                    register={{ ...register("email") }}
                  />
                  {errors.email && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.email.message}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                  <TextInput
                    color={"#03EBFF!important"}
                    placeholder={"numéro de téléphone**"}
                    type={"tel"}
                    register={{ ...register("telephone") }}
                  />
                  {errors.telephone && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.telephone.message}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                display={"flex"}
                alignItem={"center"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <Box flexBasis={{ xs: "100%", md: "100%" }}>
                  <Box mt={0.75}>
                    <Typography
                      variant="caption"
                      component={"label"}
                      fontSize={{ xs: 16, md: 24 }}
                    >
                      Activités
                    </Typography>
                    <Box>
                      <FormGroup aria-label="position" row>
                        {list_metiers.map((metier) => {
                          return (
                            <FormControlLabel
                              labelPlacement="end"
                              control={
                                <Checkbox
                                  checked={checkedMetiers.includes(metier)}
                                  onChange={(event) =>
                                    handleChange(event, metier)
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 28 },
                                  }}
                                />
                              }
                              label={
                                <Typography variant="caption">
                                  {metier}
                                </Typography>
                              }
                            />
                          );
                        })}
                      </FormGroup>
                      {errorMetiers && (
                        <Typography fontSize={{ xs: 14 }} color="red">
                          Veuillez sélectionner un ou plusieurs métiers
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box mt={0.75}>
                    <Typography
                      variant="caption"
                      component={"label"}
                      fontSize={{ xs: 16, md: 24 }}
                    >
                      Disponibilités
                    </Typography>
                    <Box>
                      <FormGroup aria-label="position" row>
                        {list_dispos.map((dispo) => {
                          return (
                            <FormControlLabel
                              labelPlacement="end"
                              control={
                                <Checkbox
                                  checked={checkedDispo.includes(dispo.id)}
                                  onChange={(event) =>
                                    handleChangeDispo(event, dispo.id)
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 28 },
                                  }}
                                />
                              }
                              label={
                                <Typography variant="caption">
                                  {dispo.value}
                                </Typography>
                              }
                            />
                          );
                        })}
                      </FormGroup>
                      {errorDispo && (
                        <Typography fontSize={{ xs: 14 }} color="red">
                          Veuillez choisir vos disponibilités
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box flexBasis={{ xs: "100%", md: "100%" }}>
                  <Typography
                    variant="caption"
                    component={"label"}
                    fontSize={{ xs: 16, md: 24 }}
                  >
                    Documents officiels
                  </Typography>
                  <Box sx={Row}>
                    <Box flexBasis={{ xs: "100%", md: "100%" }}>
                      <Box sx={Row}>
                        <Box
                          sx={{ padding: 1 }}
                          flexBasis={{ xs: "100%", md: "50%" }}
                        >
                          <Typography variant="caption">
                            Kbis de moins de 3 mois{" "}
                            {partenaire?.kbis && (
                              <Link href={partenaire?.kbis} target="_blank">
                                voir
                              </Link>
                            )}
                          </Typography>

                          <input
                            id={"upload-kbis"}
                            /* {...register("kbis")} */
                            type={"file"}
                            onChange={(event) => changeHandler(event, "kbis")}
                            hidden
                            accept={
                              "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            }
                          />

                          <Box component={"label"} htmlFor="upload-kbis">
                            <Box
                              sx={{
                                border: "1px solid rgba(62, 195, 236, 1)",
                                height: 45,
                                padding: 1,
                                marginBottom: 0.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "uppercase",
                                fontSize: 14,
                                cursor: "pointer",
                              }}
                            >
                              {!selectedFileKbis ? (
                                <>
                                  <DownloadForOffline
                                    sx={{ marginRight: "10px" }}
                                  />{" "}
                                  cliquez ici pour télécharger
                                </>
                              ) : (
                                <>
                                  <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                                  {selectedFileKbis?.name}
                                </>
                              )}
                            </Box>
                          </Box>

                          {errorFileSize && errorTypeFileSize === "kbis" && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Le fichier dépassent la limite autorisée (2MB).
                              Veuillez sélectionner un fichier plus petit.
                            </Typography>
                          )}
                          {errorFiles && !selectedFileKbis && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Ce document est obligatoire
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{ padding: 1 }}
                          flexBasis={{ xs: "100%", md: "50%" }}
                        >
                          <Typography variant="caption">
                            CNI recto / verso{" "}
                            {partenaire?.cni && (
                              <Link href={partenaire?.cni} target="_blank">
                                voir
                              </Link>
                            )}
                          </Typography>
                          <input
                            id={"upload-cni"}
                            /* {...register("cni")} */
                            type={"file"}
                            onChange={(event) => changeHandler(event, "cni")}
                            hidden
                            accept={
                              "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            }
                          />

                          <Box component={"label"} htmlFor="upload-cni">
                            <Box
                              sx={{
                                border: "1px solid rgba(62, 195, 236, 1)",
                                height: 45,
                                padding: 1,
                                marginBottom: 0.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "uppercase",
                                fontSize: 14,
                                cursor: "pointer",
                              }}
                            >
                              {!selectedFileCni ? (
                                <>
                                  <DownloadForOffline
                                    sx={{ marginRight: "10px" }}
                                  />{" "}
                                  cliquez ici pour télécharger
                                </>
                              ) : (
                                <>
                                  <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                                  {selectedFileCni?.name}
                                </>
                              )}
                            </Box>
                          </Box>
                          {errorFileSize && errorTypeFileSize === "cni" && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Le fichier dépassent la limite autorisée (2MB).
                              Veuillez sélectionner un fichier plus petit.
                            </Typography>
                          )}
                          {errorFiles && !selectedFileCni && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Ce document est obligatoire
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{ padding: 1 }}
                          flexBasis={{ xs: "100%", md: "50%" }}
                        >
                          <Typography variant="caption">
                            Attestation d'assurance{" "}
                            {partenaire?.attestation && (
                              <Link
                                href={partenaire?.attestation}
                                target="_blank"
                              >
                                voir
                              </Link>
                            )}
                          </Typography>
                          <input
                            id={"upload-attestationAssurance"}
                            /* {...register("attestationAssurance")} */
                            type={"file"}
                            onChange={(event) => changeHandler(event, "AtAs")}
                            hidden
                            accept={
                              "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            }
                          />

                          <Box
                            component={"label"}
                            htmlFor="upload-attestationAssurance"
                          >
                            <Box
                              sx={{
                                border: "1px solid rgba(62, 195, 236, 1)",
                                height: 45,
                                padding: 1,
                                marginBottom: 0.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "uppercase",
                                fontSize: 14,
                                cursor: "pointer",
                              }}
                            >
                              {!selectedFileAtAs ? (
                                <>
                                  <DownloadForOffline
                                    sx={{ marginRight: "10px" }}
                                  />{" "}
                                  cliquez ici pour télécharger
                                </>
                              ) : (
                                <>
                                  <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                                  {selectedFileAtAs?.name}
                                </>
                              )}
                            </Box>
                          </Box>
                          {errorFileSize && errorTypeFileSize === "AtAs" && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Le fichier dépassent la limite autorisée (2MB).
                              Veuillez sélectionner un fichier plus petit.
                            </Typography>
                          )}
                          {errorFiles && !selectedFileAtAs && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Ce document est obligatoire
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{ padding: 1 }}
                          flexBasis={{ xs: "100%", md: "50%" }}
                        >
                          <Typography variant="caption">
                            IBAN{" "}
                            {partenaire?.iban && (
                              <Link href={partenaire?.iban} target="_blank">
                                voir
                              </Link>
                            )}
                          </Typography>
                          <input
                            id={"upload-iban"}
                            /* {...register("iban")} */
                            type={"file"}
                            onChange={(event) => changeHandler(event, "iban")}
                            hidden
                            accept={
                              "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            }
                          />

                          <Box component={"label"} htmlFor="upload-iban">
                            <Box
                              sx={{
                                border: "1px solid rgba(62, 195, 236, 1)",
                                height: 45,
                                padding: 1,
                                marginBottom: 0.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "uppercase",
                                fontSize: 14,
                                cursor: "pointer",
                              }}
                            >
                              {!selectedFileIban ? (
                                <>
                                  <DownloadForOffline
                                    sx={{ marginRight: "10px" }}
                                  />{" "}
                                  cliquez ici pour télécharger
                                </>
                              ) : (
                                <>
                                  <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                                  {selectedFileIban?.name}
                                </>
                              )}
                            </Box>
                          </Box>
                          {errorFileSize && errorTypeFileSize === "iban" && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Le fichier dépassent la limite autorisée (2MB).
                              Veuillez sélectionner un fichier plus petit.
                            </Typography>
                          )}
                          {errorFiles && !selectedFileIban && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Ce document est obligatoire
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{ padding: 1 }}
                          flexBasis={{ xs: "100%", md: "50%" }}
                        >
                          <Typography variant="caption">
                            Selfi{" "}
                            {partenaire?.image && (
                              <Link href={partenaire?.image} target="_blank">
                                voir
                              </Link>
                            )}
                          </Typography>
                          <input
                            id={"upload-selfi"}
                            /* {...register("selfi")} */
                            type={"file"}
                            onChange={(event) => changeHandler(event, "selfi")}
                            hidden
                            accept={"image/png, image/jpeg"}
                          />

                          <Box component={"label"} htmlFor="upload-selfi">
                            <Box
                              sx={{
                                border: "1px solid rgba(62, 195, 236, 1)",
                                height: 45,
                                padding: 1,
                                marginBottom: 0.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "uppercase",
                                fontSize: 14,
                                cursor: "pointer",
                              }}
                            >
                              {!selectedFileSelfi ? (
                                <>
                                  <DownloadForOffline
                                    sx={{ marginRight: "10px" }}
                                  />{" "}
                                  cliquez ici pour télécharger
                                </>
                              ) : (
                                <>
                                  <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                                  {selectedFileSelfi?.name}
                                </>
                              )}
                            </Box>
                          </Box>
                          {errorFileSize && errorTypeFileSize === "selfi" && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Le fichier dépassent la limite autorisée (2MB).
                              Veuillez sélectionner un fichier plus petit.
                            </Typography>
                          )}
                          {errorFiles && !selectedFileSelfi && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Ce document est obligatoire
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{ padding: 1 }}
                          flexBasis={{ xs: "100%", md: "50%" }}
                        >
                          <Typography variant="caption">
                            Ajouter un CV (facultatif){" "}
                            {partenaire?.cv && (
                              <Link href={partenaire?.cv} target="_blank">
                                voir
                              </Link>
                            )}
                          </Typography>
                          <input
                            id={"upload-cv"}
                            /* {...register("cv")} */
                            type={"file"}
                            onChange={(event) => changeHandler(event, "cv")}
                            hidden
                            accept={
                              "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            }
                          />

                          <Box component={"label"} htmlFor="upload-cv">
                            <Box
                              sx={{
                                border: "1px solid rgba(62, 195, 236, 1)",
                                height: 45,
                                padding: 1,
                                marginBottom: 0.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "uppercase",
                                fontSize: 14,
                                cursor: "pointer",
                              }}
                            >
                              {!selectedFileCv ? (
                                <>
                                  <DownloadForOffline
                                    sx={{ marginRight: "10px" }}
                                  />{" "}
                                  cliquez ici pour télécharger
                                </>
                              ) : (
                                <>
                                  <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                                  {selectedFileCv?.name}
                                </>
                              )}
                            </Box>
                          </Box>

                          {errorFileSize && errorTypeFileSize === "cv" && (
                            <Typography fontSize={{ xs: 14 }} color="red">
                              Le fichier dépassent la limite autorisée (2MB).
                              Veuillez sélectionner un fichier plus petit.
                            </Typography>
                          )}
                          {/* {errors.cv && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.cv.message}
                </Typography>
              )} */}
                        </Box>
                        <Box
                          sx={{ padding: 1 }}
                          flexBasis={{ xs: "100%", md: "50%" }}
                        >
                          <Typography variant={"caption"} component={"label"}>
                            Ajouter un lien vers votre site (facultatif)
                          </Typography>

                          <Input
                            type={"text"}
                            placeholder={"Saisissez le lien ici"}
                            {...register("link")}
                          />
                          {/*  {errors.link && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.link.message}
                </Typography>
              )} */}
                        </Box>
                        <Box
                          display={"flex"}
                          mb={4}
                          mt={4}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={2}
                          flexBasis={{ xs: "100%", md: "100%" }}
                        >
                          {" "}
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <CustomButton
                              type="blue"
                              handleClick={handleSubmit(HandleRegister)}
                            >
                              Modifier
                            </CustomButton>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          )}
        </Box>
        <Congrats congrat={congrat} onClose={() => setCongrat(false)} />
      </Box>
    </ThemeProvider>
  );
};

export default Profile;
