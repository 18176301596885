import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  EmailInput,
  LinkInput,
  TextAreaInput,
  TextInput,
} from "../../../../components/inputs";
import { Window, WinItem } from "../../../../components/Window";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useSelector } from "react-redux";
import { DisabledByDefault } from "@mui/icons-material";
import assistanceService from "../../../../store/apis/assistanceApi";

const schema = yup.object({});

export default function MyAssistanceForm({ setShowForm, sujet }) {
  const { user } = useSelector((state) => state.userAuth);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    setLoading(true);
    data.client = `/api/users/${user.data.id}`;

    assistanceService
      .userAssistance(data)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    setValue("sujet", sujet);
  }, []);

  return (
    <Window>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          rowGap={2}
          flexWrap={"wrap"}
        >
          <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
            <Typography
              variant="h2"
              textTransform={"capitalize"}
              fontSize={{ sm: 14, md: 18 }}
              width={160}
              textAlign="right"
              paddingRight={0.6}
            >
              Nom complet :
            </Typography>

            <Typography
              variant="h2"
              textTransform={"capitalize"}
              fontSize={{ sm: 14, md: 18 }}
              width={{ xs: 230, md: 444 }}
              color={"primary"}
            >
              {user.data.prenom + " " + user.data.nom}
            </Typography>
          </Box>
          <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
            <Typography
              variant="h2"
              textTransform={"capitalize"}
              fontSize={{ sm: 14, md: 18 }}
              width={160}
              textAlign="right"
              paddingRight={0.6}
            >
              Email :
            </Typography>

            <Typography
              variant="h2"
              fontSize={{ sm: 14, md: 18 }}
              width={{ xs: 230, md: 444 }}
              color={"primary"}
            >
              {user.data.email}
            </Typography>
          </Box>
          <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
            <Typography
              variant="h2"
              fontSize={{ sm: 14, md: 18 }}
              width={160}
              textAlign="right"
              paddingRight={0.6}
            >
              Téléphone :
            </Typography>

            <Typography
              variant="h2"
              fontSize={{ sm: 14, md: 18 }}
              width={{ xs: 230, md: 444 }}
              color={"primary"}
            >
              {user.data.telephone}
            </Typography>
          </Box>
          <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
            <TextInput
              placeholder={"Sujet*"}
              register={{ ...register("sujet") }}
            />
          </Box>
          <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
            <TextAreaInput
              placeholder={"Votre message*"}
              register={{ ...register("message") }}
            />
          </Box>
        </Box>
      </form>
      <Box
        display={"flex"}
        mb={4}
        mt={4}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <CustomButton type={"red"} handleClick={() => setShowForm(false)}>
              retour
            </CustomButton>
            <CustomButton
              type={"yellow"}
              textColor={"white"}
              handleClick={handleSubmit(onSubmit)}
            >
              envoyer
            </CustomButton>
          </>
        )}
      </Box>
    </Window>
  );
}
