import { styled } from "@mui/system";

const GoBack = styled('div')(({theme}) => ({
    display: 'block',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#03EBFF',

    'svg': {
        verticalAlign: 'text-bottom',
    }
}));

export default GoBack;