import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../../components/buttons/CustomButton";
import moment from "moment";
import Select from "react-select";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import partnersPartner from "../../../../store/apis/partnersApis";
import PrestationService from "../../../../store/apis/PrestationApi";
import {
  hoursList,
  rdv_type_call,
  rdv_type_prestation,
  status_demande,
} from "../../../../config/keys";
import CalendarService from "../../../../store/apis/CalendarApi";
import { TextAreaInput } from "../../../../components/inputs";
import { TextInput } from "react-native";
import { changeDateFormat } from "../../../../config/utils";
import dateFormat from "dateformat";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object({
  message: yup.string().required("Le message est requis"),
});

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const EditRDVForm = ({
  dialog,
  onClose,
  demande,
  step,
  dateCall,
  setDateCall,
  datePlanification,
  setDatePlanification,
  setLoadPresta,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    dateCall
      ? dateCall.date_prevu
        ? dateCall.date_prevu.split("T")[0]
        : dateCall.date_debut.split("T")[0]
      : null
  );
  const [selectedCreneau, setSelectedCreneau] = useState(
    dateCall
      ? {
          value: dateCall.date_prevu
            ? dateCall.date_prevu.split("T")[1].split("+")[0].substring(0, 5)
            : dateCall.date_debut.split("T")[1].split("+")[0].substring(0, 5),
          label: dateCall.date_prevu
            ? dateCall.date_prevu.split("T")[1].split("+")[0].substring(0, 5)
            : dateCall.date_debut.split("T")[1].split("+")[0].substring(0, 5),
        }
      : null
  );

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [typeRdv, setTypeRdv] = useState(
    step === 1 ? rdv_type_call : rdv_type_prestation
  );
  const [typeRdvTitre, setTypeRdvTitre] = useState(
    step === 1 ? "Appel téléphonique" : "Intervention de la prestation"
  );

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    setLoading(true);
    let newData = {
      demandes: [
        "Type de rdv : " +
          typeRdvTitre +
          "<br/>Message : " +
          data.message +
          "<br/>Date proposée : " +
          changeDateFormat(selectedDate) +
          " à " +
          selectedCreneau.value,
      ],
    };

    let newDate = {
      date_prevu: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
    };

    CalendarService.editCalendar(dateCall.id, newDate)
      .then((res) => {
        PrestationService.editPrestation(demande.id, newData)
          .then((res) => {
            setLoading(false);
            onClose();
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleChangeCreneau = (creneau) => {
    setSelectedCreneau(creneau);
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 700, maxWidth: { md: 700, lg: 700 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Demande de changement/annulation du rdv
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItem={"center"}
            justifyContent={"space-between"}
            rowGap={2}
            flexWrap={"wrap"}
          >
            <Box flex={{ xs: "0 1 100%", md: "0 1 100%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Type du rendez-vous
                </Typography>{" "}
                <TextInput
                  type="text"
                  style={{
                    height: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    width: "300px",
                  }}
                  value={typeRdvTitre}
                  disabled
                />
              </Box>
            </Box>
            <Box flex={{ xs: "0 1 100%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Votre message
                </Typography>
                <Box>
                  <TextAreaInput
                    placeholder={"Votre message"}
                    register={{ ...register("message") }}
                    // disable={isFetching || sent}
                  />
                  {errors.message && (
                    <Typography
                      fontSize={{ xs: 14 }}
                      lineHeight={{ xs: "20px" }}
                      color="red"
                    >
                      {errors.message.message}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Selectionner une nouvelle date
                </Typography>
                <Box>
                  <input
                    type="date"
                    name="date-planification"
                    id="date-planification"
                    lang="fr_FR"
                    locale="fr_FR"
                    min={dateFormat(tomorrow, "yyyy-mm-dd")}
                    style={{
                      color: "#ddd",
                      fontSize: "24px",
                      border: "1px solid #3EC3EC",
                      borderRadius: "3px",
                      padding: "10px",
                      width: "100%",
                    }}
                    value={selectedDate}
                    onChange={handleChangeDate}
                  />
                </Box>
              </Box>
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Selectionner le créneau
                </Typography>
                <Box>
                  <Controller
                    control={control}
                    name={"creneau"}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={selectedCreneau}
                        styles={customStyles}
                        placeholder={"Créneau"}
                        options={hoursList}
                        onChange={handleChangeCreneau}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </form>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton type={"red"} handleClick={onClose}>
                Annuler
              </CustomButton>

              <CustomButton
                type={"yellow"}
                textColor={"white"}
                handleClick={handleSubmit(onSubmit)}
              >
                Envoyer
              </CustomButton>
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default EditRDVForm;
