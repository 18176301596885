import { View, Text } from "react-native-web";
import { NavBar, Footer } from "../../../components";
import { Box, Typography, Container } from "@mui/material";
import styles from "../../../assets/css/style";
import { StyleSheet } from "react-native";
import MyAssistance from "./tabs/MyAssistance";
import ServiceProgress from "./tabs/ServiceProgress";
import TabPanel from "../../../components/TabPanel";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PrestationService from "../../../store/apis/PrestationApi";

export default function Dashboard() {
  const { user } = useSelector((state) => state.userAuth);
  let userData = user.user?.data ? user.user?.data : user.data;

  const [datas, setDatas] = useState(null);
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetchData();
  }, [userData]);

  const refresh = () => {
    setDatas();
    fetchData();
  };

  const fetchData = () => {
    setLoad(true);
    if (userData?.id) {
      PrestationService.detailPrestation(userData?.id)
        .then((res) => {
          setDatas(res);
          setLoad(false);
        })
        .catch((e) => {
          setLoad(false);
          setError(e);
        });
    } else {
      setLoad(false);
      console.log("erreur");
    }
  };

  const items = [
    {
      label: "Déroulement de la prestation",
      content: <ServiceProgress datas={datas} load={load} refresh={refresh} />,
    },
    /*  {
      label: "Mes documents",
      content: <MyDocuments datas={datas} />,
    }, */
    {
      label: "Mon assistance",
      content: <MyAssistance />,
    },
  ];

  return (
    <View style={StyleSheet.flatten(styles.body)}>
      <NavBar />
      <Box style={StyleSheet.flatten(styles.bgWrap)}>
        <Typography
          style={StyleSheet.flatten(styles.dottedLine)}
          align="center"
          variant="h3"
        >
          Dashboard
        </Typography>
        <Container maxWidth="lg">
          <TabPanel items={items} />
        </Container>
      </Box>

      <Footer />
    </View>
  );
}
