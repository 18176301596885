import { forwardRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomButton from "../../../components/buttons/CustomButton";
import { useTheme } from "@emotion/react";
import partnersService from "../../../store/apis/partnersApis";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Remove({ remove, onClose, setRemoveCongrat, id }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = () => {
    setLoading(true);
    partnersService
      .removePartner(id)
      .then((res) => {
        if (res?.response?.status) {
          setError("Utilisateur impossible à supprimer!");
        } else {
          onClose();
          setRemoveCongrat(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };
  return (
    <Dialog
      open={remove}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          maxWidth: { sm: 650 },
          height: { xs: 232, md: 385 },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      onClose={onClose}
    >
      <Container maxWidth={isMd ? "sm" : "xs"}>
        <Box paddingLeft={{ md: 5 }} paddingRight={{ md: 5 }}>
          <Box mb={4}>
            <Typography
              fontFamily={"Bauhaus"}
              textAlign={"center"}
              fontSize={{ xs: 16 }}
              fontWeight={700}
            >
              Êtes-vous sûr de vouloir supprimer ce profile ?
            </Typography>
            {error && (
              <Typography
                fontSize={{
                  xs: 14,
                  margin: "-15px 0 15px",
                  textAlign: "center",
                }}
                color="red"
              >
                {error}
              </Typography>
            )}
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Box
              display={"flex"}
              mb={4}
              mt={6}
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <CustomButton type={"red"} handleClick={onClose}>
                    annuler
                  </CustomButton>
                  <CustomButton type="yellow" handleClick={onSubmit}>
                    Supprime
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
}
