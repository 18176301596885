import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import AdvancedList from "../../../../components/AdvancedList";
import moment from "moment";
import { KeyboardBackspaceOutlined, Refresh } from "@mui/icons-material";
import MyCalendar from "../popups/MyCalendar";
import {
  document_type_devis,
  document_type_fiche_intervention,
  rdv_type_call,
  rdv_type_prestation,
} from "../../../../config/keys";
import PDF from "../popups/PDF";
import PDFFicheIntervention from "../popups/PDFFicheIntervention";
import EditRDVForm from "../popups/EditRDV";
import PaymentForm from "../popups/Payment";

export default function ServiceProgress({ datas, load, refresh }) {
  const [showEditRdv, setShowEditRdv] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showDevis, setShowDevis] = useState(false);
  const [showFicheIntervention, setShowFicheIntervention] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [step, setStep] = useState(0);
  const [dateCall, setDateCall] = useState();
  const [datePlanification, setDatePlanification] = useState();
  const [detailDevis, setDetailDevis] = useState();

  const handleShowDocument = (type) => {
    if (type === document_type_devis) {
      setShowDevis(true);
    }
    if (type === document_type_fiche_intervention) {
      setShowFicheIntervention(true);
    }
  };

  const handleEditRdv = (type_rdv) => {
    console.log(type_rdv);
    setShowEditRdv(true);
  };

  const goDetail = () => {
    setShowDevis(false);
    setShowFicheIntervention(false);
    setShowCalendar(false);
  };

  const handleSigned = () => {
    setShowDevis(false);
    setShowCalendar(true);
    refresh()
  };
  const handleSignedFicheIntervention = () => {
    setShowFicheIntervention(false);
    refresh()
  };

  const handleDateIntervention = () => {
    setShowDevis(false);
    setShowCalendar(false);
    setStep(step + 1);
    refresh()
  };

  useEffect(() => {
    if (!load && datas?.documents) {
      setDetailDevis(
        datas?.documents?.find(
          (obj) => obj.type.toLowerCase() === document_type_devis
        )
      );
    }
    if (!load && datas?.client?.calendar) {
      setDateCall(
        datas?.client?.calendar?.filter((rdv) => rdv.type === rdv_type_call).pop()
      );
      setDatePlanification(
        datas?.client?.calendar?.filter((rdv) => rdv.type === rdv_type_prestation).pop()
      );
    }
    setStep(parseInt(datas?.etape));
  }, [load, datas?.documents]);

  const data = [
    {
      id: 1,
      text:
        "Votre demande d’inscription le " +
        moment.parseZone(datas?.client?.date_inscription).format("DD/MM/YYYY"),
      state: -1,
    },
    {
      id: 2,
      text: "Votre inscription validée par Fibme",
      state: -1,
    },
    {
      id: 3,
      text: dateCall?.date_prevu
        ? "Appel téléphonique prévu le " +
          moment.parseZone(dateCall?.date_debut).format("DD/MM/YYYY") +
          " à " +
          moment.parseZone(dateCall?.date_debut).format("HH:mm") +
          " pour la planification de l’intervention du diagnostic et/ou de la réalisation." +
          " Date proposée : " +
          moment.parseZone(dateCall?.date_prevu).format("DD/MM/YYYY") +
          " à " +
          moment.parseZone(dateCall?.date_prevu).format("HH:mm")
        : "Appel téléphonique prévu le " +
          moment.parseZone(dateCall?.date_debut).format("DD/MM/YYYY") +
          " à " +
          moment.parseZone(dateCall?.date_debut).format("HH:mm") +
          " pour la planification de l’intervention du diagnostic et/ou de la réalisation.",
      state: step > 3 ? -1 : 0,
      update: step === 1 ? true : false,
      signer: false,
      voir: false,
      regler: false,
      typeRdv: rdv_type_call,
    },
    {
      id: 4,
      text: detailDevis ? "Signature du devis." : "Devis non disponible.",
      state: step === 4 ? 0 : step > 4 ? -1 : 1,
      update: false,
      signer: step === 4 ? true : false,
      voir: detailDevis && step > 4 ? true : false,
      regler: false,
      typeDocument: document_type_devis,
    },
    {
      id: 5,
      text: datePlanification?.date_prevu
        ? "Rendez-vous pour diagnostiquer le problème et/ou réaliser la prestation prevu le " +
          moment.parseZone(datePlanification?.date_debut).format("DD/MM/YYYY") +
          " " +
          " á " +
          moment.parseZone(datePlanification?.date_debut).format("HH:mm") +
          " Date proposée : " +
          moment.parseZone(datePlanification?.date_prevu).format("DD/MM/YYYY") +
          " à " +
          moment.parseZone(datePlanification?.date_prevu).format("HH:mm")
        : datePlanification?.date_debut
        ? "Rendez-vous pour diagnostiquer le problème et/ou réaliser la prestation prevu le " +
          moment.parseZone(datePlanification?.date_debut).format("DD/MM/YYYY") +
          " " +
          " á " +
          moment.parseZone(datePlanification?.date_debut).format("HH:mm")
        : "Rendez-vous pour diagnostiquer le problème et/ou réaliser la prestation",
      state: step === 6 ? 0 : step > 6 ? -1 : 1,
      update: step === 6 || step === 5 ? true : false,
      signer: false,
      voir: false,
      regler: false,
      typeRdv: rdv_type_prestation,
    },
    {
      id: 6,
      text: "Signature de la fiche d’intervention.",
      state: step === 9 ? 0 : step > 9 ? -1 : 1,
      update: false,
      signer: step === 9 ? true : false,
      voir: step > 9 ? true : false,
      regler: false,
      typeDocument: document_type_fiche_intervention,
    },
    {
      id: 7,
      text:
        datas?.type_prestation === "devis_ignorer" ? "Terminer" : "Paiement.",
      state: step === 10 ? 0 : step > 10 ? -1 : 1,
      update: false,
      signer: false,
      voir: false,
      regler:
        datas?.type_prestation !== "devis_ignorer" && step === 10
          ? true
          : false,
    },
  ];

  if (load) {
    return (
      <Box
        height={"30vh"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    );
  } else if (showDevis) {
    return (
      <>
        <Button
          startIcon={<KeyboardBackspaceOutlined />}
          variant={"text"}
          color={"primary"}
          sx={{ fontWeight: 700, textTransform: "capitalize" }}
          onClick={goDetail}
        >
          Retour
        </Button>
        <PDF
          presta={datas}
          client={datas?.client}
          type={document_type_devis}
          handleSigned={handleSigned}
        />
      </>
    );
  } else if (showFicheIntervention) {
    return (
      <>
        <Button
          startIcon={<KeyboardBackspaceOutlined />}
          variant={"text"}
          color={"primary"}
          sx={{ fontWeight: 700, textTransform: "capitalize" }}
          onClick={goDetail}
        >
          Retour
        </Button>
        <PDFFicheIntervention
          presta={datas}
          client={datas?.client}
          type={document_type_fiche_intervention}
          handleSigned={handleSignedFicheIntervention}
        />
      </>
    );
  } else if (showCalendar) {
    return (
      <>
        <Button
          startIcon={<KeyboardBackspaceOutlined />}
          variant={"text"}
          color={"primary"}
          sx={{ fontWeight: 700, textTransform: "capitalize" }}
          onClick={goDetail}
        >
          Retour
        </Button>
        <MyCalendar
          presta={datas}
          client={datas?.client}
          handleDateIntervention={handleDateIntervention}
        />
      </>
    );
  } else {
    return (
      <>
        <Typography align="right">
          <Button
            startIcon={<Refresh />}
            variant={"text"}
            color={"primary"}
            sx={{ fontWeight: 700, textTransform: "capitalize" }}
            onClick={refresh}
          >
            Rafraîchir
          </Button>
        </Typography>
        <Box className="my-service-progress">
          <AdvancedList
            items={data}
            handleShowDocument={handleShowDocument}
            handleEditRdv={handleEditRdv}
            handleShowPayemnt={setShowPayment}
            listType="numbers"
          />
        </Box>
        {showEditRdv && (
          <EditRDVForm
            dialog={showEditRdv}
            onClose={() => setShowEditRdv(false)}
            demande={datas}
            dateCall={dateCall}
            setDateCall={setDateCall}
            datePlanification={datePlanification}
            setDatePlanification={setDatePlanification}
            step={step}
            /* setLoadPresta={() => fetchData(props.presta)} */
          />
        )}
        {showPayment && (
          <PaymentForm
            dialog={showPayment}
            onClose={() => setShowPayment(false)}
            demande={datas}
            onSuccess={() => {
              refresh();
              setShowPayment(false);
            }}
          />
        )}
      </>
    );
  }
}
