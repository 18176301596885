import { Paper, Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { keyframes } from "@mui/system";

/*const animate = keyframes`
0% {
  box-shadow: 0px 0px 0px rgba(3,235, 255, 1);
}
25% {
  box-shadow: 4px 4px 12px rgba(3,235, 255, 1);
}
50% {
  box-shadow: -4px -4px 12px rgba(3,235, 255, 1);
}
75% {
  box-shadow: -4px -4px 12px rgba(3,235, 255, 1);
}
100% {
  box-shadow: 0px 0px 0px rgba(3,235, 255, 1);
}
`;*/

const animate = keyframes`
0% {
      box-shadow: 0 0.5rem 2rem 0.2rem rgba(236, 221, 59, 0.5);
    }
    15% {
      box-shadow: -0.5rem 0.5rem 2rem 0.2rem rgba(236, 221, 59, 0.25);
    }
    25% {
      box-shadow: -0.5rem 0 2rem 0.2rem rgba(62, 195, 236, 0.25);
    }
    32% {
      box-shadow: -0.5rem -0.5rem 2rem 0.2rem rgba(62, 195, 236, 0.5);
    }
    50% {
      box-shadow: 0 -0.5rem 2rem 0.2rem rgba(62, 195, 236, 0.25);
    }
    75% {
      box-shadow: 0.5rem 0 2rem 0.2rem rgba(62, 195, 236, 0.25);
    }
    87% {
      box-shadow: 0.5rem 0.5rem 2rem 0.2rem rgba(236, 221, 59, 0.25);
    }
    100% {
      box-shadow: 0 0.5rem 2rem 0.2rem rgba(236, 221, 59, 0.5);
    }
`;

const Card = styled(Box)(({ theme }) => ({
  flex: "0 1 90%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: "transparent",
  textAlign: "center",
  //boxShadow: `-7px -7px 14px ${theme.palette.primary.main}`,
  animation: `${animate} 8s 1s infinite ease`,
  padding: theme.spacing(3, 4),
  marginBottom: theme.spacing(3),
  borderRadius: "10px",
  [theme.breakpoints.up("md")]: {
    marginBottom: 0,
    flex: "0 1 50%",
    animation: "none",
  },
}));

export default function WhyCard({ img, miniImg, heading, description }) {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Card elevation={0}>
      <Box>
        <img src={isLarge ? img : miniImg} alt="icon" />
      </Box>
      <Typography
        variant="h2"
        component={"h3"}
        paddingBottom={2}
        paddingTop={2}
        textAlign={"center"}
      >
        {heading}
      </Typography>
      <Typography variant={"body2"} color={"textSecondary"}>
          {description}
      </Typography>
    </Card>
  );
}
