import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { KeyboardBackspaceOutlined, EditOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import partnersService from "../../../store/apis/partnersApis";
import moment from "moment";
import { list_dispos } from "../../../config/keys";

export default function ProfilePartener({
  goBack,
  setEdit,
  currentProfile,
}) {
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);
  const [dispoValues, setDispoValues] = useState("");

  useEffect(() => {
    let filteredObjects = list_dispos.filter((obj) =>
      currentProfile.disponibilites.includes(obj.id)
    );
    let values = filteredObjects.map((obj) => obj.value);
    setDispoValues(values.join(", "));
  }, [currentProfile]);

  if (error)
    return (
      <Box>
        <Typography fontSize={{ xs: 16 }} color="red">
          Something Went Wrong
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ color: "#fff" }}>
      <Button
        startIcon={<KeyboardBackspaceOutlined />}
        variant={"text"}
        color={"primary"}
        sx={{ fontWeight: 700, textTransform: "capitalize" }}
        onClick={goBack}
      >
        Retour
      </Button>

      {load && (
        <Box
          height={"100%"}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Box>
      )}
      {currentProfile && (
        <>
          <Typography
            variant={"body2"}
            fontWeight={"700"}
            textTransform={"uppercase"}
            fontSize={{ md: 24 }}
            mt={2}
            mb={2}
          >
            Profil partenaire
          </Typography>
          <Box
            display={"flex"}
            alignItems={{ xs: "center", md: "start" }}
            justifyContent={{ xs: "center", md: "start" }}
            gap={{ xs: 3, md: "25px" }}
            flexWrap={"wrap"}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Box>
              <Typography
                variant={"h1"}
                fontSize={{ md: 36 }}
                fontWeight={700}
                mb={2}
              >
                {`${currentProfile.prenom} ${currentProfile.nom}`}
              </Typography>
              {/*  <Box
                width={{ xs: 100, md: 142 }}
                height={{ xs: 100, md: 142 }}
                m={"0 auto"}
              >
                <img src={admin} alt="admin" height={"100%"} width={"100%"} />
              </Box> */}
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={{ xs: "center", md: "start" }}
            justifyContent={{ xs: "center", md: "start" }}
            gap={{ xs: 3, md: "25px" }}
            flexWrap={"wrap"}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Box mt={{ md: 6 }}>
              <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={135}
                  textAlign="right"
                  paddingRight={1}
                >
                  Adresse :
                </Typography>

                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                >
                  {currentProfile.adresse}
                </Typography>
              </Box>
              <Box display={"flex"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={135}
                  textAlign="right"
                  paddingRight={1}
                >
                  Tel :
                </Typography>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                >
                  {currentProfile.telephone}
                </Typography>
              </Box>
              <Box display={"flex"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  width={135}
                  textAlign="right"
                  paddingRight={1}
                  fontSize={{ sm: 14, md: 18 }}
                >
                  Email :
                </Typography>
                <Typography
                  variant="h2"
                  fontSize={{ sm: 14, md: 18 }}
                  width={192}
                  textTransform={"lowercase"}
                >
                  {currentProfile.email}
                </Typography>
              </Box>
              {/* <Box display={"flex"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={135}
                  textAlign="right"
                  paddingRight={1}
                >
                  Type :
                </Typography>

                <Box width={192}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                  >
                    {currentProfile.forme_juridique}
                  </Typography>
                </Box>
              </Box> */}
              <Box display={"flex"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={135}
                  textAlign="right"
                  paddingRight={1}
                >
                  Metiers :
                </Typography>

                <Box width={192}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                  >
                    {currentProfile.metiers.join(", ")}
                  </Typography>
                  {/* <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                  >
                    Date de début : {moment.parseZone(currentProfile.date_debut).format("MM/YYYY")}
                  </Typography>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                  >
                    Date de fin : {moment.parseZone(currentProfile.date_fin).format("MM/YYYY")}
                  </Typography> */}
                </Box>
              </Box>
              <Box display={"flex"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={135}
                  textAlign="right"
                  paddingRight={1}
                >
                  Disponibilités :
                </Typography>

                <Box width={192}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                  >
                    {dispoValues}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={{ xs: "center", md: "end" }}
              justifyContent={{ xs: "center", md: "end" }}
              flexDirection={{ md: "column" }}
              flexWrap={"wrap"}
              flexGrow={2}
              gap={1}
            >
              <Button
                startIcon={<EditOutlined />}
                variant={"text"}
                sx={{
                  fontWeight: { xs: 700 },
                  fontSize: { xs: 16 },
                  fontFamily: { xs: "Bauhaus" },
                  textTransform: "capitalize",
                  marginRight: { xs: "10%", md: 0 },
                  marginLeft: { xs: "auto", md: 0 },
                }}
                color="inherit"
                onClick={() => setEdit(true)}
              >
                Modifier
              </Button>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={{ md: "column" }}
                gap={1}
                flexBasis={"100%"}
              >
                {currentProfile.kbis && (
                  <Box
                    border={"1px solid rgba(3, 235, 255, 1)"}
                    width={{ xs: 150, md: 190, lg: 264 }}
                    height={{ xs: 30, md: 45 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontWeight={{ xs: 300 }}
                      fontSize={{ xs: 14, md: 24 }}
                      color={"#ddd"}
                      component={"a"}
                      href={currentProfile.kbis}
                      sx={{ textDecoration: "none" }}
                      target={"_blank"}
                    >
                      KBIS
                    </Typography>
                  </Box>
                )}
                {currentProfile.cni && (
                  <Box
                    border={"1px solid rgba(3, 235, 255, 1)"}
                    width={{ xs: 150, md: 190, lg: 264 }}
                    height={{ xs: 30, md: 45 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontWeight={{ xs: 300 }}
                      fontSize={{ xs: 14, md: 24 }}
                      color={"#ddd"}
                      component={"a"}
                      href={currentProfile.cni}
                      sx={{ textDecoration: "none" }}
                      target={"_blank"}
                    >
                      CNI
                    </Typography>
                  </Box>
                )}
                {currentProfile.attestation && (
                  <Box
                    border={"1px solid rgba(3, 235, 255, 1)"}
                    width={{ xs: 150, md: 190, lg: 264 }}
                    height={{ xs: 30, md: 45 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontWeight={{ xs: 300 }}
                      fontSize={{ xs: 14, md: 24 }}
                      color={"#ddd"}
                      component={"a"}
                      href={currentProfile.attestation}
                      sx={{ textDecoration: "none" }}
                      target={"_blank"}
                    >
                      attestation d'assurance
                    </Typography>
                  </Box>
                )}
                {currentProfile.iban && (
                  <Box
                    border={"1px solid rgba(3, 235, 255, 1)"}
                    width={{ xs: 150, md: 190, lg: 264 }}
                    height={{ xs: 30, md: 45 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontWeight={{ xs: 300 }}
                      fontSize={{ xs: 14, md: 24 }}
                      color={"#ddd"}
                      component={"a"}
                      href={currentProfile.iban}
                      sx={{ textDecoration: "none" }}
                      target={"_blank"}
                    >
                      IBAN
                    </Typography>
                  </Box>
                )}
                {currentProfile.image && (
                  <Box
                    border={"1px solid rgba(3, 235, 255, 1)"}
                    width={{ xs: 150, md: 190, lg: 264 }}
                    height={{ xs: 30, md: 45 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontWeight={{ xs: 300 }}
                      fontSize={{ xs: 14, md: 24 }}
                      color={"#ddd"}
                      component={"a"}
                      href={currentProfile.image}
                      sx={{ textDecoration: "none" }}
                      target={"_blank"}
                    >
                      Selfi
                    </Typography>
                  </Box>
                )}
                {currentProfile.cv && (
                  <Box
                    border={"1px solid rgba(3, 235, 255, 1)"}
                    width={{ xs: 150, md: 190, lg: 264 }}
                    height={{ xs: 30, md: 45 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontWeight={{ xs: 300 }}
                      fontSize={{ xs: 14, md: 24 }}
                      color={"#ddd"}
                      component={"a"}
                      href={currentProfile.cv}
                      sx={{ textDecoration: "none" }}
                      target={"_blank"}
                    >
                      CV
                    </Typography>
                  </Box>
                )}
                {currentProfile.web_site && (
                  <Box
                    border={"1px solid rgba(3, 235, 255, 1)"}
                    width={{ xs: 150, md: 190, lg: 264 }}
                    height={{ xs: 30, md: 45 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontWeight={{ xs: 300 }}
                      fontSize={{ xs: 14, md: 24 }}
                      color={"#ddd"}
                      component={"a"}
                      href={currentProfile.web_site}
                      sx={{ textDecoration: "none" }}
                      target={"_blank"}
                    >
                      Lien vers son site
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
