import axios from "axios";
import { base_url_api } from "../../config/keys";

const userContact = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/contacts`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const contactService = { userContact };

export default contactService;
