import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  Dialog,
  FormControlLabel,
  Slide,
  Switch,
  Typography,
  useMediaQuery,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import { EmailInput, TextInput } from "../../../components/inputs";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";

import clientsService from "../../../store/apis/clientsApis";
import PrestationService from "../../../store/apis/PrestationApi";
import moment from "moment";
import { DeleteOutline, DriveFolderUpload } from "@mui/icons-material";
import { generateEmail, generateRandomUsername } from "../../../config/utils";
import { rdv_type_call } from "../../../config/keys";
import CalendarService from "../../../store/apis/CalendarApi";

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const schema = yup
  .object({
    /* email: yup
      .string()
      .email("entrer un email valide")
      .required("email est requis"), */
    //password: yup.string().required(),
    prenom: yup.string().required("prenom est requis"),
    nom: yup.string().required("nom est requis"),
    telephone: yup
      .string()
      .required("telephone est requis")
      .matches(phoneRegExp, "entrez un numéro de téléphone valide"),
    adresse: yup.string().required("adresse est requis"),
    code_postal: yup
      .string()
      .required("code postal est requis")
      .matches(/\d{2}[ ]?\d{3}/, "entrer un code postal valide"),
    ville: yup.string().required("ville est requis"),
    //message: yup.string().required(),
    // nom_societe: yup.string().required(),
    //function_client: yup.string().required(),
    type_batiment: yup.string().required("type de batiment est requis"),
    type_client: yup.string().required("type de client est requis"),
    /* operateur: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }), */
    problemes: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    urgent: yup.string().required(),
  })
  .required();

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} unmountOnExit />;
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 46,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "transparent",
    border: "2px solid rgba(62, 195, 236, 1)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    //margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 16,
    },
    "&:first-of-type": {
      borderRadius: 16,
    },
  },
}));

const obj = [
  { type: "Immeuble", key: "Immeuble" },
  { type: "Maison", key: "Maison" },
  { type: "Local commercial", key: "Local commercial" },
];

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const operatorOptions = [{ value: "string", label: "ORANGE" }];
const problemsOptions = [
  {
    value: "Câblage réseau et déploiement Wifi",
    label: "Câblage réseau et déploiement Wifi",
  },
  { value: "Antenne TV", label: "Antenne TV" },
  { value: "Interphonie", label: "Interphonie" },
  { value: "Électricité courant faible", label: "Électricité courant faible" },
  { value: "Autres", label: "Autres" },
];

const AddCustomerForm = ({ dialog, onClose, setCongrat }) => {
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [globalErrors, setGlobalErrors] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [alignment, setAlignment] = useState("Professionnel");

  useEffect(() => {
    setValue("type_client", alignment, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [alignment, setValue]);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const {
      email,
      prenom,
      nom,
      code_postal,
      telephone,
      adresse,
      ville,
      type_batiment,
      type_client,
      problemes,
      urgent,
    } = data;

    const obj = {
      email,
      roles: ["ROLE_CLIENT"],
      password: "clientfibme",
      prenom,
      nom,
      telephone,
      adresse,
      code_postal: code_postal.toString(),
      ville,
      /* message: "Comment",
      nom_societe: "test",
      function_client: "Ingenieur", */
      type_batiment,
      type_client: type_client.toString(),
      problemes: [`${problemes.value}`],
      urgent: urgent ? 1 : 0,
      client_fibme: true,
    };

    setGlobalErrors(false);

    if (obj.email === "") {
      const username = generateRandomUsername();
      const generatedEmail = generateEmail(username);
      obj.email = generatedEmail;
    }

    clientsService
      .addClientAdmin(obj)
      .then((res) => {
        if (res?.response?.data?.code == 302) {
          setGlobalErrors(res?.response?.data?.message);
          throw new Error(res?.response?.data?.message);
        }
        if (res?.response?.status == 302) {
          setGlobalErrors(res?.response?.data);
          throw new Error(res?.response?.data);
        }
        handlDateRdv(moment(), res, obj);
      })
      .catch((err) => {
        setGlobalErrors(err.message);
        setLoading(false);
      });
  };

  const CreatePrestation = (DTE, client, obj) => {
    // FBMHttp("/demandes", "post", { datePrestation: DTE }).then((response) => {
    const DmdData = {
      datePrestation: DTE,
      client: "api/users/" + client.id,
      urgent: obj.urgent ? true : false,
      nature_probleme: obj.problemes[0],
      type_batiment: obj.type_batiment,
      // type_prestation: data.type_client,
    };
    PrestationService.addPrestationAdmin(DmdData).then((response) => {
      handleFileUpload(response);
    });
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };
  const handleDeleteFile = (index) => {
    const newUploadedFiles = Array.from(selectedFiles).filter(
      (_, i) => i !== index
    );
    setSelectedFiles(newUploadedFiles);
  };

  const handleFileUpload = async (prestation) => {
    const formData = new FormData();
    formData.append("demande", prestation?.id);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files[]", selectedFiles[i]);
    }

    try {
      if (selectedFiles.length > 0) {
        PrestationService.addImages(formData).then((response) => {
          onClose();
          setLoading(false);
          setCongrat(true);
        });
      } else {
        onClose();
        setLoading(false);
        setCongrat(true);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Failed to upload files");
      setLoading(false);
    }
  };

  const handlDateRdv = (DTE, client, prestation) => {
    setLoading(true);

    const obj = {
      user: `/api/users/${client.id}`,
      dateDebut: DTE,
      dateFin: DTE,
      titre: "Rdv",
      type: rdv_type_call,
      status: true,
    };

    CalendarService.addCalendar(obj)
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    CreatePrestation(DTE, client, prestation);

    return;
  };

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { maxWidth: { md: 760, lg: 865 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            ajouter un client
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            variant="caption"
            component={"label"}
            fontSize={{ xs: 16, md: 24 }}
          >
            Problème rencontré par le client :
          </Typography>
          <Box
            display={"flex"}
            alignItems={{ md: "start", lg: "center" }}
            justifyConten={"center"}
            flexWrap={"wrap"}
            //gap={2}
          >
            <Box flexBasis={{ xs: "100%", md: "70%" }} mb={1}>
              <Box>
                <Controller
                  name={"problemes"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      styles={customStyles}
                      placeholder={"---"}
                      options={problemsOptions}
                    />
                  )}
                />
              </Box>
              {errors.problemes && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.problemes.value.message}
                </Typography>
              )}
            </Box>
            <Box
              flexBasis={{ xs: "100%", md: "30%" }}
              display={"flex"}
              justifyContent={{ xs: "end", md: "center" }}
              alignItems={"center"}
            >
              <Typography
                variant="caption"
                component={"label"}
                fontSize={{ xs: 16, md: 24 }}
                mr={2}
              >
                Urgent
              </Typography>
              <Controller
                defaultValue={true}
                name="urgent"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={{ mr: 0 }}
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                  />
                )}
              />
            </Box>
          </Box>
          <Box mb={2}>
            <Typography
              variant="caption"
              component={"label"}
              fontSize={{ xs: 16, md: 24 }}
            >
              Type du client :
            </Typography>
            <Box>
              <StyledToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                sx={{
                  borderRadius: "12px",
                  border: "1px solid rgba(62, 195, 236, 1)",
                }}
              >
                <ToggleButton
                  value="Particulier"
                  sx={{
                    fontFamily: "Bauhaus",
                    fontWeight: "700",
                    fontSize: 18,
                    padding: "10px 32px",
                    borderRadius: 4,
                  }}
                >
                  Particulier{" "}
                </ToggleButton>
                <ToggleButton
                  value="Hôtel & résidence"
                  sx={{
                    fontFamily: "Bauhaus",
                    fontWeight: "700",
                    fontSize: 18,
                    padding: "10px 32px",
                    borderRadius: 4,
                  }}
                >
                  Hôtel & résidence
                </ToggleButton>
                <ToggleButton
                  value="Professionnel"
                  sx={{
                    fontFamily: "Bauhaus",
                    fontWeight: "700",
                    fontSize: 18,
                    padding: "10px 30px",
                    borderRadius: 4,
                  }}
                >
                  Professionnel
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="caption"
              component={"label"}
              fontSize={{ xs: 16, md: 24 }}
            >
              Type du bâtiment :
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              //justifyContent={{ xs: "space-between", lg: "left" }}
              columnGap={"1%"}
              flexWrap={"wrap"}
              mb={4}
            >
              {obj.map((item, i) => {
                if (alignment !== "Professionnel" && i === 2) return null;
                return (
                  <Box
                    key={item.type}
                    onClick={() => {
                      setSelected(i);
                      setValue("type_batiment", item.type, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    sx={{
                      border: `1px solid`,
                      borderColor:
                        selected === i
                          ? "rgba(62, 195, 236, .2)"
                          : "rgba(62, 195, 236, 1)",
                      height: { xs: 76, md: 99 },
                      marginBottom: 0.5,
                      textAlign: "center",
                      textTransform: "uppercase",
                      fontSize: 14,
                      flexBasis: { xs: "48%", md: "32%", lg: "26%" },
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography fontSize={{ xs: 18 }}>{item.type}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            rowGap={2}
            flexWrap={"wrap"}
          >
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"Prénom*"}
                register={{ ...register("prenom") }}
              />
              {errors.prenom && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.prenom.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"Nom*"}
                register={{ ...register("nom") }}
              />
              {errors.nom && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.nom.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%" }}>
              <TextInput
                placeholder={"adresse*"}
                register={{ ...register("adresse") }}
              />
              {errors.adresse && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.adresse.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"code postal*"}
                register={{ ...register("code_postal") }}
              />
              {errors.codePostal && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.codePostal.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"ville*"}
                register={{ ...register("ville") }}
              />
              {errors.ville && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.ville.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <EmailInput register={{ ...register("email") }} />
              {errors.email && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.email.message}
                </Typography>
              )}
              {globalErrors && (
                <Typography
                  fontSize={{
                    xs: 14,
                    margin: "-15px 0 15px",
                    textAlign: "center",
                  }}
                  color="red"
                >
                  {globalErrors}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"numéro de téléphone**"}
                type={"tel"}
                register={{ ...register("telephone") }}
              />
              {errors.telephone && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.telephone.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%" }}>
              <Box
                sx={{
                  padding: "0",
                  width: "100%",
                  borderRadius: "5px",
                  cursor: "pointer",
                  textAlign: "center",
                  marginBottom: "20px",
                  border: "2px solid transparent",
                  backgroundColor: "rgba(62, 195, 236, 0.02)",
                  borderImageSlice: "1",
                  backgroundImage:
                    "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
                  borderImageSource:
                    "linear-gradient(360deg, #3EC3EC -36%, rgba(62, 195, 236, 0) 76%)",
                }}
                htmlFor="file-input-after"
              >
                <input
                  id="file-input-after"
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="file-input-after"
                  className="custom-file-upload"
                  style={{
                    cursor: "pointer",
                    fontSize: "24px",
                  }}
                >
                  {/* <DriveFolderUpload
                    fontSize="large"
                    sx={{
                      cursor: "pointer",
                      marginBottom: "-7px",
                    }}
                  />{" "} */}
                  Ajouter des photos
                </label>
              </Box>
              {selectedFiles &&
                Array.from(selectedFiles).map((file, key) => {
                  return (
                    <div
                      style={{ float: "left", marginRight: "10px" }}
                      key={key}
                      className="image-preview"
                    >
                      <p style={{ margin: 0 }}>
                        <a href={URL.createObjectURL(file)} target="_blank">
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            maxWidth={"80px"}
                            height={"80px"}
                            style={{
                              verticalAlign: "middle",
                              border: "1px solid #F2E208",
                            }}
                          />
                        </a>
                        <br />
                        <Button
                          startIcon={<DeleteOutline />}
                          variant={"text"}
                          sx={{
                            fontWeight: { xs: 700 },
                            fontSize: { xs: 16 },
                            fontFamily: { xs: "Bauhaus" },
                            textTransform: "capitalize",
                          }}
                          color="inherit"
                          onClick={() => handleDeleteFile(key)}
                        >
                          Supprimer
                        </Button>
                      </p>
                    </div>
                  );
                })}
            </Box>
          </Box>
        </form>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton type={"red"} handleClick={onClose}>
                retour
              </CustomButton>
              <CustomButton
                type={"yellow"}
                textColor={"white"}
                handleClick={handleSubmit(onSubmit)}
              >
                envoyer
              </CustomButton>
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default AddCustomerForm;
