import React, { useEffect } from "react";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import logo from "../../assets/images/logos/logo.svg";

import { FacebookRounded, Instagram } from "@mui/icons-material/";
import BlackButton from "../buttons/BlackButton";
import { userLogout } from "../../store/slices/userAuthReducer";
import { useDispatch, useSelector } from "react-redux";
import * as Linking from "expo-linking";
import theme from "../../assets/theme";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ContactButton } from "../buttons";

const Bottom = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItem: "center",
  justifyContent: "center",
  padding: theme.spacing(1, 0),
  borderTop: "1px dashed #002C3A",
}));

/*const data_1 = [
  // "Mentions légales",
  // "Conditions d’utilisation",
  // "Politiques des cookies",
  {
    item: "Mentions légales",
  },
  {
    item: "Conditions d'utilisation",

  },
  {
    item: "Politiques des cookies",

  },
];*/

export default function Footer({ handleContact }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userAuth);
  const route = useRoute();
  const navigation = useNavigation();
  const data_2_1 = [
    {
      item: "Obtenir l’aide",
      handleClick: () => {
        return;
      },
    }
  ];
  const data_2 = [
    {
      item: "Obtenir l’aide",
      handleClick: () => {
        return;
      },
    },
    {
      item: "Devenir partenaire",
      handleClick: () => {
        navigation.navigate("RegisterPartner");
      },
    },
    /* {
    item: "Déconnexion",
    handleClick: (callback) => {
      callback();
    },
  }, */
  ];

  const [date, setDate] = React.useState(null);
  useEffect(() => {
    let currentYear = new Date();
    let date = currentYear.getFullYear();
    setDate(date);
  }, []);

  const handleLogo = () => {
    if (route.name !== "Home") {
      navigation.navigate("Home");
    }
  };

  const data_1 = [
    {
      item: "Mentions légales",
      handleClick: () => {
        navigation.navigate("Mentions légales");
      },
    },
    {
      item: "Conditions d'utilisation",
      handleClick: () => {
        navigation.navigate("CGU");
      },
    },
    {
      item: "Politiques de confidentialités",
      handleClick: () => {
        navigation.navigate("Politiques de confidentialités");
      },
    },
  ];

  return (
    <Box component={"div"}>
      <Container maxWidth={"xl"}>
        <Box
          paddingTop={4}
          paddingBottom={4}
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          flexWrap={"wrap"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box
            display={"flex"}
            flexDirection={{ md: "column" }}
            justifyContent={{ xs: "center" }}
            // mb={4}
            order={{ md: 5 }}
          >
            <Box
              margin={{ xs: "0 16px 0  0", md: "0 0 16px 0" }}
              onClick={() =>
                Linking.openURL("https://www.facebook.com/fibmeservices/")
              }
              sx={{
                cursor: "pointer",
                "&:hover": {
                  ".facebookIcon": {
                    color: theme.palette.secondary.socialIcon,
                  },
                },
              }}
            >
              <FacebookRounded
                /*fontSize="large"*/ sx={{ fontSize: 40 }}
                className="facebookIcon"
              />
            </Box>
            <Box
              onClick={() =>
                Linking.openURL("https://www.instagram.com/fibmeservices/")
              }
              sx={{
                cursor: "pointer",
                "&:hover": {
                  ".instagramIcon": {
                    color: theme.palette.secondary.socialIcon,
                  },
                },
              }}
            >
              <Instagram
                /*fontSize="large"*/ sx={{ fontSize: 40 }}
                className="instagramIcon"
              />
            </Box>
          </Box>

          {!user && <Box
            display={"flex"}
            justifyContent={{ xs: "center", md: "flex-end" }}
            flexBasis={{ md: "100%", lg: "auto" }}
            order={{ md: 1, lg: 5 }}
            mt={{ xs: 4, md: 0 }}
            marginBottom={{ md: 4, lg: 0 }}
          >
            <ContactButton handleClick={handleContact} shadow>
              Contact
            </ContactButton>
          </Box>}

          <Box
            display={"flex"}
            justifyContent={{ xs: "center" }}
            order={{ md: 2 }}
            mt={{ xs: 4, md: 0 }}
            marginBottom={{ xs: 4, md: 0 }}
          >
            <Box onClick={handleLogo} sx={{ cursor: "pointer" }}>
              <img src={logo} alt="logo" width={210} height={64} />
            </Box>
          </Box>
          {/* 
        <Box
          display={"flex"}
          justifyContent={{ xs: "center" }}
          flexDirection={{ xs: "column", md: "row" }}
          flex={1}
          order={{ md: 3 }}
          mt={4}
        > */}
          <Box order={{ md: 3 }}>
            <List dense={true} disablePadding>
              {data_1.map((x) => (
                <ListItem
                  sx={{ textAlign: { xs: "center", md: "left" } }}
                  key={x.item}
                  component={"a"}
                  onClick={() => x.handleClick(() => dispatch(userLogout()))}
                >
                  <ListItemText
                    primary={x.item}
                    primaryTypographyProps={{ fontSize: 18, fontWeight: 300 }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box order={{ md: 4 }}>
            <List dense={true} disablePadding>
              {!user && data_2.map((x, i) => (
                <ListItem
                  sx={{ textAlign: { xs: "center", md: "left" } }}
                  key={x.item}
                  component={"a"}
                  onClick={() => x.handleClick(() => dispatch(userLogout()))}
                >
                  <ListItemText
                    primary={x.item}
                    primaryTypographyProps={{ fontSize: 18, fontWeight: 300 }}
                    sx={{ cursor: "pointer" }}
                  />
                </ListItem>
              ))}
              {user && data_2_1.map((x, i) => (
                <ListItem
                  sx={{ textAlign: { xs: "center", md: "left" } }}
                  key={x.item}
                  component={"a"}
                  onClick={() => x.handleClick(() => dispatch(userLogout()))}
                >
                  <ListItemText
                    primary={x.item}
                    primaryTypographyProps={{ fontSize: 18, fontWeight: 300 }}
                    sx={{ cursor: "pointer" }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          {/* </Box> */}
        </Box>
      </Container>

      <Bottom>
        <Typography
          fontFamily={"Bauhaus"}
          color={"#002C3A"}
          fontSize={{ xs: 16, lg: 16 }}
        >
          &copy; HCUBE - {date}
        </Typography>
      </Bottom>
    </Box>
  );
}
