import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Alert, Box, CircularProgress } from "@mui/material";
import { STRIPE_URL } from "../../../../config/keys";

export default function CheckoutForm({
  paymentIntentId,
  userId,
  offreId,
  offrePrice,
  onSuccess,
  onClose,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [messageWarning, setMessageWarning] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let user_id = userId; //id utilisateur
  let offre_id = offreId; //id offre
  let price = offrePrice; // prix total a paye

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Url de la page de fin de paiement
        return_url: window.location.href,
      },
      redirect: "if_required",
    });

    if (!paymentIntent) {
      setIsLoading(false);
      return;
    }

    switch (paymentIntent.status) {
      case "succeeded":
        // Publiez les informations de transaction sur le script côté serveur et redirigez vers la page d'état du paiement
        fetch(`${STRIPE_URL}` + "payment_insert", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            request_type: "payment_insert",
            payment_intent: paymentIntent,
            id_offre: offre_id,
            id_client: user_id,
            prix: price,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            data = JSON.parse(data);
            if (data.payment_id) {
              setIsLoading(false);
              setMessageWarning();
              setMessageError();
              setMessage("Paiement réussi!");
              setTimeout(() => {
                onSuccess();
              }, 1500);
            } else {
              setMessage(data.error);
            }
          })
          .catch(console.error);
        break;
      case "processing":
        setMessage();
        setMessageError();
        setMessageWarning("Votre paiement est en cours de traitement.");
        setIsLoading(false);
        break;
      case "requires_payment_method":
        setMessageWarning();
        setMessage();
        setMessageError("Votre paiement n'a pas abouti, veuillez réessayer.");
        setIsLoading(false);
        break;
      default:
        setMessageWarning();
        setMessage();
        setMessageError("Quelque chose s'est mal passé.");
        setIsLoading(false);
        break;
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {isLoading ? (
        <Box textAlign={"center"} marginTop={"30px"}>
          <CircularProgress />
        </Box>
      ) : (
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 55,
            width: "180px",
            borderRadius: 1,
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "20px",
            cursor: "pointer",
            opacity: "none",
            margin: "14px auto",

            cursor: "pointer",
            border: "1px solid",
            //backgroundImage:
            // "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
            backgroundColor: "rgba(14, 69, 74, 0.05)",
            borderImageSlice: 1,
            borderImageSource:
              "linear-gradient(180deg, #F4EB7C 0%, rgba(244, 235, 124, 0) 100%)",
            clipPath:
              "polygon(10% 0, 80% 0%, 100% 0, 100% 70%, 90% 100%, 20% 100%, 0 100%, 0 30%)",
          }}
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">Payer</span>
        </button>
      )}
      {/* Afficher les messages d'erreur ou de réussite */}
      {message && (
        <Alert
          severity="success"
          style={{ fontSize: "18px", lineHeight: 1, marginBottom: "20px" }}
        >
          {message}
        </Alert>
      )}
      {messageWarning && (
        <Alert
          severity="warning"
          style={{ fontSize: "18px", lineHeight: 1, marginBottom: "20px" }}
        >
          {messageWarning}
        </Alert>
      )}
      {messageError && (
        <Alert
          severity="error"
          style={{ fontSize: "18px", lineHeight: 1, marginBottom: "20px" }}
        >
          {messageError}
        </Alert>
      )}
    </form>
  );
}
