
import { Container as C } from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(C)(({ theme }) => ({
  maxWidth: 1410,
  margin: "auto",
  width: "calc(100% - 30px)"
}));

export default Container;
