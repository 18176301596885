import { styled } from "@mui/system";

const Title = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 24,
  lineHeight: "34px",
  textTransform: "uppercase",
  color: "#FFFFFF",
  marginRight: 15,
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    fontSize: 17,
  },
}));
export default Title;
