import { useTheme } from "@emotion/react";
import {
  AddOutlined,
  CheckCircle,
  DownloadForOffline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  FormControlLabel,
  FormGroup,
  Input,
  Link,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";

import Select from "react-select";
import { EmailInput, LinkInput, TextInput } from "../../../components/inputs";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import partnersService from "../../../store/apis/partnersApis";
import { list_dispos, list_metiers } from "../../../config/keys";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Row = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  maxWidth: 1010,
  margin: "auto",
  flexWrap: "wrap",
};

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const schema = yup
  .object({
    email: yup
      .string()
      .email("entrer un email valide")
      .required("email est requis"),
    //password: yup.string().required(),
    prenom: yup.string().required("prenom est requis"),
    nom: yup.string().required("nom est requis"),
    telephone: yup
      .string()
      .required("telephone est requis")
      .matches(phoneRegExp, "entrez un numéro de téléphone valide"),
    adresse: yup.string().required("adresse est requis"),
    code_postal: yup
      .string()
      .required("code postal est requis")
      .matches(/\d{2}[ ]?\d{3}/, "entrer un code postal valide"),
    ville: yup.string().required("ville est requis"),
    /*  niveau_experience: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter a problem"),
    startMon: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter your experience start month"),
    startYear: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter your experience start end"),
    endMon: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter your experience end month"),
    endYear: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("enter your experience start month"),

    cv: yup.mixed().required("Cv est requis"),
    link: yup.string().required("lien est requis"),
    type_client: yup.string().required("function est requis"), */
    //urgent: yup.string().required(),
  })
  .required();

const EditPartnerForm = ({
  dialog,
  onClose,
  editedProfil,
  setCurrentProfile,
  setEditCongrat,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    setValue("prenom", editedProfil.prenom);
    setValue("nom", editedProfil.nom);
    setValue("email", editedProfil.email);
    setValue("telephone", editedProfil.telephone);
    setValue("ville", editedProfil.ville);
    setValue("code_postal", editedProfil.code_postal);
    setValue("adresse", editedProfil.adresse);
    setValue("link", editedProfil.web_site);
    setCheckedDispo(editedProfil.disponibilites);
    setCheckedMetiers(editedProfil.metiers);
  }, [editedProfil]);

  const onChangeStatus = (status) => {
    
    setLoading(true);
    const obj = {
      status: status,
    };

    partnersService
      .editPartner(editedProfil.id, obj)
      .then((res) => {
        if (res?.response?.status === 302) {
          setLoading(false);
          setGlobalErrors(res.response.data);
        } else {
          setCurrentProfile(res);
          setLoading(false);
          setEditCongrat(true);
          onClose();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onSubmit = (data) => {
    setErrorMetiers(false);
    setErrorDispo(false);
    setErrorFiles(false);
    if (checkedMetiers.length === 0) setErrorMetiers(true);
    if (checkedDispo.length === 0) setErrorDispo(true);
    /* if (
      !selectedFileKbis ||
      !selectedFileAtAs ||
      !selectedFileCni ||
      !selectedFileIban ||
      !selectedFileSelfi
    )
      setErrorFiles(true); */

    if (checkedMetiers.length === 0 || checkedDispo.length === 0) return;

    setLoading(true);
    const {
      adresse,
      code_postal,
      email,
      link,
      nom,
      prenom,
      telephone,
      ville,
      /*  cv,
      niveau_experience,
      endMon,
      endYear,
      startMon,
      startYear,
      type_client, */
    } = data;

    const obj = {
      email,
      /* roles: ["ROLE_PRESTATAIRE"],
      password: "teststs", */
      prenom,
      nom,
      telephone,
      adresse,
      code_postal: code_postal.toString(),
      ville,
      web_site: link,
      metiers: checkedMetiers,
      disponibilites: checkedDispo,
    };

    partnersService
      .editPartner(editedProfil.id, obj)
      .then((res) => {
        if (res?.response?.status === 302) {
          setLoading(false);
          setGlobalErrors(res.response.data);
        } else {
          setCurrentProfile(res);
          setLoading(false);
          setEditCongrat(true);
          onClose();
        }
        /* const { id } = res;
        const formData = new FormData();
        formData.append("file", cv[0]);

        partnersService.addCvPartner(id, formData).then((res) => {
          onClose();
          setLoading(false);
          setCongrat(true);
        }); */
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [checkedMetiers, setCheckedMetiers] = useState([]);

  const handleChange = (event, metier) => {
    if (event.target.checked) {
      setCheckedMetiers([...checkedMetiers, metier]);
    } else {
      const newMetiers = checkedMetiers.filter((item) => item !== metier);
      setCheckedMetiers(newMetiers);
    }
  };

  const [checkedDispo, setCheckedDispo] = useState([]);

  const handleChangeDispo = (event, dispo) => {
    if (event.target.checked) {
      setCheckedDispo([...checkedDispo, dispo]);
    } else {
      const newMetiers = checkedDispo.filter((item) => item !== dispo);
      setCheckedDispo(newMetiers);
    }
  };

  const [selectedFileKbis, setSelectedFileKbis] = useState();
  const [selectedFileCni, setSelectedFileCni] = useState();
  const [selectedFileAtAs, setSelectedFileAtAs] = useState();
  const [selectedFileIban, setSelectedFileIban] = useState();
  const [selectedFileSelfi, setSelectedFileSelfi] = useState();
  const [selectedFileCv, setSelectedFileCv] = useState();
  const [errorFiles, setErrorFiles] = useState(false);
  const [errorMetiers, setErrorMetiers] = useState(false);
  const [errorDispo, setErrorDispo] = useState(false);

  const changeHandler = (event, type) => {
    switch (type) {
      case "kbis":
        setSelectedFileKbis(event.target.files[0]);
        break;
      case "cni":
        setSelectedFileCni(event.target.files[0]);
        break;
      case "AtAs":
        setSelectedFileAtAs(event.target.files[0]);
        break;
      case "iban":
        setSelectedFileIban(event.target.files[0]);
        break;
      case "selfi":
        setSelectedFileSelfi(event.target.files[0]);
        break;
      case "cv":
        setSelectedFileCv(event.target.files[0]);
        break;
    }
    /* setSelectedFile(event.target.files, ...selectedFile]);
        setValue("files", [event.target.files, ...selectedFile]) */
    // setIsSelected(true);
  };

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { maxWidth: { md: 760, lg: 865 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Modifier le partenaire
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            rowGap={2}
            flexWrap={"wrap"}
          >
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"Prénom*"}
                register={{ ...register("prenom") }}
              />
              {errors.prenom && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.prenom.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"Nom**"}
                register={{ ...register("nom") }}
              />
              {errors.nom && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.nom.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%" }}>
              <TextInput
                placeholder={"adresse*"}
                register={{ ...register("adresse") }}
              />
              {errors.adresse && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.adresse.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"code postal*"}
                register={{ ...register("code_postal") }}
              />
              {errors.code_postal && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.code_postal.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"ville*"}
                register={{ ...register("ville") }}
              />
              {errors.ville && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.ville.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <EmailInput register={{ ...register("email") }} />
              {errors.email && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.email.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <TextInput
                placeholder={"numéro de téléphone**"}
                type={"tel"}
                register={{ ...register("telephone") }}
              />
              {errors.telephone && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.telephone.message}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItem={"center"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          >
            <Box flexBasis={{ xs: "100%", md: "100%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16, md: 24 }}
                >
                  Activités
                </Typography>
                <Box>
                  <FormGroup aria-label="position" row>
                    {list_metiers.map((metier) => {
                      return (
                        <FormControlLabel
                          labelPlacement="end"
                          control={
                            <Checkbox
                              checked={checkedMetiers.includes(metier)}
                              onChange={(event) => handleChange(event, metier)}
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            />
                          }
                          label={
                            <Typography variant="caption">{metier}</Typography>
                          }
                        />
                      );
                    })}
                  </FormGroup>
                  {errorMetiers && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      Veuillez sélectionner un ou plusieurs métiers
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16, md: 24 }}
                >
                  Disponibilités
                </Typography>
                <Box>
                  <FormGroup aria-label="position" row>
                    {list_dispos.map((dispo) => {
                      return (
                        <FormControlLabel
                          labelPlacement="end"
                          control={
                            <Checkbox
                              checked={checkedDispo.includes(dispo.id)}
                              onChange={(event) =>
                                handleChangeDispo(event, dispo.id)
                              }
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            />
                          }
                          label={
                            <Typography variant="caption">
                              {dispo.value}
                            </Typography>
                          }
                        />
                      );
                    })}
                  </FormGroup>
                  {errorDispo && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      Veuillez choisir vos disponibilités
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box flexBasis={{ xs: "100%", md: "100%" }}>
              <Typography
                variant="caption"
                component={"label"}
                fontSize={{ xs: 16, md: 24 }}
              >
                Documents officiels
              </Typography>
              <Box sx={Row}>
                <Box
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  flexBasis={{ xs: "100%", md: "50%" }}
                >
                  <Typography variant="caption">
                    Kbis de moins de 3 mois{" "}
                    {editedProfil?.kbis && (
                      <Link href={editedProfil?.kbis} target="_blank">
                        voir
                      </Link>
                    )}
                  </Typography>

                  {/* <input
                    id={"upload-kbis"}
                    {...register("kbis")}
                    type={"file"}
                    onChange={(event) => changeHandler(event, "kbis")}
                    hidden
                    accept={
                      "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                  />

                  <Box component={"label"} htmlFor="upload-kbis">
                    <Box
                      sx={{
                        border: "1px solid rgba(62, 195, 236, 1)",
                        height: 45,
                        padding: 1,
                        marginBottom: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        fontSize: 14,
                        cursor: "pointer",
                      }}
                    >
                      {!selectedFileKbis ? (
                        <>
                          <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                          cliquez ici pour télécharger
                        </>
                      ) : (
                        <>
                          <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                          {selectedFileKbis?.name}
                        </>
                      )}
                    </Box>
                  </Box> */}

                  {errorFiles && !selectedFileKbis && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      Ce document est obligatoire
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  flexBasis={{ xs: "100%", md: "50%" }}
                >
                  <Typography variant="caption">
                    CNI recto / verso{" "}
                    {editedProfil?.cni && (
                      <Link href={editedProfil?.cni} target="_blank">
                        voir
                      </Link>
                    )}
                  </Typography>
                  {/* <input
                    id={"upload-cni"}
                    {...register("cni")}
                    type={"file"}
                    onChange={(event) => changeHandler(event, "cni")}
                    hidden
                    accept={
                      "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                  />

                  <Box component={"label"} htmlFor="upload-cni">
                    <Box
                      sx={{
                        border: "1px solid rgba(62, 195, 236, 1)",
                        height: 45,
                        padding: 1,
                        marginBottom: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        fontSize: 14,
                        cursor: "pointer",
                      }}
                    >
                      {!selectedFileCni ? (
                        <>
                          <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                          cliquez ici pour télécharger
                        </>
                      ) : (
                        <>
                          <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                          {selectedFileCni?.name}
                        </>
                      )}
                    </Box>
                  </Box> */}
                  {errorFiles && !selectedFileCni && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      Ce document est obligatoire
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  flexBasis={{ xs: "100%", md: "50%" }}
                >
                  <Typography variant="caption">
                    Attestation d'assurance{" "}
                    {editedProfil?.attestation && (
                      <Link href={editedProfil?.attestation} target="_blank">
                        voir
                      </Link>
                    )}
                  </Typography>
                  {/* <input
                    id={"upload-attestationAssurance"}
                    {...register("attestationAssurance")}
                    type={"file"}
                    onChange={(event) => changeHandler(event, "AtAs")}
                    hidden
                    accept={
                      "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                  />

                  <Box
                    component={"label"}
                    htmlFor="upload-attestationAssurance"
                  >
                    <Box
                      sx={{
                        border: "1px solid rgba(62, 195, 236, 1)",
                        height: 45,
                        padding: 1,
                        marginBottom: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        fontSize: 14,
                        cursor: "pointer",
                      }}
                    >
                      {!selectedFileAtAs ? (
                        <>
                          <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                          cliquez ici pour télécharger
                        </>
                      ) : (
                        <>
                          <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                          {selectedFileAtAs?.name}
                        </>
                      )}
                    </Box>
                  </Box> */}
                  {errorFiles && !selectedFileAtAs && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      Ce document est obligatoire
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  flexBasis={{ xs: "100%", md: "50%" }}
                >
                  <Typography variant="caption">
                    IBAN{" "}
                    {editedProfil?.iban && (
                      <Link href={editedProfil?.iban} target="_blank">
                        voir
                      </Link>
                    )}
                  </Typography>
                  {/* <input
                    id={"upload-iban"}
                    {...register("iban")}
                    type={"file"}
                    onChange={(event) => changeHandler(event, "iban")}
                    hidden
                    accept={
                      "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                  />

                  <Box component={"label"} htmlFor="upload-iban">
                    <Box
                      sx={{
                        border: "1px solid rgba(62, 195, 236, 1)",
                        height: 45,
                        padding: 1,
                        marginBottom: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        fontSize: 14,
                        cursor: "pointer",
                      }}
                    >
                      {!selectedFileIban ? (
                        <>
                          <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                          cliquez ici pour télécharger
                        </>
                      ) : (
                        <>
                          <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                          {selectedFileIban?.name}
                        </>
                      )}
                    </Box>
                  </Box> */}
                  {errorFiles && !selectedFileIban && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      Ce document est obligatoire
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  flexBasis={{ xs: "100%", md: "50%" }}
                >
                  <Typography variant="caption">
                    Selfi{" "}
                    {editedProfil?.image && (
                      <Link href={editedProfil?.image} target="_blank">
                        voir
                      </Link>
                    )}
                  </Typography>
                  {/* <input
                    id={"upload-selfi"}
                    {...register("selfi")}
                    type={"file"}
                    onChange={(event) => changeHandler(event, "selfi")}
                    hidden
                    accept={
                      "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                  />

                  <Box component={"label"} htmlFor="upload-selfi">
                    <Box
                      sx={{
                        border: "1px solid rgba(62, 195, 236, 1)",
                        height: 45,
                        padding: 1,
                        marginBottom: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        fontSize: 14,
                        cursor: "pointer",
                      }}
                    >
                      {!selectedFileSelfi ? (
                        <>
                          <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                          cliquez ici pour télécharger
                        </>
                      ) : (
                        <>
                          <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                          {selectedFileSelfi?.name}
                        </>
                      )}
                    </Box>
                  </Box> */}
                  {errorFiles && !selectedFileSelfi && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      Ce document est obligatoire
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  flexBasis={{ xs: "100%", md: "50%" }}
                >
                  <Typography variant="caption">
                    CV{" "}
                    {editedProfil?.cv && (
                      <Link href={editedProfil?.cv} target="_blank">
                        voir
                      </Link>
                    )}
                  </Typography>
                  {/* <input
                    id={"upload-cv"}
                    {...register("cv")}
                    type={"file"}
                    onChange={(event) => changeHandler(event, "cv")}
                    hidden
                    accept={
                      "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                  />

                  <Box component={"label"} htmlFor="upload-cv">
                    <Box
                      sx={{
                        border: "1px solid rgba(62, 195, 236, 1)",
                        height: 45,
                        padding: 1,
                        marginBottom: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        fontSize: 14,
                        cursor: "pointer",
                      }}
                    >
                      {!selectedFileCv ? (
                        <>
                          <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                          cliquez ici pour télécharger
                        </>
                      ) : (
                        <>
                          <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                          {selectedFileCv?.name}
                        </>
                      )}
                    </Box>
                  </Box> */}

                  {errors.cv && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.cv.message}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  flexBasis={{ xs: "100%", md: "100%" }}
                >
                  <Typography variant={"caption"} component={"label"}>
                    Lien vers le site (facultatif)
                  </Typography>
                  <LinkInput
                    sx={{ color: "rgba(0, 44, 58, 1)" }}
                    placeholder={"Saisissez le lien ici"}
                    register={{ ...register("link") }}
                    type={"url"}
                  />
                  {errors.link && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.link.message}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </form>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton type={"red"} handleClick={onClose}>
                annuler
              </CustomButton>

              <CustomButton
                type={editedProfil?.status === 1 ? "red" : "green"}
                handleClick={() =>
                  onChangeStatus(editedProfil?.status === 1 ? 3 : 1)
                }
              >
                {editedProfil?.status === 1 ? "Désactiver" : "Activer"}
              </CustomButton>

              {editedProfil?.status === 0 && <CustomButton
                type={"yellow"}
                textColor={"white"}
                handleClick={() =>
                  onChangeStatus(2)
                }
              >
                Demander Documents
              </CustomButton>}

              {editedProfil?.status !== 0 && <CustomButton
                type={"yellow"}
                textColor={"white"}
                handleClick={handleSubmit(onSubmit)}
              >
                modifier
              </CustomButton>}
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default EditPartnerForm;
