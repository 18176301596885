import axios from "axios";
import { base_url_api } from "../../config/keys";

const resetPassword = async (email) => {
  try {
    const response = await axios.get(`${base_url_api}/resetemail?email=${email.toString()}`);

    return response.data;
  } catch (error) {
    return error;
  }
};

const resetPasswordService = {
  resetPassword,
};

export default resetPasswordService;
