import { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { styled, ThemeProvider } from "@mui/system";
import blackbtn from "../../assets/images/blackbtn.jpg";
import blackbtnleft from "../../assets/images/blackbtn-left.png";
import blackbtnright from "../../assets/images/blackbtn-right.png";
import bluebtn from "../../assets/images/bluebtn.png";
import bluebtnleft from "../../assets/images/bluebtn-left.png";
import bluebtnright from "../../assets/images/bluebtn-right.png";
import theme from "../../assets/theme";

const TabsPanel = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const HeaderTabs = styled("div")(({ theme, type }) => ({
  maxWidth: 300,
  paddingRight: 40,
  position: "relative",

  ul: {
    textDecoration: "none",
    padding: 0,
    margin: 0,
    listStyle: "none",
    position: "relative",

    ".header-item": {
      fontFamily: theme.typography.fontFamily,
      fontStyle: "normal",
      fontWeight: 390,
      fontSize: 18,
      lineHeight: "26px",
      textTransform: "uppercase",
      color: type && type == "style2" ? "#fff" : theme.palette.text.main,
      display: "flex",
      height: 63,
      marginBottom: 7,
      alignItems: "center",
      padding: "0 30px",
      position: "relative",
      zIndex: 1,
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",

      "&.active":
        type && type == "style2"
          ? {
              color: theme.palette.primary.main,
            }
          : {
              background:
                "linear-gradient(101.22deg, #ecdd3b 6.23%, #3ec3ec 90.48%)",
              backgroundClip: "text",
              textFillColor: "transparent",
              fontWeight: "bold",
            },
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "none",
    marginBottom: 40,
    paddingRight: 0,

    ul: {
      display: "flex",
      justifyContent: "center",
      maxWidth: 850,
      marginLeft: "auto",
      marginRight: "auto",

      "li.header-item": {
        flex: 1,
        textAlign: "center",
        justifyContent: "center",
        padding: "0 10px",
        fontSize: 14,
        lineHeight: "18px",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    paddingRight: 0,

    ul: {
      flexDirection: "column",
      justifyContent: "flex-start",
      maxWidth: "100%",

      "&:after": {
        content: "''",
        position: "absolute",
        right: 25,
        width: 11,
        height: 11,
        borderBottom: "2px solid #fff",
        borderRight: "2px solid #fff",
        top: 22,
        transform: "rotate(45deg)",
        transition: "all .3s ease-in-out",
      },
      "&.open": {
        "&:after": {
          transform: "rotate(-135deg)",
        },
        li: {
          display: "flex",
        },
      },
      "li, .header-item": {
        minHeight: 63,
        textAlign: "left",
        justifyContent: "flex-start",
        order: 2,
        transition: "all .3 ease-in-out",
        border: "1px solid #000",
        display: "none",

        "&.active": {
          order: 1,
          paddingRight: 50,
          transition: "all 0s",
          border: "none",
          display: "flex",
        },
      },
    },
  },
}));

const HeaderItem = styled("li")(({ theme }) => ({}));

const BodyTabs = styled("div")(({ theme }) => ({
  flex: 1,
  maxWidth: 855,
}));

const BodyTab = styled("div")(({ theme }) => ({
  display: "none",
  "&.active": {
    display: "block",
  },
}));

const ActiveItem = styled("span")(({ theme, type }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: 63,
  backgroundImage:
    type && type == "style2"
      ? `url(${bluebtn}), url(${bluebtnleft}), url(${bluebtnright})`
      : `url(${blackbtn}), url(${blackbtnleft}), url(${blackbtnright})`,
  backgroundSize:
    type && type == "style2"
      ? "calc(100% - 79px) 100%, auto 100%, auto 100%"
      : "calc(100% - 35px) 100%, auto 100%, auto 100%",
  backgroundPosition: "center, left center, right center",
  backgroundRepeat: "no-repeat",
  zIndex: 0,
  transition: "all 0.3s ease-in-out",
}));

const TabPanel = (props) => {
  const [activeTab, setActiveTab] = useState(
    props.activeTab ? props.activeTab : 0
  );
  const [activeTabWidth, setActiveTabWidth] = useState("100%");
  const [activeStyles, setActiveStyles] = useState({
    left: 0,
    width: "100%",
    top: 0,
  });
  const tabsStyle = props.type ? props.type : "";

  // const TabPanel = (props) => {
  //   const [activeTab, setActiveTab] = useState(
  //     props.activeTab ? props.activeTab : 0
  //   );
  //   const [activeTabWidth, setActiveTabWidth] = useState("100%");
  //   const [activeStyles, setActiveStyles] = useState({
  //     left: 0,
  //     width: "100%",
  //     top: 0,
  //   });
  //   const tabsStyle = props.type ? props.type : "";

  // {top: (activeTab * 70), width: activeTabWidth}

  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: true,
  });
  const matchesTablette = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  useEffect(() => {
    handleResponsive();
  }, [activeTab]);

  const handleResponsive = () => {
    if (matchesMobile) {
      setActiveStyles({ left: 0, width: "100%", top: 0 });
    } else if (matchesTablette) {
      setActiveStyles({
        left: activeTab * (100 / props.items.length) + "%",
        width: 100 / props.items.length + "%",
        top: 0,
      });
    } else if (matchesDesktop) {
      setActiveStyles({ top: activeTab * 70, width: activeTabWidth });
    }
  };

  //   return (
  //     <ThemeProvider theme={theme}>
  //       <TabsPanel className={"tabs-panel"}>
  //         <HeaderTabs type={tabsStyle} className="header-tabs">
  //           <Box
  //             component="ul"
  //             currentTab={activeTab}
  //             onClick={(e) => {
  //               console.log(e.currentTarget.classList.toggle("open"));
  //             }}
  //           >
  //             {props.items.map((item, ind) => {
  //               return (
  //                 <HeaderItem
  //                   onClick={() => setActiveTab(ind)}
  //                   key={ind}
  //                   className={
  //                     "header-item" + (activeTab == ind ? " active " : "")
  //                   }
  //                 >
  //                   {item.label}
  //                 </HeaderItem>
  //               );
  //             })}
  //             <ActiveItem
  //               type={tabsStyle}
  //               className="active-span"
  //               style={activeStyles}
  //             />
  //           </Box>
  //         </HeaderTabs>

  //         <BodyTabs className="body-tabs">
  //           {props.items.map((item, ind) => {
  //             return activeTab == ind ? (
  //               <BodyTab key={ind} className={"tab active"}>
  //                 {item.content}
  //               </BodyTab>
  //             ) : (
  //               ""
  //             );
  //           })}
  //         </BodyTabs>
  //       </TabsPanel>
  //     </ThemeProvider>
  //   );
  // };

  return (
    <ThemeProvider theme={theme}>
      <TabsPanel className={"tabs-panel"}>
        <HeaderTabs type={tabsStyle} className="header-tabs">
          <Box
            component="ul"
            currentTab={activeTab}
            /* onClick={(e) => {
              e.currentTarget.classList.toggle("open");
            }} */
          >
            {props.items.map((item, ind) => {
              return (
                <HeaderItem
                  onClick={() => setActiveTab(ind)}
                  key={ind}
                  className={
                    "header-item" + (activeTab == ind ? " active " : "")
                  }
                >
                  {item.label}
                </HeaderItem>
              );
            })}
            <ActiveItem
              type={tabsStyle}
              className="active-span"
              style={activeStyles}
            />
          </Box>
        </HeaderTabs>
        {/* 
        <BodyTabs className="body-tabs">
          {props.items.map((item, ind) => {
            return (
              <BodyTab
                key={ind}
                className={"tab" + (activeTab == ind ? " active " : "")}
              >
                {item.content}
              </BodyTab>
            );
          })}
        </BodyTabs> */}

        <BodyTabs className="body-tabs">
          {props.items.map((item, ind) => {
            return activeTab == ind ? (
              <BodyTab key={ind} className={"tab active"}>
                {item.content}
              </BodyTab>
            ) : (
              ""
            );
          })}
        </BodyTabs>
      </TabsPanel>
    </ThemeProvider>
  );
};

export default TabPanel;
