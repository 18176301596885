import { Box, Button, Typography } from "@mui/material";
import { styled, ThemeProvider } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import ButtonInput from "../../../components/buttons/ButtonInput";
import { TextAreaInput, TextInput } from "../../../components/inputs";
import theme from "../../../assets/theme";
import { CustomCalendar } from "../../../components";
import CheckCir from "../../../assets/images/check-circle.png";
import { Img } from "../../../components/Window";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import "moment/locale/fr";
import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import clientsService from "../../../store/apis/clientsApis";
import PrestationService from "../../../store/apis/PrestationApi";
import CalendarService from "../../../store/apis/CalendarApi";
import { useNavigation } from "@react-navigation/native";
import { rdv_type_call } from "../../../config/keys";
import { Add, DeleteOutline, DriveFolderUpload } from "@mui/icons-material";
moment.locale("fr");

const Question = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyBauhaus,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "30px",
  color: "#002C3A",
  textAlign: "center",
  marginBottom: "45px",
}));

const Row = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  maxWidth: 1010,
  margin: "auto",
  flexWrap: "wrap",
};
const col4 = {
  width: { xs: "calc(100% - 30px)", md: "calc(33.33% - 15px)" },
  margin: "15px auto",
};

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const passwordRegExp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
const schema = yup.object({
  companyname: yup.string("Ce champ doit être une chaîne de caractères."),
  job: yup.string("Ce champ doit être une chaîne de caractères."),
  firstname: yup
    .string("Ce champ doit être une chaîne de caractères.")
    .required("Ce champ est obligatoire."),
  lastname: yup
    .string("Ce champ doit être une chaîne de caractères.")
    .required("Ce champ est obligatoire."),
  address: yup
    .string("Ce champ doit être une chaîne de caractères.")
    .required("Ce champ est obligatoire."),
  zipcode: yup
    .string("Ce champ doit être une chaîne de caractères.")
    .required("Ce champ est obligatoire."),
  city: yup
    .string("Ce champ doit être une chaîne de caractères.")
    .required("Ce champ est obligatoire."),
  phone: yup.string().matches(phoneRegExp, "Téléphone n'est pas valide"),
  email: yup
    .string("Ce champ doit être une chaîne de caractères.")
    .email("Ce champ doit respecter le format email")
    .required("Ce champ est obligatoire."),
  password: yup
    .string("Ce champ doit être une chaîne de caractères.")
    .matches(
      passwordRegExp,
      "Votre mot de passe doit comporter au moins 6 caractères et doit contenir au moins une lettre, un chiffre et un caractère spécial."
    )
    .required("Ce champ est obligatoire."),
  cfrpassword: yup
    .string()
    .required("Ce champ est obligatoire.")
    .oneOf([yup.ref("password")], "Les mots de passe ne correspondent pas"),
  /*  message: yup
    .string("Ce champ doit être une chaîne de caractères.")
    .required("Ce champ est obligatoire."), */
});

export default function Steps({ step, onChange }) {
  const navigation = useNavigation();
  const [currentStep, setCurrentStep] = useState(step);
  const [pathStep, setPathStep] = useState([]);
  const [presta, setPresta] = useState();
  const [total, setTotal] = useState(10);
  const [operator, setOperator] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalErrors, setGlobalErrors] = useState(false);
  const [clientId, setClientId] = useState(false);

  const [data, setData] = useState({
    email: "",
    roles: ["ROLE_CLIENT"],
    password: "",
    prenom: "",
    nom: "",
    telephone: "",
    adresse: "",
    codePostal: "",
    ville: "",
    message: "",
    nom_societe: "",
    function_client: "",
    type_batiment: "",
    type_client: "",
    operateur: [],
    problemes: [],
    urgent: 0,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const MB19 = {
    marginBottom: "19px",
  };
  const Step = styled("div")(({ theme }) => ({}));

  // Set Data
  const SetProblems = (Prob) => {
    let DT = data;
    DT.problemes.push(Prob);
    setData(DT);

    let prevSteps = pathStep;
    prevSteps.push(currentStep);
    setPathStep(prevSteps);
    setCurrentStep(parseInt(currentStep) + 1);
  };
  const SetUrgent = (isUrgent) => {
    let DT = data;
    DT.urgent = isUrgent;
    setData(DT);

    let prevSteps = pathStep;
    prevSteps.push(currentStep);
    setPathStep(prevSteps);
    setCurrentStep(parseInt(currentStep) + 1);
  };
  const handleOperator = (oper) => {
    let DT = data;
    DT.operateur.push(oper.value);
    setData(DT);
    setOperator(oper);

    let prevSteps = pathStep;
    prevSteps.push(currentStep);
    setPathStep(prevSteps);
    setCurrentStep(parseInt(currentStep) + 1);
  };
  const setTypicalBuildingPrticular = (type) => {
    let DT = data;
    DT.type_batiment = type;
    setData(DT);

    let prevSteps = pathStep;
    prevSteps.push(currentStep);
    setPathStep(prevSteps);
    setCurrentStep(parseInt(currentStep) + 2);
  };
  const setTypicalBuildingProfessional = (type) => {
    let DT = data;
    DT.type_batiment = type;
    setData(DT);

    let prevSteps = pathStep;
    prevSteps.push(currentStep);
    setPathStep(prevSteps);
    setCurrentStep(parseInt(currentStep) + 2);
  };
  const setClientType = (type) => {
    let DT = data;
    DT.type_client = type;
    setData(DT);

    let prevSteps = pathStep;
    prevSteps.push(currentStep);
    setPathStep(prevSteps);
    if (type == "Particulier") {
      setCurrentStep(parseInt(currentStep) + 1);
    } else {
      setCurrentStep(parseInt(currentStep) + 2);
    }
  };

  const HandleRegister = (formDt) => {
    let DT = data;
    DT.nom_societe = formDt.companyname ? formDt.companyname : "";
    DT.function_client = formDt.job ? formDt.job : "";
    DT.prenom = formDt.firstname;
    DT.nom = formDt.lastname;
    DT.password = formDt.password;
    DT.adresse = formDt.address;
    DT.codePostal = formDt.zipcode;
    DT.ville = formDt.city;
    DT.telephone = formDt.phone;
    DT.email = formDt.email;
    DT.message = formDt.message;

    setData(DT);
    setIsLoading(true);
    clientsService
      .addClient(DT)
      .then((response) => {
        if (response?.response?.data?.code == 302) {
          throw new Error(response?.response?.data?.message);
        }
        if (response?.response?.status == 302) {
          throw new Error(response?.response?.data);
        }

        let prevSteps = pathStep;
        prevSteps.push(currentStep);
        setPathStep(prevSteps);
        setClientId(response.id);
        if (data.type_client == "Particulier") {
          setCurrentStep(parseInt(currentStep) + 2);
        } else {
          setCurrentStep(parseInt(currentStep) + 1);
        }
        setIsLoading(false);
        setGlobalErrors(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setGlobalErrors(err.message);
      });
  };
  const handlHourSelected = (date, hour) => {
    setLoading(true);
    setSelectedDate(date + "T" + hour + ":00.120Z");

    const obj = {
      user: `/api/users/${clientId}`,
      dateDebut: new Date(date + "T" + hour + ":00.120Z").toISOString(),
      dateFin: new Date(
        new Date(date + "T" + hour + ":00.120Z").getTime() + 30 * 60000
      ).toISOString(),
      titre: "Rdv",
      type: rdv_type_call,
      status: true,
    };

    CalendarService.addCalendar(obj)
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    CreatePrestation(date + "T" + hour + ":00.120Z");

    return;
  };
  const stepBack = () => {
    let prevSteps = pathStep;
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    prevSteps.pop();
    setPathStep(prevSteps);
  };
  const stepNext = () => {
    let prevSteps = pathStep;
    prevSteps.push(currentStep);
    setPathStep(prevSteps);
    setCurrentStep(parseInt(currentStep) + 1);
  };
  const CreatePrestation = (DTE) => {
    // FBMHttp("/demandes", "post", { datePrestation: DTE }).then((response) => {
    const DmdData = {
      datePrestation: DTE,
      client: "api/users/" + clientId,
      urgent: data.urgent ? true : false,
      nature_probleme: data.problemes[0],
      operateur: data.operateur[0],
      type_batiment: data.type_batiment,
      // type_prestation: data.type_client,
    };
    PrestationService.addPrestation(DmdData).then((response) => {
      let prevSteps = pathStep;
      prevSteps.push(currentStep);
      setPathStep(prevSteps);
      setPresta(response)
      handleFileUpload(response);
      //setCurrentStep(parseInt(currentStep) + 1);
    });
  };
  useEffect(() => {
    onChange((100 / (total - 1)) * currentStep);
  }, [currentStep]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };
  const handleDeleteFile = (index) => {
    const newUploadedFiles = Array.from(selectedFiles).filter(
      (_, i) => i !== index
    );
    setSelectedFiles(newUploadedFiles);
  };

  const handleFileUpload = async (prestation) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("demande", prestation?.id);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files[]", selectedFiles[i]);
    }

    try {
      if (selectedFiles.length > 0) {
        PrestationService.addImages(formData).then((response) => {
          setCurrentStep(parseInt(currentStep) + 1);
          setIsLoading(false);
        });
      } else {
        setCurrentStep(parseInt(currentStep) + 1);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Failed to upload files");
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="registerSteps" mt={"75px"}>
        {currentStep == 0 && (
          <Step>
            <Question>
              Pour vous inscrire, veuillez cocher la case correspondant à votre
              besoin
            </Question>
            <Box sx={Row} justifyContent={"space-between"}>
              <Box
                sx={col4}
                onClick={() =>
                  SetProblems("Câblage réseau et déploiement Wifi")
                }
              >
                <ButtonInput type="button" height={220}>
                  <b>Câblage réseau et déploiement Wifi</b>
                  <br />
                  Switch, câblage RJ45, modification de câblage fibre optique et
                  ADSL…
                </ButtonInput>
              </Box>
              <Box sx={col4} onClick={() => SetProblems("Antenne TV")}>
                <ButtonInput type="button" height={220}>
                  <b>Antenne TV</b>
                  <br />
                  Installation d’antenne TV sur-mesure et complet : conseils,
                  installation et câblage des équipements, maintenance
                </ButtonInput>
              </Box>
              <Box sx={col4} onClick={() => SetProblems("Interphonie")}>
                <ButtonInput type="button" height={220}>
                  <b>Interphonie</b>
                  <br />
                  Installation et maintenance
                </ButtonInput>
              </Box>
              <Box sx={col4} onClick={() => SetProblems("Interphonie")}>
                <ButtonInput type="button" height={220}>
                  <b>Électricité courant faible</b>
                  <br />
                  Installations, dépannages et maintenance en courant faible
                </ButtonInput>
              </Box>
              <Box sx={col4} onClick={() => SetProblems("Autres")}>
                <ButtonInput type="button" height={220}>
                  <b>Autres</b>
                  <br />
                  Tranchée et pose de nouveaux fourreaux, débouchage de fourreau
                  et gaine
                </ButtonInput>
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 1 && (
          <Step>
            <Question>Est-ce une urgence ?</Question>
            <Box sx={Row} justifyContent={"center"}>
              <Box
                width={"100%"}
                sx={{
                  margin: { xs: "15px auto", md: "0 23px" },
                  maxWidth: { xs: "280px", md: "360px" },
                }}
                onClick={() => SetUrgent(1)}
              >
                <ButtonInput type="button" height={77}>
                  Oui
                </ButtonInput>
              </Box>
              <Box
                width={"100%"}
                sx={{
                  margin: { xs: "15px auto", md: "0 23px" },
                  maxWidth: { xs: "280px", md: "360px" },
                }}
                onClick={() => SetUrgent(0)}
              >
                <ButtonInput type="button" height={77}>
                  Non
                </ButtonInput>
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 2 && false && (
          <Step>
            <Question>Quel est votre opérateur ?</Question>
            <Box sx={Row} justifyContent={"center"}>
              <Box
                width={"100%"}
                sx={{
                  margin: { xs: "15px auto", md: "0 23px" },
                  maxWidth: { xs: "280px", md: "360px" },
                }}
              >
                <Select
                  placeholder="Sélectioner votre opérateur"
                  onChange={(e) => {
                    handleOperator(e);
                  }}
                  value={operator}
                  options={[
                    { value: "Operateur 1", label: "Operateur 1" },
                    { value: "Operateur 2", label: "Operateur 2" },
                    { value: "Operateur 3", label: "Operateur 3" },
                  ]}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "rgba(62, 195, 236, 0.02)"
                        : state.isFocused
                        ? "rgba(62, 195, 236, 0.2)"
                        : "rgba(62, 195, 236, 0.08)",
                      color: "rgba(0, 44, 58, 1)",
                      //   padding: 20,
                      fontSize: 24,
                      cursor: "pointer",
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: "2px solid transparent",
                      backgroundColor: "rgba(62, 195, 236, 0.02)",
                      backgroundImage:
                        "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
                      borderImageSlice: 1,
                      borderImageSource:
                        "linear-gradient(360deg, #3EC3EC -36%, rgba(62, 195, 236, 0) 76%)",
                      fontSize: 24,
                      height: 77,
                      boxShadow:
                        "0px 5px 5px -3px rgb(130 130 130 / 20%), " +
                        "0px 8px 10px 1px rgb(255 255 255 / 14%)," +
                        "0px 3px 14px 2px rgb(213 213 213 / 12%) !important",
                      cursor: "pointer",
                    }),
                    singleValue: (provided, state) => {
                      const opacity = state.isDisabled ? 0.5 : 1;
                      const transition = "opacity 300ms";

                      return { ...provided, opacity, transition };
                    },
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: "rgba(238, 241, 242)",
                      backgroundImage:
                        "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
                      borderImageSlice: 1,
                      borderImageSource:
                        "linear-gradient(360deg, #3EC3EC -36%, rgba(62, 195, 236, 0) 76%)",
                    }),
                  }}
                />
              </Box>
            </Box>
          </Step>
        )}

        {currentStep == 2 && (
          <Step>
            <Question>Vous êtes ?</Question>
            <Box sx={Row} justifyContent={"center"} alignItems={"center"}>
              <Box
                width={"100%"}
                sx={{
                  margin: { xs: "15px auto", md: "0 23px" },
                  maxWidth: "280px",
                }}
              >
                <ButtonInput
                  onClick={() => setClientType("Particulier")}
                  type="button"
                  height={220}
                >
                  Particulier
                </ButtonInput>
              </Box>
              <Box
                width={"100%"}
                sx={{
                  margin: { xs: "15px auto", md: "0 23px" },
                  maxWidth: "280px",
                }}
              >
                <ButtonInput
                  onClick={() => setClientType("Hôtel & résidence")}
                  type="button"
                  height={220}
                >
                  Hôtel & résidence
                </ButtonInput>
              </Box>
              <Box
                width={"100%"}
                sx={{
                  margin: { xs: "15px auto", md: "0 23px" },
                  maxWidth: "280px",
                }}
              >
                <ButtonInput
                  onClick={() => setClientType("Professionnel")}
                  type="button"
                  height={220}
                >
                  Professionnel
                </ButtonInput>
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 3 && (
          <Step>
            <Question>Où va se dérouler l’intervention ?</Question>
            <Box sx={Row} justifyContent={"space-between"}>
              <Box
                sx={col4}
                onClick={() => setTypicalBuildingPrticular("Immeuble")}
              >
                <ButtonInput type="button" height={220}>
                  Immeuble
                </ButtonInput>
              </Box>
              <Box
                sx={col4}
                onClick={() => setTypicalBuildingPrticular("Maison")}
              >
                <ButtonInput type="button" height={220}>
                  Maison
                </ButtonInput>
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 4 && (
          <Step>
            <Question>Où va se dérouler l’intervention ?</Question>
            <Box sx={Row} justifyContent={"space-between"}>
              <Box
                sx={col4}
                onClick={() => setTypicalBuildingProfessional("Logement")}
              >
                <ButtonInput type="button" height={220}>
                  Logement
                </ButtonInput>
              </Box>
              <Box
                sx={col4}
                onClick={() =>
                  setTypicalBuildingProfessional("Local commercial")
                }
              >
                <ButtonInput type="button" height={220}>
                  Local commercial
                </ButtonInput>
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 5 && (
          <Step>
            <Question>Vous y êtes presque !</Question>
            <Box sx={Row} justifyContent={"space-between"}>
              <Box
                width={"100%"}
                sx={{
                  maxWidth: { xs: "280px", md: "458px" },
                  margin: { xs: "15px auto", md: "0 23px" },
                }}
              >
                <TextInput
                  register={{ ...register("firstname") }}
                  placeholder={"Prénom*"}
                  styles={MB19}
                />
                {errors.firstname && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.firstname.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("lastname") }}
                  placeholder={"nom*"}
                  styles={MB19}
                />
                {errors.lastname && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.lastname.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("address") }}
                  placeholder={"Adresse*"}
                  styles={MB19}
                />
                {errors.address && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.address.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("phone") }}
                  placeholder={"numéro de téléphone*"}
                  styles={MB19}
                />
                {errors.phone && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.phone.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("zipcode") }}
                  placeholder={"code postal*"}
                  styles={MB19}
                />
                {errors.zipcode && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.zipcode.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("city") }}
                  placeholder={"ville*"}
                  styles={MB19}
                />
                {errors.city && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.city.message}
                  </Typography>
                )}
              </Box>
              <Box
                width={"100%"}
                sx={{
                  maxWidth: { xs: "280px", md: "458px" },
                  margin: { xs: "15px auto", md: "0 23px" },
                }}
              >
                <TextInput
                  register={{ ...register("email") }}
                  placeholder={"Adresse mail*"}
                  styles={MB19}
                />
                {errors.email && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.email.message}
                  </Typography>
                )}
                {globalErrors && (
                  <Typography
                    fontSize={{
                      xs: 14,
                      margin: "-15px 0 15px",
                      textAlign: "center",
                    }}
                    color="red"
                  >
                    {globalErrors}
                  </Typography>
                )}
                <TextInput
                  register={{ ...register("password") }}
                  type="password"
                  placeholder={"Mot de passe*"}
                  styles={MB19}
                />
                {errors.password && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.password.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("cfrpassword") }}
                  type="password"
                  placeholder={"Confirmer le mot de passe*"}
                  styles={MB19}
                />
                {errors.cfrpassword && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.cfrpassword.message}
                  </Typography>
                )}

                <TextAreaInput
                  register={{ ...register("message") }}
                  placeholder={"Votre message"}
                  styles={MB19}
                />
                {errors.message && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-10px 0 15px" }}
                    color="red"
                  >
                    {errors.message.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 6 && (
          <Step>
            <Question>Vous y êtes presque !</Question>
            <Box sx={Row} justifyContent={"space-between"}>
              <Box
                width={"100%"}
                sx={{
                  maxWidth: { xs: "280px", md: "458px" },
                  margin: { xs: "15px auto", md: "0 23px" },
                }}
              >
                <TextInput
                  register={{ ...register("companyname") }}
                  placeholder={"Nom de la société"}
                  styles={MB19}
                />
                {errors.companyname && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.companyname.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("job") }}
                  placeholder={"fonction"}
                  styles={MB19}
                />
                {errors.job && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.job.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("firstname") }}
                  placeholder={"Prénom*"}
                  styles={MB19}
                />
                {errors.firstname && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.firstname.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("lastname") }}
                  placeholder={"nom*"}
                  styles={MB19}
                />
                {errors.lastname && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.lastname.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("address") }}
                  placeholder={"Adresse*"}
                  styles={MB19}
                />
                {errors.address && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.address.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("zipcode") }}
                  placeholder={"code postal*"}
                  styles={MB19}
                />
                {errors.zipcode && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.zipcode.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("city") }}
                  placeholder={"ville*"}
                  styles={MB19}
                />
                {errors.city && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.city.message}
                  </Typography>
                )}
              </Box>
              <Box
                width={"100%"}
                sx={{
                  maxWidth: { xs: "280px", md: "458px" },
                  margin: { xs: "15px auto", md: "0 23px" },
                }}
              >
                <TextInput
                  register={{ ...register("phone") }}
                  placeholder={"numéro de téléphone*"}
                  styles={MB19}
                />
                {errors.phone && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.phone.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("email") }}
                  placeholder={"Adresse mail*"}
                  styles={MB19}
                />
                {errors.email && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.email.message}
                  </Typography>
                )}
                {globalErrors && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {globalErrors}
                  </Typography>
                )}
                <TextInput
                  register={{ ...register("password") }}
                  type="password"
                  placeholder={"Mot de passe*"}
                  styles={MB19}
                />
                {errors.password && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.password.message}
                  </Typography>
                )}

                <TextInput
                  register={{ ...register("cfrpassword") }}
                  type="password"
                  placeholder={"Confirmer le mot de passe*"}
                  styles={MB19}
                />
                {errors.cfrpassword && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                    color="red"
                  >
                    {errors.cfrpassword.message}
                  </Typography>
                )}

                <TextAreaInput
                  register={{ ...register("message") }}
                  placeholder={"Votre message"}
                  styles={MB19}
                />
                {errors.message && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-10px 0 15px" }}
                    color="red"
                  >
                    {errors.message.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 7 && (
          <Step>
            <Question>
              Auriez-vous des photos de ce dont vous avez besoin à nous envoyer?
            </Question>
            <Box sx={Row}>
              <Box width={"100%"}>
                <Box
                  sx={{
                    border: "1px solid #002c3a",
                    padding: "8px 15px",
                    width: "100%",
                    borderRadius: "5px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  htmlFor="file-input-after"
                >
                  <input
                    id="file-input-after"
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label
                    htmlFor="file-input-after"
                    className="custom-file-upload"
                  >
                    <DriveFolderUpload
                      fontSize="large"
                      sx={{
                        cursor: "pointer",
                        marginBottom: "-7px",
                      }}
                    />{" "}
                    Télécharger
                  </label>
                </Box>
                {selectedFiles &&
                  Array.from(selectedFiles).map((file, key) => {
                    return (
                      <div
                        style={{ float: "left", marginRight: "10px" }}
                        key={key}
                        className="image-preview"
                      >
                        <p>
                          <a href={URL.createObjectURL(file)} target="_blank">
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              maxWidth={"80px"}
                              height={"80px"}
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #F2E208",
                              }}
                            />
                          </a>
                          <br />
                          <Button
                            startIcon={<DeleteOutline />}
                            variant={"text"}
                            sx={{
                              fontWeight: { xs: 700 },
                              fontSize: { xs: 16 },
                              fontFamily: { xs: "Bauhaus" },
                              textTransform: "capitalize",
                            }}
                            color="inherit"
                            onClick={() => handleDeleteFile(key)}
                          >
                            Supprimer
                          </Button>
                        </p>
                      </div>
                    );
                  })}
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 8 && (
          <Step>
            <Question>Quand souhaîtez-vous être rappelé ?</Question>
            <Box sx={Row}>
              <Box width={"100%"}>
                <CustomCalendar
                  loading={loading}
                  onHourSelected={(date, hour) => handlHourSelected(date, hour)}
                />
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 9 && (
          <Step>
            <Question style={{ fontSize: 24 }}>Merci !</Question>
            <Box sx={Row}>
              <Box width={"100%"}>
                <Box fontSize={18} textAlign={"center"}>
                  Un conseiller FIBME va vous contacter le{" "}
                  <Box component={"b"}>
                    {moment.parseZone(selectedDate).format("DD MMMM,YYYY")} à{" "}
                    {moment.parseZone(selectedDate).format("HH:mm")}
                  </Box>{" "}
                  afin d’identifier votre besoin et planifier une intervention.
                </Box>
              </Box>
              <Box width={"100%"}>
                {/* <Box display='block' margin='auto' width="150px" height="150px" style={{backgroundImage: 'url('+CheckCir+')', backgroundPosition: 'center', backgroundSize: 'contain'}} /> */}
                <Img
                  src={CheckCir}
                  style={{
                    margin: "40px auto 80px",
                    width: 150,
                    display: "block",
                  }}
                />
                <Box textAlign={"center"}>
                  <CustomButton
                    type="blue"
                    handleClick={() => navigation.navigate("Home")}
                  >
                    Accueil
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Step>
        )}

        {currentStep != total - 1 && currentStep != 0 && (
          <Box textAlign={"center"} paddingTop={"75px"}>
            <CustomButton type="red" handleClick={() => stepBack()}>
              Retour
            </CustomButton>
            {(currentStep == 5 || currentStep == 6) && (
              <>
                <Box
                  component={"span"}
                  sx={{
                    display: { xs: "block", md: "inline-block" },
                    margin: "30px",
                  }}
                />
                <CustomButton
                  type="blue"
                  handleClick={handleSubmit(HandleRegister)}
                >
                  S’inscrire
                  {isLoading && (
                    <CircularProgress
                      color="inherit"
                      width="27px"
                      height="27px"
                      sx={{
                        width: "27px !important",
                        height: "27px !important",
                        display: "inline-block",
                        verticalAlign: "bottom",
                        marginLeft: "15px",
                        position: "absolute",
                      }}
                    />
                  )}
                </CustomButton>
              </>
            )}
            {currentStep == 7 && (
              <Box
                component={"span"}
                sx={{
                  display: { xs: "block", md: "inline-block" },
                  margin: "30px",
                }}
              >
                <CustomButton type="blue" handleClick={() => stepNext()}>
                  Suivant
                  
                </CustomButton>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
