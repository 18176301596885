import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Tooltip,
  useMediaQuery,
  Slide,
  Link,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import logo from "../../assets/images/logos/logo.png";
import logoMobile from "../../assets/images/logos/logo_mobile.png";
import logoMd from "../../assets/images/logos/logo_md.png";
import account from "../../assets/images/icons/account.png";
import { styled as muiStyled } from "@mui/system";
import BlackButton from "../buttons/BlackButton";
import { AccountButton } from "../buttons";
import { useTheme } from "@emotion/react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { styled } from "@mui/material/styles";
import theme from "../../assets/theme";
import { Email, LocationOn, PhoneEnabled } from "@mui/icons-material";
import { useSelector } from "react-redux";

const pages = [
  "Qui sommes-nous ?",
  "Comment ça marche ?",
  "Pourquoi Fibme ?",
  /*"Galerie",*/
  "Témoignages",
];

const ListContainer = muiStyled(Box, {
  shouldForwardProp: (prop) => prop !== "activeLink",
  name: "ListContainer",
})(({ theme, activeLink }) => ({
  display: "inline-flex",
  padding: theme.spacing(3, 0),
  flexGrow: 1,
  cursor: "pointer",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: activeLink ? `calc((100% / 4) * ${activeLink})` : 0,
    width: "25%",
    height: "50%",
    backgroundImage: theme.palette.gradient.navlistHeader,
    transition: "left .25s linear",
    zIndex: -99,
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
  },
}));

const ListContainerVer = muiStyled(Box, {
  shouldForwardProp: (prop) => prop !== "activeLink",
  name: "ListContainer",
})(({ theme, activeLink }) => ({
  display: "flex",
  flexDirection: "column",
  height: "90vh",
  flexGrow: 1,
  cursor: "pointer",
  "&::after": {
    content: '""',
    position: "absolute",
    left: "50%",
    top: activeLink ? `calc(80px * ${activeLink} + 40px)` : "40px",
    width: "60%",
    transform: "translateX(-50%)",
    height: 40,
    backgroundImage:
      "linear-gradient( 0deg,rgba(3,235,255,0.9) -38%,rgba(3,235,255,0.0935268) 85%)",
    transition: "top .25s linear",
    zIndex: 1,
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    height: 96,

    "&::after": {
      content: '""',
      position: "absolute",
      top: "auto",
      bottom: 0,
      transform: "none",
      left: activeLink ? `calc((100% / 4) * ${activeLink})` : 0,
      width: "25%",
      height: "50%",
      backgroundImage:
        "linear-gradient( 0deg,rgba(3,235,255,0.9) -38%,rgba(3,235,255,0.0935268) 85%)",
      transition: "left .25s linear",
      borderBottom: `4px solid ${theme.palette.secondary.main}`,
    },
  },
}));

const MenuContainer = muiStyled(Box, {
  shouldForwardProp: (prop) => prop !== "checked",
  name: "MenuContainer",
})(({ theme, checked }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  /*padding: checked ? theme.spacing(1.6, 0.6, 1.6, 2.4) : theme.spacing(1.6),*/
  /*padding: checked ? theme.spacing(1.6, 1.6, 1.6, 1.6) : theme.spacing(1.6),*/
  padding: "5px 5px 5px 5px",
  /*height: "60px",*/
  position: "relative",
  transition: "all .8 ease-in-out",
  border: checked ? "1px solid" : "none",
  borderRadius: "50%",
  ["&::before"]: {
    background: "none",
    border: checked ? "2px dashed rgba(3, 235, 255, 1)" : "none",
    content: `""`,
    display: "block",
    position: "absolute",
    top: -10,
    left: -10,
    right: -10,
    bottom: -10,
    pointerEvents: "none",
    borderRadius: "50%",
    transition: "all .8 ease-in-out",
  },
  ["& .checkbox"]: {
    display: "none",
  },

  ["& .hamburger-lines"]: {
    /*height: "26px",*/
    /*   //width: "32px",*/
    display: "flex",
    flexDirection: "column",
    /*justifyContent: "space-around",
    transition: " justify-content 0.2s ease-in-out",*/
    /*transitionDelay: "1s",*/

    justifyContent: "center",
    alignItems: "center",
    transition: "all 500ms ease-in",

    position: "relative",
    width: "40px",
    height: "40px",

    ["& .line"]: {
      display: "block",
      height: "3px",
      width: "32px",
      borderRadius: "10px",
      background: "#0e2431",

      position: "absolute",
      transition: "700ms ease-in-out",
    },

    ["& .line1"]: {
      /*transformOrigin: "0% 0%",
      transition: "all 0.4s ease-in-out",*/

      top: "15px",
    },
    ["& .line3"]: {
      /*transformOrigin: "0% 100%",
      transition: "all 0.4s ease-in-out",*/

      top: "25px",
    },
  },

  ["& input[type='checkbox']:checked ~ .hamburger-lines"]: {
    justifyContent: "space-between",
  },
  ["& input[type='checkbox']:checked ~ .hamburger-lines .line"]: {
    backgroundColor: "rgba(3, 235, 255, 1)",
  },

  ["& input[type='checkbox']:checked ~ .hamburger-lines .line1"]: {
    /*transform: "rotate(47deg)",*/
    transform: "rotate(135deg)",
    top: "18px",
  },
  ["& input[type='checkbox']:checked ~ .hamburger-lines .line3"]: {
    /*transform: "rotate(-47deg)",*/
    transform: "rotate(45deg)",
    top: "20px",
  },
}));

const TopContact = styled(Box)(() => ({
  display: "flex",
  alignItem: "center",
  justifyContent: "center",
  padding: theme.spacing(1, 0),
}));

const ResponsiveAppBar = ({ handleDialog }) => {
  const { user } = useSelector((state) => state.userAuth);
  const theme = useTheme();
  const route = useRoute();
  const navigation = useNavigation();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const [activeLink, setActiveLink] = useState(0);
  const [checked, setChecked] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    if (route.params && route.params.activeLink) {
      handleClick(route.params.activeLink);
    }
  }, [route.params, handleClick]);
  useEffect(() => {
    if (route.name === "Dashboard") {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [route.name]);

  const handleClick = useCallback(
    (i) => {
      if (route.name !== "Home") {
        navigation.navigate("Home", { activeLink: i });
      }

      const anchor = document.querySelector(`#sec${i + 1}`);

      if (anchor) {
        anchor.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }

      //setActiveLink(i);
    },
    [navigation, route.name]
  );

  const handleLogo = () => {
    if (route.name !== "Home") {
      navigation.navigate("Home");
    }
  };

  const handleScroll = (event) => {
    /* const { contentOffset } = event.nativeEvent;
      console.log('Scroll position:', window.scrollY); */
    const homeOffset = document.getElementById("sec1").offsetTop - 250;
    const aboutOffset = document.getElementById("sec2").offsetTop - 250;
    const servicesOffset = document.getElementById("sec3").offsetTop - 250;
    const contactOffset = document.getElementById("sec4").offsetTop - 250;

    if (document.getElementById("sec1").offsetTop !== 0) {
      const scrollPosition = window.scrollY;

      if (scrollPosition >= 0 && scrollPosition < aboutOffset) {
        setActiveLink(0);
      } else if (
        scrollPosition >= aboutOffset &&
        scrollPosition < servicesOffset
      ) {
        setActiveLink(1);
      } else if (
        scrollPosition >= servicesOffset &&
        scrollPosition < contactOffset
      ) {
        setActiveLink(2);
      } else if (scrollPosition >= contactOffset) {
        setActiveLink(3);
      }
    } else {
      setActiveLink(0);
    }
    //console.log(activeLink)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <>
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{
          height: 160,
          /*backdropFilter: "blur(5px)",*/
          backgroundColor: theme.palette.background.main,
          boxShadow: theme.palette.shadow.header,
          padding: "8px 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TopContact>
          <Typography
            fontFamily={"Bauhaus"}
            fontSize={{ xs: 16, lg: 18 }}
            margin={2}
            lineHeight={"1px !important"}
          >
            <PhoneEnabled
              sx={{ width: 25, height: 25, marginBottom: "-5px" }}
            />{" "}
            <Link color={"#002C3A"} href="tel:01 64 47 52 49">
              01 64 47 52 49
            </Link>
          </Typography>
          <Typography
            fontFamily={"Bauhaus"}
            fontSize={{ xs: 16, lg: 18 }}
            margin={2}
            lineHeight={"1px !important"}
          >
            <LocationOn sx={{ width: 25, height: 25, marginBottom: "-5px" }} />{" "}
            <Link color={"#002C3A"} href="tel:01 64 47 52 49">
              Intervention secteur Île De France
            </Link>
          </Typography>
          <Typography
            fontFamily={"Bauhaus"}
            fontSize={{ xs: 16, lg: 18 }}
            margin={2}
            lineHeight={"1px !important"}
          >
            <Email sx={{ width: 25, height: 25, marginBottom: "-5px" }} />{" "}
            <Link color={"#002C3A"} href="mailto:contact@fibme.com">
              contact@fibme.com
            </Link>
          </Typography>
        </TopContact>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                mr: 2,
                flexGrow: { xs: 1, lg: 0 },
                display: "flex",
                cursor: "pointer",
              }}
              onClick={handleLogo}
            >
              <img src={isLg ? logo : isMd ? logoMd : logoMobile} alt="logo" />
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "flex", lg: "none" },
                alignItems: "center",
              }}
            >
              <Box>
                <MenuContainer
                  onClick={() => setChecked(!checked)}
                  checked={checked}
                >
                  <input
                    className="checkbox"
                    type="checkbox"
                    name=""
                    id=""
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <div className="hamburger-lines">
                    <span className="line line1"></span>

                    <span className="line line3"></span>
                  </div>
                </MenuContainer>
              </Box>

              <Box>
                <Tooltip title="Open settings">
                  <IconButton color="inherit" onClick={handleDialog}>
                    <img src={account} alt="account icon" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", lg: "flex" },
                alignItems: "center",
                position: "relative",
              }}
            >
              {showMenu && (
                <ListContainer component={"div"} activeLink={activeLink}>
                  {pages.map((page, i) => (
                    <Box
                      key={page}
                      flexBasis={"25%"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      onClick={(e) => handleClick(i)}
                    >
                      <Typography
                        fontFamily={"Bauhaus"}
                        color={theme.palette.text.navlistHeader}
                        fontSize={{ xs: 16, lg: 16 }}
                        lineHeight={{ xs: "19px", lg: "19px" }}
                      >
                        {page}
                      </Typography>
                    </Box>
                  ))}
                </ListContainer>
              )}
            </Box>
            <Box
              sx={{
                flexGrow: { md: 0.75, lg: 0.75 },
                display: { xs: "none", md: "block" },
              }}
            />
            {!user && (
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  visibility: route.name === "Register" ? "hidden" : "visible",
                }}
              >
                <BlackButton
                  shadow
                  textRainbow
                  handleClick={() => navigation.navigate("Register")}
                >
                  prendre un rendez-vous
                </BlackButton>
              </Box>
            )}
            <Box sx={{ display: { xs: "none", lg: "block" } }} ml={4}>
              <AccountButton handleClick={handleDialog} />
            </Box>
          </Toolbar>
        </Container>
        {!isLg && (
          <Slide direction="down" in={checked}>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", lg: "flex" },
                alignItems: "center",
                position: "absolute",
                /*top: "100%",*/
                top: "100%",
                left: 0,
                width: "100%",
                height: { xs: "90vh", md: 96 },
                backgroundColor: "rgba(244, 242, 246, 1)",
                zIndex: "-1",
              }}
            >
              {showMenu && (
                <ListContainerVer component={"div"} activeLink={activeLink}>
                  {pages.map((page, i) => (
                    <Box
                      key={page}
                      flexBasis={{ xs: "auto", md: "25%" }}
                      height={80}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      onClick={(e) => {
                        handleClick(i);
                        setChecked(!checked);
                      }}
                    >
                      <Typography
                        fontFamily={"Bauhaus"}
                        color={"#002C3A"}
                        fontSize={{ xs: 16, lg: 16 }}
                      >
                        {page}
                      </Typography>
                    </Box>
                  ))}
                </ListContainerVer>
              )}
            </Box>
          </Slide>
        )}
      </AppBar>
    </>
  );
};
export default ResponsiveAppBar;
