import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { styled, ThemeProvider } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import ButtonInput from "../../../components/buttons/ButtonInput";
import {
  EmailInput,
  LinkInput,
  TextAreaInput,
  TextInput,
} from "../../../components/inputs";
import theme from "../../../assets/theme";
import { CustomCalendar } from "../../../components";
import CheckCir from "../../../assets/images/check-circle.png";
import { Img } from "../../../components/Window";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import clientsService from "../../../store/apis/clientsApis";
import PrestationService from "../../../store/apis/PrestationApi";
import CalendarService from "../../../store/apis/CalendarApi";
import { useNavigation } from "@react-navigation/native";
import {
  AddOutlined,
  CheckCircle,
  DownloadForOffline,
} from "@mui/icons-material";
import partnersService from "../../../store/apis/partnersApis";
import { list_dispos, list_metiers } from "../../../config/keys";

const Question = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyBauhaus,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "30px",
  color: "#002C3A",
  textAlign: "center",
  marginBottom: "45px",
}));

const Row = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  maxWidth: 1010,
  margin: "auto",
  flexWrap: "wrap",
};
const col4 = {
  width: { xs: "calc(100% - 30px)", md: "calc(33.33% - 15px)" },
  margin: "15px auto",
};

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const schema = yup
  .object({
    email: yup
      .string()
      .email("entrer un email valide")
      .required("email est requis"),
    //password: yup.string().required(),
    prenom: yup.string().required("prenom est requis"),
    nom: yup.string().required("nom est requis"),
    telephone: yup
      .string()
      .required("telephone est requis")
      .matches(phoneRegExp, "entrez un numéro de téléphone valide"),
    adresse: yup.string().required("adresse est requis"),
    code_postal: yup
      .string()
      .required("code postal est requis")
      .matches(/\d{2}[ ]?\d{3}/, "entrer un code postal valide"),
    ville: yup.string().required("ville est requis"),
    /* niveau_experience: yup
      .object()
      .shape({
        label: yup.string().required("Ce champ est requis"),
        value: yup.string().required("Ce champ est requis"),
      })
      .required("Ce champ est requis"),
    startMon: yup
      .object()
      .shape({
        label: yup.string().required("Ce champ est requis"),
        value: yup.string().required("Ce champ est requis"),
      })
      .required("Ce champ est requis"),
    startYear: yup
      .object()
      .shape({
        label: yup.string().required("Ce champ est requis"),
        value: yup.string().required("Ce champ est requis"),
      })
      .required("Ce champ est requis"),
    endMon: yup
      .object()
      .shape({
        label: yup.string().required("Ce champ est requis"),
        value: yup.string().required("Ce champ est requis"),
      })
      .required("Ce champ est requis"),
    endYear: yup
      .object()
      .shape({
        label: yup.string().required("Ce champ est requis"),
        value: yup.string().required("Ce champ est requis"),
      })
      .required("Ce champ est requis"),

    /* cv: yup.mixed().required("Cv est requis"),
    link: yup.string().required("lien est requis"), 
    forme_juridique: yup.string().required("Ce champ est requis"), */
    //urgent: yup.string().required(),
  })
  /* .shape({
    kbis: yup.mixed().required("Kbis est requis"),
    cni: yup.mixed().required("CNI est requise"),
    attestationAssurance: yup
      .mixed()
      .required("Attestation d'assurance est requise"),
    iban: yup.mixed().required("Iban est requis"),
    selfi: yup.mixed().required("Selfi est requis"),
  }) */
  .required();

export default function Steps({ step, onChange }) {
  const navigation = useNavigation();
  const [currentStep, setCurrentStep] = useState(step);
  const [pathStep, setPathStep] = useState([]);
  const [total, setTotal] = useState(4);
  const [operator, setOperator] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [globalErrors, setGlobalErrors] = useState(false);
  const [clientId, setClientId] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const MB19 = {
    marginBottom: "19px",
  };
  const Step = styled("div")(({ theme }) => ({}));

  const HandleRegister = (data) => {
    setLoading(true);
    const {
      adresse,
      code_postal,
      email,
      link,
      nom,
      prenom,
      telephone,
      ville,
      message,
    } = data;

    const obj = {
      email,
      roles: ["ROLE_PRESTATAIRE"],
      prenom,
      nom,
      telephone,
      adresse,
      code_postal: code_postal.toString(),
      ville,
      message,
      web_site: link,
      metiers: checkedMetiers,
      disponibilites: checkedDispo,
      /* forme_juridique: forme_juridique.toString(), */
      /* niveau_experience: niveau_experience.value,
      date_debut: `${startYear.value}-${startMon.value}-01`,
      date_fin: `${endYear.value}-${endMon.value}-01`, */
    };
    /* console.log(
      obj,
      selectedFileKbis,
      selectedFileIban,
      selectedFileCni,
      selectedFileAtAs,
      selectedFileSelfi,
      selectedFileCv
    ); */
    //return;
    partnersService
      .addPartner(obj)
      .then((res) => {
        if (res?.response?.status === 302) {
          setCurrentStep(0);
          setLoading(false);
          setGlobalErrors(res.response.data);
        } else {
          const { id } = res;
          const formData = new FormData();
          if (selectedFileCv) formData.append("cv", selectedFileCv);
          formData.append("iban", selectedFileIban);
          formData.append("kbis", selectedFileKbis);
          formData.append("cni", selectedFileCni);
          formData.append("selfi", selectedFileSelfi);
          formData.append("attestation", selectedFileAtAs);

          if (
            selectedFileIban &&
            selectedFileKbis &&
            selectedFileCni &&
            selectedFileSelfi &&
            selectedFileAtAs
          ) {
            partnersService.addFilesPartner(id, formData).then((res) => {
              setCurrentStep(parseInt(currentStep) + 1);
              setLoading(false);
              setGlobalErrors(false);
            });
          } else {
            setCurrentStep(parseInt(currentStep) + 1);
            setLoading(false);
            setGlobalErrors(false);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setGlobalErrors(err.message);
      });
  };

  const stepBack = () => {
    let prevSteps = pathStep;
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    prevSteps.pop();
    setPathStep(prevSteps);
  };

  const stepNext = async () => {
    let isValid = false;
    setErrorFiles(false);
    setErrorMetiers(false);
    setErrorDispo(false);
    switch (currentStep) {
      case 0:
        isValid = await trigger([
          "adresse",
          "nom",
          "prenom",
          "telephone",
          "email",
          "code_postal",
          "ville",
        ]);
        break;
      case 1:
        isValid = checkedMetiers.length > 0 && checkedDispo.length > 0;
        if (checkedMetiers.length === 0) setErrorMetiers(true);
        if (checkedDispo.length === 0) setErrorDispo(true);
        break;
      /* case 2:
        isValid =
          selectedFileKbis &&
          selectedFileAtAs &&
          selectedFileCni &&
          selectedFileIban &&
          selectedFileSelfi; */
        break;
      default:
        isValid = false;
        break;
    }
    if (isValid) {
      let prevSteps = pathStep;
      prevSteps.push(currentStep);
      setPathStep(prevSteps);
      setCurrentStep(parseInt(currentStep) + 1);
    } else {
      if (currentStep === 2) setErrorFiles(true);
      console.log({ errors });
    }
  };

  useEffect(() => {
    onChange((100 / (total - 1)) * currentStep);
  }, [currentStep]);

  const [checkedMetiers, setCheckedMetiers] = useState([]);

  const handleChange = (event, metier) => {
    if (event.target.checked) {
      setCheckedMetiers([...checkedMetiers, metier]);
    } else {
      const newMetiers = checkedMetiers.filter((item) => item !== metier);
      setCheckedMetiers(newMetiers);
    }
  };

  const [checkedDispo, setCheckedDispo] = useState([]);

  const handleChangeDispo = (event, dispo) => {
    if (event.target.checked) {
      setCheckedDispo([...checkedDispo, dispo]);
    } else {
      const newMetiers = checkedDispo.filter((item) => item !== dispo);
      setCheckedDispo(newMetiers);
    }
  };

  const [selectedFileKbis, setSelectedFileKbis] = useState();
  const [selectedFileCni, setSelectedFileCni] = useState();
  const [selectedFileAtAs, setSelectedFileAtAs] = useState();
  const [selectedFileIban, setSelectedFileIban] = useState();
  const [selectedFileSelfi, setSelectedFileSelfi] = useState();
  const [selectedFileCv, setSelectedFileCv] = useState();
  const [errorFiles, setErrorFiles] = useState(false);
  const [errorMetiers, setErrorMetiers] = useState(false);
  const [errorDispo, setErrorDispo] = useState(false);

  const changeHandler = (event, type) => {
    switch (type) {
      case "kbis":
        setSelectedFileKbis(event.target.files[0]);
        break;
      case "cni":
        setSelectedFileCni(event.target.files[0]);
        break;
      case "AtAs":
        setSelectedFileAtAs(event.target.files[0]);
        break;
      case "iban":
        setSelectedFileIban(event.target.files[0]);
        break;
      case "selfi":
        setSelectedFileSelfi(event.target.files[0]);
        break;
      case "cv":
        setSelectedFileCv(event.target.files[0]);
        break;
    }
    /* setSelectedFile(event.target.files, ...selectedFile]);
        setValue("files", [event.target.files, ...selectedFile]) */
    // setIsSelected(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="registerSteps" mt={"75px"}>
        {currentStep == 0 && (
          <Step>
            <Question>Informations personnnelles</Question>
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={Row}
              justifyContent={"space-between"}
            >
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"Prénom*"}
                  register={{ ...register("prenom") }}
                />
                {errors.prenom && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.prenom.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"Nom*"}
                  register={{ ...register("nom") }}
                />
                {errors.nom && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.nom.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%" }}>
                <TextInput
                  placeholder={"adresse*"}
                  register={{ ...register("adresse") }}
                />
                {errors.adresse && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.adresse.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"code postal*"}
                  register={{ ...register("code_postal") }}
                />
                {errors.code_postal && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.code_postal.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"ville*"}
                  register={{ ...register("ville") }}
                />
                {errors.ville && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.ville.message}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <EmailInput register={{ ...register("email") }} />
                {errors.email && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.email.message}
                  </Typography>
                )}
                {globalErrors && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {globalErrors}
                  </Typography>
                )}
              </Box>
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <TextInput
                  placeholder={"numéro de téléphone**"}
                  type={"tel"}
                  register={{ ...register("telephone") }}
                />
                {errors.telephone && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.telephone.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 1 && (
          <Step>
            <Question>Informations professionnels</Question>
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={Row}
              justifyContent={"space-between"}
            >
              <Box flexBasis={{ xs: "100%", md: "100%" }}>
                <Box mt={0.75}>
                  <Typography
                    variant="caption"
                    component={"label"}
                    fontSize={{ xs: 16, md: 24 }}
                  >
                    Vos activités
                  </Typography>
                  <Box>
                    <FormGroup aria-label="position" row>
                      {list_metiers.map((metier) => {
                        return (
                          <FormControlLabel
                            labelPlacement="end"
                            control={
                              <Checkbox
                                checked={checkedMetiers.includes(metier)}
                                onChange={(event) =>
                                  handleChange(event, metier)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {metier}
                              </Typography>
                            }
                          />
                        );
                      })}
                    </FormGroup>
                    {errorMetiers && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        Veuillez sélectionner un ou plusieurs métiers
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box mt={0.75}>
                  <Typography
                    variant="caption"
                    component={"label"}
                    fontSize={{ xs: 16, md: 24 }}
                  >
                    Vos disponibilités
                  </Typography>
                  <Box>
                    <FormGroup aria-label="position" row>
                      {list_dispos.map((dispo) => {
                        return (
                          <FormControlLabel
                            labelPlacement="end"
                            control={
                              <Checkbox
                                checked={checkedDispo.includes(dispo.id)}
                                onChange={(event) =>
                                  handleChangeDispo(event, dispo.id)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {dispo.value}
                              </Typography>
                            }
                          />
                        );
                      })}
                    </FormGroup>
                    {errorDispo && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        Veuillez choisir vos disponibilités
                      </Typography>
                    )}
                  </Box>
                </Box>
                {/* <Box mt={0.75}>
                  <Typography
                    variant="caption"
                    component={"label"}
                    fontSize={{ xs: 16 }}
                  >
                    Niveau d’expérience ( électricien, techicien fibre, bâtiment
                    )
                  </Typography>
                  <Box>
                    <Controller
                      name={"niveau_experience"}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={customStyles}
                          placeholder={"Saisissez votre niveau ici"}
                          options={problems}
                        />
                      )}
                    />
                    {errors.niveau_experience && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        {errors.niveau_experience.label.message}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box mt={0.75} display={"flex"} gap={1}>
                  <Box flexBasis={"50%"}>
                    <Typography variant="caption" component={"label"}>
                      Date de début
                    </Typography>
                    <Box display={"flex"} gap={1}>
                      <Box flexBasis={"45%"}>
                        <Controller
                          name={"startMon"}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={customStyles}
                              placeholder={"MM"}
                              options={months}
                            />
                          )}
                        />
                        {errors.startMon && (
                          <Typography fontSize={{ xs: 14 }} color="red">
                            {errors.startMon.label.message}
                          </Typography>
                        )}
                      </Box>
                      <Box flexBasis={"55%"}>
                        <Controller
                          name={"startYear"}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={customStyles}
                              placeholder={"AAAA"}
                              options={years}
                            />
                          )}
                        />
                        {errors.startYear && (
                          <Typography fontSize={{ xs: 14 }} color="red">
                            {errors.startYear.label.message}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box flexBasis={"50%"}>
                    <Typography variant="caption" component={"label"}>
                      Date de fin
                    </Typography>
                    <Box display={"flex"} gap={1}>
                      <Box flexBasis={"45%"}>
                        <Controller
                          name={"endMon"}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={customStyles}
                              placeholder={"MM"}
                              options={months}
                            />
                          )}
                        />
                        {errors.endMon && (
                          <Typography fontSize={{ xs: 14 }} color="red">
                            {errors.endMon.label.message}
                          </Typography>
                        )}
                      </Box>
                      <Box flexBasis={"55%"}>
                        <Controller
                          name={"endYear"}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={customStyles}
                              placeholder={"AAAA"}
                              options={years}
                            />
                          )}
                        />
                        {errors.endYear && (
                          <Typography fontSize={{ xs: 14 }} color="red">
                            {errors.endYear.label.message}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography variant="caption" component={"label"}>
                      Salarié ou entrepreneur ?
                    </Typography>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      flexWrap={"wrap"}
                    >
                      {obj.map((item, i) => {
                        return (
                          <Box
                            key={item.type}
                            onClick={() => {
                              setSelected(i);
                              setValue("forme_juridique", item.type, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }}
                            sx={{
                              border: `1px solid`,
                              borderColor:
                                selected === i
                                  ? "rgba(62, 195, 236, .2)"
                                  : "rgba(62, 195, 236, 1)",
                              padding: 1,
                              marginBottom: 0.5,
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontSize: 14,
                              flexBasis: { xs: "100%", md: "49%" },
                              cursor: "pointer",
                            }}
                          >
                            <Typography fontSize={{ xs: 18 }}>
                              {item.type}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                    {errors.forme_juridique && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        {errors.forme_juridique.message}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Button
                      startIcon={<AddOutlined />}
                      variant={"text"}
                      color={"primary"}
                      sx={{ fontWeight: 700 }}
                    >
                      ajouter une autre expérience
                    </Button>
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 22 && (
          <Step>
            <Question>Documents officiels</Question>
            <Box sx={Row} justifyContent={"center"}>
              <Box flexBasis={{ xs: "100%", md: "100%" }}>
                <Box sx={Row}>
                  <Box
                    sx={{ padding: 1 }}
                    flexBasis={{ xs: "100%", md: "50%" }}
                  >
                    <Typography variant="caption">
                      Kbis de moins de 3 mois
                    </Typography>

                    <input
                      id={"upload-kbis"}
                      {...register("kbis")}
                      type={"file"}
                      onChange={(event) => changeHandler(event, "kbis")}
                      hidden
                      accept={
                        "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      }
                    />

                    <Box component={"label"} htmlFor="upload-kbis">
                      <Box
                        sx={{
                          border: "1px solid rgba(62, 195, 236, 1)",
                          height: 45,
                          padding: 1,
                          marginBottom: 0.5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textTransform: "uppercase",
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                      >
                        {!selectedFileKbis ? (
                          <>
                            <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                            cliquez ici pour télécharger
                          </>
                        ) : (
                          <>
                            <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                            {selectedFileKbis?.name}
                          </>
                        )}
                      </Box>
                    </Box>

                    {errorFiles && !selectedFileKbis && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        Ce document est obligatoire
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{ padding: 1 }}
                    flexBasis={{ xs: "100%", md: "50%" }}
                  >
                    <Typography variant="caption">CNI recto / verso</Typography>
                    <input
                      id={"upload-cni"}
                      {...register("cni")}
                      type={"file"}
                      onChange={(event) => changeHandler(event, "cni")}
                      hidden
                      accept={
                        "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      }
                    />

                    <Box component={"label"} htmlFor="upload-cni">
                      <Box
                        sx={{
                          border: "1px solid rgba(62, 195, 236, 1)",
                          height: 45,
                          padding: 1,
                          marginBottom: 0.5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textTransform: "uppercase",
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                      >
                        {!selectedFileCni ? (
                          <>
                            <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                            cliquez ici pour télécharger
                          </>
                        ) : (
                          <>
                            <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                            {selectedFileCni?.name}
                          </>
                        )}
                      </Box>
                    </Box>
                    {errorFiles && !selectedFileCni && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        Ce document est obligatoire
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{ padding: 1 }}
                    flexBasis={{ xs: "100%", md: "50%" }}
                  >
                    <Typography variant="caption">
                      Attestation d'assurance
                    </Typography>
                    <input
                      id={"upload-attestationAssurance"}
                      {...register("attestationAssurance")}
                      type={"file"}
                      onChange={(event) => changeHandler(event, "AtAs")}
                      hidden
                      accept={
                        "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      }
                    />

                    <Box
                      component={"label"}
                      htmlFor="upload-attestationAssurance"
                    >
                      <Box
                        sx={{
                          border: "1px solid rgba(62, 195, 236, 1)",
                          height: 45,
                          padding: 1,
                          marginBottom: 0.5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textTransform: "uppercase",
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                      >
                        {!selectedFileAtAs ? (
                          <>
                            <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                            cliquez ici pour télécharger
                          </>
                        ) : (
                          <>
                            <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                            {selectedFileAtAs?.name}
                          </>
                        )}
                      </Box>
                    </Box>
                    {errorFiles && !selectedFileAtAs && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        Ce document est obligatoire
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{ padding: 1 }}
                    flexBasis={{ xs: "100%", md: "50%" }}
                  >
                    <Typography variant="caption">IBAN</Typography>
                    <input
                      id={"upload-iban"}
                      {...register("iban")}
                      type={"file"}
                      onChange={(event) => changeHandler(event, "iban")}
                      hidden
                      accept={
                        "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      }
                    />

                    <Box component={"label"} htmlFor="upload-iban">
                      <Box
                        sx={{
                          border: "1px solid rgba(62, 195, 236, 1)",
                          height: 45,
                          padding: 1,
                          marginBottom: 0.5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textTransform: "uppercase",
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                      >
                        {!selectedFileIban ? (
                          <>
                            <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                            cliquez ici pour télécharger
                          </>
                        ) : (
                          <>
                            <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                            {selectedFileIban?.name}
                          </>
                        )}
                      </Box>
                    </Box>
                    {errorFiles && !selectedFileIban && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        Ce document est obligatoire
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{ padding: 1 }}
                    flexBasis={{ xs: "100%", md: "50%" }}
                  >
                    <Typography variant="caption">Selfi</Typography>
                    <input
                      id={"upload-selfi"}
                      {...register("selfi")}
                      type={"file"}
                      onChange={(event) => changeHandler(event, "selfi")}
                      hidden
                      accept={
                        "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      }
                    />

                    <Box component={"label"} htmlFor="upload-selfi">
                      <Box
                        sx={{
                          border: "1px solid rgba(62, 195, 236, 1)",
                          height: 45,
                          padding: 1,
                          marginBottom: 0.5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textTransform: "uppercase",
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                      >
                        {!selectedFileSelfi ? (
                          <>
                            <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                            cliquez ici pour télécharger
                          </>
                        ) : (
                          <>
                            <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                            {selectedFileSelfi?.name}
                          </>
                        )}
                      </Box>
                    </Box>
                    {errorFiles && !selectedFileSelfi && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        Ce document est obligatoire
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{ padding: 1 }}
                    flexBasis={{ xs: "100%", md: "50%" }}
                  >
                    <Typography variant="caption">
                      Ajouter un CV (facultatif)
                    </Typography>
                    <input
                      id={"upload-cv"}
                      {...register("cv")}
                      type={"file"}
                      onChange={(event) => changeHandler(event, "cv")}
                      hidden
                      accept={
                        "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      }
                    />

                    <Box component={"label"} htmlFor="upload-cv">
                      <Box
                        sx={{
                          border: "1px solid rgba(62, 195, 236, 1)",
                          height: 45,
                          padding: 1,
                          marginBottom: 0.5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textTransform: "uppercase",
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                      >
                        {!selectedFileCv ? (
                          <>
                            <DownloadForOffline sx={{ marginRight: "10px" }} />{" "}
                            cliquez ici pour télécharger
                          </>
                        ) : (
                          <>
                            <CheckCircle sx={{ marginRight: "10px" }} />{" "}
                            {selectedFileCv?.name}
                          </>
                        )}
                      </Box>
                    </Box>

                    {errors.cv && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        {errors.cv.message}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{ padding: 1 }}
                    flexBasis={{ xs: "100%", md: "50%" }}
                  >
                    <Typography variant={"caption"} component={"label"}>
                      Ajouter un lien vers votre site (facultatif)
                    </Typography>
                    <LinkInput
                      sx={{ color: "rgba(0, 44, 58, 1)" }}
                      placeholder={"Saisissez le lien ici"}
                      register={{ ...register("link") }}
                      type={"url"}
                    />
                    {errors.link && (
                      <Typography fontSize={{ xs: 14 }} color="red">
                        {errors.link.message}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 2 && (
          <Step>
            <Question>Avez-vous d’autres choses à nous dire ?</Question>
            <Box sx={Row} justifyContent={"center"}>
              <Box
                width={"100%"}
                sx={{
                  margin: { xs: "15px auto", md: "0 23px" },
                  maxWidth: { xs: "360px", md: "500px" },
                }}
              >
                <TextAreaInput
                  register={{ ...register("message") }}
                  placeholder={
                    "Décrivez-nous ici en quelques mots les raisons pour lesquelles vous voulez collaborer avec Fibme..."
                  }
                  styles={MB19}
                />
                {errors.message && (
                  <Typography
                    fontSize={{ xs: 14, margin: "-10px 0 15px" }}
                    color="red"
                  >
                    {errors.message.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Step>
        )}
        {currentStep == 3 ? (
          <Step>
            <Question style={{ fontSize: 24 }}>
              Nous vous remercions de l’intérêt que vous portez à rejoindre
              notre réseau !
            </Question>
            <Box sx={Row}>
              <Box width={"100%"}>
                <Box fontSize={18} textAlign={"center"}>
                  Nous étudierons votre demande avec attention et ne manquerons
                  pas de revenir vers vous rapidement.
                </Box>
              </Box>
              <Box width={"100%"}>
                {/* <Box display='block' margin='auto' width="150px" height="150px" style={{backgroundImage: 'url('+CheckCir+')', backgroundPosition: 'center', backgroundSize: 'contain'}} /> */}
                <Img
                  src={CheckCir}
                  style={{
                    margin: "40px auto 80px",
                    width: 150,
                    display: "block",
                  }}
                />
                <Box textAlign={"center"}>
                  <CustomButton
                    type="blue"
                    handleClick={() => navigation.navigate("Home")}
                  >
                    Accueil
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Step>
        ) : (
          <Box
            display={"flex"}
            mb={4}
            mt={4}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {currentStep !== 0 && (
                  <CustomButton type={"red"} handleClick={() => stepBack()}>
                    retour
                  </CustomButton>
                )}
                {currentStep == 2 ? (
                  <>
                    <Box
                      component={"span"}
                      sx={{
                        display: { xs: "block", md: "inline-block" },
                        margin: "30px",
                      }}
                    />
                    <CustomButton
                      type="blue"
                      handleClick={handleSubmit(HandleRegister)}
                    >
                      S’inscrire
                      {loading && (
                        <CircularProgress
                          color="inherit"
                          width="27px"
                          height="27px"
                          sx={{
                            width: "27px !important",
                            height: "27px !important",
                            display: "inline-block",
                            verticalAlign: "bottom",
                            marginLeft: "15px",
                            position: "absolute",
                          }}
                        />
                      )}
                    </CustomButton>
                  </>
                ) : (
                  <CustomButton
                    type={"yellow"}
                    textColor={"white"}
                    handleClick={() => stepNext()}
                  >
                    poursuivre
                  </CustomButton>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
