import styled from "@emotion/styled";
import { StarRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Slider, { SliderThumb } from "@mui/material/Slider";

function ThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <StarRounded
        color={"primary"}
        fontSize={"large"}
        sx={{ width: 56, height: 56 }}
      />
    </SliderThumb>
  );
}

const CustomSlider = styled(Slider)({
  width: "100%",
  color: "rgba(244, 235, 124, 1)",
  height: 21,
  "& .MuiSlider-root": {
    pointerEvents: "none",
  },
  "& .MuiSlider-track": {
    border: "none",
    borderRadius: "0",
  },
  "& .MuiSlider-rail": {
    border: "none",
    borderRadius: "0",
  },
  "& .MuiSlider-thumb": {
    pointerEvents: "none",
    height: 48,
    width: 48,
    backgroundColor: "transparent",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
});

export default function HeadingWithSlider() {
  return (
    <Box
      mt={4}
      mb={4}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexWrap={"wrap"}
    >
      <Typography variant="h3" width={"100%"} textAlign="center" gutterBottom>
        créer un espace personnel
      </Typography>
      <Box width={262}>
        <CustomSlider
          components={{ Thumb: ThumbComponent }}
          disableSwap={true}
          value={30}
        />
      </Box>
    </Box>
  );
}
