import { useState } from "react";
import { Box } from "@mui/material";
import { styled, ThemeProvider } from "@mui/system";
import dashedCircle from "../../assets/images/dashed-circle.png";
import theme from "../../assets/theme";
import { Text } from "react-native-web";
import CustomButton from "../../components/buttons/CustomButton";
import { SignButton } from "../buttons";

const ListWrap = styled("div")(({ theme }) => ({}));

const ListItem = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 18,
  "._cnt": {
    flex: 1,
    minHeight: 73,
    display: "flex",
    alignItems: "center",
    border: "1px solid #323232",
    borderRadius: 75,
    padding: "5px 34px",
    position: "relative",

    "&:after": {
      content: '""',
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(50%, -50%)",
      width: 31,
      height: 31,
      borderRadius: 31,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(50%, -50%) rotate(-45deg)",
      width: 12,
      height: 7,
      borderBottom: "2px solid " + theme.palette.text.primary,
      borderLeft: "2px solid " + theme.palette.text.primary,
      zIndex: 1,
      display: "none",
      transformOrigin: "calc(50% - 2px) calc(50% - 2px)",
    },
    "&.done": {
      border: "2px solid " + theme.palette.primary.main,

      "&:after": {
        backgroundColor: theme.palette.primary.main,
      },
      "&:before": {
        display: "block",
      },
    },
    "&.inProgress": {
      border: "double 3px transparent",
      backgroundImage:
        "linear-gradient(#f2f2f2, #f2f2f2), linear-gradient(101.22deg, #3EC3EC 6.23%, #ECDD3B 90.48%)",
      backgroundOrigin: "border-box",
      backgroundClip: "content-box, border-box, content-box",
      padding: 0,

      "&:after": {
        content: '"..."',
        background:
          "linear-gradient(95.34deg, #3EC3EC -30.13%, #ECDD3B 85.11%)",
        fontSize: 22,
        fontWeight: "bold",
        lineHeight: "21px",
        textAlign: "center",
        letterSpacing: "1.5px",
        paddingLeft: 1,
      },
      ".btns": {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 34,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: 51,
    borderRadius: 51,
    padding: "5px 12px 5px 0",
    marginBottom: 0,

    "._cnt": {
      borderRadius: 25,
      padding: "15px 18px",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      minHeight: 51,

      ".btns": {
        margin: "0 0 0 auto",
        textAlign: "right",
      },
      ".css-text-901oao, .css-901oao": {
        fontSize: 14,
        flex: "none",
      },
      "&.inProgress": {
        fontSize: 14,

        ".css-text-901oao, .css-901oao": {
          padding: 15,
        },
        ".btns": {
          padding: "5px 15px",
        },
      },
    },
  },
}));

const TextItem = styled("p")(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyBauhaus,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "19px",
  color: theme.palette.text.primary,
  flex: 1,
  "&.inProgress": {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 34,
  },
}));

const Link = styled("a")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "19px",
  textAlign: "center",
  color: theme.palette.text.primary,
  minWidth: 150,
  display: "block",
  transition: "all .23s",
  cursor: "pointer",

  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const ListNumber = styled("span")(({ theme }) => ({
  marginRight: 25,
  padding: 0,
  "&.done": {
    i: {
      borderColor: theme.palette.primary.main,
    },
  },
  "&.inProgress": {
    padding: 15,
    marginRight: 0,
    position: "relative",
    left: -15,
    backgroundImage: "url(" + dashedCircle + ")",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: 60,

    i: {
      borderColor: theme.palette.primary.main,
      fontWeight: 700,
    },
  },
  i: {
    width: 35,
    height: 35,
    borderRadius: 35,
    border: "2px solid #232323",
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: 390,
    fontSize: 24,
    lineHeight: "34px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "-0.02em",
    textTransform: "uppercase",
    color: "#002C3A",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    marginRight: 15,
    "&.inProgress": {
      padding: 10,
      backgroundSize: 50,
      left: "-10px",
    },
  },
}));

const AdvancedList = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <ListWrap>
        {props.items &&
          props.items.map((item, index) => (
            <ListItem key={index}>
              {props.listType == "numbers" && (
                <ListNumber
                  className={
                    item.state == -1
                      ? " done "
                      : item.state == 0
                      ? " inProgress "
                      : item.state == 1
                      ? " inPending "
                      : ""
                  }
                >
                  <i>{index + 1}</i>
                </ListNumber>
              )}
              <Box
                className={
                  "_cnt " +
                  (item.state == -1
                    ? " done "
                    : item.state == 0
                    ? " inProgress "
                    : item.state == 1
                    ? " inPending "
                    : "")
                }
              >
                <TextItem
                  className={
                    "css-text-901oao " +
                    (item.state == -1
                      ? " done "
                      : item.state == 0
                      ? " inProgress "
                      : item.state == 1
                      ? " inPending "
                      : "")
                  }
                >
                  {item.text}
                </TextItem>
                <Box
                  className="btns"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {item.update && (
                    <Link onClick={() => props.handleEditRdv(item.typeRdv)}>
                      Modifier
                    </Link>
                  )}
                  {item.signer && (
                    <SignButton
                      handleClick={() =>
                        item.state == 0
                          ? props.handleShowDocument(item.typeDocument)
                          : ""
                      }
                    >
                      Signer
                    </SignButton>
                  )}
                  {item.voir && (
                    <SignButton
                      handleClick={() =>
                        props.handleShowDocument(item.typeDocument)
                      }
                    >
                      Voir
                    </SignButton>
                  )}
                  {item.regler && (
                    <SignButton handleClick={() => props.handleShowPayemnt(true)}>
                      Regler
                    </SignButton>
                  )}
                </Box>
              </Box>
            </ListItem>
          ))}
      </ListWrap>
    </ThemeProvider>
  );
};

export default AdvancedList;
