import { styled } from "@mui/system";

const HeaderTab = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
        marginBottom: '20px',
    }
}));
const TabItems = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
        order: 2,
        width: '100%',
    }
    
}));
const TabItem = styled('div')(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '19px',
    color: '#FFFFFF',
    opacity: 0.5,
    marginRight: 15,

    '&.hovered': {
        opacity: 1,
        color: theme.palette.secondary.main,
    },
}));
const Filter = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flex: 1,

    '.filterIcon': {
        color: '#fff',
        marginLeft: 15,
    },

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',

        '.filterIcon': {
            display: 'none'
        }
    }
}));

export {HeaderTab, TabItems, TabItem, Filter};