import {
  Autocomplete,
  Box,
  CircularProgress,
  Drawer,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import partnersPartner from "../../../store/apis/partnersApis";
import { etat_demande } from "../../../config/keys";
import { ValidationButton } from "../../../components/buttons";
import moment from "moment";

export default function FilterBenefits({
  toggleDrawer,
  open,
  setOpen,
  setFilterDetail,
  filterDetail,
}) {
  const [load, setLoad] = useState(true);
  const [partners, setPartners] = useState({});
  const [openPartner, setOpenPartner] = useState(false);
  const [openEtat, setOpenEtat] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEtat, setSelectedEtat] = useState(null);

  useEffect(() => {
    const listPartners = [];
    partnersPartner
      .listPartners(1)
      .then((res) => {
        for (let i = 0; i < res.length; i++) {
          const El = res[i];
          listPartners.push({
            value: El.id,
            label: El.nom + " " + El.prenom,
            id: El.id,
            libelle: El.nom + " " + El.prenom,
            description:
              "Email : " + El.email + "<br /> Telephone : " + El.telephone,
          });
        }
        setPartners(listPartners);
        setLoad(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleChangeEtat = (event, value) => {
    setSelectedEtat(value);
  };

  const handleChangePartner = (event, value) => {
    setSelected(value);
  };

  const reset = () => {
    setSelected("");
    setSelectedEtat("");
    setSelectedDate("");
    setFilterDetail("");
    setOpen(false);
  };

  const applyFilter = () => {
    let filterDetail = "";
    if (selected) filterDetail = filterDetail + "&prestataire=" + selected.id;
    if (selectedEtat)
      filterDetail = filterDetail + "&status=" + selectedEtat.id;
    if (selectedDate)
      filterDetail =
        filterDetail +
        "&client.calendar.dateDebut=" +
        moment.parseZone(selectedDate).format("YYYY-MM-DD");

    setFilterDetail(filterDetail);
    setOpen(false);
  };

  useEffect(() => {
    if (filterDetail === "") {
      setSelected("");
      setSelectedEtat("");
      setSelectedDate("");
    }
  }, [filterDetail]);

  return (
    <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
      <Box
        display={"flex"}
        alignItem={"center"}
        justifyContent={"space-between"}
        rowGap={2}
        flexWrap={"wrap"}
        width={"400px"}
        padding={"20px"}
      >
        <Box component="h5" margin={0}>
          Filtrer les prestations
        </Box>
        <Box flexBasis={{ xs: "100%", md: "100%" }}>
          <Box mt={0.75}>
            <Box>
              <Typography
                variant="caption"
                component={"label"}
                fontSize={{ xs: 16 }}
              >
                Selectionner une date
              </Typography>
              <input
                type="date"
                name="date-planification"
                id="date-planification"
                lang="fr_FR"
                locale="fr_FR"
                style={{
                  color: "#ddd",
                  fontSize: "24px",
                  border: "1px solid #0000003b",
                  borderRadius: "3px",
                  padding: "10px",
                  width: 300,
                }}
                value={selectedDate}
                onChange={handleChangeDate}
              />
            </Box>
          </Box>
          <Box mt={0.75}>
            <Box>
              <Typography
                variant="caption"
                component={"label"}
                fontSize={{ xs: 16 }}
              >
                Selectionner un état
              </Typography>
              <Autocomplete
                id="asynchronous-etat"
                sx={{
                  width: 300,
                }}
                open={openEtat}
                onOpen={() => {
                  setOpenEtat(true);
                }}
                onClose={() => {
                  setOpenEtat(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                getOptionLabel={(option) => option.label}
                options={etat_demande}
                defaultValue={selectedEtat ? selectedEtat : null}
                fullWidth
                PaperComponent={({ children }) => (
                  <Paper style={{ fontSize: 18, lineHeight: 1 }}>
                    {children}
                  </Paper>
                )}
                onChange={(event, newValue) => {
                  handleChangeEtat(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: "100%",
                      maxWidth: "inherit",
                      "& .MuiInputBase-root": {
                        height: "49px",

                        "&.focus": {
                          backgroundColor: "transparent",
                        },
                      },
                      "& .MuiAutocomplete-endAdornment": {
                        marginTop: "-10px",
                      },
                      "& .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
                        width: 32,
                        height: 32,
                      },
                    }}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: 18 },
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box mt={0.75}>
            <Box>
              <Typography
                variant="caption"
                component={"label"}
                fontSize={{ xs: 16 }}
              >
                Selectionner un partenaire
              </Typography>
              <Autocomplete
                id="asynchronous-partners"
                sx={{
                  width: 300,
                }}
                open={openPartner}
                onOpen={() => {
                  setOpenPartner(true);
                }}
                onClose={() => {
                  setOpenPartner(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                onChange={(event, newValue) => {
                  handleChangePartner(event, newValue);
                }}
                getOptionLabel={(option) => option.label}
                options={partners}
                loading={load}
                fullWidth
                defaultValue={selected ? selected : null}
                PaperComponent={({ children }) => (
                  <Paper style={{ fontSize: 18, lineHeight: 1 }}>
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: "100%",
                      maxWidth: "inherit",
                      "& .MuiInputBase-root": {
                        height: "49px",

                        "&.focus": {
                          backgroundColor: "transparent",
                        },
                      },
                      "& .MuiAutocomplete-endAdornment": {
                        marginTop: "-10px",
                      },
                      "& .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
                        width: 32,
                        height: 32,
                      },
                    }}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: 18 },
                      endAdornment: (
                        <React.Fragment>
                          {load ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2} p={2}>
        <Grid item xs={6}>
          <ValidationButton handleClick={reset} icon>
            Réinitialiser
          </ValidationButton>
        </Grid>
        <Grid item xs={6}>
          <ValidationButton handleClick={applyFilter}>Filter</ValidationButton>
        </Grid>
      </Grid>
    </Drawer>
  );
}
