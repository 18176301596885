import { useTheme } from "@emotion/react";
import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Container,
  Dialog,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useState } from "react";
import dots from "../../../../assets/images/dots.png";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmailCongrats = ({ email, setEmail }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      open={email}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          maxWidth: { sm: 650 },
          width: { md: 650 },
          height: { xs: 232 },
        },
      }}
    >
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton onClick={() => setEmail(false)}>
          <CloseRounded fontSize="large" />
        </IconButton>
      </Box>
      <Container maxWidth={"md"}>
        {/* <Box mt={3} mb={3}></Box> */}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          columnGap={4}
          position={"relative"}
        >
          <Box alignSelf={"flex-end"}>
            <img src={dots} />
          </Box>
          <Box>
            <Typography variant="h1" textAlign={"center"} mb={2}>
              Félicitations !
            </Typography>
            <Typography
              fontFamily={"Bauhaus"}
              textAlign={"center"}
              fontSize={{ xs: 16 }}
              fontWeight={700}
            >
              un nouveau mot de passe a été envoyé à votre e-mail, vérifiez-le
              maintenant
            </Typography>
          </Box>
          <Box alignSelf={"flex-end"}>
            <img src={dots} />
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default EmailCongrats;
