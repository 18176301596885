import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

const CustomButton = styled(Button)(({ theme }) => ({
  /* padding: theme.spacing(2, 0), */
  width: 143,
  cursor: "pointer",
  border: "1px solid",
  //backgroundImage:
  // "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
  backgroundColor: "rgba(14, 69, 74, 0.05)",
  borderImageSlice: 1,
  borderImageSource:
    "linear-gradient(180deg, #F4EB7C 0%, rgba(244, 235, 124, 0) 100%)",
  clipPath:
    "polygon(10% 0, 80% 0%, 100% 0, 100% 70%, 90% 100%, 20% 100%, 0 100%, 0 30%)",
}));

export default function SignButton({ handleClick, children }) {
  return (
    <CustomButton onClick={handleClick} color={"inherit"}>
      <Typography
        fontSize={{ xs: 16 }}
        fontFamily={"Bauhaus"}
        fontWeight={700}
        textTransform={"capitalize"}
      >
        {children}
      </Typography>
    </CustomButton>
  );
}
