import { Box, Typography, Button, CircularProgress } from "@mui/material";
import {
  KeyboardBackspaceOutlined,
  EditOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import admin from "../../../assets/images/admin.png";
import Activities from "./Activities";
import adminsService from "../../../store/apis/adminsApis";
import { useEffect, useState } from "react";
import moment from "moment";

export default function ProfileAdmin({ id, goBack, setEdit, setRemove }) {
  const [data, setData] = useState(null);
  const [err, setErr] = useState(false);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(true);
    adminsService
      .detailAdmin(id)
      .then((res) => {
        setLoad(false);
        setData(res);
      })
      .catch((err) => {
        setLoad(false);
        setErr(err);
      });
  }, [id]);

  return (
    <>
      <Box sx={{ color: "#fff" }}>
        <Button
          startIcon={<KeyboardBackspaceOutlined />}
          variant={"text"}
          color={"primary"}
          sx={{ fontWeight: 700, textTransform: "capitalize" }}
          onClick={goBack}
        >
          Retour
        </Button>
        <Typography
          variant={"body2"}
          fontWeight={"700"}
          textTransform={"uppercase"}
          fontSize={{ md: 24 }}
          mt={2}
          mb={2}
        >
          Profil Admin
        </Typography>
        {load && (
          <>
            <CircularProgress
              color="inherit"
              width="27px"
              height="27px"
              sx={{
                width: "27px !important",
                height: "27px !important",
                display: "inline-block",
                verticalAlign: "bottom",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            />
          </>
        )}
        {data && (
          <Box
            display={"flex"}
            alignItems={{ xs: "center", md: "start" }}
            justifyContent={{ xs: "center", md: "start" }}
            gap={{ xs: 3, md: 6 }}
            flexWrap={"wrap"}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Box>
              <Typography
                variant={"h1"}
                fontSize={{ md: 36 }}
                fontWeight={700}
                mb={2}
              >
                {`${data.prenom} ${data.nom}`}
              </Typography>
              {/* <Box
                width={{ xs: 100, md: 142 }}
                height={{ xs: 100, md: 142 }}
                m={"0 auto"}
              >
                <img src={admin} alt="admin" height={"100%"} width={"100%"} />
              </Box> */}
            </Box>
            <Box width={320} mt={{ md: 6 }}>
              <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={128}
                  textAlign="right"
                  paddingRight={1}
                >
                  Adresse :
                </Typography>

                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={192}
                >
                  {data.adresse}
                </Typography>
              </Box>
              <Box display={"flex"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={128}
                  textAlign="right"
                  paddingRight={1}
                >
                  Tel :
                </Typography>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={192}
                >
                  {data.telephone}
                </Typography>
              </Box>
              <Box display={"flex"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  width={128}
                  textAlign="right"
                  paddingRight={1}
                  fontSize={{ sm: 14, md: 18 }}
                >
                  Email :
                </Typography>
                <Typography
                  variant="h2"
                  fontSize={{ sm: 14, md: 18 }}
                  width={192}
                  textTransform={"lowercase"}
                >
                  {data.email}
                </Typography>
              </Box>
              <Box display={"flex"} mb={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={128}
                  textAlign="right"
                  paddingRight={1}
                >
                  Admin depuis :
                </Typography>
                <Typography
                  variant="h2"
                  textTransform={"capitalize"}
                  fontSize={{ sm: 14, md: 18 }}
                  width={192}
                >
                  {
                      moment.parseZone(data.date_inscription).format("DD/MM/YYYY") +
                      " " +
                      moment.parseZone(data.date_inscription).format("HH:mm")
                    }
                </Typography>
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={"end"}
              justifyContent={{ xs: "center", md: "end" }}
              flexDirection={{ md: "column" }}
              gap={{ xs: 2, md: 0 }}
              flexGrow={{ md: 2 }}
              mt={{ md: 6 }}
            >
              <Button
                startIcon={<EditOutlined />}
                variant={"text"}
                sx={{
                  fontWeight: { xs: 700 },
                  fontSize: { xs: 16 },
                  fontFamily: { xs: "Bauhaus" },
                  textTransform: "capitalize",
                }}
                color="inherit"
                onClick={() => setEdit(true)}
              >
                Modifier
              </Button>
              <Button
                startIcon={<DeleteOutline />}
                variant={"text"}
                sx={{
                  fontWeight: { xs: 700 },
                  fontSize: { xs: 16 },
                  fontFamily: { xs: "Bauhaus" },
                  textTransform: "capitalize",
                }}
                color="inherit"
                onClick={() => setRemove(true)}
              >
                Supprimer
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      {/* <Activities /> */}
    </>
  );
}
