import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import Slider from "react-slick";
import { ThemeProvider } from "@mui/system";
import theme from "../../../../assets/theme";
import { HdwBloc, SectionsHeading } from "../../../../components";
import classnames from "classnames";
import { BlackButton } from "../../../../components/buttons";
import { useNavigation } from "@react-navigation/native";
import icon1 from "../../../../assets/images/icones/2-1.png";
import icon2 from "../../../../assets/images/icones/2-2.png";
import icon3 from "../../../../assets/images/icones/2-3.png";
import icon4 from "../../../../assets/images/icones/2-4.png";
import icon5 from "../../../../assets/images/icones/2-5.png";

export default function ClockSlider() {
  const [sliderCircule, setSliderCircule] = useState(null);
  const [sliderCircule2, setSliderCircule2] = useState(null);
  const [activeSlide, setActiveSlide] = useState(1);

  const navigation = useNavigation();

  const settings1 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    dots: false,
    arrows: false,
    /* autoPlay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,*/
    // fade: true
  };
  const settings2 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    dots: true,
    arrows: false,
    /*autoPlay: true,
    autoplaySpeed: 2000,*/
    asNavFor: sliderCircule,
  };
  return (
    <ThemeProvider theme={theme}>
      <HdwBloc className="hdw-bloc">
        <Box className="container" id="sec2" sx={{ scrollMarginTop: 170 }}>
          <Container maxWidth="xl">
            <Box className="wrap">
              <SectionsHeading>comment ça marche ?</SectionsHeading>

              <Box
                className="adv-slider"
                sx={{ marginTop: { xs: "60px", lg: 0 } }}
              >
                <Box className="circle">
                  {/* <!-- Circle  --> */}
                  <Box className="slds">
                    <Box
                      onClick={() => {
                        setActiveSlide(1);
                        console.log(0);
                        sliderCircule2.slickGoTo(0);
                      }}
                      className={classnames({
                        "sld-item": true,
                        active: activeSlide == 1,
                      })}
                      data="1"
                    >
                      1
                    </Box>

                    <Box
                      onClick={() => {
                        setActiveSlide(2);
                        console.log(1);
                        sliderCircule2.slickGoTo(1);
                      }}
                      className={classnames({
                        "sld-item": true,
                        active: activeSlide == 2,
                      })}
                      data="2"
                    >
                      2
                    </Box>

                    <Box
                      onClick={() => {
                        setActiveSlide(3);
                        console.log(2);
                        sliderCircule2.slickGoTo(2);
                      }}
                      className={classnames({
                        "sld-item": true,
                        active: activeSlide == 3,
                      })}
                      data="3"
                    >
                      3
                    </Box>

                    <Box
                      onClick={() => {
                        setActiveSlide(4);
                        console.log(3);
                        sliderCircule2.slickGoTo(3);
                      }}
                      className={classnames({
                        "sld-item": true,
                        active: activeSlide == 4,
                      })}
                      data="4"
                    >
                      4
                    </Box>

                    <Box
                      onClick={() => {
                        setActiveSlide(5);
                        console.log(4);
                        sliderCircule2.slickGoTo(4);
                      }}
                      className={classnames({
                        "sld-item": true,
                        active: activeSlide == 5,
                      })}
                      data="5"
                    >
                      5
                    </Box>
                  </Box>
                  <Box className="clock-animation" data-active={activeSlide}>
                    <Box className="clock"></Box>
                    <Box className="ylw-circle"></Box>
                    <Box className="crl-arrow"></Box>
                    <Slider
                      className="circle-sldr"
                      {...settings1}
                      ref={(slider) => setSliderCircule(slider)}
                      beforeChange={(oldIndex, newIndex) =>
                        setActiveSlide(newIndex + 1)
                      }
                    >
                      <Box
                        className="itm"
                        fontWeight={"bold"}
                        fontSize={24}
                        lineHeight={"34px"}
                        textTransform="uppercase"
                        height={79}
                        display={"flex !important"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        1
                        {/* <img src={icon1} alt={"Demande de rendez-vous"} width={70} /> */}
                      </Box>

                      <Box
                        className="itm"
                        fontWeight={"bold"}
                        fontSize={24}
                        lineHeight={"34px"}
                        textTransform="uppercase"
                        height={79}
                        display={"flex !important"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        2
                        {/* <img
                          src={icon2}
                          alt={"Validation de la commande par téléphone"}
                          width={70}
                        /> */}
                      </Box>

                      <Box
                        className="itm"
                        fontWeight={"bold"}
                        fontSize={24}
                        lineHeight={"34px"}
                        textTransform="uppercase"
                        height={79}
                        display={"flex !important"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        3
                        {/* <img
                          src={icon3}
                          alt={"La mise en relation"}
                          width={70}
                        /> */}
                      </Box>

                      <Box
                        className="itm"
                        fontWeight={"bold"}
                        fontSize={24}
                        lineHeight={"34px"}
                        textTransform="uppercase"
                        height={79}
                        display={"flex !important"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        4
                        {/* <img src={icon4} alt={"L’intervention"} width={70} /> */}
                      </Box>

                      <Box
                        className="itm"
                        fontWeight={"bold"}
                        fontSize={24}
                        lineHeight={"34px"}
                        textTransform="uppercase"
                        height={79}
                        display={"flex !important"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        5
                        {/* <img src={icon5} alt={"La notation"} width={70} /> */}
                      </Box>
                    </Slider>
                  </Box>
                  {/* <!-- ./ Circle  --> */}
                </Box>

                <Box className="_cntsld">
                  <Slider
                    className="items"
                    {...settings2}
                    ref={(slider) => setSliderCircule2(slider)}
                  >
                    <Box className="item">
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: { xs: 30, md: 40 },
                          lineHeight: { xs: "40px", md: "56px" },
                        }}
                        textTransform="none"
                        component={"h4"}
                        className="h1"
                      >
                        Demande de rendez-vous
                      </Typography>
                      <Box
                        className="txt"
                        fontSize={{ xs: 18, md: 24 }}
                        lineHeight={{ xs: "26px", md: "34px" }}
                      >
                        J’ai une demande d’intervention ou de devis à faire : je
                        laisse mes coordonnées à travers le formulaire en
                        cochant la catégorie de l’intervention et en précisant
                        la date et l’heure souhaitées.<br></br>
                        <br></br>
                      </Box>
                    </Box>
                    <Box className="item">
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: { xs: 30, md: 40 },
                          lineHeight: { xs: "40px", md: "56px" },
                        }}
                        textTransform="none"
                        component={"h4"}
                        className="h1"
                      >
                        Validation de la commande par téléphone
                      </Typography>
                      <Box
                        className="txt"
                        fontSize={{ xs: 18, md: 24 }}
                        lineHeight={{ xs: "26px", md: "34px" }}
                      >
                        Un conseiller vous téléphone immédiatement après la
                        commande afin de confirmer la nature de votre problème,
                        son prix et les détails d’interventions. Après cette
                        confirmation téléphonique, la réservation est validée.
                      </Box>
                    </Box>
                    <Box className="item">
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: { xs: 30, md: 40 },
                          lineHeight: { xs: "40px", md: "56px" },
                        }}
                        textTransform="none"
                        component={"h4"}
                        className="h1"
                      >
                        La mise en relation
                      </Typography>
                      <Box
                        className="txt"
                        fontSize={{ xs: 18, md: 24 }}
                        lineHeight={{ xs: "26px", md: "34px" }}
                      >
                        Une fois la réservation validée et le rendez-vous
                        planifié, le professionnel vous contactera par téléphone
                        pour confirmer le rendez-vous. Il en profitera si besoin
                        pour approfondir le diagnostic.<br></br>
                        <br></br>
                      </Box>
                    </Box>
                    <Box className="item">
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: { xs: 30, md: 40 },
                          lineHeight: { xs: "40px", md: "56px" },
                        }}
                        textTransform="none"
                        component={"h4"}
                        className="h1"
                      >
                        L’intervention
                      </Typography>
                      <Box
                        className="txt"
                        fontSize={{ xs: 18, md: 24 }}
                        lineHeight={{ xs: "26px", md: "34px" }}
                      >
                        Le professionnel se déplace chez vous pour effectuer
                        l’intervention :<br></br>
                        Une fois l’intervention effectuée, vous réglez
                        directement la somme due.<br></br>
                        <br></br>
                      </Box>
                    </Box>
                    <Box className="item">
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: { xs: 30, md: 40 },
                          lineHeight: { xs: "40px", md: "56px" },
                        }}
                        textTransform="none"
                        component={"h4"}
                        className="h1"
                      >
                        La notation
                      </Typography>
                      <Box
                        className="txt"
                        fontSize={{ xs: 18, md: 24 }}
                        lineHeight={{ xs: "26px", md: "34px" }}
                      >
                        Par la suite, vous aurez la possibilité de noter la
                        prestation du professionnel et laisser vos commentaires.
                        <br></br>
                        <br></br>
                      </Box>
                    </Box>
                  </Slider>

                  <Box
                    textAlign={"right"}
                    sx={{ margin: { xs: "0 15px", lg: 0 } }}
                  >
                    <BlackButton
                      textRainbow
                      handleClick={() => navigation.navigate("Register")}
                    >
                      prendre un rendez-vous
                    </BlackButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </HdwBloc>
    </ThemeProvider>
  );
}
