import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import Select from "react-select";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import { EmailInput, TextInput } from "../../../components/inputs";
import dots from "../../../assets/images/dots.png";
import adminsService from "../../../store/apis/adminsApis";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} unmountOnExit />;
});

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const schema = yup.object({
  nom: yup.string("entrer un nom"),
  prenom: yup.string("entrer un prenom"),
  email: yup
    .string()
    .email("entrer un email valide")
    .required("email est requis"),
  telephone: yup
    .string()
    .required("telephone est requis")
    .matches(phoneRegExp, "entrez un numéro de téléphone valide"),
});

const roles = [
  { value: "ROLE_ADMIN", label: "Accès admin" },
  { value: "ROLE_FACTURATION", label: "Accès facturation" },
  { value: "ROLE_ACTIVITE", label: "Accès activité" },
];

const AdminForm = ({ dialog, onClose, setCongrat }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [globalErrors, setGlobalErrors] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    setLoading(true);
    setGlobalErrors(false);
    const { email, telephone, nom, prenom, role } = data;

    adminsService
      .addAdmin({ email, telephone, nom, prenom, roles: [role.value] }, true)
      .then((res) => {
        if (res?.response?.data?.code == 302) {
          setGlobalErrors(res?.response?.data?.message);
          throw new Error(res?.response?.data?.message);
        }
        if (res?.response?.status == 302) {
          setGlobalErrors(res?.response?.data);
          throw new Error(res?.response?.data);
        }
        onClose();
        setLoading(false);
        setCongrat(true);
      })
      .catch((err) => {
        setGlobalErrors(err.message);
        setLoading(false);
      });
  };
  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { maxWidth: { md: 560, lg: 760 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            ajouter un administrateur
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          columnGap={4}
        >
          <Box alignSelf={"flex-end"}>
            <img src={dots} />
          </Box>
          <Box>
            <Typography
              fontFamily={"Bauhaus"}
              fontSize={{ xs: 16 }}
              fontWeight={700}
              gutterBottom
            >
              Veillez renseigner l’adresse mail et le numéro de téléphone du
              profil que vous souhaitez ajouter.{" "}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                rowGap={2}
                flexWrap={"wrap"}
              >
                <Box flex={{ xs: "0 1 100%" }}>
                  <Controller
                    name={"role"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        styles={customStyles}
                        placeholder={"Sélectionner le rôle"}
                        options={roles}
                      />
                    )}
                  />
                  {errors.role && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.role.message}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%" }}>
                  <TextInput
                    placeholder={"Nom**"}
                    register={{ ...register("nom") }}
                  />
                  {errors.nom && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.nom.message}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%" }}>
                  <TextInput
                    placeholder={"Prenom**"}
                    register={{ ...register("prenom") }}
                  />
                  {errors.prenom && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.prenom.message}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%" }}>
                  <EmailInput register={{ ...register("email") }} />
                  {errors.email && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.email.message}
                    </Typography>
                  )}
                  {globalErrors && (
                    <Typography
                      fontSize={{
                        xs: 14,
                        margin: "-15px 0 15px",
                        textAlign: "center",
                      }}
                      color="red"
                    >
                      {globalErrors}
                    </Typography>
                  )}
                </Box>
                <Box flex={{ xs: "0 1 100%" }}>
                  <TextInput
                    placeholder={"numéro de téléphone**"}
                    type={"tel"}
                    register={{ ...register("telephone") }}
                  />
                  {errors.telephone && (
                    <Typography fontSize={{ xs: 14 }} color="red">
                      {errors.telephone.message}
                    </Typography>
                  )}
                </Box>
              </Box>
            </form>
          </Box>
          <Box alignSelf={"flex-end"}>
            <img src={dots} />
          </Box>
        </Box>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton
                type={"yellow"}
                textColor={"white"}
                handleClick={handleSubmit(onSubmit)}
              >
                envoyer
              </CustomButton>
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default AdminForm;
