import { useTheme } from "@emotion/react";
import { CloseRounded, ErrorOutline } from "@mui/icons-material";
import {
  Box,
  Container,
  Dialog,
  IconButton,
  Slide,
  useMediaQuery,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { forwardRef, useState } from "react";
import signinBg from "../../../../assets/images/signin_bg.png";
import CustomButton from "../../../../components/buttons/CustomButton";
import { RegisterHeading } from "../../../../components/headings";
import { EmailInput, TextInput } from "../../../../components/inputs";
import TextAreaInput from "../../../../components/inputs/TextAreaInput";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import contactService from "../../../../store/apis/contactApi";

const schema = yup
  .object({
    prenom: yup.string().required("Le prénom est requis"),
    nom: yup.string().required("Le nom est requis"),
    email: yup
      .string()
      .email("entrer un email valide")
      .required("L'email est requis"),
    sujet: yup.string().required("L'objet est requis"),
    message: yup.string().required("Le message est requis"),
  })
  .required();

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} unmountOnExit />;
});

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
  height: "100%",
  backgroundColor: "#fff",
  backgroundImage: `url(${signinBg})`,
  backgroundSize: "100%",
  backgroundPosition: "bottom center",
  padding: theme.spacing(4, 1),
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("md")]: {
    backgroundImage: "none",
    padding: theme.spacing(2, 0),
  },
}));

export default function ContactSection({ contact, handleContact, setCongrat }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const onSubmit = (data) => {
    setIsFetching(true);
    setError(null);
    contactService
      .userContact(data)
      .then((res) => {
        setIsFetching(false);
        handleContact();
        setCongrat(true);
      })
      .catch((err) => {
        setIsFetching(false);
        setError(err.response.data.message);
      });
  };
  return (
    <Dialog
      open={contact}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{
        sx: {
          width: { md: 690 },
          borderRadius: { md: 4 },
        }
      }}
      onClose={handleContact}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: theme.palette.background.backdropModal,
        },
      }}
    >
      <BoxContainer>
        <Container maxWidth={isMd ? "sm" : "xs"}>
          <Box display={"flex"} flexDirection={{ xs: "column" }}>
            <Box component={"span"} alignSelf={{ xs: "center", md: "end" }}>
              <IconButton
                aria-label="close"
                size="large"
                onClick={handleContact}
                sx={{
                  "& .closeIcon": {
                    fontSize: "30px",
                    color: "black",
                    transition: "all 0.5s ease-in-out"
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.closeButton,
                    "& .closeIcon": {
                      transform: "rotate(-90deg)"
                    }
                  }
                }}
              >
                <CloseRounded fontSize="large" className="closeIcon" />
              </IconButton>
            </Box>
            <Box mb={2} mt={{ md: -5 }}>
              <RegisterHeading>Contact</RegisterHeading>
            </Box>
          </Box>
          <Box paddingLeft={{ md: 5 }} paddingRight={{ md: 5 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box>
                  <TextInput
                    placeholder={"Prénom*"}
                    register={{ ...register("prenom") }}
                    // disable={isFetching || sent}
                  />
                  {errors.prenom && (
                    <Typography fontSize={{ xs: 14 }} lineHeight={{xs: "20px"}} color="red">
                      {errors.prenom.message}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <TextInput
                    placeholder={"nom*"}
                    register={{ ...register("nom") }}
                    // disable={isFetching || sent}
                  />
                  {errors.nom && (
                    <Typography fontSize={{ xs: 14 }} lineHeight={{xs: "20px"}} color="red">
                      {errors.nom.message}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <EmailInput
                    placeholder={"email*"}
                    register={{ ...register("email") }}
                    // disable={isFetching || sent}
                  />
                  {errors.email && (
                    <Typography fontSize={{ xs: 14 }} lineHeight={{xs: "20px"}} color="red">
                      {errors.email.message}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <TextInput
                    placeholder={"objet*"}
                    register={{ ...register("sujet") }}
                    // disable={isFetching || sent}
                  />
                  {errors.sujet && (
                    <Typography fontSize={{ xs: 14 }} lineHeight={{xs: "20px"}} color="red">
                      {errors.sujet.message}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <TextAreaInput
                    placeholder={"Votre message*"}
                    register={{ ...register("message") }}
                    // disable={isFetching || sent}
                  />
                  {errors.message && (
                    <Typography fontSize={{ xs: 14 }} lineHeight={{xs: "20px"}} color="red">
                      {errors.message.message}
                    </Typography>
                  )}
                </Box>
              </Box>
            </form>
          </Box>

          {error && (
            <Typography fontSize={{ xs: 18 }} lineHeight={{xs: "20px"}} color="red">
              {error}
            </Typography>
          )}
          <Box
            display={"flex"}
            mb={4}
            mt={6}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {!isFetching && (
              <CustomButton handleClick={handleSubmit(onSubmit)}>
                envoyer
              </CustomButton>
            )}
            {isFetching && <CircularProgress />}
          </Box>
        </Container>
      </BoxContainer>
    </Dialog>
  );
}
