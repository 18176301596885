import theme from '../../assets/theme'
import { styled } from '@mui/system'
import bgimg from '../../assets/images/background-top.png'
import clockCircle from '../../assets/images/clock-circle.png'
import Circle from '../../assets/images/circle.png'
import circleArrow from '../../assets/images/circle-arrow.png'

const HdwBloc = styled('div')(({theme}) => ({
    paddingBottom: '100px',
    paddingTop: '50px',
    backgroundImage: 'url('+bgimg+')',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',


    '.h2': {
        marginBottom: '60px',
    },
    '.adv-slider': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '.circle': {
            width: '100%',
            maxWidth: '490px',
            marginRight: '40px',
            position: 'relative',

            '.slds': {
                position: 'absolute',
                left: '50%',
                top: '50%',
                width: '60px',
                height: '60px',
                transform: 'translate(-50%, -50%)',
                zIndex: 100,

                '.sld-item': {
                    width: '60px',
                    height: '60px',
                    backgroundColor: '#1E6278',
                    borderRadius: '50%',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    textAlign: 'center',
                    lineHeight: '52px',
                    border: '4px solid ' + theme.palette.primary.main,
                    fontFamily: 'Brandon Grotesque',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    /*fontSize: '30px',*/
                    textTransform: 'uppercase',
                    color: '#000000',
                    fontSize: 0,
                    transition: 'all .3s ease-in-out',
                    cursor: 'pointer',

                    '&.active': {
                        fontSize: '38px',
                        backgroundColor: theme.palette.primary.main,
                        borderColor: '#1E6278',
                    },
                    '&:nth-child(1)': {
                        transform: 'rotate(-45deg) translate(260px, -5px) rotate(45deg) scale(.3)',
                        
                        '&.active': {
                            transform: 'rotate(-45deg) translate(280px, -5px) rotate(45deg)',
                        }
                    },
                    '&:nth-child(2)': {
                        transform: 'rotate(0deg) translate(260px, -5px) rotate(-0deg) scale(.3)',
                        
                        '&.active': {
                            transform: 'rotate(0deg) translate(280px, -5px) rotate(-0deg)',
                        }
                    },
                    '&:nth-child(3)': {
                        transform: 'rotate(45deg) translate(260px, -5px) rotate(-45deg) scale(.3)',
                        
                        '&.active': {
                            transform: 'rotate(45deg) translate(280px, -5px) rotate(-45deg)',
                        }
                    },
                    '&:nth-child(4)': {
                        transform: 'rotate(90deg) translate(260px, -5px) rotate(-90deg) scale(.3)',
                        
                        '&.active': {
                            transform: 'rotate(90deg) translate(280px, -5px) rotate(-90deg)',
                        }
                    },
                    '&:nth-child(5)': {
                        transform: 'rotate(135deg) translate(260px, -5px) rotate(-135deg) scale(.3)',
                        
                        '&.active': {
                            transform: 'rotate(135deg) translate(280px, -5px) rotate(-135deg)',
                        }
                    },
                    '&:nth-child(6)': {
                        transform: 'rotate(225deg) translate(260px, -5px) rotate(-225deg) scale(.3)',
                        
                        '&.active': {
                            transform: 'rotate(225deg) translate(280px, -5px) rotate(-225deg)',
                        }
                    },
                    '&:nth-child(7)': {
                        transform: 'rotate(270deg) translate(260px, -5px) rotate(-270deg) scale(.3)',
                        
                        '&.active': {
                            transform: 'rotate(270deg) translate(280px, -5px) rotate(-270deg)',
                        }
                    },
                    '&:nth-child(8)': {
                        transform: 'rotate(315deg) translate(260px, -5px) rotate(-315deg) scale(.3)',
                        
                        '&.active': {
                            transform: 'rotate(315deg) translate(280px, -5px) rotate(-315deg)',
                        }
                    }
                }
            },
            '.clock-animation': {
                width: '100%',
                paddingTop: '100%',
                position: 'relative',

                '&[data-active="1"]': {
                    '.clock' :{
                        transform: 'rotate(0deg)',
                    },
                    '.ylw-circle': {
                        transform: 'translate(-50%, -50%) rotate(85deg)',
                    },
                    '.crl-arrow': {
                        transform: 'translate(-50%, -50%) rotate(-90deg)',
                    }
                },
                '&[data-active="2"]': {
                    '.clock': {
                        transform: 'rotate(-45deg)',
                    },
                    '.ylw-circle': {
                        transform: 'translate(-50%, -50%) rotate(86deg)',
                    },
                    '.crl-arrow': {
                        transform: 'translate(-50%, -50%) rotate(-45deg)',
                    }
                },
                '&[data-active="3"]': {
                    '.clock': {
                        transform: 'rotate(45deg)',
                    },
                    '.ylw-circle': {
                        transform: 'translate(-50%, -50%) rotate(0deg)',
                    },
                    '.crl-arrow': {
                        transform: 'translate(-50%, -50%) rotate(0deg)',
                    }
                },
                '&[data-active="4"]': {
                    '.clock': {
                        transform: 'rotate(-90deg)',
                    },
                    '.ylw-circle': {
                        transform: 'translate(-50%, -50%) rotate(45deg)',
                    },
                    '.crl-arrow': {
                        transform: 'translate(-50%, -50%) rotate(45deg)',
                    }
                },
                '&[data-active="5"]': {
                    '.clock': {
                        transform: 'rotate(90deg)',
                    },
                    '.ylw-circle': {
                        transform: 'translate(-50%, -50%) rotate(90deg)',
                    },
                    '.crl-arrow': {
                        transform: 'translate(-50%, -50%) rotate(90deg)',
                    }
                },
                '.clock': {   
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: 'url('+clockCircle+')',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    transition: 'all .5s ease-in-out',
                    
                },
                '.ylw-circle': {
                    backgroundImage: 'url('+ Circle +')',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    width: '60%',
                    height: '60%',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    transition: 'all .5s ease-in-out',
                },
                '.crl-arrow': {
                    backgroundImage: 'url('+ circleArrow +')',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    width: '60%',
                    height: '60%',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    transition: 'all .5s ease-in-out',
                },
                '.circle-sldr': {
                    width: 'auto',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                }
            }
        },
        '._cntsld': {
            paddingLeft: '60px',  
            /*paddingRight: '100px',*/
            flex: 1,
            width: 'calc(100% - 490px)',

            '.items': {
                '.txt': {
                    textAlign: 'left',
                },
                '.slick-dots': {
                    bottom: '-135px',
                    display: 'flex !important',
                    position: 'relative',
                    backgroundColor: 'rgba(65, 200, 241, 0.2)',
                    height: 5,

                    'li': {
                        flex: 1,
                        width: 'auto',
                        height: 'auto',
                        position: 'relative',
                        transition: 'all .3s ease-in-out',
                        margin: 0,

                        // '&:first-child': {
                        //     'button': {
                        //         margin: '0 0 0 auto',
                        //     }
                        // },
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        },
                        '&.slick-active': {
                            'button': {
                                backgroundColor: theme.palette.text.primary,
                                width: '100%',

                            }
                        },
                        'button': {
                            height: 4,
                            width: '0%',
                            transition: 'all .3s ease-in-out',
                            padding: 0,  
                            
                            '&:after, &:before': {
                                display: 'none',
                            }
                        }
                    }
                
                }
            },

            '.button': {
                margin: '30px 0 0 auto',
            }
        }
    },

    [theme.breakpoints.down('lg')]: {
        '.adv-slider': {
            '.circle': {
                width: '360px',

                'svg': {
                    width: '440px',
                },
                '.slds': {
                    '.sld-item': {
                        '&:nth-child(1)': {
                            transform: 'rotate(-45deg) translate(200px, -5px) rotate(45deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(-45deg) translate(220px, -5px) rotate(45deg)',
                            }
                        },
                        '&:nth-child(2)': {
                            transform: 'rotate(0deg) translate(200px, -5px) rotate(-0deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(0deg) translate(220px, -5px) rotate(-0deg)',
                            }
                        },
                        '&:nth-child(3)': {
                            transform: 'rotate(45deg) translate(200px, -5px) rotate(-45deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(45deg) translate(220px, -5px) rotate(-45deg)',
                            }
                        },
                        '&:nth-child(4)': {
                            transform: 'rotate(90deg) translate(200px, -5px) rotate(-90deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(90deg) translate(220px, -5px) rotate(-90deg)',
                            }
                        },
                        '&:nth-child(5)': {
                            transform: 'rotate(135deg) translate(200px, -5px) rotate(-135deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(135deg) translate(220px, -5px) rotate(-135deg)',
                            }
                        },
                        '&:nth-child(6)': {
                            transform: 'rotate(225deg) translate(200px, -5px) rotate(-225deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(225deg) translate(220px, -5px) rotate(-225deg)',
                            }
                        },
                        '&:nth-child(7)': {
                            transform: 'rotate(270deg) translate(200px, -5px) rotate(-270deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(270deg) translate(220px, -5px) rotate(-270deg)',
                            }
                        },
                        '&:nth-child(8)': {
                            transform: 'rotate(315deg) translate(200px, -5px) rotate(-315deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(315deg) translate(220px, -5px) rotate(-315deg)',
                            }
                        }
                    }
                }
            },
            '._cntsld': {
                padding: '0 20px',
                width: 'calc(100% - 360px)',

                '.button': {
                    marginTop: '20px',
                },
                '.items': {
                    '.slick-dots': {
                        bottom: '-110px',
                    }
                }
            }
        }
    },
    [theme.breakpoints.down('md')]: {
        '.adv-slider': {
            '.circle': {
                width: '280px',

                '.slds': {
                    width: '45px',
                    height: '45px',

                    '.sld-item': {
                        width: '45px',
                        height: '45px',
                        lineHeight: '37px',

                        '&.active': {
                            fontSize: '29px',
                            backgroundColor: theme.palette.primary.main,
                            borderColor: '#1E6278',
                        },
                        '&:nth-child(1)': {
                            transform: 'rotate(-45deg) translate(150px, 0px) rotate(45deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(-45deg) translate(170px, 0px) rotate(45deg)',
                            }
                        },
                        '&:nth-child(2)': {
                            transform: 'rotate(0deg) translate(150px, 0px) rotate(-0deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(0deg) translate(170px, 0px) rotate(-0deg)',
                            }
                        },
                        '&:nth-child(3)': {
                            transform: 'rotate(45deg) translate(150px, 0px) rotate(-45deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(45deg) translate(170px, 0px) rotate(-45deg)',
                            }
                        },
                        '&:nth-child(4)': {
                            transform: 'rotate(90deg) translate(150px, 0px) rotate(-90deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(90deg) translate(170px, 0px) rotate(-90deg)',
                            }
                        },
                        '&:nth-child(5)': {
                            transform: 'rotate(135deg) translate(150px, 0px) rotate(-135deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(135deg) translate(170px, 0px) rotate(-135deg)',
                            }
                        },
                        '&:nth-child(6)': {
                            transform: 'rotate(225deg) translate(150px, 0px) rotate(-225deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(225deg) translate(170px, 0px) rotate(-225deg)',
                            }
                        },
                        '&:nth-child(7)': {
                            transform: 'rotate(270deg) translate(150px, 0px) rotate(-270deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(270deg) translate(170px, 0px) rotate(-270deg)',
                            }
                        },
                        '&:nth-child(8)': {
                            transform: 'rotate(315deg) translate(150px, 0px) rotate(-315deg) scale(.3)',
                            
                            '&.active': {
                                transform: 'rotate(315deg) translate(170px, 0px) rotate(-315deg)',
                            }
                        }
                    }
                },
                'svg': {
                    width: '300px',
                }
            }    
        }
    },
   [theme.breakpoints.down('sm')]: {
        '.adv-slider': {
            flexDirection: 'column',

            '.circle': {
                marginRight: 0,

                'svg': {
                    width: '100%',
                    height: 'auto',
                },
                '.clock-animation': {
                    display: 'none',
                },
                '.slds': {
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',

                    '.sld-item': {
                        transform: 'none !important',
                        position: 'relative',
                        backgroundColor: '#1E6278',
                        borderColor: '#1E6278',
                        color: '#fff',
                        fontSize: '29px',

                        '&.active': {
                            fontSize: '29px',
                            borderColor: theme.palette.text.primary,
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.text.primary,
                        }
                    },

                    '&:after': {
                        content: "''",
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        width: '100%',
                        height: '1px',
                        zIndex: '-1',
                        backgroundImage: 'linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)',
                        backgroundPosition: 'bottom',
                        backgroundSize: '3px 1px',
                        backgroundRepeat: 'repeat-x',
                    }
                }
            },
            '._cntsld': {
                width:'100%',
                padding: 0,
                
                '.items': {
                    '.item':{
                        padding: '0 15px',
                    }
                }
            }
        }
    }
}));


export default HdwBloc;