export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return {};
  }
}

export function authHeaderDataForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    return {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    };
  } else {
    return {};
  }
}

export function authHeaderExport() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    return {
      "Content-Type": "blob",
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: "Bearer " + user.token,
    };
  } else {
    return {};
  }
}
