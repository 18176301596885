import { Box, styled } from "@mui/system";

const InfoBullWrap = styled('div')(({theme, image}) => ({
    position: 'relative',
    '.css-text-901oao' : {
        fontFamily: theme.typography.fontFamilyBauhaus,
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '19px',
        textTransform: 'capitalize',
        color: '#fff',
        textAlign: 'center',
    }
}));
const Text = styled('div')(({theme, image}) => ({
    position: 'relative',
    transition: 'all .25s',
    
    '&:hover ': {
        color: theme.palette.secondary.main,
        '.infobull': {
            opacity: 1,
            visibility: 'visible',
        }
    }
}));
const Link = styled('div')(({theme, image}) => ({
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '19px',
    color: '#fff',
    textAlign: 'center',
    marginTop: '6px',
}));
const Span = styled('div')(({theme, image}) => ({
    position: 'absolute',
    left: '50%',
    top: 'calc(100% + 6px)',
    transform: 'translateX(-50%)',
    backgroundColor: '#fff',
    padding: '4px 15px',
    borderRadius: 5,
    minWidth: 130,
    opacity: 0,
    visibility: 'hidden',
    transition: 'all .25s',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#000000',
    zIndex: 100,

    '&:after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        bottom: '100%',
        transform: 'translateX(-50%)',
        border: '6px solid transparent',
        borderBottomColor: '#fff',
    }
}));

export default function InfoBull(props) {
  return (
    <InfoBullWrap display={'flex'} alignItems="center" className="userfigure">
        <Text >{props.datetime} <Span className="infobull">{props.infobulltext}</Span></Text>
        <Box position="relative">
            <Link>{props.linktext}</Link>
            
        </Box>
    </InfoBullWrap>
  )
}
