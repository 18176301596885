import { useState } from "react";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { CustomCalendar } from "../../../../components";
import CalendarService from "../../../../store/apis/CalendarApi";
import { rdv_type_prestation } from "../../../../config/keys";

const CalendarWrap = styled("div")(({ theme }) => ({
  border: "1px solid #464646",
  borderRadius: 20,
  padding: "34px 44px",

  h2: {
    textTransform: "none",
  },
  ".css-text-901oao, .p": {
    marginTop: 20,
    fontFamily: theme.typography.fontFamilyBauhaus,
    fontSize: 18,
    lineHeight: "21px",
    display: "block",
  },

  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    ".text-left-md": {
      textAlign: "left",
    },
  },
  [theme.breakpoints.down("sm")]: {
    border: "none",
    padding: 0,

    h2: {
      display: "none",
    },
    ".p": {
      display: "none",
    },
  },
}));

export default function MyCalendar({ client, presta, handleDateIntervention }) {
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);

  const handlHourSelected = (date, hour) => {
    setLoading(true);
    setSelectedDate(date + "T" + hour + ":00.120Z");

    const obj = {
      user: `/api/users/${client.id}`,
      dateDebut: new Date(date + "T" + hour + ":00.120Z").toISOString(),
      dateFin: new Date(
        new Date(date + "T" + hour + ":00.120Z").getTime() + 30 * 60000
      ).toISOString(),
      titre: "Rdv",
      type: rdv_type_prestation,
      status: true,
    };

    CalendarService.addCalendar(obj)
      .then((res) => {
        console.log(res);
        setLoading(false);
        handleDateIntervention();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    /* let dataDocument = {
      prestation: "/api/demandes/" + presta?.id,
      type: document_type_fiche_intervention,
    };
    PrestationService.addDocument(dataDocument).then((response) => {
      //setLoading(false);
      console.log(response);
    });

    let data = {
      etape: "3",
    };

    PrestationService.editPrestation(presta?.id, data)
      .then((response) => {
        setLoading(false);
        console.log(response);
        handleDateIntervention();
      })
      .catch((err) => console.log(err)); */

    return;
  };

  return (
    <CalendarWrap>
      <Typography variant="h2">
        Nous vous remercions pour votre validation du devis !
      </Typography>
      <Typography component="p" className="p">
        C’est parti pour la prochaine étape, prevenez-nous votre disponibilité
        pour le rendez-vous pour effectuer la prestation !
      </Typography>
      <Typography component="p" className="p text-left-md">
        Choissisez un des créneaux disponibles ci-dessous :{" "}
      </Typography>

      <CustomCalendar loading={loading} onHourSelected={handlHourSelected} />
    </CalendarWrap>
  );
}
