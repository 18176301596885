import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useState } from "react";
import dots from "../../../assets/images/dots.png";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RemoveCongrat = ({ removeCongrat, onClose }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      open={removeCongrat}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          maxWidth: { sm: 650 },
          width: { sm: 650 },
          height: { xs: 232, md: 385 },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        {/* <Box mt={3} mb={3}></Box> */}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          columnGap={4}
        >
          <Box alignSelf={"flex-end"}>
            <img src={dots} />
          </Box>
          <Box flexGrow={2}>
            <Typography variant="h1" textAlign={"center"} mb={2}>
              Félicitations !
            </Typography>
            <Typography
              fontFamily={"Bauhaus"}
              textAlign={"center"}
              fontSize={{ xs: 16 }}
              fontWeight={700}
            >
              Le profil a bien été supprime
            </Typography>
          </Box>
          <Box alignSelf={"flex-end"}>
            <img src={dots} />
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default RemoveCongrat;
