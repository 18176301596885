import React from 'react'
import { Box, Typography } from "@mui/material";
import logo from '../../../assets/images/logos/light_logo_lg.png';
import { styled, ThemeProvider } from '@mui/system';
import theme from '../../../assets/theme';
import styles from '../../../assets/css/style.js';
import { StyleSheet } from 'react-native';
import signature from '../../../assets/images/signature.png';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextInput } from 'react-native-web';
import GoBack from '../Tools/BoBack';

const PdfWrap = styled('div')(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 390,
    fontSize: 14,
    lineHeight: '20px',
    color: '#fff',
    maxWidth: 725, 
    border: '1px solid #03EBFF',
    backgroundColor: 'rgba(0, 0, 0, .69)',
    padding: '20px 30px',

    '.paddingRight40' : {
        paddingRight: 40,
    },      
    '.infos': {
        maxWidth: 'max-content',
        margin: '0 0 0 auto',
    },
    '.fz18' : {
        fontSize: 18,
    },
    '.details': {
        margin: '30px 0 20px',

        '.dt1': {
            width: '56%',
        },
        '.dt2': {
            width: '44%',
        },
    },
    '.h2': {
        marginBottom: 10,
    },
    '.prodlist': {
        borderSpacing: 0,

        'tr': {
            'td': {
                padding: 6,

                '&:not(:nth-of-type(2))': {
                    textAlign: 'center',
                }
            },
            '&:first-of-type': {
                fontSize: 16,
                lineHeight: '23px',
            },
            '&:not(:first-of-type)': {
               border: '1px solid #03EBFF',
               'td': {
                   border: '1px solid #03EBFF',
                   verticalAlign: 'top',
               }
           } 
        }
    },
    '.paiment': {
        margin: '0 -30px',
        padding: '10px 30px 30px',
        borderBottom: '1px solid #03EBFF',
    },
    '.signature': {
        padding: '10px 0',
        'img': {
            display: 'block',
            margin: 'auto',
        }
    },
    '.textBfrBtns': {
        display: 'flex',
        marginBottom: 20,
        '> div:first-of-type': {
            flex: 1,
        },
        '> div:last-child': {
            width: 250,
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: '0 -20px',
            width: 'auto',

            '> div:last-child': {
                display: 'none',
            },
        }
    },
    '._btssp': {
        marginTop: '40px',
        display: 'flex',
        justifyContent: 'space-between',

        '> div:first-of-type': {
            width: 250,
        },
        '> div:last-child': {
            width: 250,
        },
        '._blcsntr': {
            minHeight: '120px',
            marginTop: '10px',
            display: 'flex',
            border: '2px dashed #04ebff',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 18,
            lineHeight: '22px',
            fontFamily: theme.typography.fontFamilyBauhaus,
            color: '#04ebff',

            '&.op15': {
                opacity: .15,
            }
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: '10px -20px 0',
            width: 'auto',

            '>div:last-child': {
                width: '100%',
            },
            '._blcsntr': {
                minHeight: 100,
                margin: '13px 0 0 0',
            },
        },
    },
    '.btn': {
        height: 44,
        fontSize: 18,
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #03EBFF',

        '&.style1': {
            backgroundImage: 'linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)',
            textTransform: 'uppercase',
            borderWidth: 2,
            marginBottom: 10,
        }
    },
    '.txt':{
        fontSize: 12,
        lineHeight: '17px',
        margin: '20px 0',
    } ,
    '.ftr': {
        margin: 'auto',
        maxWidth: 270,
        fontSize: 9,
        lineHeight: '13px',
        textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
        '.fz18': {
            fontSize: 14,
        },
        '.head': {
            '> tbody > tr':{
                display: 'flex',
                flexDirection: 'column',

                'img': {
                    width: 105,
                }
            }
        },
        '.infos': {
            margin: 0,
            marginTop: '15px',

            'td': {
                textAlign: 'left',

                '&:last-child': {
                    textAlign: 'center',
                }
            }
        },
        '.paiment': {
            padding: '10px 10px 10px',

            '> table > tbody > tr':{
                display: 'flex',
                flexDirection: 'column-reverse',

                '> td': {
                    marginBottom: 15,
                    
                    '&:last-child': {
                        maxWidth: 170,
                        width: '100%',
                        margin: '0 0 0 auto',
                    
                    },
                    'table td:first-of-type': {
                        whiteSpace: 'nowrap',
                        verticalAlign: 'text-bottom',
                    },
                    'table td': {
                        paddingTop: 4,
                    }
                }
            },
        },
        '.details': {
            marginBottom: 5,

            '> tbody > tr':{
                display: 'flex',
                flexDirection: 'column',

                '> td': {
                    marginBottom: 15,
                }
            },
            '.dt1, .dt2': {
                width: '100%',

                'td': {
                    width: '50%',
                }
            }
        },
        '.prodlist ': {
            margin: '15px -31px 0',
            width: 'calc(100% + 62px)',

            'tr': {
                '&:first-of-type': {
                    fontSize: 12,
                }
            },
            '.tgl': {
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',

                '&:before': {
                    content: 'attr(data-label-sm)',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontFamily: theme.typography.fontFamilyBauhaus,
                    color: '#000',
                },
                '&:after': {
                    content: '""',
                    border: '6px solid transparent',
                    borderTopColor: theme.palette.primary.main,
                    borderBottom: 'none',
                    marginLeft: 6,
                },
                '> div': {
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    width: 200,
                    backgroundColor: '#fff',
                    padding: 4,
                    zIndex: 100,
                    border: '1px solid #ccc',
                    borderRadius: 4,
                    opacity: 0,
                    visibility: 'hidden',
                },
                '&:hover': {
                    '> div': {
                        opacity: 1,
                        visibility: 'visible',
                    }
                }
            }
        },
        '.signature': {
            margin: '0 -20px',
            width: 'auto',

            '> tbody > tr':{
                display: 'flex',
                flexWrap: 'wrap',

                'td': {
                    width: '50%',
                    textAlign: 'center',

                    '&:first-of-type': {
                        width: '100%',
                        marginBottom: 15,
                        textAlign: 'left',
                    }
                }
            }
        },
        '.txt': {
            margin: '20px -20px',
        }
    }
}));
const Table = styled('table')(({ theme }) => ({
    width: '100%',
    '.b' : {
        fontWeight: 'bold',
    },
    '.ffbauhaus' : {
        fontFamily: theme.typography.fontFamilyBauhaus,
    },
}));
const Tbody = styled('tbody')(({ theme }) => ({}));
const Tr = styled('tr')(({ theme, color }) => ({color: color ? color : 'inherit',}));
const Td = styled('td')(({ theme }) => ({}));
const Img = styled('img')(({ theme }) => ({}));
const Link = styled('a')(({ theme }) => ({}));
const Strong = styled('strong')(({ theme }) => ({}));
const Card = styled('div')(({ theme, color, minHeight, borderColor }) => ({
    color: color ? color : 'inherit',
    '&.card': {
        border: '1px solid rgba(62, 195, 236, 0.6)',
        borderColor: borderColor ? borderColor : 'rgba(62, 195, 236, 0.6)',
        padding: '10px 25px',
        minHeight: minHeight ? minHeight : 126,
        
    },
    [theme.breakpoints.down('sm')]: {
        '&.card': {
            padding: '10px 16px',
        }
    }
}));
const CRTBtn = styled('div')(({ theme }) => ({
    height: 60,
    position: 'relative',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamilyBauhaus,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '18px',
    textAlign: 'center',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    background: 'radial-gradient(circle at center, rgba(3, 235, 255, .31) 0%, transparent 88%, transparent 0%)',

    '.before': {
        width: 15,
        height: 1,
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        bottom: 0,
        right: 0,
        transformOrigin: 'center',
        transform: 'translate(2px, -5px) rotate(-45deg)',
    },
    '.after': {
        width: 15,
        height: 1,
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        left: 0,
        top: 0,
        transformOrigin: 'center',
        transform: 'translate(-2px, 5px) rotate(-45deg)',
    },
    '&:after': {
        content: "''",
        position: 'absolute',
        right: 0,
        top: 0,
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        borderTop: '1px solid '+theme.palette.primary.main,
        borderRight: '1px solid '+theme.palette.primary.main,
    },
    '&:before': {
        content: "''",
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        borderBottom: '1px solid '+theme.palette.primary.main,
        borderLeft: '1px solid '+theme.palette.primary.main,
    },
}));

export default function PDFBon({goBack}) {
  return (
    <ThemeProvider theme={theme}>
        <Box display={'flex'} alignItems="flex-start">
            <Box>
                <Box display={'flex'} justifyContent="space-between" marginBottom={'6px'}>
                    <Box><GoBack onClick={goBack}><ArrowBackIcon  /> Retour</GoBack></Box>
                    <Box fontSize="16px" fontFamily={theme.typography.fontFamilyBauhaus}><BorderColorIcon fontSize='16' /> Modifier</Box>
                </Box>
                <PdfWrap className="pdf" style={StyleSheet.flatten(styles.pdf)}>
                    <Table className="head">
                        <Tbody>
                            <Tr>
                                <Td>
                                    <Img src={logo} />
                                </Td>
                                <Td>
                                    <Table className="infos">
                                        <Tbody>
                                            <Tr className="b fz18">
                                                <Td align="right" className="paddingRight40">Bon d’Intervention N° </Td>
                                                <Td>1</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>

                    <Table className="details">
                        <Tbody>
                            <Tr>
                                <Td className="dt1">
                                    <Table>
                                        <Tbody>
                                            <Tr>
                                                <Td><Strong className='fz18'>Devis N° 1</Strong></Td>
                                            </Tr>
                                            <Tr>
                                                <Td><Strong>35 rue du Saint Lazare</Strong></Td>
                                            </Tr>
                                            <Tr>
                                                <Td>91140 VILLEJUST</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>(33) 77 87 56 87</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </Td>
                                <Td className="dt2">
                                    <Table>
                                        <Tbody>
                                            <Tr>
                                                <Td align='right' width="50%"><Strong>Date</Strong></Td>
                                                <Td>
                                                    <Strong>
                                                        <TextInput 
                                                            type="text" 
                                                            style={
                                                                {
                                                                    height: 25,
                                                                    paddingLeft: 10,
                                                                    paddingRight: 10,
                                                                    border: '1px solid '+theme.palette.primary.main,
                                                                    textAlign: 'center',
                                                                    color: '#fff',
                                                                    placeholderTextColor: '#fff',
                                                                }
                                                            } 
                                                            value="25/11/2022"
                                                            placeholder="--" 
                                                        />
                                                    </Strong>
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td align='right'><Strong>Durée</Strong></Td>
                                                <Td>
                                                    <TextInput 
                                                        type="text" 
                                                        style={
                                                            {
                                                                height: 25,
                                                                paddingLeft: 10,
                                                                paddingRight: 10,
                                                                border: '1px solid '+theme.palette.primary.main,
                                                                textAlign: 'center',
                                                                color: '#fff',
                                                                placeholderTextColor: '#fff',
                                                            }
                                                        } 
                                                        value="3 heurs"
                                                        placeholder="--" 
                                                    />
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td align='right'><Strong>Début</Strong></Td>
                                                <Td>
                                                    <TextInput 
                                                        type="text" 
                                                        style={
                                                            {
                                                                height: 25,
                                                                paddingLeft: 10,
                                                                paddingRight: 10,
                                                                border: '1px solid '+theme.palette.primary.main,
                                                                textAlign: 'center',
                                                                color: '#fff',
                                                                placeholderTextColor: '#fff',
                                                            }
                                                        } 
                                                        value="11h30"
                                                        placeholder="--" 
                                                    /> 
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td align='right'><Strong>Fin</Strong></Td>
                                                <Td>
                                                    <TextInput 
                                                        type="text" 
                                                        style={
                                                            {
                                                                height: 25,
                                                                paddingLeft: 10,
                                                                paddingRight: 10,
                                                                border: '1px solid '+theme.palette.primary.main,
                                                                textAlign: 'center',
                                                                color: '#fff',
                                                                placeholderTextColor: '#fff',
                                                            }
                                                        } 
                                                        value="14h30"
                                                        placeholder="--" 
                                                    /> 
                                                </Td>
                                            </Tr>
                                            
                                        </Tbody>
                                    </Table>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>

                    <Box display={'flex'} flexWrap={'wrap'} justifyContent="space-between">
                        <Box width={'calc(50% - 5px)'}>
                            <Typography variant="h2" className="h2">Client</Typography>
                            <Card className="card" minHeight="42px">
                                David Estèves
                            </Card>
                        </Box>
                        <Box width={'calc(50% - 5px)'}>
                            <Typography variant="h2" className="h2">Intervenant(s)</Typography>
                            <Card className="card" minHeight="42px" borderColor={theme.palette.secondary.main} color={theme.palette.secondary.main}>
                                
                            </Card>
                        </Box>
                    </Box>
                    <Typography variant="h2" className="h2" marginTop={'30px'}>Nature du problème</Typography>
                    <Card className="card" minHeight="auto">
                        - Recherche de fourreaux Telecom existants <Box component={'br'} />
                        - Aiguillage en partie privative du logement Client <Box component={'br'} />
                        - Recherche chambre intermédiaire (introuvable)
                    </Card>

                    <Table className="prodlist">
                        <Tbody>
                            <Tr>
                                <Td width="90">Réf</Td>
                                <Td>Désignation</Td>
                            </Tr>
                            <Tr>
                                <Td className="td b fz18 ffbauhaus">P0001</Td>
                                <Td>
                                    <Box className='ffbauhaus' fontSize="16px">
                                        Diagnostic sur site pour raccordement fibre optique suite à échec rencontré lors du raccordement opérateur sur partie privative du logement Client (offre comerciale).
                                    </Box>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td className="td b fz18">00001</Td>
                                <Td>
                                    <Box className="ffbauhaus" fontSize="16px">
                                        Travaux de raccrodement fibre optique sur partie privative du logement Client (Tarif forfaitaire pour une intervention ponctuelle)
                                    </Box>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                    
                    <Box fontFamily={theme.typography.fontFamilyBauhaus} display={'flex'} marginTop="50px">
                        <Box  flex="1" display={'flex'} alignItems="center" marginRight={'15px'}>
                            <Box marginRight={'10px'}>
                                Fait à 
                            </Box>
                            <Box fontFamily={theme.typography.fontFamily} flex="1">
                                <Card minHeight="auto" className='card' >
                                    Ullamcorper tristique laoreet diam lorem.
                                </Card>
                            </Box>
                        </Box>
                        <Box maxWidth="225px" flex="1" display={'flex'} alignItems="center">
                            <Box marginRight={'10px'}>
                                Le 
                            </Box>
                            <Box fontFamily={theme.typography.fontFamily} flex="1">
                                <Card minHeight="auto" className='card'>
                                    25/11/2021
                                </Card>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="_btssp">
                        <Box>
                            <Typography fontSize="16px">Signature de l’intervenant</Typography>
                            <Box className="_blcsntr">
                                Cliquer ici pour signer
                            </Box>
                        </Box>
                        <Box >
                            <Typography fontSize="16px">Signature du client</Typography>
                            <Box className="_blcsntr op15">
                                Cliquer ici pour signer
                            </Box>
                        </Box>
                    </Box>
                </PdfWrap>
            </Box>
        </Box>
    </ThemeProvider>
  )
}
