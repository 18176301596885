import { Box, styled } from "@mui/system";

const Figure = styled('div')(({theme, image}) => ({
    width: 56,
    height: 56,
    borderRadius: 56,
    backgroundImage: 'url('+image+')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: 10,
}));
const Name = styled('div')(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '22px',
    color: '#FFFFFF',
}));
const Address = styled('div')(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '22px',
    color: '#FFFFFF',
}));


import React from 'react'

export default function UserFigure(props) {
  return (
    <Box margin={'0 auto  0 0'} display={'flex'} alignItems="center" className="userfigure">
        {props.image && <Figure image={props.image} />}
        <Box>
            <Name >{props.name}</Name>
            <Address >{props.Address}</Address>
        </Box>
    </Box>
  )
}
