import React from "react";
import verticalLine from "../images/vertical-line.png";
import bgBottom from "../images/background-bottom.png";
import { StyleSheet } from "react-native";

let styles = StyleSheet.create({
  body: {
    backgroundImage: "linear-gradient(#F4F2F6, #fff)",
  },
  dottedLine: {
    paddingBottom: 20,
    marginBottom: 16,
    marginTop: 50,
    backgroundImage: "url(" + verticalLine + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
  },
  bgWrap: {
    paddingBottom: 150,
    backgroundImage: "url(" + bgBottom + ")",
    backgroundPosition: "bottom",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
  }
});

export default styles;
