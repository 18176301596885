import { Button, Typography, Box, Badge } from "@mui/material";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";

const CustomButton = styled(Box)(({ theme }) => ({
  position: "relative",
  height: theme.spacing(8),
  width: 293,
  cursor: "pointer",
  //border: "1px solid red",
  //backgroundImage:
  // "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
  backgroundColor: theme.palette.primary.main,
  // borderImageSlice: 1,
  // borderImageSource:
  //   "linear-gradient(180deg, #F4EB7C 0%, rgba(244, 235, 124, 0) 100%)",
  clipPath:
    "polygon(10% 0, 80% 0%, 100% 0, 100% 70%, 90% 100%, 20% 100%, 0 100%, 0 30%)",
}));

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  columnGap: theme.spacing(1.4),
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  height: theme.spacing(7.8),
  width: 291,
  backgroundColor: "black",
  clipPath:
    "polygon(10% 0, 80% 0%, 100% 0, 100% 70%, 90% 100%, 20% 100%, 0 100%, 0 30%)",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Brandon Grotesque",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "26px",
  /* identical to box height */
  textAlign: "center",
  textTransform: "uppercase",

  /* rb */

  background: "linear-gradient(99.47deg, #03EBFF 6.68%, #ECDD3B 85.19%)",

  backgroundClip: "text",
  textFillColor: "transparent",

  [theme.breakpoints.up("xs")]: {
    fontSize: 18,
  },
}));

const Icon = styled(CalendarTodayOutlined)(({ theme }) => ({
  background: "linear-gradient(99.47deg, #03EBFF 6.68%, #ECDD3B 85.19%)",

  backgroundClip: "text",
  textFillColor: "transparent",
}));

export default function SignButton({ handleClick, children }) {
  const { user } = useSelector((state) => state.userAuth);
  let userData = user.user?.data ? user.user?.data : user.data;
  return (
    <Badge
      badgeContent={0}
      color={"warning"}
      sx={{
        "& .MuiBadge-badge": {
          fontSize: 16,
          fontWeight: 700,
          fontFamily: "Bauhaus",
        },
      }}
    >
      <CustomButton onClick={handleClick} color={"inherit"}>
        <CustomBox>
          {/* <Icon /> */}
          <Text>
            {userData.role === "ROLE_PRESTATAIRE"
              ? "calendrier des rdv"
              : "calendrier"}
          </Text>
        </CustomBox>
      </CustomButton>
    </Badge>
  );
}
