import { styled } from "@mui/system";
import { useRoute } from "@react-navigation/native";
import {Box, FormControl} from "@mui/material";
import theme from "../../assets/theme";

const Input = styled("input", {
  shouldForwardProp: (prop) => prop !== "route",
})(({ theme, route, color }) => ({
  color: color ? color : "initial",
  textAlign: "center",
  outline: "none",
  fontSize: theme.spacing(1.8),
  height: theme.spacing(6),
  width: "100%",
  padding: theme.spacing(1, 2),
  border: "2px solid transparent",
  backgroundColor:
    route !== "home" ? "rgba(62, 195, 236, 0.02)" : "transparent",

  backgroundImage:
    route !== "home"
      ? "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)"
      : "none",
  borderImageSlice: 1,
  borderImageSource:
    "linear-gradient(360deg, #3EC3EC -36%, rgba(62, 195, 236, 0) 76%);",

  "&:focus, &:hover": {
    backgroundColor: "rgba(62, 195, 236, 0.08)",
    border: `2px dashed ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: theme.spacing(2.2),
  },
}));

export default function EmailInput({ register, color }) {
  const route = useRoute();
  return (
      <FormControl sx={{
        position: "relative",
        width: "100%",
        "&:focus, &:hover": {
          "& div": {
            display: "flex"
          }
        }
      }}>
        <Input
            type={"email"}
            placeholder={"EMAIL*"}
            route={route.name}
            color={color}
            {...register}
        />
        <Box sx={{
          display: 'none',
          width: 15,
          height: 15,
          borderTop: `4px solid ${theme.palette.primary.main}`,
          borderLeft: `4px solid ${theme.palette.primary.main}`,
          position: 'absolute',
          top: 0,
          left: 0,
        }}/>
        <Box sx={{
          display: 'none',
          width: 15,
          height: 15,
          borderTop: `4px solid ${theme.palette.primary.main}`,
          borderRight: `4px solid ${theme.palette.primary.main}`,
          position: 'absolute',
          top: 0,
          right: 0,
        }}/>
        <Box sx={{
          display: 'none',
          width: 15,
          height: 15,
          borderBottom: `4px solid ${theme.palette.primary.main}`,
          borderLeft: `4px solid ${theme.palette.primary.main}`,
          position: 'absolute',
          bottom: 0,
          left: 0,
        }}/>
        <Box sx={{
          display: 'none',
          width: 15,
          height: 15,
          borderBottom: `4px solid ${theme.palette.primary.main}`,
          borderRight: `4px solid ${theme.palette.primary.main}`,
          position: 'absolute',
          bottom: 0,
          right: 0,
        }}/>
      </FormControl>
  );
}
