import { useTheme } from "@emotion/react";
import { AddOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  FormControl,
  Input,
  InputLabel,
  ListSubheader,
  MenuItem,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";

import Select from "react-select";
import { EmailInput, LinkInput, TextInput } from "../../../components/inputs";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import partnersService from "../../../store/apis/partnersApis";
import ServicesService from "../../../store/apis/ServicesApi";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object({
  quantity: yup
    .number()
    .typeError("La quantité doit être une valeur numérique")
    .required("La quantité est requise"),
  prix: yup
    .number()
    .typeError("Le prix doit être une valeur numérique")
    .required("Le prix est requis"),
});
const schema1 = yup.object({
  quantity: yup
    .number()
    .typeError("La quantité doit être une valeur numérique")
    .required("La quantité est requise"),
  prix: yup
    .number()
    .typeError("Le prix doit être une valeur numérique")
    .required("Le prix est requis"),
  nom_service: yup.string().required("Le nom du service est requis"),
});

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const AddNewServiceForm = ({
  dialog,
  onClose,
  subServicesNew,
  setSubServicesNew,
}) => {
  const [selected, setSelected] = useState();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState({});
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(selected?.id === 0 ? schema1 : schema) });

  const onSubmit = (data) => {
    let newData = {
      service: selected,
      quantity: data.quantity,
      prix: data.prix,
      nom_service: data.nom_service,
    };
    /* console.log(newData)
    return */
    setSubServicesNew([...subServicesNew, ...[newData]]);
    setSelected();
    setValue("quantity", "");
    setValue("prix", "");
  };

  useEffect(() => {
    const listServices = [];
    ServicesService.listServices()
      .then((res) => {
        for (let i = 0; i < res.length; i++) {
          const El = res[i];
          listServices.push({
            value: El.id,
            label: El.libelle,
            id: El.id,
            libelle: El.libelle,
            description: El.description,
            prix: El.price,
          });
        }
        listServices.push({
          value: 0,
          label: "Autre",
          id: 0,
          libelle: "Autre",
          description: "Autre",
          prix: 0,
        });
        setServices(listServices);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChangeService = (newService) => {
    setSelected(newService);
    setValue("quantity", 1);
    setValue("prix", newService.prix);
  };

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 600, maxWidth: { md: 600, lg: 600 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            ajouter un service
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItem={"center"}
            justifyContent={"space-between"}
            rowGap={2}
            flexWrap={"wrap"}
          >
            <Box flexBasis={{ xs: "100%", md: "100%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Selectionner le service
                </Typography>
                <Box>
                  {/* <Controller
                    name="service"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="service-select-label">Select Service</InputLabel>
                        <Select
                          {...field}
                          labelId="service-select-label"
                          value={selected}
                          onChange={handleChangeService}
                        >
                          {categories.map((category) => (
                            <optgroup label={category.name} key={category.id}>
                              {category.options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </optgroup>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  /> */}
                  <Controller
                    name={"service"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={selected}
                        styles={customStyles}
                        placeholder={"Saisissez le nom du service ici"}
                        options={services}
                        onChange={handleChangeService}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            {selected?.id === 0 && (
              <>
                <Box flexBasis={{ xs: "100%", md: "100%" }}>
                  <Box mt={0.75}>
                    <Typography
                      variant="caption"
                      component={"label"}
                      fontSize={{ xs: 16 }}
                    >
                      Nom du service
                    </Typography>
                    <Box>
                      <TextInput
                        type={"text"}
                        register={{ ...register("nom_service") }}
                      />
                      {errors.nom_service && (
                        <Typography fontSize={{ xs: 14 }} color="red">
                          {errors.nom_service.message}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
              <Typography variant="caption" component={"label"}>
                Quantité*
              </Typography>
              <TextInput
                type={"number"}
                register={{ ...register("quantity") }}
              />
              {errors.quantity && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.quantity.message}
                </Typography>
              )}
            </Box>
            {selected && (
              <Box flex={{ xs: "0 1 100%", md: "0 1 49%" }}>
                <Typography variant="caption" component={"label"}>
                  Prix du service
                </Typography>
                <TextInput
                  placeholder={""}
                  register={{ ...register("prix") }}
                />
                {errors.prix && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.prix.message}
                  </Typography>
                )}
                {/* <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                    fontSize={18}
                  >
                    {selected.prix}
                  </Box> */}
              </Box>
            )}
            <Box flex={{ xs: "0 1 100%", md: "0 1 100%" }}> </Box>
            {selected && (
              <Box>
                <Box>
                  <Typography variant="caption" component={"label"}>
                    Description du service
                  </Typography>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                    fontSize={18}
                    lineHeight={"26px"}
                  >
                    {selected.description}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </form>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton type={"red"} handleClick={onClose}>
                terminer
              </CustomButton>
              <CustomButton
                type={"yellow"}
                textColor={"white"}
                handleClick={handleSubmit(onSubmit)}
              >
                ajouter
              </CustomButton>
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default AddNewServiceForm;
