import { useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import signinBg from "../../../../assets/images/signin_bg.png";
import { styled } from "@mui/system";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useTheme } from "@emotion/react";
import { RegisterHeading } from "../../../../components/headings";
import { EmailInput } from "../../../../components/inputs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import resetPasswordService from "../../../../store/apis/resetPasswordApi";

const schema = yup.object({
  email: yup
    .string()
    .email("Entrer un email valide")
    .required("L'email est requis"),
});

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
  height: "100%",
  backgroundColor: "#fff",
  backgroundImage: `url(${signinBg})`,
  backgroundSize: "100%",
  backgroundPosition: "bottom center",
  padding: theme.spacing(4, 1),
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("md")]: {
    backgroundImage: "none",
    padding: theme.spacing(2, 0),
  },
}));

export default function ForgotPassword({ handleDialog, setPage, setCongrat }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);
    resetPasswordService
      .resetPassword(data.email)
      .then((res) => {
        setLoading(false);
        setCongrat(true);

        setPage("Sign_In");
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        console.log(err);
      });
  };
  return (
    <>
      <BoxContainer>
        <Container maxWidth={isMd ? "sm" : "xs"}>
          <Box display={"flex"} flexDirection={{ xs: "column" }}>
            <Box component={"span"} alignSelf={{ xs: "center", md: "end" }}>
              <IconButton
                aria-label="close"
                size="large"
                onClick={handleDialog}
                sx={{
                  "& .closeIcon": {
                    fontSize: "30px",
                    color: "black",
                    transition: "all 0.5s ease-in-out",
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.closeButton,
                    "& .closeIcon": {
                      transform: "rotate(-90deg)",
                    },
                  },
                }}
              >
                <CloseRounded fontSize="large" className="closeIcon" />
              </IconButton>
            </Box>
            <Box mb={2} mt={{ md: -5 }}>
              <RegisterHeading>mot de passe oublié</RegisterHeading>
            </Box>
          </Box>
          <Box paddingLeft={{ md: 5 }} paddingRight={{ md: 5 }}>
            <Box mb={4}>
              <Typography
                textAlign={{ xs: "center", md: "left" }}
                fontSize={{ xs: 16, md: 18 }}
                lineHeight={{ xs: "23px", md: "26px" }}
                variant="body2"
              >
                Saisissez votre adresse e-mail, vous recevrez un nouveau mot de passe, utilise le pour se connecter et
                réinitialiser votre mot de passe.
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <EmailInput register={{ ...register("email") }} />
                {errors.email && (
                  <Typography
                    fontSize={{ xs: 14 }}
                    lineHeight={{ xs: "20px" }}
                    color="red"
                  >
                    {errors.email.message}
                  </Typography>
                )}

                {error && (
                  <Typography fontSize={{ xs: 18 }} color="red">
                    {error}
                  </Typography>
                )}

                <Box
                  display={"flex"}
                  mb={4}
                  mt={6}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <CustomButton
                        type={"red"}
                        handleClick={() => setPage("Sign_In")}
                      >
                        annuler
                      </CustomButton>
                      <CustomButton type="submit">réinitialiser</CustomButton>
                    </>
                  )}
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </BoxContainer>
    </>
  );
}
