import AppNavigation from "./src/navigation/AppNavigation";
import "./src/assets/translation/i18n";
import { Provider } from "react-redux";
import store from "./src/store";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { SafeAreaProvider } from "react-native-safe-area-context";

import theme from "./src/assets/theme";
import "./src/assets/translation/i18n";

export default function App() {
  return (
    <Provider store={store}>
      <SafeAreaProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppNavigation/>
        </ThemeProvider>
      </SafeAreaProvider>
    </Provider>
  );
}
