import axios from "axios";
import authHeader, { authHeaderDataForm } from "./authHeader";
import { base_url_api } from "../../config/keys";

const listPartners = async (page) => {
  try {
    const response = await axios.get(
      `${base_url_api}/users?page=${page}&roles=ROLE_PRESTATAIRE`,
      { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const addPartner = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/users`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const addAdminPartner = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/users`, data, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const editPartner = async (id, data) => {
  try {
    const response = await axios.put(
      `${base_url_api}/users/${id}`,
      {
        ...data,
      },
      { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const removePartner = async (id) => {
  try {
    const response = await axios.delete(`${base_url_api}/users/${id}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

const detailPartner = async (id) => {
  try {
    const response = await axios.get(`${base_url_api}/users/${id}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

const addFilesPartner = async (id, formData) => {
  try {
    const response = await axios.post(
      `${base_url_api}/user/${id}/files`,
      formData,
      {
        headers: authHeaderDataForm(),
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const partnersService = {
  addPartner,
  addAdminPartner,
  editPartner,
  removePartner,
  detailPartner,
  listPartners,
  addFilesPartner,
};

export default partnersService;
