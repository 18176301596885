import { Typography, Box } from "@mui/material";
import {styled} from "@mui/system";

const H2 = styled(Typography)(({theme}) => ({
    fontFamily: theme.typography.fontFamilyBold,
    fontSize: {xs: 18, md: 22},
    lineHeight: {xs: "24px", md: "29px"},
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    margin: "30px 0"
}))

function H2Legal({children}) {
    return (
        <H2 variant={"h2"} component={"h2"}>
            {children}
        </H2>
    )
}

export default H2Legal;