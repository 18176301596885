import { Box, Typography } from "@mui/material";
import client from "../../assets/images/client.png";
import star from "../../assets/images/star.png";

export default function TestimonialCard({ id }) {
  return (
    <Box
      width={{ xs: 325, md: 400 }}
      paddingTop={{ xs: 4, md: 6 }}
      paddingBottom={{ xs: 4, md: 6 }}
      paddingLeft={{ xs: 2, md: 3 }}
      paddingRight={{ xs: 2, md: 3 }}
      boxShadow={
        "inset -4px -5px 17px rgba(3, 235, 255, 0.43), inset 5px 4px 17px rgba(3, 235, 255, 0.43)"
      }
      mr={3}
      backgroundColor={"rgba(255, 255, 255, 0.2)"}
      id={id}
    >
      <Box
        display={"flex"}
        alignItems={"flex-start"}
        //justifyContent={"center"}
        mb={1}
      >
        <Box mr={2} ml={2}>
          <img src={client} alt={"client's testimonial"} />
        </Box>
        <Box>
          <Typography
            component={"h4"}
            variant={"body1"}
            fontSize={{ lg: 24 }}
            fontWeight={600}
          >
            Marie Sarp
          </Typography>
          <Typography
            component={"p"}
            variant={"body2"}
            fontSize={{ lg: 18 }}
            fontWeight={300}
            color={"textSecondary"}
          >
            Infrastructure manager
          </Typography>
        </Box>
      </Box>

      <Box diplay={"flex"}>
        <img src={star} alt={"star"} />
        <img src={star} alt={"star"} />
        <img src={star} alt={"star"} />
        <img src={star} alt={"star"} />
        <img src={star} alt={"star"} />
      </Box>
      <Box>
        <Typography component={"p"} variant={"body2"} fontSize={{ lg: 18 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed at
          pharetra tellus felis consequat morbi. Tortor risus facilisi ipsum in
          mauris, sociis nunc. Mattis quam tellus at massa pulvinar in lectus
          ullamcorper. Aliquet ac dictum lectus vitae. Nibh mollis imperdiet
          tristique nullam justo. Felis faucibus massa est sed ac pellentesque
          elementum sollicitudin rutrum. Leo malesuada gravida tristique congue.
          Quis in non euismod id.
        </Typography>
      </Box>
    </Box>
  );
}
